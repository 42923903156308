import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import { BsPlusLg, BsPersonCircle } from "react-icons/bs";
import { MdOutlineFilterCenterFocus } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "../css/NavBar.css";

function NavBar(props) {
  const navigate = useNavigate();
  const [activeModal, setActiveModal] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const handleCloseModal = () => {
    setActiveModal(null);
  };

  const handlePlusButtonClick = () => {
    if (props.currentCollection) {
      if (props.currentCollection.type === "collections") {
        setActiveModal("collection");
      } else if (props.currentCollection.type === "artefacts") {
        setActiveModal("artefact");
      } else {
        setActiveModal("choice");
      }
    } else {
      setActiveModal("collection");
    }
  };

  const handleFilterChange = (newFilter) => {
    props.setFilter(newFilter);
  };

  console.log("logged in username tttdrake", props.loggedInUsername);
  console.log("og username tttdrake", props.og_username);

  return (
    <div
      className="navbar-container"
      style={{ height: "0vh", marginTop: "44px" }}
    >
      <Navbar
        className="navbar-sub-container"
        style={{
          alignItems: "center",
          position: "fixed",
          zIndex: 1,
        }}
      >
        <Nav
          className="nav-whole"
          style={{
            marginTop: "0px",
            marginBottom: "2px",
            height: "47px",
            padding: "2px 5px",
            // padding: "6px 5px 67px 10px",
            // backgroundColor: "rgba(243, 243, 243, 0.95)",
            borderRadius: "10px",
            pointerEvents: "auto",
            userSelect: "none",
          }}
        >
          <div className="spacing-container-6" style={{ width: "1.2vw" }}></div>

          <div
            className="personal-public-container"
            style={{
              marginTop: "3px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              className="tabs"
              variant="light"
              onClick={() => {
                handleFilterChange("PUBLIC_ONLY");
                props.setIsPublicView(true);
              }}
              style={{
                background: "#EAEAEA",
                height: "18px",
                width: "67px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "16.5px",
                backgroundColor: props.isPublicView ? "#D6D6D6" : "#EAEAEA",
                border: props.isPublicView ? "1px solid #D6D6D6" : "EAEAEA",
                boxShadow: props.isPublicView
                  ? "0px 2px 4px rgba(0, 0, 0, 0.1)"
                  : "none",
              }}
            >
              <p
                className="tabs-text"
                style={{
                  marginTop: "7px",
                  color: props.isPublicView ? "#8E8E8E" : "#9D9D9D",
                  fontSize: "14px",
                  fontWeight: props.isPublicView ? "bold" : "normal",
                }}
              >
                Public
              </p>
            </Button>
            <Button
              className="tabs"
              variant="light"
              onClick={() => {
                handleFilterChange("ALL");
                props.setIsPublicView(false);
              }}
              style={{
                background: "#EAEAEA",
                height: "18px",
                width: "67px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "17px",
                backgroundColor: !props.isPublicView ? "#D6D6D6" : "#EAEAEA",
                border: !props.isPublicView ? "1px solid #D6D6D6" : "EAEAEA",
                boxShadow: !props.isPublicView
                  ? "0px 2px 4px rgba(0, 0, 0, 0.1)"
                  : "none",
              }}
            >
              <p
                className="tabs-text"
                style={{
                  marginTop: "4px",
                  color: !props.isPublicView ? "#8E8E8E" : "#9D9D9D",
                  fontSize: "14px",
                  fontWeight: !props.isPublicView ? "bold" : "normal",
                }}
              >
                Private
              </p>
            </Button>
          </div>

          <div className="spacing-container-1" style={{ width: "4vw" }}></div>
          <div>
            <Nav.Link
              href="/"
              className="explore-group"
              style={{ textAlign: "center", marginTop: "28px" }}
              draggable="false"
              onDragStart={(e) => e.preventDefault()}
            >
              <img
                src={process.env.PUBLIC_URL + "/exploreIcon2.svg"}
                style={{ width: "55%", height: "55%" }}
              />{" "}
              <br />{" "}
              <p
                className="explore-text"
                style={{
                  // marginTop: "-3px",
                  marginTop: "-3px",

                  color: "#9D9D9D",
                  fontSize: "15px",
                }}
              >
                Explore
              </p>
            </Nav.Link>
          </div>
          <div className="spacing-container-2" style={{ width: "4vw" }}></div>
          <div className="plus-button-icon" style={{ marginTop: "5.7px" }}>
            <Button
              className="add-icon"
              variant="light"
              onClick={handlePlusButtonClick}
              disabled={props.isPublicView}
              style={{
                // height: "52px",
                // width: "68px",
                height: "45px",
                width: "57px",
                borderRadius: "12%",
                backgroundColor: "#E4E4E4",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "20px",
                border: "none",
                color: "#CFD2CF",
                cursor: "pointer",
              }}
            >
              <BsPlusLg size={22} color="#9D9D9D" />
            </Button>
          </div>
          <div className="spacing-container-3" style={{ width: "4vw" }}></div>
          <div>
            <Nav.Link
              href="/"
              className="friends-group"
              style={{
                textAlign: "center",
                marginTop: "29px",
              }}
              onClick={() => setActiveModal("friends")}
              draggable="false"
              onDragStart={(e) => e.preventDefault()}
            >
              <img
                src={process.env.PUBLIC_URL + "/friends-icon2.svg"}
                style={{
                  width: "65%",
                  height: "65%",
                }}
                // draggable="false"
                // onDragStart={(e) => e.preventDefault()}
              />{" "}
              <br />{" "}
              <p
                className="friends-text"
                style={{
                  marginTop: "-6px",
                  color: "#9D9D9D",
                  fontSize: "15px",
                }}
              >
                Friends
              </p>
            </Nav.Link>
          </div>
          <div className="spacing-container-4" style={{ width: "4vw" }}></div>
          <div>
            <Nav.Link href="/" style={{ padding: 0 }}>
              <Button
                variant="link"
                className="profile-button"
                onClick={() => setActiveModal("profile")}
                style={{
                  fontSize: "30px",
                  color: "#CFD2CF",
                  textDecoration: "none",
                  marginTop: "-3px",
                }}
              >
                <BsPersonCircle />
              </Button>
            </Nav.Link>
          </div>
          <div className="spacing-container-4" style={{ width: "0.5vw" }}></div>
        </Nav>
        <div
          className="center-button-container"
          style={{
            position: "absolute",
            left: "107%",

            transform: "translateX(-50%)",
            top: "16px", // Adjust this value to place the button vertically where you want it
            pointerEvents: "auto",
            opacity: props.isScreenCentered ? 0 : 1,
            transition: "opacity 0.5s ease-in-out",
            zIndex: 2, // Ensure it is above other elements
            // marginLeft: "320px",
          }}
        >
          <button
            style={{
              border: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            onClick={props.centerScreenHandler}
          >
            <MdOutlineFilterCenterFocus size={30} color="#9D9D9D" />
          </button>
        </div>
      </Navbar>
    </div>
  );
}

export default NavBar;
