import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../../css/CreateCollectionForm.css";

function CreateCollectionForm(props) {
  const nameRef = useRef(null);
  const [collectionName, setCollectionName] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const name = nameRef.current.value;
    // props.setCollectionData(name);

    props.setCollectionData({
      collectionName: name,
      collectionId: null,
    });

    console.log("yeye props.collectionData", props.collectionData);

    props.handleCreateButtonClick();
  };

  const handleInputChange = (e) => {
    setCollectionName(e.target.value); // Update collection name on input change
  };

  return (
    <Form
      className="collections-form"
      onSubmit={handleFormSubmit}
      style={{ userSelect: "none" }}
    >
      <div className="here">
        <Form.Group className="input-box">
          <div
            style={{ marginTop: "-80px", marginLeft: "-50px", nowrap: "true" }}
          ></div>
          <div
          // stykle={{
          //   marginLeft: "-50px",
          //   display: "flex",
          //   alignItems: "center",
          //   // marginLeft: "-50px",
          // }}
          >
            <Form.Label
              style={{
                fontFamily: "raleway",
                fontSize: "27px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Collection
            </Form.Label>
          </div>
          <div style={{ height: "4vh" }}></div>

          <Form.Control
            type="text"
            ref={nameRef}
            onChange={handleInputChange}
            placeholder="Enter Collection Name"
            className="create-collection-input"
            size="sm"
            autoFocus
            required
            style={{ fontFamily: "raleway", fontSize: "16px" }}
          />
        </Form.Group>
        <div style={{ height: "1.4vh" }}></div>

        <div
          className="create-button-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "0vw",
          }}
        >
          <Button
            className="create-collection-button"
            variant="primary"
            type="submit"
            onClick={() => {
              props.handleClose();
            }}
            disabled={!collectionName}
            style={{
              backgroundColor: "#79b8ff",
              border: "none",
              marginTop: "0px",
              fontFamily: "raleway",
            }}
          >
            Create
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default CreateCollectionForm;
