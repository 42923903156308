import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import emailjs from "@emailjs/browser"; // Import EmailJS
import "../../css/ContactUsModal.css"; // Adjust path as needed

const ContactUsModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleModalClose = () => {
    setFormData({
      name: "",
      email: "",
      message: "",
    });
    handleClose(); // Call the original handleClose function to close the modal
  };

  const isFormValid = () => {
    return formData.name && formData.email && formData.message;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const templateParams = {
      to_name: "Benjamin", // Replace with the recipient's name
      from_name: formData.name,
      from_email: formData.email, // Add the email to the template parameters
      message: formData.message,
    };

    emailjs
      .send(
        "service_zhgbju5", // Replace with your EmailJS service ID
        "template_ql66wxx", // Replace with your EmailJS template ID
        templateParams,
        "PKGfKSnn5_Cg-Yld6" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setFormData({
            name: "",
            email: "",
            message: "",
          });
          handleClose(); // Close the modal after submission
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };

  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      size="lg"
      centered
      className="profile-modal no-select"
      style={{ fontFamily: "raleway" }}
    >
      <Modal.Header
        className="no-select"
        closeButton
        style={{ marginRight: "5px" }}
      >
        <Modal.Title style={{ marginLeft: "1vw" }}>Contact Us</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="profile-modal-body"
        style={{ display: "flex", padding: 0 }}
      >
        <div
          className="d-flex"
          style={{
            height: "90%",
            width: "70%",
            marginTop: "0px",
            overflow: "hidden",
          }}
        >
          <Form onSubmit={handleSubmit} className="w-100">
            <Form.Floating className="mb-3">
              <Form.Control
                type="text"
                id="floatingInputName"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                style={{ height: "53px" }}
                required
              />
              <Form.Label htmlFor="floatingInputName">
                Name{" "}
                <span
                  className="required-asterisk"
                  style={{ position: "absolute" }}
                >
                  *
                </span>
              </Form.Label>
            </Form.Floating>

            <Form.Floating className="mb-3">
              <Form.Control
                type="email"
                id="floatingInputEmail"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                style={{ height: "53px" }}
                required
              />
              <Form.Label htmlFor="floatingInputEmail">
                Email{" "}
                <span
                  className="required-asterisk"
                  style={{ position: "absolute" }}
                >
                  *
                </span>
              </Form.Label>
            </Form.Floating>

            <Form.Floating className="mb-3">
              <Form.Control
                as="textarea"
                id="floatingTextareaMessage"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Message"
                style={{
                  height: "200px", // Initial height
                  resize: "vertical", // Allow resizing only vertically
                  maxHeight: "200px", // Maximum height
                  minHeight: "200px", // Minimum height
                  maxWidth: "100%", // Maximum width
                  minWidth: "100%", // Minimum width
                }}
                required
              />
              <Form.Label htmlFor="floatingTextareaMessage">
                Message
                <span
                  className="required-asterisk"
                  style={{ position: "absolute" }}
                >
                  *
                </span>
              </Form.Label>
            </Form.Floating>

            <Button
              variant="secondary"
              onClick={handleModalClose}
              style={{ marginRight: "10px" }}
            >
              Close
            </Button>
            <Button
              style={{ backgroundColor: "#79b8ff", border: "none" }}
              variant="primary"
              type="submit"
              disabled={!isFormValid()}
            >
              Submit
            </Button>
          </Form>
        </div>
      </Modal.Body>
      <p
        className="no-select"
        style={{
          marginTop: "auto",
          textAlign: "center",
          padding: "10px 0",
          fontSize: "16px",
          width: "100%",
          // backgroundColor: "#f8f9fa",
          // borderTop: "1px solid #dee2e6",
        }}
      >
        Or contact us at{" "}
        <a href="mailto:contact@showcase-app.co" style={{ color: "#007bff" }}>
          contact@showcase-app.co
        </a>
      </p>
    </Modal>
  );
};

export default ContactUsModal;
