import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import NavBar_sharelink from "../components/NavBar_sharelink";
import Home_navbar from "../components/Home_Navbar";
import { PannableBox } from "../components/PannableBox";
import logoIcon from "../logo_icon.png";
import logoShowcase from "../logo_showcase.png";
import { MdOutlineFilterCenterFocus } from "react-icons/md";
// import "../css/Collection.css";
import "../css/SharelinkView.css";
import FadeTransition from "../components/FadeTransition";

function SharelinkView(props) {
  // props.setSharelinkUsername(true);

  const { linkId } = useParams();
  const [artefacts, setArtefacts] = useState([]);
  const [thisCollection, setThisCollection] = useState(null);
  const [droppedImageFile, setDroppedImageFile] = useState(null);
  const [zoomedInFlag, setZoomedInFlag] = useState(false);
  const [isMovingArtefact, setIsMovingArtefact] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLinkValid, setIsLinkValid] = useState(false);
  const [showValidMessage, setShowValidMessage] = useState(false);
  const [durationMessage, setDurationMessage] = useState("");
  const [moveUpZoomSlider, setMoveUpZoomSlider] = useState(true);

  const [sharelinkUsername, setSharelinkUsername] = useState("");
  const [sharelinkFullname, setSharelinkFullname] = useState("");

  const [sharelinkOwner, setSharelinkOwner] = useState("");

  // const fetchArtefactContent = async (artefact) => {
  //   if (artefact.content_type === "image" || artefact.content_type === "pdf") {
  //     try {
  //       // Fetch content from the backend API
  //       const response = await fetch(`/api/sharelink/artefact/${artefact.artefact_id}/content`);

  //       if (!response.ok) {
  //         throw new Error("Failed to fetch artefact content.");
  //       }

  //       const blob = await response.blob();
  //       const contentUrl = URL.createObjectURL(blob);
  //       console.log("Fetched content URL:", contentUrl);

  //       return { ...artefact, content: contentUrl };
  //     } catch (error) {
  //       console.error("Error fetching artefact content:", error);
  //       return artefact; // Return artefact as is in case of failure
  //     }
  //   }

  //   return artefact;
  // };

  // const fetchSharedData = () => {
  //   fetch(`/api/sharelink?link=${linkId}`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         // If the response status is 404, display a custom message
  //         if (response.status === 404) {
  //           throw new Error("This sharelink has expired.");
  //         }
  //         // Handle other types of errors, if necessary
  //         throw new Error("Error");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setThisCollection({
  //         type: data.type,
  //         collection_name: data.collection_name || "Unnamed Collection",
  //         parent_id: data.parent_id,
  //       });

  //       if (data.owner) {
  //         console.log("yessssssir", data);
  //         setSharelinkUsername(data.owner);
  //         setSharelinkFullname(data.full_name);
  //       }
  //       // sharelinkUsername(data.owner);

  //       console.log("Data yyy: ", data);

  //       const durationMessage = data.account_public ? "2 months " : "48 hours ";

  //       // if (data.type === "artefacts") {
  //       //   setArtefacts(data.data || []);
  //       // } else {
  //       //   setArtefacts([]);
  //       // }

  //       if (data.type === "artefacts") {
  //         // Map the artefacts to fetch their content and update state
  //         const artefactsWithContent = await Promise.all(
  //           data.data.map(fetchArtefactContent)
  //         );
  //         setArtefacts(artefactsWithContent || []);
  //       } else {
  //         setArtefacts([]);
  //       }

  //       setIsLinkValid(true);

  //       // Delay showing the valid message by 500ms
  //       setTimeout(() => {
  //         setShowValidMessage(true);

  //         // Hide the valid message after 4 seconds (4000ms after it's shown)
  //         setTimeout(() => {
  //           setShowValidMessage(false);
  //         }, 4000);
  //       }, 500); // Delay of 500ms
  //       setDurationMessage(durationMessage);
  //     })
  //     .catch((error) => {
  //       setErrorMessage(error.message);
  //       console.log("Fetch error: ", error);
  //     });
  // };

  // const fetchArtefactContent = async (artefact) => {
  //   if (artefact.content_type === "image" || artefact.content_type === "pdf") {
  //     try {
  //       // Fetch content from the backend API
  //       // const response = await fetch(
  //       //   `/api/sharelink/artefact/${artefact.artefact_id}/content`
  //       // );

  //       console.log(
  //         "props.loggedInUsername hesreeee",
  //         props.loggedInUsername,
  //         sharelinkUsername
  //       );

  //       // const response = await fetch(
  //       //   `/api/user/${props.loggedInUsername}/artefact/${artefact.artefact_id}/content`
  //       // );

  //       // const response = await fetch(
  //       //   `/api/sharelink/artefact/${artefact.artefact_id}/content`
  //       // );
  //       // const response = await fetch(
  //       //   `/api/sharelink/content?link=${linkId}&artefact_id=${artefact.artefact_id}`
  //       // );
  //       // const response = await fetch(
  //       //   `/api/sharelink/content?link=${linkId}&artefact_id=${artefact.artefact_id}`
  //       // );
  //       const response = await fetch(
  //         `/api/sharelink/content?link=${linkId}&artefact_id=${artefact.artefact_id}`
  //       );

  //       if (!response.ok) {
  //         throw new Error("Failed to fetch artefact content.");
  //       }

  //       const blob = await response.blob();
  //       const contentUrl = URL.createObjectURL(blob);
  //       console.log("Fetched content URL:", contentUrl);

  //       return { ...artefact, content: contentUrl };
  //     } catch (error) {
  //       console.error("Error fetching artefact content:", error);
  //       return artefact; // Return artefact as is in case of failure
  //     }
  //   }

  //   return artefact;
  // };

  const fetchArtefactContent = async (artefact) => {
    try {
      const response = await fetch(
        `/api/sharelink/content?link=${linkId}&artefact_id=${artefact.artefact_id}`
      );

      if (
        artefact.content_type === "image" ||
        artefact.content_type === "pdf"
      ) {
        const blob = await response.blob();
        const contentUrl = URL.createObjectURL(blob);
        return { ...artefact, content: contentUrl };
      } else if (artefact.content_type === "website") {
        const data = await response.json();
        if (data.hasOwnProperty("data") && typeof data.data === "string") {
          return {
            ...artefact,
            content: data.data,
          };
        } else {
          return {
            ...artefact,
            content: data,
          };
        }
      } else if (artefact.content_type === "text") {
        const data = await response.json();
        return { ...artefact, content: data.data };
      } else {
        return artefact;
      }
    } catch (error) {
      console.error("Error fetching artefact content:", error);
      return artefact;
    }
  };

  const fetchSharedData = () => {
    // fetch(`/api/sharelink?link=${linkId}`)
    fetch(`/api/sharelink?link=${linkId}`)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("This sharelink has expired.");
          }
          throw new Error("Error");
        }
        return response.json();
      })
      .then(async (data) => {
        console.log("Fetched data shhhar:", data);
        setThisCollection({
          type: data.type,
          collection_id: data.collection_id,
          collection_name: data.collection_name || "Unnamed Collection",
          parent_id: data.parent_id,
        });

        if (data.owner) {
          setSharelinkUsername(data.owner);
          setSharelinkFullname(data.full_name);
        }

        const durationMessage = data.account_public ? "2 months " : "48 hours ";

        if (data.type === "artefacts") {
          // Map the artefacts to fetch their content and update state
          const artefactsWithContent = await Promise.all(
            data.data.map(fetchArtefactContent)
          );
          setArtefacts(artefactsWithContent || []);
        } else {
          setArtefacts([]);
        }

        setIsLinkValid(true);

        setTimeout(() => {
          setShowValidMessage(true);
          setTimeout(() => {
            setShowValidMessage(false);
          }, 4000);
        }, 500);
        setDurationMessage(durationMessage);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        console.log("Fetch error: ", error);
      });
  };

  useEffect(() => {
    fetchSharedData();
  }, [linkId, sharelinkUsername]);

  return (
    <FadeTransition in={true}>
      <div className="collection-contents no-select">
        <div style={{ position: "relative" }}>
          {" "}
          <Home_navbar />
          <div
            className="center-button-container"
            style={{
              position: "absolute", // Changed from 'fixed' to 'absolute'
              left: "calc(50% + 208px)", // Align it exactly 10px to the right of the navbar
              top: "30px", // Align with the navbar's top position
              // pointerEvents: "auto",
              pointerEvents: props.isScreenCentered ? "none" : "auto",
              opacity: props.isScreenCentered ? 0 : 1,
              transition: "opacity 0.5s ease-in-out",
              zIndex: 11, // Ensure it is above other elements
            }}
          >
            <button
              style={{
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                outline: "none",
              }}
              onClick={props.centerScreenHandler}
            >
              <MdOutlineFilterCenterFocus size={30} color="#9D9D9D" />
            </button>
          </div>
          {/* {errorMessage && (
            <div
              className="error-message"
              style={{
                position: "absolute",
                left: "calc(50% - 96px)",
                color: "red",
                textAlign: "center",
                marginTop: "87px",
                flexShrink: 0,
              }}
            >
              <p>{errorMessage}</p>
            </div>
          )} */}
          {errorMessage ? (
            <div
              className="error-message"
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                color: "red",
                textAlign: "center",
                marginTop: "87px",
                flexShrink: 0,
                zIndex: 100,
              }}
            >
              <p>{errorMessage}</p>
            </div>
          ) : (
            isLinkValid && (
              <div
                className={`valid-message ${showValidMessage ? "show" : ""}`}
              >
                <p>
                  This link is valid for {durationMessage} after{" "}
                  <span style={{ position: "relative", top: "-2px" }}>@</span>
                  {sharelinkUsername} shared this collection.
                </p>
              </div>
            )
          )}
        </div>
        <div
          className="logo-container-sharelink no-select"
          style={{
            display: "flex",
            flexDirection: "column", // Stack the content vertically
            alignItems: "center", // Center horizontally
            position: "fixed", // Fixes the logo to the bottom of the page
            bottom: "4px", // Adjust positioning from the bottom
            left: "50%", // Horizontally center
            transform: "translateX(-50%)", // Ensure it's centered properly
            zIndex: 1000, // Ensure it stays on top of other content
            pointerEvents: "none", // Prevent interaction with the logo
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={logoIcon}
              style={{
                height: "43px",
                marginTop: "11px",
                marginLeft: "2px",
              }}
              alt="Logo Icon-sharelink"
              className="logo-icon-sharelink"
            />
            <img
              src={logoShowcase}
              style={{
                height: "65px",
                marginTop: "0px",
                marginLeft: "7px",
              }}
              alt="Logo Showcase-sharelink"
              className="logo-showcase-sharelink"
            />
          </div>

          <div
            className="url-text-sharelink"
            style={{
              marginTop: "-10px",
              fontSize: "14px",
              marginBottom: "5px",
              color: "#000",
              textAlign: "center",
              pointerEvents: "auto",
            }}
          >
            showcase-app.co
          </div>
        </div>

        <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
          <PannableBox
            collections={[]}
            placingMode={false}
            setPlacingMode={props.setPlacingMode}
            getter={props.submittedForm}
            setter={props.setSubmittedForm}
            currentCollection={thisCollection || {}}
            collectionData={props.collectionData || {}}
            setCollectionData={props.setCollectionData}
            artefactData={props.artefactData || {}}
            artefacts={artefacts}
            setArtefacts={setArtefacts}
            setArtefactData={props.setArtefactData}
            setCollections={() => {}}
            placeArtefactsTrigger={props.placeArtefactsTrigger}
            setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
            centerScreen={props.centerScreen}
            setCenterScreen={props.setCenterScreen}
            setIsScreenCentered={props.setIsScreenCentered}
            loggedInUsername={null}
            handleCreateButtonClick={props.handleCreateButtonClick}
            isPublicView={true}
            otherUser={null}
            zoomedInFlag={zoomedInFlag}
            setZoomedInFlag={setZoomedInFlag}
            isOwner={false}
            og_username={props.og_username}
            droppedImageFile={droppedImageFile}
            setDroppedImageFile={setDroppedImageFile}
            setIsMovingArtefact={setIsMovingArtefact}
            sharelinkUsername={sharelinkUsername}
            setSharelinkUsername={setSharelinkUsername}
            sharelinkFullname={sharelinkFullname}
            setSharelinkFullname={setSharelinkFullname}
            moveUpZoomSlider={moveUpZoomSlider}
          />
        </div>
      </div>
    </FadeTransition>
  );
}

export default SharelinkView;
