export const deleteArtefact = async (username, artefact_id) => {
  try {
    await fetch(`/api/user/${username}/artefact/${artefact_id}`, {
      method: "DELETE",
      credentials: "same-origin",
    });
    return true;
  } catch (error) {
    console.error("API call failed:", error);
    return false;
  }
};
