import React, { useState, useEffect, useRef } from "react";
import Collection from "./Collection";
import ContextMenuCollections from "./ContextMenus/ContextMenuCollections";
import "../css/CollectionsList.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { deleteCollection } from "../api/deleteCollection";
import { updateCollectionName } from "../api/updateCollectionName";
// import updateCollectionName from "../api/updateCollectionName";

function CollectionsList(props) {
  const collections = props.collections;
  // const collections = props.collections.filter((col) =>
  //   props.isPublicView ? col.public_count > 0 : true
  // );

  const cellSize = props.cellSize;

  console.log("allCollections: ", props.allCollections);

  const [attemptingToCloseMenu, setAttemptingToCloseMenu] = useState(false);

  const [longPressTimeout, setLongPressTimeout] = useState(null);
  const [contextMenuOpened, setContextMenuOpened] = useState(false);
  const [isSubMenuOpen, setSubMenuOpen] = useState(false); // Separate state for submenu visibility
  const [submenuStack, setSubmenuStack] = useState([]); // Stack to keep track of submenus
  const [isChangeCollectionPressed, setChangeCollectionPressed] =
    useState(false);

  const pannableContainerRef = useRef(null);

  const [contextMenuState, setContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
    collection_id: null,
  });

  // const [editingCollectionId, setEditingCollectionId] = useState(null);

  const handleEditCollection = (collectionId) => {
    props.setEditingCollectionId(collectionId);
  };

  const editCollectionName = (collectionId, newName) => {
    const trimmedName = newName.trim();

    // Validate the trimmed name: it should not be empty
    if (!trimmedName) {
      console.error("Collection name cannot be empty.");
      alert("Collection name cannot be empty."); // Show an alert or replace this with a more user-friendly UI
      return; // Exit the function early to prevent the update
    }
    const updatedCollections = collections.map((collection) =>
      collection.collection_id === collectionId
        ? { ...collection, collection_name: newName }
        : collection
    );
    props.setCollections(updatedCollections);
    props.setEditingCollectionId(null); // Exit editing mode

    // Use the API function to update the collection name in the backend
    updateCollectionName(props.loggedInUsername, collectionId, newName)
      .then((data) => {
        console.log("Collection name updated successfully", data);
        // Handle successful update
      })
      .catch((error) => {
        console.error("Error updating collection name:", error);
        // Handle error
      });
  };

  const handleMoveCollection = (collectionId) => {
    console.log("Attempting to move collection with ID:", collectionId);
    const collectionToMove = collections.find(
      (col) => col.collection_id === collectionId
    );

    if (!collectionToMove) {
      console.error("Collection not found");
      return;
    }
    console.log("Collection to move:", collectionToMove);

    console.log(
      "dada",
      collectionToMove.collection_id,
      collectionToMove.collection_name
    );

    props.setCollectionData({
      collectionName: collectionToMove.collection_name,
      collectionId: collectionToMove.collection_id,
    });

    console.log("dada2", props.collectionData);

    const remainingCollections = collections.filter(
      (col) => col.collection_id !== collectionId
    );
    props.setCollections(remainingCollections);

    props.handleCreateButtonClick();

    console.log("Updated collections list:", remainingCollections);
  };

  // const changeCollection = async (collectionId, newParentCollectionId) => {
  //   try {
  //     if (!collectionId) {
  //       console.error("Invalid collection.");
  //       return;
  //     }

  //     // If newParentCollectionId is 'root', set it to null

  //     console.log("newParentCollectionId: ", newParentCollectionId);
  //     const parentId =
  //       newParentCollectionId === "root" ? null : newParentCollectionId;

  //     // Step 1: Update the parent_id and reset coordinates when moving the collection
  //     const response = await fetch(
  //       `/api/user/${props.loggedInUsername}/collection/${collectionId}/change_parent`,
  //       {
  //         method: "PATCH",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           new_parent_id: parentId,
  //           coordx_private: null, // Reset the private x-coordinate
  //           coordy_private: null, // Reset the private y-coordinate
  //           // coordx_public: null, // Reset the public x-coordinate
  //           // coordy_public: null, // Reset the public y-coordinate
  //         }),
  //       }
  //     );

  //     if (response.ok) {
  //       console.log(
  //         `Collection ${collectionId} moved to ${
  //           newParentCollectionId === "root" ? "root" : newParentCollectionId
  //         } and coordinates reset successfully.`
  //       );

  //       // Step 2: Update collections state with new parent_id and reset coordinates
  //       props.setCollections((prevCollections) => {
  //         return prevCollections.map((col) => {
  //           if (col.collection_id === collectionId) {
  //             return {
  //               ...col,
  //               parent_id: parentId, // Set parent_id to null if moved to root
  //               coordx_private: null,
  //               coordy_private: null,
  //               // coordx_public: null,
  //               // coordy_public: null,
  //             };
  //           } else if (col.collection_id === newParentCollectionId) {
  //             return col.type !== "collections"
  //               ? { ...col, type: "collections" }
  //               : col;
  //           }
  //           return col;
  //         });
  //       });
  //     } else {
  //       console.error("Failed to move collection.");
  //     }
  //   } catch (error) {
  //     console.error("Error in moving collection:", error);
  //   }
  // };

  const handleTouchStart = (event, colId, col) => {
    event.preventDefault(); // Prevent default touch behavior

    console.log("Touch start for collection:", col); // Debug the touch event

    // Ensure that `col` is correctly received
    if (!col) {
      console.error("Invalid collection object on touch start.");
      return;
    }

    const timeoutId = setTimeout(() => {
      handleRightClick(event, colId, col); // Ensure `col` is passed here
      setContextMenuOpened(true);
    }, 400); // Adjust the duration for a long press as needed

    setLongPressTimeout(timeoutId);
  };

  const handleTouchEnd = () => {
    // Clear the long press timeout without altering context menu visibility
    clearTimeout(longPressTimeout);
    setLongPressTimeout(null); // Reset timeout state
  };

  const handleTouchCancel = () => {
    clearTimeout(longPressTimeout);
    setLongPressTimeout(null); // Reset timeout state
  };

  const handleRightClick = (event, colId, col) => {
    console.log("bbbbbbbb  Opening context menu for collection:", colId);
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    console.log("Is Mobile:", isMobile);

    // Add logs to check the event flow on mobile
    console.log(
      "Handling right click/touch:",
      event.type,
      "on mobile:",
      isMobile
    );
    console.log("Collection ID:", colId);
    console.log("Collection (col):", col);

    if (!colId) {
      console.error("Invalid collection_id for right click.");
      return;
    }

    if (!props.isOwner) {
      event.preventDefault();
      return;
    }

    event.preventDefault();

    let x, y;

    if (event.type === "touchstart" || event.type === "touchend") {
      const touch = event.changedTouches[0];
      x = touch.clientX;
      y = touch.clientY;
    } else {
      x = event.clientX;
      y = event.clientY;
    }

    const rect = pannableContainerRef.current.getBoundingClientRect();
    // const relativeX = x - rect.left;
    // const relativeY = y - rect.top;
    const relativeX = (x - rect.left) / props.zoomLevel; // props.zoomLevel is the current zoom level
    const relativeY = (y - rect.top) / props.zoomLevel;

    setSubMenuOpen(false);
    setChangeCollectionPressed(false);
    setSubmenuStack([]);

    setContextMenuState({
      visible: true,
      x: relativeX,
      y: relativeY,
      collection_id: colId,
      col: col,
    });
  };

  const hideContextMenu = () => {
    setContextMenuState({
      visible: false,
      x: 0,
      y: 0,
      collection_id: null,
    });
    setContextMenuOpened(false);
    setSubMenuOpen(false);
    setSubmenuStack([]);
  };

  const handleTouchMove = () => {
    // Hide the context menu on touch move
    if (contextMenuState.visible && !isSubMenuOpen) {
      hideContextMenu();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the context menu is visible and the click is outside the context menu
      if (
        contextMenuState.visible &&
        pannableContainerRef.current &&
        !pannableContainerRef.current.contains(event.target)
      ) {
        // hideContextMenu();
        if (!isSubMenuOpen) {
          hideContextMenu();
        }
      }
    };

    if (contextMenuState.visible) {
      // Add event listener when the context menu is visible
      document.addEventListener("click", handleClickOutside);
      document.addEventListener("touchmove", handleTouchMove);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, [contextMenuState.visible, isSubMenuOpen]);

  const handleDeleteCollection = async (collection_id) => {
    console.log("bbbbbbbb YES", collection_id);

    if (!collection_id) {
      console.error(" bbbbbbbb No collection_id provided, aborting deletion.");
      return;
    }

    try {
      const success = await deleteCollection(
        props.loggedInUsername,
        collection_id
      );

      if (success) {
        const updatedCollections = collections.filter(
          (col) => col.collection_id !== collection_id
        );
        props.setCollections(updatedCollections);
        hideContextMenu();
      } else {
        console.error("Failed to delete collection via API");
      }
    } catch (error) {
      console.error("Failed to delete collection: ", error);
    }
  };

  const listCollections = () => {
    const collectionsJSX = [];
    console.log("collections in CollectionsList: ", collections);
    if (collections != null) {
      //   if (Array.isArray(collections)) {
      //     collections.forEach((col) => {
      //       const { coordx_private: x, coordy_private: y } = col;

      //       if (x === undefined || y === undefined) {
      //         return;
      //       }

      if (Array.isArray(collections)) {
        collections.forEach((col) => {
          // Choose coordinates based on the isPublicView prop
          const x = props.isPublicView ? col.coordx_public : col.coordx_private;
          const y = props.isPublicView ? col.coordy_public : col.coordy_private;

          // Log for debugging purposes, can be removed or commented out in production
          console.log(
            `Rendering collection ${col.collection_id} at x: ${x}, y: ${y}`
          );

          // Skip collections without valid coordinates for the current view
          if (x === undefined || y === undefined || x === null || y === null) {
            return;
          }
          collectionsJSX.push(
            <CSSTransition
              key={col.collection_id}
              timeout={300}
              classNames="collection-transition"
            >
              <Collection
                key={col.collection_id}
                col={col}
                cellSize={cellSize}
                // handleRightClick={handleRightClick}
                handleRightClick={(event) =>
                  handleRightClick(event, col.collection_id, col)
                }
                handleTouchStart={(event) =>
                  handleTouchStart(event, col.collection_id, col)
                }
                handleTouchEnd={handleTouchEnd}
                handleTouchCancel={handleTouchCancel}
                attemptingToCloseMenu={attemptingToCloseMenu}
                setAttemptingToCloseMenu={setAttemptingToCloseMenu}
                contextMenuOpened={contextMenuOpened}
                setContextMenuOpened={setContextMenuOpened}
                otherUser={props.otherUser}
                isPublicView={props.isPublicView}
                isOwner={props.isOwner}
                isEditing={props.editingCollectionId === col.collection_id}
                editCollectionName={editCollectionName}
                pannableBoxHeight={props.pannableBoxHeight}
                pannableBoxWidth={props.pannableBoxWidth}
                isPlacementCooldown={props.isPlacementCooldown}
              />
            </CSSTransition>
          );
        });
      } else {
        console.error(
          "Expected collections to be an array but received:",
          collections
        );
      }
    }
    return collectionsJSX;
  };

  return (
    <div ref={pannableContainerRef}>
      {/* {collections && listCollections().map((i) => i)} */}
      <TransitionGroup>{listCollections()}</TransitionGroup>
      {/* {collections && listCollections().map((i) => i)} */}

      <ContextMenuCollections
        isVisible={contextMenuState.visible}
        x={contextMenuState.x}
        y={contextMenuState.y}
        contextMenuStateCollection={contextMenuState.col}
        contextMenuStateCollectionId={contextMenuState.collection_id}
        hideContextMenu={hideContextMenu}
        handleDeleteCollection={handleDeleteCollection}
        attemptingToCloseMenu={attemptingToCloseMenu}
        setAttemptingToCloseMenu={setAttemptingToCloseMenu}
        onMoveCollection={handleMoveCollection}
        onEditCollection={handleEditCollection}
        allCollections={props.allCollections} // Make sure this is passed
        changeCollection={props.changeCollection}
        isSubMenuOpen={isSubMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        submenuStack={submenuStack}
        setSubmenuStack={setSubmenuStack}
        loggedInUsername={props.loggedInUsername}
        cursorPosition={props.cursorPosition}
        innerDivRef={props.innerDivRef}
        setCollectionData={props.setCollectionData}
        setPlacingMode={props.setPlacingMode}
        setPreviewCollection={props.setPreviewCollection}
        collections={collections}
        isPublicView={props.isPublicView}
        pendingParentChange={props.pendingParentChange}
        setPendingParentChange={props.setPendingParentChange}
        rootCollectionId={props.rootCollectionId}
        setRootCollectionId={props.setRootCollectionId}
        isChangeCollectionPressed={isChangeCollectionPressed}
        setChangeCollectionPressed={setChangeCollectionPressed}
        isFetching={props.isFetching}
      />
    </div>
  );
}

export default CollectionsList;
