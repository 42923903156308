import React, { useEffect, useState, useLayoutEffect } from "react";
import { Modal, Tab, Nav, Button, Form } from "react-bootstrap";
import ContactUsModal from "./ContactUsModal";
import TutorialModal from "./TutorialModal";
import "../../css/SettingsModal.css";
import logoIcon from "../../logo_icon.png";
import logoShowcase from "../../logo_showcase.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoTiktok } from "react-icons/io5";
import CloseAccount from "./CloseAccount";

function SettingsModal({
  show,
  handleClose,
  logoutClick,
  loggedInUsername,
  og_username,
  setOg_username,
  loggedInFullname,
  setLoggedInFullname,
}) {
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const [initialFullName, setInitialFullName] = useState("");
  const [initialUsername, setInitialUsername] = useState("");
  const [initialEmail, setInitialEmail] = useState("");

  const [isModified, setIsModified] = useState(false);
  const [currentTab, setCurrentTab] = useState("profile");

  const [isPrivate, setIsPrivate] = useState(true);
  const [initialIsPrivate, setInitialIsPrivate] = useState(true); // Assuming default is true, adjust based on actual default or fetched value

  const [isContactModalOpen, setIsContactModalOpen] = useState(false); // New state for controlling ContactUsModal
  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);

  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
  const openTutorialModal = () => setIsTutorialModalOpen(true);
  const closeTutorialModal = () => setIsTutorialModalOpen(false);

  const [isCloseAccountModalOpen, setIsCloseAccountModalOpen] = useState(false);
  const openCloseAccountModal = () => setIsCloseAccountModalOpen(true);
  const closeCloseAccountModal = () => setIsCloseAccountModalOpen(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [passwordSuccess, setPasswordSuccess] = useState("");

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const [isPasswordChangeValid, setIsPasswordChangeValid] = useState(false);

  const resetPasswordFields = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setPasswordError("");
    setPasswordSuccess("");
    setIsPasswordChangeValid(false);
  };

  useEffect(() => {
    // Reset password fields and messages when the modal closes or tab changes
    resetPasswordFields();
  }, [currentTab, show]);

  // Updated handleModalClose to include password reset

  useEffect(() => {
    const isModified =
      currentPassword !== "" || newPassword !== "" || confirmNewPassword !== "";

    setIsPasswordChangeValid(isModified);
  }, [currentPassword, newPassword, confirmNewPassword]);

  const handlePasswordChangeSubmit = (e) => {
    e.preventDefault();
    setPasswordError(""); // Clear previous errors
    setPasswordSuccess("");

    if (!currentPassword.trim()) {
      setPasswordError("Current password is required.");
      return;
    }
    if (!newPassword.trim()) {
      setPasswordError("New password is required.");
      return;
    }
    if (!confirmNewPassword.trim()) {
      setPasswordError("Confirm new password is required.");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setPasswordError("New passwords do not match.");
      return;
    }

    // Proceed with password update fetch call...
    fetch("/api/account/update_password", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        current_password: currentPassword,
        new_password: newPassword,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            // Handle specific status codes
            if (response.status === 401) {
              throw new Error("Current password is incorrect.");
            } else if (response.status === 400) {
              throw new Error(data.error || "Failed to update password.");
            } else {
              throw new Error("Failed to update password.");
            }
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log("Password updated successfully:", data);
        setPasswordSuccess("Password updated successfully.");
        // alert("Password updated successfully.");
        // Reset the input fields
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setIsPasswordChangeValid(false); // Reset the button state
        setPasswordError(""); // Clear the error message after success
      })
      .catch((error) => {
        console.error("Error updating password:", error);
        setPasswordError(error.message);
      });
  };

  // useLayoutEffect(() => {
  //   // When the modal is opened, reset the tab to "profile"
  //   if (show) {
  //     setCurrentTab("profile");
  //   }
  // }, [show]);

  console.log("Privacy setting being sent:", !isPrivate, og_username);

  useEffect(() => {
    // Replace `userId` with the actual user ID you want to fetch details for
    console.log("weweaa2", og_username);
    fetch(
      // `/api/user/${og_username}/details`
      `/api/account/details`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }
        return response.json();
      })
      .then((data) => {
        console.log("weweaa data 55", data);
        setFullName(data.full_name);
        // setLoggedInFullname(data.full_name);
        setUsername(data.username);
        setEmail(data.email);

        setInitialFullName(data.full_name);
        setInitialUsername(data.username);
        setInitialEmail(data.email);
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  }, [og_username]);

  // useEffect(() => {
  //   fetch(`/api/user/${og_username}/privacy_settings`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ username: og_username }),
  //   })
  //     .then((data) => {
  //       console.log("Privacy data:", data);

  //       setIsPrivate(!data.account_public); // Inverse since false = private
  //       // setIsPrivate(data.account_public ? false : true);
  //       setInitialIsPrivate(!data.account_public);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching account privacy:", error);
  //     });
  // }, [og_username]);

  useEffect(() => {
    fetch(
      // `/api/user/${og_username}/privacy_settings`
      `/api/account/privacy_settings`,

      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch privacy settings");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Privacy data:", data);
        setIsPrivate(!data.account_public); // Inverse since false = private
        setInitialIsPrivate(!data.account_public); // Ensure initialIsPrivate is set correctly
      })
      .catch((error) => {
        console.error("Error fetching account privacy:", error);
      });
  }, [og_username]);

  useEffect(() => {
    // Check if any changes have been made
    const privacyChanged = isPrivate !== initialIsPrivate;

    if (
      fullName !== initialFullName ||
      username !== initialUsername ||
      email !== initialEmail ||
      privacyChanged
    ) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [
    fullName,
    username,
    email,
    isPrivate,
    initialFullName,
    initialUsername,
    initialEmail,
    // initialIsPrivate,
  ]);

  console.log("wewewaa", loggedInFullname);
  console.log("wewewaa", og_username);

  // In your SettingsModal component:
  const isFullNameValid = (name) => {
    return name.trim() !== ""; // This will return true if the name is not just spaces
  };

  const isModifiedAndValid = isModified && isFullNameValid(fullName);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting form");

    if (!isFullNameValid(fullName)) {
      alert("Full name cannot be empty or only contain spaces."); // Show an error message
      return; // Prevent the form from being submitted
    }

    const updatePromises = [];

    if (fullName !== initialFullName) {
      const fullNameUpdate = fetch(
        // `/api/user/${og_username}/update_full_name`,
        `/api/account/update_full_name`,

        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ full_name: fullName }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to update full name");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Full name updated successfully:", data);
          setLoggedInFullname(fullName); // Update the state in parent component if needed
        })
        .catch((error) => {
          console.error("Error updating full name:", error);
        });

      updatePromises.push(fullNameUpdate);
    }

    if (username !== initialUsername) {
      const usernameUpdate = fetch(
        // `/api/user/${og_username}/update_username`
        `/api/account/update_username`,

        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            if (response.status === 409) {
              throw new Error("Username already taken");
            } else {
              throw new Error("Failed to update username");
            }
          }
          return response.json();
        })
        .then((data) => {
          console.log("Username updated successfully:", data);
          setOg_username(username); // Update the username in the parent component or state
        })
        .catch((error) => {
          console.error("Error updating username:", error);
        });

      updatePromises.push(usernameUpdate);
    }

    if (isPrivate !== initialIsPrivate) {
      const privacyUpdate = fetch(
        // `/api/user/${og_username}/update_privacy_settings`,
        `/api/account/update_privacy_settings`,

        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ account_public: !isPrivate }), // Inverse since false = private
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to update privacy");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Privacy setting updated successfully:", data);
          setInitialIsPrivate(isPrivate); // Sync the state after successful update
        })
        .catch((error) => {
          console.error("Error updating privacy:", error);
        });

      updatePromises.push(privacyUpdate);
    }

    Promise.all(updatePromises).then(() => {
      setInitialFullName(fullName);
      setInitialUsername(username);
      setInitialIsPrivate(isPrivate);
      // Reset the isModified state to disable the button
      setIsModified(false);
    });
  };

  const resetForm = () => {
    setFullName(initialFullName);
    setUsername(initialUsername);
    setEmail(initialEmail);
    setIsPrivate(initialIsPrivate);
    setIsModified(false);
  };

  // const handleTabSelect = (selectedTab) => {
  //   if (isModified) {
  //     // If there are unsaved changes, reset state to initial values
  //     setFullName(initialFullName);
  //     setUsername(initialUsername);
  //     setEmail(initialEmail);
  //     setIsPrivate(initialIsPrivate);
  //     setIsModified(false);
  //   }
  //   setCurrentTab(selectedTab); // Update the current tab
  // };

  const handleTabSelect = (selectedTab) => {
    if (isModified) {
      resetForm();
    }
    setCurrentTab(selectedTab);
  };

  const handleModalClose = () => {
    if (isModified) {
      resetForm();
    }
    resetPasswordFields();
    handleClose();
  };

  const removeActiveClasses = () => {
    const navLinks = document.querySelectorAll(
      ".profile-modal .nav-pills .nav-link"
    );
    navLinks.forEach((link) => {
      link.classList.remove("active");
    });
    setCurrentTab(""); // Clear the currentTab state as well
  };

  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      size="lg"
      centered
      className="profile-modal no-select"
      onEnter={() => setCurrentTab("profile")}
      // onExit={() => removeActiveClasses()}
      onExit={() => {
        removeActiveClasses();
        resetForm();
      }}
    >
      <Modal.Header className="profile-modal-cc no-select" closeButton>
        {/* <Modal.Title style={{ marginLeft: "1vw" }}>Settings</Modal.Title> */}
        <div style={{}}>
          <Modal.Title
            className="settings-title"
            style={{ marginLeft: "1vw", flexGrow: 1, fontWeight: "500" }}
          >
            Settings
          </Modal.Title>
          {/* Add Logo Container */}
          <div
            className="logo-container-settings no-select"
            style={{
              display: "flex",
              flexDirection: "column", // Stack the content vertically
              alignItems: "center", // Center horizontally
              // marginLeft: "auto", // Push the logo to the right
              marginLeft: "215px",
              marginTop: "-40px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                className="logo-icon-settings"
                src={logoIcon}
                style={{
                  height: "29px",
                  marginTop: "8px",
                  marginLeft: "2px",
                }}
                alt="Logo Icon"
              />
              <img
                className="logo-showcase-settings"
                src={logoShowcase}
                style={{
                  height: "45px",
                  marginTop: "0px",
                  marginLeft: "7px",
                }}
                alt="Logo Showcase"
              />
            </div>
            {/* <div
              className="url-text"
              style={{
                marginTop: "-18px",
                fontSize: "14px",
                color: "#000",
                textAlign: "center",
                pointerEvents: "auto",
              }}
            >
              showcase-app.co
            </div> */}
          </div>
        </div>
      </Modal.Header>

      <Modal.Body
        className="profile-modal-body"
        style={{ display: "flex", padding: 0 }}
      >
        <Tab.Container
          defaultActiveKey="profile"
          activeKey={currentTab}
          onSelect={handleTabSelect}
        >
          <div className="d-flex" style={{ height: "100%", width: "100%" }}>
            <Nav
              variant="pills"
              className="flex-column align-items-start no-select"
              style={{
                padding: "10px 2vw",
                minHeight: "100%",
                position: "relative", // Added for positioning Style={{ userSelect: "none" }}
                userSelect: "none",
              }}
            >
              <div className="nav-border no-select"></div>
              <Nav.Item style={{ marginBottom: "20px" }}>
                <Nav.Link
                  eventKey="profile"
                  style={{
                    textAlign: "center", // Center text horizontally
                    display: "flex",
                    alignItems: "center", // Center text vertically
                    justifyContent: "center", // Center text horizontally
                    height: "100%", // Ensure the link takes up the full height
                  }}
                >
                  Profile
                </Nav.Link>
              </Nav.Item>
              <Nav.Item style={{ marginBottom: "20px" }}>
                <Nav.Link eventKey="account">Account</Nav.Link>
              </Nav.Item>
              <Nav.Item style={{ marginBottom: "20px" }}>
                <Nav.Link
                  eventKey="password"
                  style={{
                    textAlign: "center", // Center text horizontally
                    display: "flex",
                    alignItems: "center", // Center text vertically
                    justifyContent: "center", // Center text horizontally
                    height: "100%", // Ensure the link takes up the full height
                  }}
                >
                  Password
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item style={{ marginBottom: "20px" }}>
                <Nav.Link eventKey="preferences">Preferences</Nav.Link>
              </Nav.Item> */}
              <Nav.Item style={{ marginBottom: "20px" }}>
                <Nav.Link eventKey="help">Help</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content
              className="ml-3 no-select"
              style={{
                width: "100%",
                overflow: "auto",
                paddingTop: "10px",
                overflow: "hidden",
              }}
            >
              <Tab.Pane eventKey="profile">
                <h4>Profile Information</h4>
                {/* <p>Here you can update your profile information.</p> */}
                <Form onSubmit={handleFormSubmit}>
                  <Form.Floating style={{ marginTop: "30px" }} className="mb-3">
                    <Form.Control
                      type="text"
                      id="floatingFullName"
                      placeholder={loggedInFullname}
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      maxLength={18}
                      style={{ height: "53px" }} // Custom height for input
                    />
                    <Form.Label htmlFor="floatingFullName">
                      Full Name
                    </Form.Label>
                  </Form.Floating>
                  <Form.Floating style={{ marginTop: "25px" }} className="mb-3">
                    <Form.Control
                      type="text"
                      id="floatingUsername"
                      placeholder={og_username}
                      value={username}
                      disabled
                      onChange={(e) => setUsername(e.target.value)}
                      style={{ height: "53px" }} // Custom height for input
                    />
                    <Form.Label htmlFor="floatingUsername">Username</Form.Label>
                  </Form.Floating>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "25px",
                    }}
                  >
                    <Button
                      variant="none"
                      type="submit"
                      // disabled={!isModified}
                      disabled={!isModifiedAndValid}
                      className="save-changes no-select"
                      style={{
                        boxShadow: "none",
                        // backgroundColor: isModified ? "#e4e4e4" : "#aaaaaa",
                        backgroundColor: isModifiedAndValid
                          ? "#e4e4e4"
                          : "#aaaaaa",
                        marginLeft: "-62px",
                      }}
                    >
                      Save Changes
                    </Button>
                  </div>
                </Form>
                <div
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="danger"
                    className="logout-button"
                    onClick={logoutClick}
                    style={{
                      width: "30%",
                      marginTop: "20px",
                      // marginLeft: '115px',
                      marginLeft: "-102px",
                    }}
                  >
                    Log out
                  </Button>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="account">
                <h4>Account Settings</h4>
                {/* <p>Here you can manage your account settings.</p> */}
                <Form onSubmit={handleFormSubmit}>
                  <Form.Floating
                    style={{ marginTop: "25px" }}
                    className="email-form"
                  >
                    <Form.Control
                      type="email" // Use "email" type for email input
                      id="floatingEmail"
                      placeholder="Email"
                      disabled
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ height: "53px" }} // Custom height for input
                    />
                    <Form.Label htmlFor="floatingEmail">Email</Form.Label>
                  </Form.Floating>

                  <Form.Group
                    style={{
                      marginTop: "35px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "13px 5px",
                      display: "flex", // Use flexbox
                      alignItems: "center", // Vertically center items
                      justifyContent: "center", // Horizontally
                      marginBottom: "-20px",
                    }}
                    controlId="privacyToggle"
                    className="privacy-toggle-group"
                  >
                    <div
                      className="privacy-toggle-container d-flex align-items-center justify-content-between"
                      style={{
                        width: "100%",
                        // marginTop: "30px",
                        marginLeft: "-1%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textDecoration: "none",
                        userSelect: "none",
                        outline: "none",
                        boxShadow: "none",
                        marginBottom: "-2px",

                        // gap: "20px", // Add space between elements if needed
                      }}
                    >
                      <div className="privacy-toggle"></div>
                      <span
                        className="privacy-toggle-label"
                        style={{
                          marginRight: "4px",
                          marginLeft: "50px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Private Account
                      </span>
                      <Form.Check
                        type="switch"
                        id="privacy-switch"
                        checked={!isPrivate}
                        onChange={() => setIsPrivate((prev) => !prev)}
                        style={{
                          margin: "0 4px",
                          boxShadow: "none",
                          outline: "none",
                          border: "none",
                        }} // Reduce gap around the switch
                      />

                      <span
                        className="privacy-toggle-label"
                        style={{
                          marginLeft: "0px",
                          marginRight: "50px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Public Account
                      </span>
                    </div>
                  </Form.Group>
                  <p
                    className="privacy-description"
                    style={{
                      color: "gray",
                      fontSize: "14px",
                      maxWidth: "100%",
                      width: "100%",
                      // textAlign: "justify",
                      textAlign: "center",
                      marginTop: "24px", // Space between border and paragraph
                      marginBottom: "-5px", // Space between paragraph and button
                    }}
                  >
                    When your account is Private, only followers can see your
                    public content. Switching to Public lets anyone view your
                    public content. Private content is always only visible to
                    you.
                  </p>
                  <div
                    className="save-changes-container"
                    style={{
                      display: "flex",
                      // justifyContent: "left",
                      justifyContent: "center",
                      marginLeft: "-10px",
                      width: "100%",
                      marginTop: "35px",
                    }}
                  >
                    <Button
                      variant="none"
                      type="submit"
                      disabled={!isModified}
                      className="save-changes no-select"
                      style={{
                        boxShadow: "none",
                        backgroundColor: isModified ? "#e4e4e4" : "#aaaaaa",
                        // marginLeft: "-62px",
                      }}
                    >
                      Save Changes
                    </Button>
                  </div>
                  <div
                    className="delete-account-container"
                    style={{
                      position: "absolute",
                      bottom: "-15px",
                      marginTop: "20px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start", // Align items to the left
                      textAlign: "left", // Change text alignment to left
                      paddingLeft: "0px", // Optional: add some left padding if needed
                    }}
                  >
                    <p
                      onClick={openCloseAccountModal}
                      className="close-account-link"
                      style={{
                        color: "red",
                        cursor: "pointer",
                        marginBottom: "5px",
                        textDecoration: "underline",
                        fontSize: "16px",
                      }}
                    >
                      Close Account
                    </p>
                    <p
                      style={{
                        color: "gray",
                        fontSize: "14px",
                        maxWidth: "90%",
                        marginTop: "-5px",
                      }}
                      className="close-account-description"
                    >
                      This will permanently delete all artefact content,
                      collections, and close your account.
                    </p>
                  </div>
                </Form>
              </Tab.Pane>

              <Tab.Pane eventKey="password">
                <h4 style={{ marginBottom: "30px" }}>Change Password</h4>
                <Form onSubmit={handlePasswordChangeSubmit}>
                  <Form.Floating
                    className="mb-3"
                    style={{ position: "relative" }}
                  >
                    <Form.Control
                      type={showCurrentPassword ? "text" : "password"}
                      id="floatingCurrentPassword"
                      placeholder="Current Password"
                      value={currentPassword}
                      onChange={(e) => {
                        setCurrentPassword(e.target.value);
                        setPasswordSuccess("");
                      }}
                      style={{ height: "53px" }}
                    />
                    <Form.Label htmlFor="floatingCurrentPassword">
                      Current Password
                    </Form.Label>
                    <span
                      className="password-icon"
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        color: "grey",
                      }}
                    >
                      {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </Form.Floating>
                  <Form.Floating
                    className="mb-3"
                    style={{ position: "relative" }}
                  >
                    <Form.Control
                      type={showNewPassword ? "text" : "password"}
                      id="floatingNewPassword"
                      placeholder="New Password"
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                        setPasswordSuccess("");
                      }}
                      style={{ height: "53px" }}
                    />
                    <Form.Label htmlFor="floatingNewPassword">
                      New Password
                    </Form.Label>
                    <span
                      className="password-icon"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        color: "grey",
                      }}
                    >
                      {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </Form.Floating>
                  <Form.Floating
                    className="mb-3"
                    style={{ position: "relative" }}
                  >
                    <Form.Control
                      type={showConfirmNewPassword ? "text" : "password"}
                      id="floatingConfirmNewPassword"
                      placeholder="Confirm New Password"
                      value={confirmNewPassword}
                      onChange={(e) => {
                        setConfirmNewPassword(e.target.value);
                        setPasswordError("");
                      }}
                      style={{ height: "53px" }}
                    />
                    <Form.Label htmlFor="floatingConfirmNewPassword">
                      Confirm New Password
                    </Form.Label>
                    <span
                      className="password-icon"
                      onClick={() =>
                        setShowConfirmNewPassword(!showConfirmNewPassword)
                      }
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        color: "grey",
                      }}
                    >
                      {showConfirmNewPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </Form.Floating>

                  {/* Error Message */}
                  <div style={{ minHeight: "60px", position: "relative" }}>
                    {passwordError && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "14px",
                          textAlign: "center",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          marginLeft: "-4%",
                          marginTop: "-7px",
                        }}
                      >
                        {passwordError}
                      </div>
                    )}
                    {passwordSuccess && (
                      <div
                        style={{
                          color: "green",
                          fontSize: "14px",
                          textAlign: "center",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          // marginLeft: "-16%",
                          marginLeft: "-4%",
                          marginTop: "-7px",
                        }}
                      >
                        {passwordSuccess}
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                    >
                      <Button
                        variant="none"
                        type="submit"
                        disabled={!isPasswordChangeValid}
                        className="save-changes-change-password no-select"
                        style={{
                          boxShadow: "none",
                          backgroundColor: isPasswordChangeValid
                            ? "#e4e4e4"
                            : "#aaaaaa",
                          marginLeft: "-3%",
                        }}
                      >
                        Change Password
                      </Button>
                    </div>
                  </div>
                </Form>
              </Tab.Pane>
              {/* <Tab.Pane eventKey="preferences">
                <h4>Preferences</h4>
                <p>Here you can manage your preferences.</p>
              </Tab.Pane> */}
              <Tab.Pane className=" no-select" eventKey="help">
                <h4>Help</h4>
                <p>Here you can find help and support.</p>
                <div
                  className="help-buttons"
                  style={{
                    position: "absolute",
                    top: "40%",
                    alignContent: "left",
                    width: "100%",
                  }}
                >
                  <Button
                    className="tutorial-button no-select"
                    variant="secondary"
                    style={{
                      width: "30%",
                      marginBottom: "30px",
                      marginLeft: "115px",
                      whiteSpace: "nowrap",
                      backgroundColor: "#6c757d",
                      border: "none",
                    }}
                    onClick={openTutorialModal}
                  >
                    Tutorial
                  </Button>
                  <Button
                    className="contact-us-button no-select"
                    variant="primary"
                    style={{
                      width: "30%",
                      // marginTop: "20px",
                      marginBottom: "20px",
                      marginLeft: "115px",
                      whiteSpace: "nowrap",
                      backgroundColor: "#79b8ff",
                      border: "none",
                    }}
                    onClick={openContactModal}
                  >
                    Contact Us
                  </Button>
                </div>
                <div
                  className="social-media-container-settings"
                  style={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    bottom: 10,
                    // marginTop: "40px",
                    // marginBottom: "10px", // Adds spacing from the bottom of the modal
                    left: "33%",
                  }}
                >
                  <a
                    href="https://www.instagram.com/showcaseappco"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                    aria-label="Instagram"
                    style={{ color: "#6ea5f7" }}
                  >
                    <FaInstagram size={28} />
                  </a>
                  <a
                    href="https://x.com/ShowcaseAppCo"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                    aria-label="Twitter"
                    style={{ color: "#6ea5f7" }}
                  >
                    <FaXTwitter size={28} />
                  </a>
                  <a
                    href="https://www.tiktok.com/@showcaseappco?_t=8qtVNZDCagK&_r=1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon"
                    aria-label="TikTok"
                    style={{ color: "#6ea5f7" }}
                  >
                    <IoLogoTiktok size={28} />
                  </a>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </Modal.Body>
      <CloseAccount
        show={isCloseAccountModalOpen}
        onHide={closeCloseAccountModal}
        onConfirm={() => {
          // Define your account closing logic here.
          console.log("Account closed");
        }}
        og_username={og_username}
      />
      <TutorialModal
        show={isTutorialModalOpen}
        handleClose={closeTutorialModal}
      />
      <ContactUsModal
        show={isContactModalOpen}
        handleClose={closeContactModal}
      />
    </Modal>
  );
}

export default SettingsModal;
