// import React, { useState, useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import { Link } from "react-router-dom";
// import "../css/Signup.css";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
// import logoIcon from "../logo_icon.png";
// import logoShowcase from "../logo_showcase.png";

// const Signup = (props) => {
//   const [errorMsg, setErrorMsg] = useState("");

//   const navigate = useNavigate();

//   // References for inputs
//   const fullNameRef = useRef(null);
//   const usernameRef = useRef(null);
//   const emailRef = useRef(null);
//   const passwordRef = useRef(null);
//   const confirmPasswordRef = useRef(null);

//   const [fullName, setFullName] = useState("");
//   const [usernameInp, setUsernameInp] = useState("");
//   const [passInp, setPassInp] = useState("");
//   const [email, setEmail] = useState("");
//   const [confirmPass, setConfirmPass] = useState("");

//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [termsAccepted, setTermsAccepted] = useState(false);

//   const [isLowConnection, setIsLowConnection] = useState(false);

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const toggleConfirmPasswordVisibility = () => {
//     setShowConfirmPassword(!showConfirmPassword);
//   };

//   const validateUsername = (username) => {
//     // Regular expression to allow letters, numbers, periods, underscores, no spaces
//     const regex = /^[a-z0-9._]+$/;
//     return regex.test(username);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (!termsAccepted) {
//       setErrorMsg("You must accept the terms and conditions to sign up.");
//       return;
//     }

//     if (!fullName.trim()) {
//       setErrorMsg("Full name is required.");
//       return;
//     }

//     if (fullName.length > 18) {
//       setErrorMsg("Full name must not exceed 18 characters.");
//       return;
//     }

//     if (!email.trim()) {
//       setErrorMsg("Email is required.");
//       return;
//     }

//     if (!usernameInp.trim()) {
//       setErrorMsg("Username is required.");
//       return;
//     }

//     if (usernameInp.length > 18) {
//       setErrorMsg("Username must not exceed 18 characters.");
//       return;
//     }

//     if (usernameInp !== usernameInp.toLowerCase()) {
//       setErrorMsg("Username must be in lowercase.");
//       return;
//     }

//     if (!validateUsername(usernameInp)) {
//       setErrorMsg(
//         "Username: use lowercase letters, numbers, periods, or underscores."
//       );
//       return;
//     }

//     if (!passInp) {
//       setErrorMsg("Password is required.");
//       return;
//     }

//     if (passInp !== confirmPass) {
//       setErrorMsg("Your passwords do not match.");
//       return;
//     }

//     fetch("/api/signup", {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       credentials: "same-origin",
//       body: JSON.stringify({
//         username: usernameInp,
//         password: passInp,
//         email: email,
//         full_name: fullName,
//       }),
//     }).then((response) => {
//       if (response.status === 201) {
//         // Successful sign-up, make sure to clear any existing session first
//         fetch("/api/signout", {
//           method: "POST",
//           credentials: "same-origin",
//         }).finally(() => {
//           // Attempt auto-login after signing out the previous session
//           fetch("/api/signin", {
//             method: "POST",
//             headers: { "Content-Type": "application/json" },
//             credentials: "same-origin",
//             body: JSON.stringify({
//               identifier: usernameInp,
//               password: passInp,
//             }),
//           }).then((loginResponse) => {
//             if (loginResponse.status === 200) {
//               loginResponse.json().then((user) => {
//                 // Set logged-in state in your app
//                 props.setLoggedInUsername(user.username);
//                 props.setLoggedIn(true);
//                 props.setOg_username(user.username);
//                 props.setLoggedInFullname(user.full_name);

//                 localStorage.setItem("username", usernameInp);
//                 localStorage.setItem("password", passInp);

//                 // Set a flag to indicate the tutorial should be shown
//                 localStorage.setItem("showTutorial", "true");

//                 // Redirect to user page or wherever you want
//                 navigate("/mypage");
//               });
//             } else {
//               setErrorMsg("Something went wrong during auto-login.");
//             }
//           });
//         });
//       } else {
//         response.json().then((j) => {
//           setErrorMsg(j.error);
//         });
//       }
//     });
//   };

//   useEffect(() => {
//     if (props.loggedIn) {
//       navigate("/user/" + props.loggedInUsername);
//     }
//   }, [props.loggedIn]);

//   useEffect(() => {
//     const preventDefaultZoomActions = (event) => {
//       if (
//         (event.ctrlKey &&
//           (event.key === "+" || event.key === "-" || event.key === "=")) ||
//         (event.ctrlKey && event.deltaY)
//       ) {
//         event.preventDefault(); // Prevent Ctrl + '+' and Ctrl + '-' for zooming
//       }
//     };

//     const preventTouchZoom = (event) => {
//       if (event.touches.length > 1) {
//         event.preventDefault(); // Prevent pinch-to-zoom
//       }
//     };

//     window.addEventListener("keydown", preventDefaultZoomActions);
//     window.addEventListener("wheel", preventDefaultZoomActions, {
//       passive: false,
//     });
//     window.addEventListener("touchmove", preventTouchZoom, { passive: false });

//     return () => {
//       window.removeEventListener("keydown", preventDefaultZoomActions);
//       window.removeEventListener("wheel", preventDefaultZoomActions);
//       window.removeEventListener("touchmove", preventTouchZoom);
//     };
//   }, []);

//   return (
//     <div className="signup-container" style={{ marginTop: "-30px" }}>
//       <div className="signup-content">
//         <div
//           className="signup-box no-select"
//           style={{ marginLeft: "110px", userSelect: "none" }}
//         >
//           <h2 style={{ marginTop: "-10px" }}>Sign Up</h2>
//           <div style={{ marginTop: "20px" }}>
//             <Form onSubmit={handleSubmit}>
//               <Form.Floating className="mb-3">
//                 <Form.Control
//                   ref={emailRef}
//                   type="email"
//                   id="floatingInputEmail"
//                   placeholder="Email"
//                   value={email}
//                   onChange={({ target: { value } }) => setEmail(value)}
//                   style={{ height: "46px" }}
//                 />
//                 <Form.Label
//                   htmlFor="floatingInputEmail"
//                   style={{ marginTop: "-5px" }}
//                 >
//                   Email
//                   <span
//                     className="required-asterisk"
//                     style={{ position: "absolute" }}
//                   >
//                     *
//                   </span>
//                 </Form.Label>
//               </Form.Floating>

//               <div style={{ display: "flex", gap: "10px" }}>
//                 <style>
//                   {`
//                   #fullNameContainer.custom-font-size,
//                   #fullNameContainer .custom-font-size {
//                     font-size: 17px !important;
//                     line-height: 1.6 !important;
//                   }
//                 `}
//                 </style>
//                 <Form.Floating
//                   className="mb-3 custom-font-size"
//                   id="fullNameContainer"
//                   style={{ flex: 1 }}
//                 >
//                   <Form.Control
//                     ref={fullNameRef}
//                     type="text"
//                     id="floatingInputFullName"
//                     placeholder="Full Name"
//                     value={fullName}
//                     onChange={({ target: { value } }) => setFullName(value)}
//                     className="custom-font-size"
//                   />
//                   <Form.Label
//                     htmlFor="floatingInputFullName"
//                     className="custom-font-size"
//                   >
//                     Full Name{" "}
//                     <span
//                       className="required-asterisk"
//                       style={{ position: "absolute", marginTop: "-5px" }}
//                     >
//                       *
//                     </span>
//                   </Form.Label>
//                 </Form.Floating>

//                 <Form.Floating className="mb-3" style={{ flex: 1 }}>
//                   <Form.Control
//                     ref={usernameRef}
//                     type="text"
//                     id="floatingInputUsername"
//                     placeholder="Username"
//                     value={usernameInp}
//                     onChange={({ target: { value } }) => setUsernameInp(value)}
//                     style={{ lineHeight: "1.8" }}
//                   />

//                   <Form.Label
//                     htmlFor="floatingInputUsername"
//                     style={{ lineHeight: "1.4" }}
//                   >
//                     Username{" "}
//                     <span
//                       className="required-asterisk"
//                       style={{
//                         position: "absolute",
//                       }}
//                     >
//                       *
//                     </span>
//                   </Form.Label>
//                 </Form.Floating>
//               </div>

//               <Form.Floating className="mb-3" style={{ position: "relative" }}>
//                 <Form.Control
//                   ref={passwordRef}
//                   type={showPassword ? "text" : "password"}
//                   id="floatingPassword"
//                   placeholder="Password"
//                   value={passInp}
//                   onChange={({ target: { value } }) => {
//                     setPassInp(value);
//                     if (errorMsg) setErrorMsg(""); // Clear error message on change
//                   }}
//                   style={{ height: "46px" }}
//                 />
//                 <Form.Label
//                   htmlFor="floatingPassword"
//                   style={{ marginTop: "-5px" }}
//                 >
//                   Password{" "}
//                   <span
//                     className="required-asterisk"
//                     style={{ position: "absolute" }}
//                   >
//                     *
//                   </span>
//                 </Form.Label>
//                 <span
//                   className="password-icon"
//                   onClick={togglePasswordVisibility}
//                   style={{
//                     position: "absolute",
//                     top: "50%",
//                     right: "20px",
//                     transform: "translateY(-50%)",
//                     cursor: "pointer",
//                     color: "grey",
//                   }}
//                 >
//                   {showPassword ? <FaEyeSlash /> : <FaEye />}
//                 </span>
//               </Form.Floating>

//               <Form.Floating className="mb-3" style={{ position: "relative" }}>
//                 <Form.Control
//                   ref={confirmPasswordRef}
//                   type={showConfirmPassword ? "text" : "password"}
//                   id="floatingConfirmPassword"
//                   placeholder="Confirm Password"
//                   value={confirmPass}
//                   onChange={({ target: { value } }) => {
//                     setConfirmPass(value);
//                     if (errorMsg) setErrorMsg(""); // Clear error message on change
//                   }}
//                   style={{ height: "46px" }}
//                 />
//                 <Form.Label
//                   htmlFor="floatingConfirmPassword"
//                   style={{ marginTop: "-5px" }}
//                 >
//                   Confirm Password{" "}
//                   <span
//                     className="required-asterisk"
//                     style={{ position: "absolute" }}
//                   >
//                     *
//                   </span>
//                 </Form.Label>
//                 <span
//                   className="password-icon"
//                   onClick={toggleConfirmPasswordVisibility}
//                   style={{
//                     position: "absolute",
//                     top: "50%",
//                     right: "20px",
//                     transform: "translateY(-50%)",
//                     cursor: "pointer",
//                     color: "grey",
//                   }}
//                 >
//                   {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
//                 </span>
//               </Form.Floating>

//               <div
//                 className="error-message"
//                 style={{
//                   height: "30px",
//                   textAlign: "center",
//                   color: "red",
//                   marginTop: "-5px",
//                 }}
//               >
//                 {errorMsg && <span>{errorMsg}</span>}
//               </div>

//               <div style={{ marginBottom: "5px" }}></div>
//               <Form.Group className="mb-3" controlId="formBasicCheckbox">
//                 <div className="terms-checkbox-container">
//                   <Form.Check
//                     className="terms-checkbox"
//                     type="checkbox"
//                     checked={termsAccepted}
//                     onChange={(e) => setTermsAccepted(e.target.checked)}
//                     onKeyDown={(e) => {
//                       if (e.key === "Enter") {
//                         e.preventDefault();
//                         setTermsAccepted(!termsAccepted);
//                       }
//                     }}
//                     label={
//                       <span>
//                         I understand and agree to the
//                         <Link
//                           to="/terms-conditions"
//                           style={{ marginLeft: "5px" }}
//                         >
//                           terms and conditions
//                         </Link>
//                         {" and "}
//                         <Link
//                           to="/privacy-policy"
//                           style={{ marginLeft: "5px" }}
//                         >
//                           privacy policy
//                         </Link>
//                         <span
//                           className="required-asterisk"
//                           style={{ position: "absolute" }}
//                         >
//                           *
//                         </span>
//                       </span>
//                     }
//                   />
//                 </div>
//               </Form.Group>
//               <div style={{ marginBottom: "5px" }}></div>

//               <div className="signup-button-container">
//                 <Button
//                   className="signup-button"
//                   variant="primary"
//                   type="submit"
//                 >
//                   Sign Up
//                 </Button>
//               </div>
//               <Form.Text
//                 id="passwordHelpBlock"
//                 style={{
//                   textAlign: "center",
//                   display: "block",
//                   width: "100%",
//                   marginTop: "30px",
//                 }}
//                 muted
//               >
//                 Already have an account?{" "}
//                 <Link to="/signin" style={{ marginLeft: "3px" }}>
//                   Sign in
//                 </Link>
//               </Form.Text>
//             </Form>
//           </div>
//         </div>
//         <div
//           className="logo-container"
//           style={{ marginLeft: "40px", marginTop: "70px" }}
//         >
//           <img src={logoIcon} alt="Logo Icon" className="logo-icon" />
//           <img
//             src={logoShowcase}
//             alt="Logo Showcase"
//             className="logo-showcase"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Signup;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import "../css/Signup.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import logoIcon from "../logo_icon.png";
import logoShowcase from "../logo_showcase.png";

const Signup = (props) => {
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();

  // References for inputs
  const fullNameRef = useRef(null);
  const usernameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const [fullName, setFullName] = useState("");
  const [usernameInp, setUsernameInp] = useState("");
  const [passInp, setPassInp] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  // New state variables for connection handling
  const [isLowConnection, setIsLowConnection] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const retryAttemptsRef = useRef(0);
  const maxRetries = 120; // Maximum number of retry attempts
  const retryDelay = 1000; // Delay between retries in milliseconds

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateUsername = (username) => {
    // Regular expression to allow letters, numbers, periods, underscores, no spaces
    const regex = /^[a-z0-9._]+$/;
    return regex.test(username);
  };

  // Connection Indicator Component
  function ConnectionIndicator() {
    return (
      <div
        className="low-connection"
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          color: "orange",
          marginLeft: "15px",
          marginTop: "15px",
          zIndex: "1000",
        }}
      >
        <div
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            backgroundColor: "orange",
            marginRight: "3px",
            marginTop: "-1px",
          }}
        ></div>
        <span>Low connection</span>
      </div>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error messages and connection status
    setErrorMsg("");
    setIsLowConnection(false);

    // Basic validation checks
    if (!termsAccepted) {
      setErrorMsg("You must accept the terms and conditions to sign up.");
      return;
    }

    if (!fullName.trim()) {
      setErrorMsg("Full name is required.");
      return;
    }

    if (fullName.length > 18) {
      setErrorMsg("Full name must not exceed 18 characters.");
      return;
    }

    if (!email.trim()) {
      setErrorMsg("Email is required.");
      return;
    }

    if (!usernameInp.trim()) {
      setErrorMsg("Username is required.");
      return;
    }

    if (usernameInp.length > 18) {
      setErrorMsg("Username must not exceed 18 characters.");
      return;
    }

    if (usernameInp !== usernameInp.toLowerCase()) {
      setErrorMsg("Username must be in lowercase.");
      return;
    }

    if (!validateUsername(usernameInp)) {
      setErrorMsg(
        "Username: use lowercase letters, numbers, periods, or underscores."
      );
      return;
    }

    if (!passInp) {
      setErrorMsg("Password is required.");
      return;
    }

    if (passInp !== confirmPass) {
      setErrorMsg("Your passwords do not match.");
      return;
    }

    // Begin submission with retry logic
    setIsSubmitting(true);
    retryAttemptsRef.current = 0; // Reset retry attempts

    const attemptSignup = async () => {
      try {
        const response = await fetch("/api/signup", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "same-origin",
          body: JSON.stringify({
            username: usernameInp,
            password: passInp,
            email: email,
            full_name: fullName,
          }),
        });

        if (response.status === 201) {
          // Successful signup, reset low connection indicator
          setIsLowConnection(false);

          // Proceed with sign-out and auto-login
          await fetch("/api/signout", {
            method: "POST",
            credentials: "same-origin",
          });

          const loginResponse = await fetch("/api/signin", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "same-origin",
            body: JSON.stringify({
              identifier: usernameInp,
              password: passInp,
            }),
          });

          if (loginResponse.status === 200) {
            const user = await loginResponse.json();
            // Set logged-in state in your app
            props.setLoggedInUsername(user.username);
            props.setLoggedIn(true);
            props.setOg_username(user.username);
            props.setLoggedInFullname(user.full_name);

            localStorage.setItem("username", usernameInp);
            localStorage.setItem("password", passInp);

            // Set a flag to indicate the tutorial should be shown
            localStorage.setItem("showTutorial", "true");

            // Redirect to user page or wherever you want
            navigate("/mypage");
          } else {
            setErrorMsg("Something went wrong during auto-login.");
          }
        } else {
          const j = await response.json();
          setErrorMsg(j.error || "Signup failed.");
        }
      } catch (error) {
        console.error("Signup attempt failed:", error);

        // Increment retry attempts
        retryAttemptsRef.current += 1;

        // Show low connection indicator after the first failed attempt
        if (retryAttemptsRef.current === 1) {
          setIsLowConnection(true);
        }

        if (retryAttemptsRef.current < maxRetries) {
          console.log(`Retrying signup... Attempt ${retryAttemptsRef.current}`);
          setTimeout(attemptSignup, retryDelay);
        } else {
          setErrorMsg(
            "Network error. Please check your connection and try again."
          );
        }
      } finally {
        // If signup is successful, isLowConnection is already set to false
        // If retries are exhausted or no low connection, stop submitting
        if (retryAttemptsRef.current >= maxRetries || !isLowConnection) {
          setIsSubmitting(false);
        }
      }
    };

    // Start the first signup attempt
    attemptSignup();
  };

  useEffect(() => {
    if (props.loggedIn) {
      navigate("/user/" + props.loggedInUsername);
    }
  }, [props.loggedIn]);

  useEffect(() => {
    const preventDefaultZoomActions = (event) => {
      if (
        (event.ctrlKey &&
          (event.key === "+" || event.key === "-" || event.key === "=")) ||
        (event.ctrlKey && event.deltaY)
      ) {
        event.preventDefault(); // Prevent Ctrl + '+' and Ctrl + '-' for zooming
      }
    };

    const preventTouchZoom = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault(); // Prevent pinch-to-zoom
      }
    };

    window.addEventListener("keydown", preventDefaultZoomActions);
    window.addEventListener("wheel", preventDefaultZoomActions, {
      passive: false,
    });
    window.addEventListener("touchmove", preventTouchZoom, { passive: false });

    return () => {
      window.removeEventListener("keydown", preventDefaultZoomActions);
      window.removeEventListener("wheel", preventDefaultZoomActions);
      window.removeEventListener("touchmove", preventTouchZoom);
    };
  }, []);

  return (
    <div
      className="signup-container"
      style={{ marginTop: "-30px", position: "relative" }}
    >
      {/* Display Connection Indicator */}
      {isLowConnection && <ConnectionIndicator />}

      <div className="signup-content">
        <div
          className="signup-box no-select"
          style={{ marginLeft: "110px", userSelect: "none" }}
        >
          <h2 style={{ marginTop: "-10px" }}>Sign Up</h2>
          <div style={{ marginTop: "20px" }}>
            <Form onSubmit={handleSubmit}>
              <Form.Floating className="mb-3">
                <Form.Control
                  ref={emailRef}
                  type="email"
                  id="floatingInputEmail"
                  placeholder="Email"
                  value={email}
                  onChange={({ target: { value } }) => setEmail(value)}
                  style={{ height: "46px" }}
                />
                <Form.Label
                  htmlFor="floatingInputEmail"
                  style={{ marginTop: "-5px" }}
                >
                  Email
                  <span
                    className="required-asterisk"
                    style={{ position: "absolute" }}
                  >
                    *
                  </span>
                </Form.Label>
              </Form.Floating>

              <div style={{ display: "flex", gap: "10px" }}>
                <style>
                  {`
                  #fullNameContainer.custom-font-size, 
                  #fullNameContainer .custom-font-size {
                    font-size: 17px !important;
                    line-height: 1.6 !important;
                  }
                `}
                </style>
                <Form.Floating
                  className="mb-3 custom-font-size"
                  id="fullNameContainer"
                  style={{ flex: 1 }}
                >
                  <Form.Control
                    ref={fullNameRef}
                    type="text"
                    id="floatingInputFullName"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={({ target: { value } }) => setFullName(value)}
                    className="custom-font-size"
                  />
                  <Form.Label
                    htmlFor="floatingInputFullName"
                    className="custom-font-size"
                  >
                    Full Name{" "}
                    <span
                      className="required-asterisk"
                      style={{ position: "absolute", marginTop: "-5px" }}
                    >
                      *
                    </span>
                  </Form.Label>
                </Form.Floating>

                <Form.Floating className="mb-3" style={{ flex: 1 }}>
                  <Form.Control
                    ref={usernameRef}
                    type="text"
                    id="floatingInputUsername"
                    placeholder="Username"
                    value={usernameInp}
                    onChange={({ target: { value } }) => setUsernameInp(value)}
                    style={{ lineHeight: "1.8" }}
                  />

                  <Form.Label
                    htmlFor="floatingInputUsername"
                    style={{ lineHeight: "1.4" }}
                  >
                    Username{" "}
                    <span
                      className="required-asterisk"
                      style={{
                        position: "absolute",
                      }}
                    >
                      *
                    </span>
                  </Form.Label>
                </Form.Floating>
              </div>

              <Form.Floating className="mb-3" style={{ position: "relative" }}>
                <Form.Control
                  ref={passwordRef}
                  type={showPassword ? "text" : "password"}
                  id="floatingPassword"
                  placeholder="Password"
                  value={passInp}
                  onChange={({ target: { value } }) => {
                    setPassInp(value);
                    if (errorMsg) setErrorMsg(""); // Clear error message on change
                  }}
                  style={{ height: "46px" }}
                />
                <Form.Label
                  htmlFor="floatingPassword"
                  style={{ marginTop: "-5px" }}
                >
                  Password{" "}
                  <span
                    className="required-asterisk"
                    style={{ position: "absolute" }}
                  >
                    *
                  </span>
                </Form.Label>
                <span
                  className="password-icon"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "20px",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "grey",
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </Form.Floating>

              <Form.Floating className="mb-3" style={{ position: "relative" }}>
                <Form.Control
                  ref={confirmPasswordRef}
                  type={showConfirmPassword ? "text" : "password"}
                  id="floatingConfirmPassword"
                  placeholder="Confirm Password"
                  value={confirmPass}
                  onChange={({ target: { value } }) => {
                    setConfirmPass(value);
                    if (errorMsg) setErrorMsg(""); // Clear error message on change
                  }}
                  style={{ height: "46px" }}
                />
                <Form.Label
                  htmlFor="floatingConfirmPassword"
                  style={{ marginTop: "-5px" }}
                >
                  Confirm Password{" "}
                  <span
                    className="required-asterisk"
                    style={{ position: "absolute" }}
                  >
                    *
                  </span>
                </Form.Label>
                <span
                  className="password-icon"
                  onClick={toggleConfirmPasswordVisibility}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "20px",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "grey",
                  }}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </Form.Floating>

              <div
                className="error-message"
                style={{
                  height: "30px",
                  textAlign: "center",
                  color: "red",
                  marginTop: "-5px",
                }}
              >
                {errorMsg && <span>{errorMsg}</span>}
              </div>

              <div style={{ marginBottom: "5px" }}></div>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <div className="terms-checkbox-container">
                  <Form.Check
                    className="terms-checkbox"
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        setTermsAccepted(!termsAccepted);
                      }
                    }}
                    label={
                      <span>
                        I understand and agree to the
                        <Link
                          to="/terms-conditions"
                          style={{ marginLeft: "5px" }}
                        >
                          terms and conditions
                        </Link>
                        {" and "}
                        <Link
                          to="/privacy-policy"
                          style={{ marginLeft: "5px" }}
                        >
                          privacy policy
                        </Link>
                        <span
                          className="required-asterisk"
                          style={{ position: "absolute" }}
                        >
                          *
                        </span>
                      </span>
                    }
                  />
                </div>
              </Form.Group>
              <div style={{ marginBottom: "5px" }}></div>

              <div className="signup-button-container">
                <Button
                  className="signup-button"
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting} // Disable button while submitting
                >
                  {isSubmitting ? "Signing Up..." : "Sign Up"}
                </Button>
              </div>
              <Form.Text
                id="passwordHelpBlock"
                style={{
                  textAlign: "center",
                  display: "block",
                  width: "100%",
                  marginTop: "30px",
                }}
                muted
              >
                Already have an account?{" "}
                <Link to="/signin" style={{ marginLeft: "3px" }}>
                  Sign in
                </Link>
              </Form.Text>
            </Form>
          </div>
        </div>
        <div
          className="logo-container"
          style={{ marginLeft: "40px", marginTop: "70px" }}
        >
          <img src={logoIcon} alt="Logo Icon" className="logo-icon" />
          <img
            src={logoShowcase}
            alt="Logo Showcase"
            className="logo-showcase"
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;
