import React, { useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useNavigate } from "react-router-dom";
import { BiSolidLockAlt } from "react-icons/bi";
import { FaFolder } from "react-icons/fa";
import { PiFoldersFill } from "react-icons/pi";
import { FaRegFolderOpen } from "react-icons/fa6";

function Collection({
  col,
  cellSize,
  handleRightClick,
  handleTouchStart,
  handleTouchEnd,
  handleTouchCancel,
  contextMenuOpened,
  setContextMenuOpened,
  attemptingToCloseMenu,
  setAttemptingToCloseMenu,
  otherUser,
  // isEditing,
  isOwner,
  isPublicView,
  isEditing,
  editCollectionName,
  pannableBoxHeight,
  pannableBoxWidth,
  isPlacementCooldown,
}) {
  const navigate = useNavigate();
  const [mouseDownPos, setMouseDownPos] = useState(null);
  const [newCollectionName, setNewCollectionName] = useState(
    col.collection_name
  );
  const halfCellSize = cellSize / 2;

  let x, y;

  if (isOwner) {
    // Assuming `otherUser == null` means the current user is the owner
    x = isPublicView ? col.coordx_public : col.coordx_private;
    y = isPublicView ? col.coordy_public : col.coordy_private;
  } else {
    x = col.coordx_public;
    y = col.coordy_public;
  }

  const style = {
    position: "absolute",
    left: x + pannableBoxWidth / 2 + 2 - 250 / 2 + "px",
    top: y + pannableBoxHeight / 2 + 2 - 170 / 2 + "px",
    userSelect: "none",
    // border: "0.5px solid #ccc", // Ensure all sides have a border
    // border: "1px solid #ccc", // Ensure all sides have a border
    border: "1px solid rgba(0, 0, 0, 0.1)", //COLLECTION BORDER ADDED!!!!!!!!
  };

  const renderIcon = () => {
    switch (col.type) {
      case "empty":
        return (
          <FaFolder
            size={19}
            color="#D2D2D2"
            style={{ position: "absolute", top: "10px", left: "14px" }}
          />
        );
      case "collections":
        return (
          <PiFoldersFill
            size={23}
            color="#D2D2D2"
            style={{ position: "absolute", top: "8px", left: "12px" }}
          />
        );
      case "artefacts":
        return (
          <FaRegFolderOpen
            size={20}
            color="#D2D2D2"
            style={{ position: "absolute", top: "9px", left: "13px" }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ListGroup.Item
        className="collection-box no-select"
        key={col.collection_id}
        id={"collection-" + col.collection_id}
        style={style}
        onMouseDown={(e) => {
          setMouseDownPos({
            x: e.screenX,
            y: e.screenY,
          });
        }}
        onMouseUp={(e) => {
          e.preventDefault();
          if (isPlacementCooldown) {
            console.log("Navigation prevented due to cooldown.");
            return; // Prevent navigation during cooldown period
          }

          if (mouseDownPos) {
            if (e.button === 0) {
              if (
                e.screenX === mouseDownPos.x &&
                e.screenY === mouseDownPos.y
              ) {
                if (contextMenuOpened) {
                  // If the context menu is open, do not navigate
                  return;
                }
                if (attemptingToCloseMenu) {
                  // If the user is attempting to close the droptop menu, reset the flag and don't navigate
                  setAttemptingToCloseMenu(false);
                } else {
                  // If looking at another user's page, navigate to the nested collections page
                  console.log("otherUser", otherUser);
                  if (otherUser != null) {
                    navigate(
                      `/user/${otherUser}/collection/${col.collection_id}`
                    );
                  } else {
                    navigate("/collection/" + col.collection_id);
                  }

                  // navigate(col.owner_id+"/collection/" + col.collection_id);
                }
              }
            }
          }
        }}
        onContextMenu={(e) => handleRightClick(e, col.collection_id)}
        onTouchStart={(e) => handleTouchStart(e, col.collection_id)}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchCancel}
      >
        {/* <div style={{ paddingTop: "100px" }} className="text-name">
          {col.collection_name}
        </div> */}

        <div
          style={{
            paddingTop: "100px",
            lineHeight: "25px",
            height: "152px", // 2 lines * 25px
            overflow: "hidden",
            display: "-webkit-box",
            whiteSpace: "wrap",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
          className="text-name no-select"
        >
          {isEditing ? (
            <>
              <input
                type="text"
                value={newCollectionName}
                onChange={(e) => setNewCollectionName(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    editCollectionName(col.collection_id, newCollectionName);
                  }
                }}
                autoFocus
                style={{
                  width: "200px", // Adjust the width as needed
                  border: "none",
                  padding: "5px",
                  borderRadius: "4px", // Optional: for rounded corners
                  outline: "none", // Remove default outline
                }}
                onMouseDown={(e) => e.stopPropagation()} // Stop event propagation on mouse down
                onMouseUp={(e) => e.stopPropagation()}
              />
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the click from propagating
                  editCollectionName(col.collection_id, newCollectionName);
                }}
                onMouseDown={(e) => e.stopPropagation()} // Stop event propagation on mouse down
                onMouseUp={(e) => e.stopPropagation()} // Stop event propagation on mouse up
                style={{
                  backgroundColor: "#ccc",
                  color: "white", // White text
                  padding: "2px 6px", // Adjust padding as needed
                  borderRadius: "5px", // Rounded corners
                  border: "none", // No border
                  cursor: "pointer", // Pointer cursor on hover
                  zIndex: 5, // High zIndex to ensure visibility
                  position: "absolute", // Absolute positioning
                  top: "173px", // Adjust to fit just below the input
                  left: "30px", // Center horizontally relative to the parent
                  transform: "translateX(-50%)", // Center the button
                  fontSize: "18px", // Adjust font size as needed
                }}
              >
                Save
              </button>
            </>
          ) : // col.collection_name
          col.collection_name.length > 35 ? (
            `${col.collection_name.substring(0, 35)}...`
          ) : (
            col.collection_name
          )}
        </div>

        {renderIcon()}

        {col.public_count === 0 && (
          <div
            style={{
              position: "absolute",
              top: "-4.5px",
              right: "5px",
            }}
          >
            <BiSolidLockAlt size={21} color="#7d8584" />
          </div>
        )}
      </ListGroup.Item>
    </>
  );
}

export default Collection;
