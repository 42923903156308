import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/TreeBreadCrumb.css"; // Assuming CSS is in a separate file

function TreeBreadCrumb({
  breadcrumbPath,
  loggedInUsername,
  og_username,
  expanded,
  homePageFlag,
  effectiveUsername,
}) {
  const navigate = useNavigate();
  const COLLAPSED_MAX_CHAR_LIMIT = 27; // Character limit for collapsed breadcrumb
  const EXPANDED_MAX_CHAR_LIMIT = 47; // Character limit for expanded breadcrumb
  // const LAST_COLLECTION_LIMIT = 11; // New constant for the last collection truncation
  const COLLAPSED_LAST_COLLECTION_LIMIT = 11;
  const EXPANDED_LAST_COLLECTION_LIMIT = 11;

  const [isVisible, setIsVisible] = useState(expanded);

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(expanded), 50); // Slight delay to ensure smooth transition
    return () => clearTimeout(timeout); // Clean up timeout on unmount or change
  }, [expanded]);

  console.log("breadcrumbPath okkk", breadcrumbPath);

  const navigateToHome = () => {
    if (homePageFlag) return;
    console.log(
      "loggedInUsername, og_username 2",
      effectiveUsername,
      loggedInUsername,
      og_username
    );

    if (effectiveUsername === og_username) {
      navigate("/mypage");
    } else {
      navigate(`/user/${effectiveUsername}`);
    }
  };

  const handleBreadcrumbClick = (collection_id) => {
    if (homePageFlag) return;
    console.log(
      "loggedInUsername, og_username",
      effectiveUsername,
      loggedInUsername,
      og_username,
      collection_id
    );
    if (effectiveUsername === og_username) {
      navigate(`/collection/${collection_id}`);
    } else {
      navigate(`/user/${effectiveUsername}/collection/${collection_id}`);
    }
  };

  // function truncateString(str, num) {
  //   if (typeof str === "string" && str.length > num) {
  //     return str.slice(0, num) + "...";
  //   } else {
  //     return str || "";
  //   }
  // }

  function truncateString(str, num) {
    if (typeof str === "string" && str.length > num) {
      if (num <= 3) {
        // Not enough space for any characters and "..."
        return str.slice(0, num); // Return as much as possible
      }
      return str.slice(0, num - 3) + "...";
    } else {
      return str || "";
    }
  }

  // Updated getTruncatedBreadcrumb function
  function getTruncatedBreadcrumb(
    breadcrumbPath,
    maxCharLimit,
    lastCollectionLimit
  ) {
    if (breadcrumbPath.length === 0) return [];

    const homeText = "Home >";
    const homeTextLength = homeText.length;

    const separator = " > ";
    const separatorLength = separator.length;

    let lastCollection = breadcrumbPath[breadcrumbPath.length - 1];
    let lastCollectionName = lastCollection.collection_name;

    // Truncate last collection name to lastCollectionLimit
    lastCollectionName = truncateString(
      lastCollectionName,
      lastCollectionLimit
    );

    // Adjust last collection name if total length exceeds maxCharLimit
    let lastCollectionNameLength = lastCollectionName.length;
    let totalLength =
      homeTextLength + separatorLength + lastCollectionNameLength;

    if (totalLength > maxCharLimit) {
      let availableLength = maxCharLimit - homeTextLength - separatorLength;
      if (availableLength <= 0) {
        // Not enough space even for "Home > "
        return [];
      }
      lastCollectionName = truncateString(lastCollectionName, availableLength);
      lastCollectionNameLength = lastCollectionName.length;
      totalLength = homeTextLength + separatorLength + lastCollectionNameLength;
    }

    let truncatedBreadcrumb = [];

    // Build the breadcrumb from the beginning
    for (let i = 0; i < breadcrumbPath.length - 1; i++) {
      const collection = breadcrumbPath[i];
      let collectionName = collection.collection_name;
      let collectionNameLength = collectionName.length;

      // Compute potential total length if we add this collection and separator
      let potentialTotalLength =
        totalLength + separatorLength + collectionNameLength;

      if (potentialTotalLength > maxCharLimit) {
        // Try to truncate collectionName to fit
        let availableLength = maxCharLimit - totalLength - separatorLength;
        if (availableLength > 0) {
          collectionName = truncateString(collectionName, availableLength);
          collectionNameLength = collectionName.length;
          totalLength += separatorLength + collectionNameLength;
          truncatedBreadcrumb.push({
            ...collection,
            collection_name: collectionName,
          });
        }
        // Can't add more collections after this
        break;
      } else {
        // Add the collection as is
        totalLength = potentialTotalLength;
        truncatedBreadcrumb.push(collection);
      }
    }

    // Always add the last collection name
    truncatedBreadcrumb.push({
      ...lastCollection,
      collection_name: lastCollectionName,
    });

    return truncatedBreadcrumb;
  }

  // Remove the first breadcrumb if its name is "root"
  const filteredBreadcrumbPath =
    breadcrumbPath.length > 0 && breadcrumbPath[0].collection_name === "root"
      ? breadcrumbPath.slice(1)
      : breadcrumbPath;

  // Create a shallow copy before reversing to prevent mutation
  const reversedBreadcrumbPath = [...filteredBreadcrumbPath].reverse();

  // For debugging purposes
  console.log("Original Breadcrumb Path:", filteredBreadcrumbPath);
  console.log("Reversed Breadcrumb Path:", reversedBreadcrumbPath);

  // Calculate the truncated breadcrumb based on character limit
  // const getTruncatedBreadcrumb = (breadcrumbPath, maxCharLimit) => {
  // const getTruncatedBreadcrumb = (
  //   breadcrumbPath,
  //   maxCharLimit,
  //   lastCollectionLimit
  // ) => {
  //   if (breadcrumbPath.length === 0) return [];

  //   const homeTextLength = "Home >".length;

  //   // Ensure the last collection name respects the defined limit
  //   // let lastCollectionName =
  //   //   breadcrumbPath[breadcrumbPath.length - 1].collection_name;
  //   // lastCollectionName = truncateString(
  //   //   lastCollectionName,
  //   //   LAST_COLLECTION_LIMIT
  //   // );
  //   // const lastCollectionNameLength = lastCollectionName.length;
  //   let lastCollectionName =
  //     breadcrumbPath[breadcrumbPath.length - 1].collection_name;
  //   lastCollectionName = truncateString(
  //     lastCollectionName,
  //     lastCollectionLimit
  //   );
  //   const lastCollectionNameLength = lastCollectionName.length;

  //   // Calculate the remaining character limit after including "Home >" and last collection
  //   const remainingCharLimit =
  //     maxCharLimit - homeTextLength - lastCollectionNameLength;

  //   // Allocate characters to intermediate collections
  //   const truncatedBreadcrumb = [];
  //   let currentTotalLength = 0;

  //   // Loop through each collection except the last one
  //   for (let i = 0; i < breadcrumbPath.length - 1; i++) {
  //     const collectionName = breadcrumbPath[i].collection_name;
  //     const collectionNameLength = collectionName.length;

  //     // If adding this collection's name exceeds the remaining limit, truncate it
  //     if (currentTotalLength + collectionNameLength > remainingCharLimit) {
  //       const availableLength = remainingCharLimit - currentTotalLength;
  //       truncatedBreadcrumb.push({
  //         ...breadcrumbPath[i],
  //         collection_name: truncateString(collectionName, availableLength),
  //       });
  //       break; // Stop adding more collections
  //     } else {
  //       truncatedBreadcrumb.push(breadcrumbPath[i]);
  //       currentTotalLength += collectionNameLength;
  //     }
  //   }

  //   // Always add the last collection name with truncation as necessary
  //   truncatedBreadcrumb.push({
  //     ...breadcrumbPath[breadcrumbPath.length - 1],
  //     collection_name: lastCollectionName,
  //   });

  //   return truncatedBreadcrumb;
  // };

  // Create two versions of the breadcrumb paths: one for expanded and one for collapsed
  // const truncatedCollapsedBreadcrumbPath = getTruncatedBreadcrumb(
  //   reversedBreadcrumbPath,
  //   COLLAPSED_MAX_CHAR_LIMIT
  // );

  // const truncatedExpandedBreadcrumbPath = getTruncatedBreadcrumb(
  //   reversedBreadcrumbPath,
  //   EXPANDED_MAX_CHAR_LIMIT
  // );

  // const truncatedCollapsedBreadcrumbPath = React.useMemo(
  //   () =>
  //     getTruncatedBreadcrumb(reversedBreadcrumbPath, COLLAPSED_MAX_CHAR_LIMIT),
  //   [reversedBreadcrumbPath]
  // );

  // const truncatedExpandedBreadcrumbPath = React.useMemo(
  //   () =>
  //     getTruncatedBreadcrumb(reversedBreadcrumbPath, EXPANDED_MAX_CHAR_LIMIT),
  //   [reversedBreadcrumbPath]
  // );

  const truncatedCollapsedBreadcrumbPath = React.useMemo(
    () =>
      getTruncatedBreadcrumb(
        reversedBreadcrumbPath,
        COLLAPSED_MAX_CHAR_LIMIT,
        COLLAPSED_LAST_COLLECTION_LIMIT
      ),
    [reversedBreadcrumbPath]
  );

  const truncatedExpandedBreadcrumbPath = React.useMemo(
    () =>
      getTruncatedBreadcrumb(
        reversedBreadcrumbPath,
        EXPANDED_MAX_CHAR_LIMIT,
        EXPANDED_LAST_COLLECTION_LIMIT
      ),
    [reversedBreadcrumbPath]
  );

  const areBreadcrumbsIdentical = (path1, path2) => {
    if (path1.length !== path2.length) return false;
    return path1.every(
      (crumb, index) =>
        crumb.collection_id === path2[index].collection_id &&
        crumb.collection_name === path2[index].collection_name
    );
  };

  // Determine if the paths are the same
  const shouldUseTransition = !areBreadcrumbsIdentical(
    truncatedCollapsedBreadcrumbPath,
    truncatedExpandedBreadcrumbPath
  );

  // Create a string that represents the full breadcrumb path for the tooltip
  // const fullBreadcrumbPath = `Home > ${reversedBreadcrumbPath
  //   .map((crumb) => crumb.collection_name)
  //   .join(" > ")}`;

  const fullBreadcrumbPath = [
    <span key="home">Home</span>,
    ...reversedBreadcrumbPath.flatMap((crumb, index) => [
      " > ",
      <span key={crumb.collection_id} className="tooltip-item">
        {crumb.collection_name}
      </span>,
    ]),
  ];

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* Breadcrumb Container */}
      <div className="breadcrumb-container">
        {/* Static "Home >" part with tooltip */}
        <span
          className="breadcrumb-tooltip"
          onClick={navigateToHome}
          style={{ cursor: "pointer" }}
        >
          <span className="breadcrumb-tooltip-text">Home</span>
          {/* Tooltip text */}
          <span className="tooltip-text">{fullBreadcrumbPath}</span>
          {/* <span style={{ textDecoration: "none" }} className="tooltip-text"> */}
          {/* {renderTooltipText()} */}
          {/* </span> */}
        </span>
        <span style={{ margin: "0 4px" }}>{">"}</span>

        {/* Collapsed breadcrumb */}
        <div
          className={`breadcrumb-transition collapsed ${
            shouldUseTransition && (isVisible || expanded)
              ? "breadcrumb-hidden"
              : "breadcrumb-visible"
          }`}
          style={{ display: "inline-flex", marginLeft: "0px" }}
        >
          {truncatedCollapsedBreadcrumbPath.map((collection, index) => (
            <React.Fragment key={collection.collection_id}>
              <span
                onClick={() => handleBreadcrumbClick(collection.collection_id)}
                className="breadcrumb-tooltip"
              >
                <span className={`breadcrumb-text-wrapper collapsed`}>
                  <span
                    className="breadcrumb-tooltip-text"
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      margin: "0 2px", // Adjust spacing here to reduce gap
                    }}
                  >
                    {collection.collection_name}
                  </span>
                </span>
                <span className="tooltip-text">{fullBreadcrumbPath}</span>
              </span>
              {index < truncatedCollapsedBreadcrumbPath.length - 1 && (
                <span style={{ margin: "0 2px" }}>{">"}</span>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Expanded breadcrumb */}
        <div
          className={`breadcrumb-transition expanded ${
            shouldUseTransition && isVisible && expanded
              ? "breadcrumb-visible"
              : "breadcrumb-hidden"
          }`}
          style={{ display: "inline-flex", marginLeft: "0px" }}
        >
          {truncatedExpandedBreadcrumbPath.map((collection, index) => (
            <React.Fragment key={collection.collection_id}>
              <span
                className="breadcrumb-tooltip"
                onClick={() => handleBreadcrumbClick(collection.collection_id)}
              >
                <span
                  className={`breadcrumb-text-wrapper ${
                    isVisible ? "expanded" : "collapsed"
                  }`}
                >
                  <span
                    className="breadcrumb-tooltip-text"
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      margin: "0 2px",
                    }}
                  >
                    {collection.collection_name}
                  </span>
                </span>
                <span className="tooltip-text">{fullBreadcrumbPath}</span>
              </span>
              {index < truncatedExpandedBreadcrumbPath.length - 1 && (
                <span style={{ margin: "0 2px" }}>{">"}</span>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TreeBreadCrumb;
