export const toggleArtefactPublicity = async (
  username,
  artefactId,
  isPublic,
  coordxPublic,
  coordyPublic
) => {
  try {
    const endpoint = isPublic
      ? `/api/user/${username}/artefact/${artefactId}/publicise`
      : `/api/user/${username}/artefact/${artefactId}/unpublicise`;

    const bodyContent = isPublic
      ? {
          is_public: isPublic,
          coordx_public: coordxPublic,
          coordy_public: coordyPublic,
        }
      : { is_public: isPublic };

    const response = await fetch(endpoint, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify(bodyContent),
    });

    if (response.ok) {
      return true;
    } else {
      console.error("Failed to toggle artefact publicity");
      return false;
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return false;
  }
};
