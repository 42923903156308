import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import greyLogoIcon from "../grey_logo_icon.png"; // Adjust the path as needed
import ContactUsModal from "./Modals/ContactUsModal";
import "../css/HomeNavbar.css";

const HomeNavbar = () => {
  const navigate = useNavigate();

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const handleOpenContactModal = () => {
    setIsContactModalOpen(true);
  };
  //Hello

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
  };

  const buttonStyle = {
    borderRadius: "5px",
    color: "#888",
    padding: "4px 12px 1px 12px",
    backgroundColor: "transparent",
    transition: "color 0.3s ease, background-color 0.3s ease",
    border: "1px solid transparent",
    textAlign: "center",
    cursor: "pointer",
    noWrap: "true",
    overflow: "hidden",
    userSelect: "none",
  };

  return (
    <div className="home-navbar-container">
      <div
        className="navbar no-select"
        style={{
          position: "absolute",
          top: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#f1f1f1",
          padding: "10px 20px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          zIndex: 100,
          display: "flex",
          alignItems: "center",
          gap: "20px",
          fontFamily: "raleway",
          flexWrap: "nowrap",
        }}
        draggable="false"
        onDragStart={(e) => e.preventDefault()}
      >
        <img
          src={greyLogoIcon}
          alt="Logo Icon"
          style={{
            height: "30px",
            pointerEvents: "auto",
            draggable: "false",
            userSelect: "none",
            cursor: "pointer",
            WebkitUserDrag: "none",
          }}
          onClick={() => navigate("/")}
        />

        <button
          className="no-select"
          style={buttonStyle}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#dddddd";
            e.target.style.color = "#777777"; // Change font color on hover
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "transparent";
            e.target.style.color = "#888"; // Reset font color on mouse leave
          }}
          // onClick={() => navigate("/contact")}
          onClick={handleOpenContactModal}
        >
          Contact Us
        </button>
        <button
          className="no-select"
          style={buttonStyle}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#dddddd";
            e.target.style.color = "#777777";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "transparent";
            e.target.style.color = "#888";
          }}
          onClick={() => navigate("/signin")}
          draggable="false"
          onDragStart={(e) => e.preventDefault()}
        >
          Sign In
        </button>
        <button
          className="no-select"
          style={buttonStyle}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#dddddd";
            e.target.style.color = "#777777";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "transparent";
            e.target.style.color = "#888";
          }}
          onClick={() => navigate("/signup")}
        >
          Sign Up
        </button>
      </div>
      <ContactUsModal
        show={isContactModalOpen}
        handleClose={handleCloseContactModal}
      />
    </div>
  );
};

export default HomeNavbar;
