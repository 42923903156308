export const fetchFollowersCount = async (username) => {
  try {
    const response = await fetch(`/api/user/${username}/followers_count`);
    if (response.ok) {
      const data = await response.json();
      return data.count; // Assuming the backend sends the count in a field named `count`
    } else {
      console.error("Failed to fetch followers count");
      return 0; // Return a default value or handle the error as needed
    }
  } catch (error) {
    console.error("Error fetching followers count:", error);
    return 0; // Return a default value or handle the error as needed
  }
};

export const fetchFolloweesCount = async (username) => {
  try {
    const response = await fetch(`/api/user/${username}/followees_count`);
    if (response.ok) {
      const data = await response.json();
      return data.count; // Assuming the backend sends the count in a field named `count`
    } else {
      console.error("Failed to fetch followees count");
      return 0; // Return a default value or handle the error as needed
    }
  } catch (error) {
    console.error("Error fetching followees count:", error);
    return 0; // Return a default value or handle the error as needed
  }
};
