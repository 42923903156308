import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "../../css/FriendsModal.css";
import CloseButton from "react-bootstrap/esm/CloseButton";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

function FriendsModal({
  show,
  handleClose,
  loggedInUsername,
  onRequestsNotification,
}) {
  const [followers, setFollowers] = useState([]);
  const [followees, setFollowees] = useState([]);
  const [incomingRequests, setIncomingRequests] = useState([]);

  const navigate = useNavigate();

  const fetchFollowers = useCallback(() => {
    fetch(`/api/followers/`)
      .then((res) => res.json())
      .then((data) => setFollowers(data))
      .catch((err) => console.error(err));
  }, []);

  const fetchFollowees = useCallback(() => {
    fetch(`/api/followees/`)
      .then((res) => res.json())
      .then((data) => setFollowees(data))
      .catch((err) => console.error(err));
  }, []);

  // const fetchRequests = useCallback(() => {
  //   fetch(`/api/follower_requests`)
  //     .then((res) => res.json())
  //     .then((data) => setIncomingRequests(data))
  //     .catch((err) => console.error(err));
  // }, []);

  const fetchRequests = useCallback(() => {
    fetch(`/api/follower_requests`)
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setIncomingRequests(data);
        } else {
          setIncomingRequests([]); // Set to empty array if not
        }
        if (onRequestsNotification) {
          onRequestsNotification(data.length > 0); // Notify NavBar if there are requests
        }
      })
      .catch((err) => console.error(err));
  }, [onRequestsNotification]);

  const handleAccept = (id) => {
    fetch("/api/accept_follow", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ follower: id }),
    })
      .then((response) => {
        if (response.ok) {
          setIncomingRequests((prevState) =>
            prevState.filter((request) => request.username !== id)
          );
          setFollowers((prevFollowers) => [...prevFollowers, { username: id }]);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleReject = (id) => {
    fetch("/api/reject_follow", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ follower: id }),
    })
      .then((response) => {
        if (response.ok) {
          setIncomingRequests((prevState) =>
            prevState.filter((request) => request.username !== id)
          );
        }
      })
      .catch((error) => console.error(error));
  };

  const handleUnfollow = (username) => {
    fetch(`/api/user/${username}/unfollow`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          setFollowees((prevFollowees) => {
            return prevFollowees.filter(
              (followee) => followee.username !== username
            );
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const handleRemoveFollower = (username) => {
    fetch(`/api/user/${username}/remove_follower`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          setFollowers((prevFollowers) => {
            return prevFollowers.filter(
              (follower) => follower.username !== username
            );
          });
        } else {
          throw new Error("Failed to remove follower");
        }
      })
      .catch((error) => {
        console.error("Error removing follower:", error);
      });
  };

  useEffect(() => {
    // Fetch the data initially for the selected tab
    fetchFollowers();
    fetchFollowees();
    fetchRequests();

    // Set up interval to fetch data every 5 seconds
    const intervalId = setInterval(() => {
      fetchFollowers();
      fetchFollowees();
      fetchRequests();
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchFollowers, fetchFollowees, fetchRequests]);

  const handleSelect = (key) => {
    if (key === "followers") {
      fetchFollowers();
    } else if (key === "following") {
      fetchFollowees();
    } else if (key === "requests") {
      fetchRequests();
    }
  };

  return (
    <div>
      <Modal
        className="friends-modal-container"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        height="200px"
        size="md"
      >
        <Modal.Body className="friends-modal-box no-select">
          <CloseButton className="close-button" onClick={handleClose} />
          <div className="friends-modal">
            <p
              className="friends-title no-select"
              style={{ userSelect: "none" }}
            >
              Friends
            </p>
            <Tab.Container defaultActiveKey="followers" onSelect={handleSelect}>
              <Nav
                variant="tabs"
                className="justify-content-center no-select"
                style={{
                  fontSize: "18px",
                  whiteSpace: "nowrap",
                  userSelect: "none",
                }}
              >
                <Nav.Item className="nav-item-hover">
                  <Nav.Link eventKey="followers">Followers</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item-hover">
                  <Nav.Link eventKey="following">Following</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item-hover">
                  <Nav.Link eventKey="requests">
                    Requests
                    {incomingRequests.length > 0 && (
                      <span
                        className="notification-dot-requests"
                        style={{
                          // display: "inline-block",
                          // marginLeft: "8px",
                          position: "absolute",
                          top: "66px",
                          // right: '52px',
                          // right: "12%",
                          marginLeft: "1px",
                          height: "6px",
                          width: "6px",
                          backgroundColor: "red",
                          borderRadius: "50%",
                        }}
                      ></span>
                    )}
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content style={{ fontSize: "19px" }}>
                <Tab.Pane eventKey="followers">
                  <div className="scrollable-area">
                    {followers.length > 0 ? (
                      followers.map((follower, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "10px",
                            marginTop: "5px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            onClick={() =>
                              navigate(`/user/${follower.username}`)
                            }
                          >
                            {follower.username}
                          </div>
                          <button
                            className="no-select"
                            onClick={() =>
                              handleRemoveFollower(follower.username)
                            }
                            style={{
                              marginLeft: "10px",
                              fontSize: "16px",
                              borderRadius: "5px",
                              border: "none",
                              backgroundColor: "#ccc",
                              padding: "2px 6px",
                              cursor: "pointer",
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      ))
                    ) : (
                      <p>No followers</p>
                    )}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="following">
                  <div className="scrollable-area">
                    {followees.length > 0 ? (
                      followees.map((followee, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "10px",
                            marginTop: "5px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            onClick={() =>
                              navigate(`/user/${followee.username}`)
                            }
                          >
                            {followee.username}{" "}
                          </div>
                          <button
                            className="no-select"
                            onClick={() => handleUnfollow(followee.username)}
                            style={{
                              fontSize: "16px",
                              borderRadius: "5px",
                              border: "none",
                              backgroundColor: "#ccc", // Adjust the grey color as you like
                              marginLeft: "7px",
                              paddingTop: "2px",
                            }}
                          >
                            Unfollow
                          </button>
                        </div>
                      ))
                    ) : (
                      <p>Not following anyone</p>
                    )}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="requests">
                  <div className="scrollable-area">
                    {incomingRequests.length === 0 ? (
                      <p>No incoming requests</p>
                    ) : (
                      incomingRequests.map((request, index) => (
                        <div
                          key={index}
                          style={{
                            marginBottom: "10px",
                            marginTop: "5px",
                            marginLeft: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{ marginRight: "0px", cursor: "pointer" }}
                          >
                            <div
                              onClick={() =>
                                navigate(`/user/${request.username}`)
                              }
                            >
                              {request.username}
                            </div>
                            <button
                              className="no-select"
                              onClick={() => handleAccept(request.username)}
                              style={{
                                fontSize: "16px",
                                borderRadius: "5px",
                                border: "none",
                                backgroundColor: "#ccc",
                                marginLeft: "15px",
                                paddingTop: "2px",
                              }}
                            >
                              Accept
                            </button>
                            <button
                              className="no-select"
                              onClick={() => handleReject(request.username)}
                              style={{
                                fontSize: "16px",
                                borderRadius: "5px",
                                border: "none",
                                backgroundColor: "#ccc",
                                marginLeft: "2px",
                                paddingTop: "2px",
                                width: "70px",
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FriendsModal;
