export const moveArtefact = async ({
  loggedInUsername,
  artefactId,
  coordx_private,
  coordy_private,
  coordx_public,
  coordy_public,
  isPublicView,
}) => {
  const response = await fetch(
    `/api/user/${loggedInUsername}/artefact/${artefactId}/move`,
    {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({
        coordx_private,
        coordy_private,
        coordx_public,
        coordy_public,
        isPublicView: isPublicView,
      }),
    }
  );
  return response.status;
};
