export const updateDescription = async (description) => {
  try {
    const response = await fetch("/api/account/update_description", {
      // <-- updated to match backend
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({
        description,
      }),
    });

    if (response.ok) {
      console.log("Description updated successfully");
      return true;
    } else {
      console.error("Failed to update description. Status:", response.status);
      return false;
    }
  } catch (error) {
    console.error("API call failed:", error);
    return false;
  }
};
