import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/ContextMenu.css";
import ConfirmDeleteModal from "../Modals/ConfirmDeleteModal";
import { FaAngleRight } from "react-icons/fa";

function CollectionContextMenu({
  isVisible,
  x,
  y,
  handleDeleteCollection,
  contextMenuStateCollectionId,
  contextMenuStateCollection,
  hideContextMenu,
  onMoveCollection,
  onEditCollection,
  allCollections,
  changeCollection,
  isSubMenuOpen,
  setSubMenuOpen,
  loggedInUsername,
  submenuStack,
  setSubmenuStack,
  innerDivRef,
  setCollectionData,
  setPlacingMode,
  setPreviewCollection,
  collections,
  isPublicView,
  pendingParentChange,
  setPendingParentChange,
  rootCollectionId,
  setRootCollectionId,
  isChangeCollectionPressed,
  setChangeCollectionPressed,
  isFetching,
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [modalCollectionType, setModalCollectionType] = useState(null);
  const [modalCollectionId, setModalCollectionId] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [collectionsTree, setCollectionsTree] = useState([]); // Store fetched collections
  // const [isChangeCollectionPressed, setChangeCollectionPressed] =
  //   useState(false);

  const navigate = useNavigate();

  // Fetch collections tree from the backend
  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await fetch(
          // `/api/user/${loggedInUsername}/collections_tree`
          `/api/account/full_tree`
        );
        const data = await response.json();
        console.log("Fetched collections data:", data);
        setRootCollectionId(data.root.collection_id);
        setCollectionsTree(data.children); // Set the nested collections
      } catch (error) {
        console.error("Failed to fetch collections:", error);
      }
    };

    fetchCollections();
  }, [loggedInUsername, collections]);

  const [pressedSubmenuButtons, setPressedSubmenuButtons] = useState([]);

  const openSubMenu = (collection, depth, event) => {
    event.stopPropagation();

    const buttonRect = event.currentTarget.getBoundingClientRect();
    const innerDivRect = innerDivRef.current.getBoundingClientRect();

    const xRelativeToPanningBox = buttonRect.left - innerDivRect.left;
    const yRelativeToPanningBox = buttonRect.top - innerDivRect.top;

    setSubmenuStack((prevStack) => {
      const isAlreadyOpenAtDepth =
        prevStack[depth] &&
        prevStack[depth].collection_id === collection.collection_id;

      setPressedSubmenuButtons((prevPressed) => {
        const newPressed = [...prevPressed];

        // Reset pressed state for this level if already pressed
        if (isAlreadyOpenAtDepth) {
          newPressed[depth] = null; // Unpress the button
        } else {
          // Set pressed state for this level
          newPressed[depth] = collection.collection_id;
        }

        return newPressed;
      });

      if (isAlreadyOpenAtDepth) {
        // return prevStack;
        return prevStack.slice(0, depth);
      }

      const newStack = prevStack.slice(0, depth).concat({
        ...collection,
        parentX: xRelativeToPanningBox,
        parentY: yRelativeToPanningBox,
      });
      return newStack;
    });
  };

  const closeLastSubMenu = () => {
    setSubmenuStack((prevStack) => {
      const newStack = prevStack.slice(0, -1);
      setPressedSubmenuButtons((prevPressed) => prevPressed.slice(0, -1));
      return newStack;
    });
  };

  // const toggleMainMenu = () => {
  //   setSubMenuOpen(!isSubMenuOpen);
  //   if (!isSubMenuOpen) {
  //     setSubmenuStack([]);
  //   }
  // };

  const toggleMainMenu = () => {
    setSubMenuOpen((prevState) => {
      const newState = !prevState;

      if (newState) {
        setChangeCollectionPressed(true);
        setSubmenuStack([]);
        setPressedSubmenuButtons([]);
        // Add event listeners to detect user interaction
        document.addEventListener("mousedown", handleDocumentMouseDown);
        document.addEventListener("mousemove", handleDocumentMouseMove);
      } else {
        setChangeCollectionPressed(false);
        setSubmenuStack([]);
        setPressedSubmenuButtons([]);
        // Remove event listeners when closing the menu
        document.removeEventListener("mousedown", handleDocumentMouseDown);
        document.removeEventListener("mousemove", handleDocumentMouseMove);
      }

      return newState;
    });
  };

  const handleDocumentMouseDown = (event) => {
    // Check if the click is outside the submenu
    if (
      isSubMenuOpen &&
      !event.target.closest(".context-submenu-wrapper") &&
      !event.target.closest(".context-menu-wrapper")
    ) {
      setSubMenuOpen(false);
      setSubmenuStack([]);
      // Remove event listeners since we're closing the menu
      document.removeEventListener("mousedown", handleDocumentMouseDown);
      document.removeEventListener("mousemove", handleDocumentMouseMove);
    }
  };

  const handleDocumentMouseMove = (event) => {
    // Check if the mouse is moving over the submenu
    const submenuElement = document.querySelector(".context-submenu-wrapper");
    if (submenuElement && submenuElement.contains(event.target)) {
      // The mouse is moving over the submenu, keep it open
      return;
    }

    // If the submenu is open and the mouse is moving outside, close it
    if (isSubMenuOpen) {
      setSubMenuOpen(false);
      setSubmenuStack([]);
      // Remove event listeners since we're closing the menu
      document.removeEventListener("mousedown", handleDocumentMouseDown);
      document.removeEventListener("mousemove", handleDocumentMouseMove);
    }
  };

  const handleMoveToAnotherCollection = (collectionId) => {
    if (!collectionId) {
      console.error("No collection selected for move.");
      return;
    }

    if (collectionId === rootCollectionId) {
      // Navigate to "/mypage" if the root collection is selected
      navigate("/mypage");
    } else {
      console.log("Selected collection:", collectionId);

      // Navigate to the selected collection
      navigate(`/collection/${collectionId}`);
    }

    setCollectionData({
      collectionName: contextMenuStateCollection.collection_name,
      collectionId: contextMenuStateCollection.collection_id,
      dummyFlag: true,
    });

    // setPendingParentChange(true);
    setPendingParentChange({
      isPending: true,
      contextMenuStateCollectionId: contextMenuStateCollection.collection_id,
      newParentId: collectionId,
    });

    // setPlacingMode(true);

    // const preview = {
    //   name: contextMenuStateCollection.collection_name,
    //   coordinates: { x: 0, y: 0 },
    // };
    // setPreviewCollection(preview);

    // changeCollection(contextMenuStateCollectionId, collectionId);

    hideContextMenu();
    setSubMenuOpen(false);
  };

  const handleConfirmDelete = () => {
    handleDeleteCollection(modalCollectionId);
    setModalOpen(false);
    setModalCollectionType(null);
    setModalCollectionId(null);
  };

  const handleDeleteClick = () => {
    if (!contextMenuStateCollectionId) {
      console.error("No valid collection_id to delete.");
      return;
    }

    if (contextMenuStateCollection?.type === "empty") {
      handleDeleteCollection(contextMenuStateCollectionId);
      hideContextMenu();
    } else {
      setDeleteModalOpen(true);
      hideContextMenu();
      setModalCollectionType(contextMenuStateCollection?.type);
      setModalCollectionId(contextMenuStateCollectionId);
    }
  };

  const renderCollections = (
    collections,
    depth = 0,
    parentCollection = null
  ) => {
    console.log("Rendering collections:", collections);
    // const rootCollectionId = "ac6ab309-de53-4c2d-a923-167bb3de037e";

    const isContextMenuInRootParent = collections.some(
      (collection) =>
        collection.collection_id === contextMenuStateCollectionId &&
        collection.parent_id === rootCollectionId
    );

    return (
      <>
        {depth === 0 && (
          <div
            key="mypage-root"
            // className="dropdown-option"
            className={`dropdown-option ${
              isContextMenuInRootParent ? "current-option" : ""
            }`}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "7px 0px",
              textDecoration: "underline",
              backgroundColor: isContextMenuInRootParent
                ? "#D1DBFF"
                : "transparent",
              cursor: isContextMenuInRootParent ? "default" : "pointer",
            }}
            onClick={(event) => {
              event.stopPropagation();
              if (isContextMenuInRootParent) {
                return;
              }
              setSelectedCollection("root");
              handleMoveToAnotherCollection(rootCollectionId);
              setSubmenuStack([]);
            }}
          >
            <span
              style={{
                color: isContextMenuInRootParent ? "#000" : "inherit",
              }}
            >
              {"Home"}
            </span>
          </div>
        )}

        {parentCollection && (
          <div
            key={parentCollection.collection_id}
            className="dropdown-option parent-collection"
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "7px 0px",
              textDecoration: "underline",
              // pointerEvents: "none",
              backgroundColor:
                parentCollection.collection_id ===
                  contextMenuStateCollectionId ||
                parentCollection.children.some(
                  (child) =>
                    child.collection_id === contextMenuStateCollectionId
                )
                  ? "#D1DBFF"
                  : "transparent",
              cursor:
                parentCollection.collection_id ===
                  contextMenuStateCollectionId ||
                parentCollection.children.some(
                  (child) =>
                    child.collection_id === contextMenuStateCollectionId
                )
                  ? "default"
                  : "pointer",
            }}
            onClick={(event) => {
              event.stopPropagation();
              if (
                parentCollection.collection_id === contextMenuStateCollectionId
              ) {
                return;
              }
              // setSelectedCollection(parentCollection.collection_id);
              handleMoveToAnotherCollection(parentCollection.collection_id);
              setSubmenuStack([]);
            }}
          >
            <span
              style={{
                color:
                  parentCollection.collection_id ===
                  contextMenuStateCollectionId
                    ? "#000"
                    : "inherit",
              }}
            >
              {`${parentCollection.collection_name}`}
            </span>
          </div>
        )}

        {/* {parentCollection && (
          <div
            key={parentCollection.collection_id}
            className="dropdown-option parent-collection"
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "7px 0px",
              textDecoration: "underline",
              backgroundColor:
                parentCollection.collection_id ===
                  contextMenuStateCollectionId ||
                (parentCollection.children &&
                  parentCollection.children.some(
                    (child) =>
                      child.collection_id === contextMenuStateCollectionId
                  ))
                  ? "#D1DBFF"
                  : "transparent",
              cursor:
                parentCollection.collection_id ===
                  contextMenuStateCollectionId ||
                (parentCollection.children &&
                  parentCollection.children.some(
                    (child) =>
                      child.collection_id === contextMenuStateCollectionId
                  ))
                  ? "default"
                  : "pointer",
            }}
            onClick={(event) => {
              event.stopPropagation();
              if (
                parentCollection.collection_id === contextMenuStateCollectionId
              ) {
                return;
              }
              handleMoveToAnotherCollection(parentCollection.collection_id);
              setSubmenuStack([]);
            }}
          >
            <span
              style={{
                color:
                  parentCollection.collection_id ===
                  contextMenuStateCollectionId
                    ? "#000"
                    : "inherit",
              }}
            >
              {`${parentCollection.collection_name}`}
            </span>
          </div>
        )} */}

        {collections
          .filter(
            (collection) =>
              collection.type === "collections" || collection.type === "empty"
          )
          .map((collection) => {
            const isCurrentCollection =
              collection.collection_id === contextMenuStateCollectionId;
            const truncatedName =
              collection.collection_name.length > 14
                ? collection.collection_name.slice(0, 14) + "..."
                : collection.collection_name;

            // const isPressed = pressedSubmenuButtons[depth];
            const isPressed =
              pressedSubmenuButtons[depth] === collection.collection_id;

            return (
              <div
                key={collection.collection_id}
                // className="dropdown-option"
                // className={`dropdown-option ${
                //   isCurrentCollection ? "current-option" : ""
                // }`}
                className={`dropdown-option ${
                  isCurrentCollection ? "current-option" : ""
                } ${isPressed ? "pressed-option" : ""}`}
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: "7px 0px",
                  // backgroundColor: isCurrentCollection
                  //   ? "#D1DBFF"
                  //   : "transparent",
                  backgroundColor: isCurrentCollection
                    ? "#D1DBFF"
                    : isPressed
                    ? "#ccc" // Darken background when pressed
                    : "transparent",
                  cursor: isCurrentCollection ? "default" : "pointer",
                }}
                onPointerDown={(event) => {
                  event.stopPropagation();

                  if (isCurrentCollection) {
                    return;
                  }

                  if (collection.children.length > 0) {
                    // Open submenu for collections with children
                    openSubMenu(collection, depth, event);
                  } else {
                    // Handle move for collections without children
                    // setSelectedCollection(collection.collection_id);
                    handleMoveToAnotherCollection(collection.collection_id);
                    setSubmenuStack([]);
                  }
                }}
              >
                <span
                  style={{
                    color: isCurrentCollection ? "#000" : "inherit",
                    // pointerEvents: "none",
                  }}
                >
                  {truncatedName}
                </span>
                {collection.children.length > 0 && (
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                    }}
                  >
                    <FaAngleRight style={{ color: "#949494" }} />
                  </span>
                )}
              </div>
            );
          })}
      </>
    );
  };

  const ContextMenuStyle = {
    position: "absolute",
    top: `${y + 50}px`,
    left: `${x + 100}px`,
    display: isVisible ? "flex" : "none",
    flexDirection: "column",
    borderRadius: "5px",
    zIndex: "11",
  };

  const SubMenuStyle = (depth, parentX, parentY) => {
    const isFirstSubmenu = depth === 0;

    return {
      width: "118px",
      position: "absolute",
      top: isFirstSubmenu ? `${parentY}px` : `${parentY - 9}px`,
      left: isFirstSubmenu ? `${parentX + 255}px` : `${parentX + 154}px`,
      marginTop: isFirstSubmenu ? "0px" : `${depth * 0}px`,
      display: isSubMenuOpen ? "flex" : "none",
      flexDirection: "column",
      borderRadius: isFirstSubmenu ? "5px" : "10px",
      backgroundColor: isFirstSubmenu ? "#e9e9e9" : "#f9f9f9",
      zIndex: 11 + depth,
      transform: isFirstSubmenu ? "translateY(-50%)" : "0px",
    };
  };

  return (
    <>
      <div className="context-menu-wrapper no-select" style={ContextMenuStyle}>
        <div className="context-menu-box no-select">
          <button
            className="full-width-button"
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              zIndex: "200",
            }}
            onClick={() => {
              onEditCollection(contextMenuStateCollectionId);
              hideContextMenu();
            }}
          >
            Edit
          </button>

          <button
            className="full-width-button"
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              cursor: isFetching ? "default" : "pointer", // Step 5: Update styles based on isFetching
              opacity: isFetching ? 0.5 : 1,
            }}
            disabled={isFetching}
            onClick={() => {
              if (!isFetching) {
                onMoveCollection(contextMenuStateCollectionId);
                hideContextMenu();
              }
            }}
          >
            Move
          </button>

          {!isPublicView && (
            <button
              // className="full-width-button change-collection-button"
              className={`full-width-button ${
                isChangeCollectionPressed ? "change-collection-pressed" : ""
              }`}
              style={{
                width: "100%",
                padding: "10px 0px 10px 0px",
                marginLeft: "0px",
                marginRight: "-30px",
                borderRadius: "5px",
              }}
              onClick={toggleMainMenu}
            >
              Change Collection
            </button>
          )}

          <button
            className="full-width-button"
            style={{
              backgroundColor: "#FA4040",
              color: "white",
              borderRadius: "5px",
              width: "100%",
              padding: "7px",
              zIndex: "10",
            }}
            onClick={handleDeleteClick}
          >
            Delete
          </button>
        </div>
      </div>

      {isSubMenuOpen && (
        <div
          className="context-submenu-wrapper no-select"
          style={SubMenuStyle(0, x, y + 150)}
        >
          <div className="context-menu-box no-select">
            <div
              style={{
                width: "150px",
                padding: "0px",
                borderRadius: "5px",
                overflowY: "auto",
                userSelect: "none",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  fontSize: "15px",
                  padding: "7px 0px",
                  backgroundColor: "#e9e9e9",
                  borderRadius: "5px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Select a Collection:
              </div>

              <div className="dropdown-options">
                {renderCollections(collectionsTree, 0)}
              </div>
            </div>

            <button
              className="full-width-button"
              style={{
                width: "100%",
                padding: "7px",
                borderRadius: "5px",
                backgroundColor: "#e9e9e9",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSubMenuOpen(false);
                setSubmenuStack([]);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {submenuStack.map((parentCollection, index) => (
        <div
          key={parentCollection.collection_id}
          className="nested-submenu-wrapper no-select"
          style={SubMenuStyle(
            index + 1,
            parentCollection.parentX,
            parentCollection.parentY
          )}
        >
          <div className="context-menu-box no-select">
            <div
              style={{
                width: "150px",
                padding: "0px",
                borderRadius: "5px",
                overflowY: "auto",
                userSelect: "none",
                cursor: "pointer",
              }}
            >
              <div className="dropdown-options">
                {renderCollections(
                  parentCollection.children,
                  index + 1,
                  parentCollection
                )}
              </div>
            </div>

            <button
              className="full-width-button"
              style={{
                width: "100%",
                padding: "7px",
                borderRadius: "5px",
                backgroundColor: "#e9e9e9",
              }}
              onClick={(e) => {
                e.stopPropagation();
                closeLastSubMenu();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      ))}

      <ConfirmDeleteModal
        show={isDeleteModalOpen}
        onHide={() => {
          setDeleteModalOpen(false);
        }}
        onConfirm={handleConfirmDelete}
        collectionType={modalCollectionType}
        collectionId={modalCollectionId}
      />
    </>
  );
}

export default CollectionContextMenu;
