import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PannableBox_2 from "../components/PannableBox_2";
import NavBar_2 from "../components/NavBar_2";
import ExplorePageSearch from "../components/ExplorePageSearch";

const ExplorePage = (props) => {
  const [zoomedInFlag, setZoomedInFlag] = useState(false);
  const [isLowConnection, setIsLowConnection] = useState(false);

  function ConnectionIndicator() {
    return (
      <div
        className="low-connection"
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          color: "orange",
          marginLeft: "15px",
          marginTop: "15px",
          zIndex: "1000",
        }}
      >
        <div
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            backgroundColor: "orange",
            marginRight: "3px",
            marginTop: "-1px",
          }}
        ></div>
        <span>Low connection</span>
      </div>
    );
  }

  return (
    <div className="mypage-contents">
      {isLowConnection && <ConnectionIndicator />}

      <div style={{ position: "relative", zIndex: 2 }}>
        <NavBar_2
          currentCollection={props.currentCollection}
          loggedInUsername={props.loggedInUsername}
          setLoggedIn={props.setLoggedIn}
          setLoggedInUsername={props.setLoggedInUsername}
          handleCreateButtonClick={props.handleCreateButtonClick}
          collectionData={props.collectionData}
          setCollectionData={props.setCollectionData}
          artefactData={props.artefactData}
          setArtefactData={props.setArtefactData}
          placeArtefactsTrigger={props.placeArtefactsTrigger}
          setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
          isScreenCentered={props.isScreenCentered}
          centerScreenHandler={props.centerScreenHandler}
          setFilter={props.setFilter}
          setIsPublicView={props.setIsPublicView}
          setOg_username={props.setOg_username}
          searchResults={props.searchResults}
          setSearchResults={props.setSearchResults}
          og_username={props.og_username}
          loggedInFullname={props.loggedInFullname}
          setLoggedInFullname={props.setLoggedInFullname}
        />
        <ExplorePageSearch loggedInUsername={props.loggedInUsername} />
      </div>

      {/* <div style={{ position: "relative", zIndex: 1 }}>
        <ExplorePageSearch />
      </div> */}

      {/* <div style={{ position: "relative", zIndex: 0 }}> */}
      <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
        <PannableBox_2
          // collections={collections}
          // collections={filteredCollections}
          placingMode={props.placingMode}
          setPlacingMode={props.setPlacingMode}
          getter={props.submittedForm}
          setter={props.setSubmittedForm}
          currentCollection={props.currentCollection}
          collectionData={props.collectionData}
          artefactData={props.artefactData}
          setArtefactData={props.setArtefactData}
          // artefacts={artefacts}
          // setArtefacts={setArtefacts}
          // setCollections={setCollections}
          placeArtefactsTrigger={props.placeArtefactsTrigger}
          setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
          centerScreen={props.centerScreen}
          setZoomedInFlag={setZoomedInFlag}
          setCenterScreen={props.setCenterScreen}
          setIsScreenCentered={props.setIsScreenCentered}
          loggedInUsername={props.loggedInUsername}
          og_username={props.og_username}
          loggedInFullname={props.loggedInFullname}
          setLoggedInFullname={props.setLoggedInFullname}
          setIsLowConnection={setIsLowConnection}
          isLowConnection={isLowConnection}
        />
        {/* </div> */}
      </div>
    </div>
  );
};

export default ExplorePage;
