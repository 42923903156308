export const changeArtefactSizing = async (username, artefactId, newSize) => {
  try {
    const response = await fetch(
      `/api/user/${username}/artefact/${artefactId}/change_size`,
      {
        method: "PATCH", // or 'POST', depending on your backend setup
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sizing_choice: newSize }),
      }
    );

    if (response.ok) {
      // Handle successful update here, e.g., by showing a success message
      console.log("Size updated successfully");
    } else {
      // Handle errors, e.g., by showing an error message
      console.error("Failed to update size");
    }
  } catch (error) {
    console.error("Error updating size:", error);
  }
};
