import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../css/ExploreModal.css";
import CloseButton from "react-bootstrap/esm/CloseButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";

function ExploreModal({ show, handleClose }) {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [followStatuses, setFollowStatuses] = useState({});

  const handleFollow = (username, newStatus) => {
    fetch("/api/user/${username}/follow", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({ user_to_follow: userId }),
    })
      .then((response) => {
        if (response.ok) {
          // On a successful follow request, update the local state
          setFollowStatuses({
            ...followStatuses,
            [username]: newStatus,
          });
        } else {
          // Handle errors here
        }
      })
      .catch((error) => {
        console.error("An error occurred while fetching data:", error);
      });
  };

  useEffect(() => {
    if (searchTerm) {
      fetch(`/api/search/${searchTerm}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          data.forEach((user) => {
            fetch(`/api/user/${user.username}/follow_status`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "same-origin",
            })
              .then((response) => response.json())
              .then((followData) => {
                // Update the follow status for each user
                setFollowStatuses((prev) => ({
                  ...prev,
                  [user.username]: followData.status,
                }));
              });
          });
          setSearchResults(data);
        })
        .catch((error) => {
          console.error("An error occurred while fetching data: ", error);
        });
    } else {
      // Clear results when there is no search term
      setSearchResults([]);
    }
  }, [searchTerm]);

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
        className="explore-modal-modal"
        autoFocus
        font-family="Raleway"
      >
        <Modal.Body className="explore-modal-box">
          <CloseButton className="close-button" onClick={handleClose} />
          <div className="search-box">
            <p className="search-title">Search</p>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Search for friends"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
            <hr className="explore-modal-divider" />
            <div
              className="search-results"
              style={{
                display: "block",
                padding: "16px",
                // margin: "8px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                textDecoration: "none",
                backgroundColor: "EEEEEE",
              }}
            >
              {searchResults.map((user, index) => (
                <div key={user.user_id}>
                  <span onClick={() => navigate(`/user/${user.username}`)}>
                    {user.username}
                  </span>
                  <button
                    style={{
                      fontSize: "16px",
                      borderRadius: "5px",
                      border: "none",
                      backgroundColor: "#ccc", // Adjust the grey color as you like
                      marginLeft: "7px",
                      paddingTop: "2px",
                    }}
                    onClick={() => {
                      let currentStatus =
                        followStatuses[user.user_id] || "none";
                      let displayStatus = currentStatus;
                      let newStatus;

                      if (
                        currentStatus === "none" ||
                        currentStatus === "rejected"
                      ) {
                        displayStatus = "Follow";
                        newStatus = "pending";
                      } else if (currentStatus === "pending") {
                        displayStatus = "Pending";
                        newStatus = "none"; // Assuming you want to revert to no follow request. Adjust as needed.
                      } else if (currentStatus === "approved") {
                        displayStatus = "Following";
                        newStatus = "none"; // Assuming unfollow sets it back to 'none'. Adjust as needed.
                      }

                      handleFollow(user.user_id, newStatus);
                    }}
                  >
                    {(() => {
                      if (
                        followStatuses[user.user_id] === "none" ||
                        followStatuses[user.user_id] === "rejected"
                      ) {
                        return "Follow";
                      } else if (followStatuses[user.user_id] === "pending") {
                        return "Pending";
                      } else if (followStatuses[user.user_id] === "approved") {
                        return "Following";
                      }
                    })()}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ExploreModal;
