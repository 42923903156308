// import React, { useState, useEffect } from "react";
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form"; // Ensure Form is imported
// import { Link } from "react-router-dom"; // Ensure Link is imported for navigation
// import "../../css/AcknowledgeUpdate.css";

// function AcknowledgeUpdate({ show, onHide, onConfirm }) {
//   const message = (
//     <p>
//       We have updated our terms and conditions and privacy policy. Please review
//       and acknowledge these changes before continuing.
//     </p>
//   );

//   const [termsAccepted, setTermsAccepted] = useState(false);

//   useEffect(() => {
//     if (show) {
//       document.body.classList.add("disable-background");
//     } else {
//       document.body.classList.remove("disable-background");
//     }

//     return () => {
//       document.body.classList.remove("disable-background");
//     };
//   }, [show]);

//   return (
//     <div
//       className="acknowledge-update no-select"
//       onMouseDown={(e) => e.stopPropagation()}
//       onMouseUp={(e) => e.stopPropagation()}
//     >
//       <Modal
//         className="close-account-modal "
//         show={show}
//         onHide={null}
//         backdrop="static"
//         keyboard={false}
//         centered
//         dialogClassName="close-account-modal"
//         size="sm"
//       >
//         <Modal.Header>
//           <Modal.Title>Latest Changes</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="modal-body-close-account">{message}</Modal.Body>

//         <Modal.Footer
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             gap: "10px", // adds space between the input and the button
//             padding: "20px", // adds padding inside the footer for spacing around
//             margin: "0px", // removes margin around the footer
//           }}
//           className="modal-footer-close-account"
//         >
//           {/* Checkbox for accepting terms */}
//           <Form.Group className="mb-3" controlId="formBasicCheckbox">
//             <div className="terms-checkbox-container">
//               <Form.Check
//                 className="terms-checkbox"
//                 type="checkbox"
//                 checked={termsAccepted}
//                 onChange={(e) => setTermsAccepted(e.target.checked)}
//                 label={
//                   <span>
//                     I understand and agree to the
//                     <Link to="/terms-conditions" style={{ marginLeft: "5px" }}>
//                       terms and conditions
//                     </Link>
//                     {" and "}
//                     <Link to="/privacy-policy" style={{ marginLeft: "5px" }}>
//                       privacy policy
//                     </Link>
//                     <span
//                       className="required-asterisk"
//                       style={{ position: "absolute" }}
//                     >
//                       *
//                     </span>
//                   </span>
//                 }
//               />
//             </div>
//           </Form.Group>

//           {/* Confirm Button */}
//           <Button
//             className={`confirm-update-btn ${termsAccepted ? "active" : ""}`}
//             variant="primary"
//             onClick={onConfirm}
//             disabled={!termsAccepted}
//           >
//             Confirm
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }

// export default AcknowledgeUpdate;

import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import "../../css/AcknowledgeUpdate.css";

function AcknowledgeUpdate({ show, onConfirm }) {
  const message = (
    <p>
      We have updated our terms and conditions and privacy policy. Please review
      and acknowledge these changes before continuing.
    </p>
  );

  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    if (show) {
      document.body.classList.add("disable-background");
    } else {
      document.body.classList.remove("disable-background");
    }

    return () => {
      document.body.classList.remove("disable-background");
    };
  }, [show]);

  return (
    <div
      className="acknowledge-update no-select"
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <Modal
        className="close-account-modal"
        show={show}
        onHide={null}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="close-account-modal"
        size="sm"
      >
        <Modal.Header>
          <Modal.Title>Latest Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-close-account">{message}</Modal.Body>

        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            padding: "20px",
            margin: "0px",
          }}
          className="modal-footer-close-account"
        >
          {/* Checkbox for accepting terms */}
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <div className="terms-checkbox-container">
              <Form.Check
                className="terms-checkbox"
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                label={
                  <span>
                    I understand and agree to the
                    <Link to="/terms-conditions" style={{ marginLeft: "5px" }}>
                      terms and conditions
                    </Link>
                    {" and "}
                    <Link to="/privacy-policy" style={{ marginLeft: "5px" }}>
                      privacy policy
                    </Link>
                    <span
                      className="required-asterisk"
                      style={{ position: "absolute" }}
                    >
                      *
                    </span>
                  </span>
                }
              />
            </div>
          </Form.Group>

          {/* Confirm Button */}
          <Button
            className={`confirm-update-btn ${termsAccepted ? "active" : ""}`}
            variant="primary"
            onClick={termsAccepted ? onConfirm : null}
            disabled={!termsAccepted}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AcknowledgeUpdate;
