// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import "../css/MyPage.css";
// import PannableBox from "../components/PannableBox";
// import NavBar from "../components/NavBar";
// import TutorialModal from "../components/Modals/TutorialModal";
// import AcknowledgeUpdate from "../components/Modals/AcknowledgeUpdate"; // Import your AcknowledgeUpdate modal component

// const MyPage = (props) => {
//   const navigate = useNavigate();
//   const [collections, setCollections] = useState([]);
//   // const [collectionData, setCollectionData] = useState([]);
//   const [artefacts, setArtefacts] = useState([]);
//   const [isParentChange, setIsParentChange] = useState(false);

//   const [isLowConnection, setIsLowConnection] = useState(false);

//   // const [artefactData, setArtefactData] = useState({
//   //   artefactName: "",
//   //   imageURL: "",
//   //   type: "",
//   // });

//   // const [isPublicView, setIsPublicView] = useState(false);

//   // const [filter, setFilter] = useState("ALL"); // can be 'ALL' or 'PUBLIC_ONLY'

//   const [showTutorialModal, setShowTutorialModal] = useState(false);
//   const [showUpdateModal, setShowUpdateModal] = useState(false);

//   useEffect(() => {
//     // Check if we should show the tutorial modal
//     const shouldShowTutorial = localStorage.getItem("showTutorial");

//     if (shouldShowTutorial === "true") {
//       // Set a timeout of 2 seconds before showing the modal
//       const timer = setTimeout(() => {
//         setShowTutorialModal(true);

//         // After showing the modal once, remove the flag
//         localStorage.removeItem("showTutorial");
//       }, 2000); // 2000ms = 2 seconds

//       // Clean up the timer if the component unmounts
//       return () => clearTimeout(timer);
//     }
//   }, []);

//   const filteredCollections =
//     props.filter === "ALL"
//       ? collections
//       : collections.filter((collection) => collection.public_count > 0);

//   const checkUpdateStatus = async () => {
//     console.log("waffle 1");
//     try {
//       console.log("waffle 2");
//       const response = await fetch(
//         // "/api/check_update"
//         "/api/account/check_update"
//       );
//       console.log("waffle 3");
//       if (response.ok) {
//         console.log("waffle 4");
//         const data = await response.json();
//         console.log("waffle 5", data);
//         if (data.showModal) {
//           setShowUpdateModal(true);
//         }
//       } else {
//         console.error("Failed to check update status");
//       }
//     } catch (error) {
//       console.error("Error checking update status:", error);
//     }
//   };

//   // Function to handle update confirmation and proceed with other actions
//   const handleConfirmUpdate = async () => {
//     console.log("waffle 6");
//     try {
//       const response = await fetch("/api/account/acknowledge_update", {
//         method: "POST",
//       });
//       console.log("waffle 7");
//       if (response.ok) {
//         setShowUpdateModal(false); // Hide modal after acknowledgment
//       } else {
//         console.error("Failed to acknowledge update");
//       }
//     } catch (error) {
//       console.error("Error acknowledging update:", error);
//     }
//   };

//   useEffect(() => {
//     if (props.loggedIn === false) {
//       navigate("/");
//     } else if (props.loggedIn === true) {
//       props.setIsFetching(true);
//       fetch(`/api/user/${props.loggedInUsername}/collection/root`, {
//         method: "GET",
//         credentials: "same-origin",
//       })
//         .then((response) => {
//           if (response.status === 200) {
//             checkUpdateStatus();
//             response.json().then((j) => {
//               console.log("j is", j);
//               setCollections(j.data);
//               setArtefacts([]);
//               props.setCollectionRefresh(false);
//               props.setArtefactRefresh(false);
//             });
//           } else if (response.status === 404) {
//             setCollections([]);
//             setArtefacts([]);
//           } else {
//             console.error("Error has occurred in fetching root collections");
//             setCollections([]);
//             setArtefacts([]);
//           }
//         })
//         .catch((error) => {
//           console.error(error);
//         })
//         .finally(() => {
//           props.setIsFetching(false); // Step 3: Set isFetching to false once data is fetched
//         });
//     }
//   }, [
//     props.loggedIn,
//     props.submittedForm,
//     props.isPublicView,
//     props.collectionRefresh,
//   ]);

//   function ConnectionIndicator() {
//     return (
//       <div
//         className="low-connection"
//         style={{
//           position: "absolute",
//           display: "flex",
//           alignItems: "center",
//           color: "orange",
//           marginLeft: "15px",
//           marginTop: "15px",
//           zIndex: "1000",
//         }}
//       >
//         <div
//           style={{
//             width: "7px",
//             height: "7px",
//             borderRadius: "50%",
//             backgroundColor: "orange",
//             marginRight: "3px",
//             marginTop: "-1px",
//           }}
//         ></div>
//         <span>Low connection</span>
//       </div>
//     );
//   }

//   return (
//     <div className="mypage-contents">
//       <div style={{ position: "relative", zIndex: 2 }}>
//         <NavBar
//           currentCollection={props.currentCollection}
//           loggedInUsername={props.loggedInUsername}
//           setLoggedIn={props.setLoggedIn}
//           setLoggedInUsername={props.setLoggedInUsername}
//           handleCreateButtonClick={props.handleCreateButtonClick}
//           collectionData={props.collectionData}
//           setCollectionData={props.setCollectionData}
//           artefactData={props.artefactData}
//           setArtefactData={props.setArtefactData}
//           placeArtefactsTrigger={props.placeArtefactsTrigger}
//           setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
//           isScreenCentered={props.isScreenCentered}
//           centerScreenHandler={props.centerScreenHandler}
//           setFilter={props.setFilter}
//           isPublicView={props.isPublicView}
//           setIsPublicView={props.setIsPublicView}
//           zoomedInFlag={null}
//           og_username={props.og_username}
//           setOg_username={props.setOg_username}
//           droppedImageFile={null}
//           setDroppedImageFile={null}
//           searchResults={props.searchResults}
//           setSearchResults={props.setSearchResults}
//           loggedInFullname={props.loggedInFullname}
//           setLoggedInFullname={props.setLoggedInFullname}
//           artefactRefresh={props.artefactRefresh}
//           setArtefactRefresh={props.setArtefactRefresh}
//         />
//       </div>
//       <div style={{ position: "relative", zIndex: 1 }}>
//         <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
//           <PannableBox
//             // collections={collections}
//             collections={filteredCollections}
//             placingMode={props.placingMode}
//             setPlacingMode={props.setPlacingMode}
//             getter={props.submittedForm}
//             setter={props.setSubmittedForm}
//             currentCollection={props.currentCollection}
//             collectionData={props.collectionData}
//             setCollectionData={props.setCollectionData}
//             artefactData={props.artefactData}
//             setArtefactData={props.setArtefactData}
//             artefacts={artefacts}
//             setArtefacts={setArtefacts}
//             setCollections={setCollections}
//             placeArtefactsTrigger={props.placeArtefactsTrigger}
//             setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
//             centerScreen={props.centerScreen}
//             setCenterScreen={props.setCenterScreen}
//             setIsScreenCentered={props.setIsScreenCentered}
//             loggedInUsername={props.loggedInUsername}
//             handleCreateButtonClick={props.handleCreateButtonClick}
//             isPublicView={props.isPublicView}
//             otherUser={null}
//             isOwner={true}
//             og_username={props.og_username}
//             droppedImageFile={null}
//             setDroppedImageFile={null}
//             sharelinkUsername={props.sharelinkUsername}
//             setSharelinkUsername={props.setSharelinkUsername}
//             loggedInFullname={props.loggedInFullname}
//             setLoggedInFullname={props.setLoggedInFullname}
//             sharelinkFullname={null}
//             setSharelinkFullname={null}
//             collectionRefresh={props.collectionRefresh}
//             setCollectionRefresh={props.setCollectionRefresh}
//             isParentChange={isParentChange}
//             setIsParentChange={setIsParentChange}
//             artefactRefresh={props.artefactRefresh}
//             setArtefactRefresh={props.setArtefactRefresh}
//             isFetching={props.isFetching}
//           />
//         </div>
//         {showTutorialModal && (
//           <TutorialModal
//             show={showTutorialModal}
//             handleClose={() => setShowTutorialModal(false)}
//           />
//         )}
//         {showUpdateModal && (
//           <AcknowledgeUpdate
//             show={showUpdateModal}
//             onConfirm={handleConfirmUpdate}
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default MyPage;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/MyPage.css";
import PannableBox from "../components/PannableBox";
import NavBar from "../components/NavBar";
import TutorialModal from "../components/Modals/TutorialModal";
import AcknowledgeUpdate from "../components/Modals/AcknowledgeUpdate"; // Import your AcknowledgeUpdate modal component

const MyPage = (props) => {
  const navigate = useNavigate();
  const [collections, setCollections] = useState([]);
  const [artefacts, setArtefacts] = useState([]);
  const [isParentChange, setIsParentChange] = useState(false);
  const [isLowConnection, setIsLowConnection] = useState(false);
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    // Check if we should show the tutorial modal
    const shouldShowTutorial = localStorage.getItem("showTutorial");

    if (shouldShowTutorial === "true") {
      // Set a timeout of 2 seconds before showing the modal
      const timer = setTimeout(() => {
        setShowTutorialModal(true);

        // After showing the modal once, remove the flag
        localStorage.removeItem("showTutorial");
      }, 2000); // 2000ms = 2 seconds

      // Clean up the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, []);

  const filteredCollections =
    props.filter === "ALL"
      ? collections
      : collections.filter((collection) => collection.public_count > 0);

  const checkUpdateStatus = async () => {
    try {
      const response = await fetch("/api/account/check_update");
      if (response.ok) {
        const data = await response.json();
        if (data.showModal) {
          setShowUpdateModal(true);
        }
      } else {
        console.error("Failed to check update status");
      }
    } catch (error) {
      console.error("Error checking update status:", error);
    }
  };

  // Function to handle update confirmation and proceed with other actions
  const handleConfirmUpdate = async () => {
    try {
      const response = await fetch("/api/account/acknowledge_update", {
        method: "POST",
      });
      if (response.ok) {
        setShowUpdateModal(false); // Hide modal after acknowledgment
      } else {
        console.error("Failed to acknowledge update");
      }
    } catch (error) {
      console.error("Error acknowledging update:", error);
    }
  };

  const fetchCollections = async (retries = 120, delay = 1000) => {
    let attempts = 0;
    let hasShownLowConnection = false;

    // Set a timer to trigger the low connection indicator if fetching takes longer than 5 seconds
    const lowConnectionTimer = setTimeout(() => {
      setIsLowConnection(true);
      hasShownLowConnection = true;
    }, 5000);

    while (attempts < retries) {
      try {
        const response = await fetch(
          `/api/user/${props.loggedInUsername}/collection/root`,
          {
            method: "GET",
            credentials: "same-origin",
          }
        );

        if (!response.ok) {
          if (response.status === 404) {
            console.error("Collection not found, navigating back.");
            navigate("/");
            clearTimeout(lowConnectionTimer);
            return;
          }
          throw new Error("Network response was not ok");
        }

        const j = await response.json();

        setCollections(j.data);
        setArtefacts([]);
        props.setCollectionRefresh(false);
        props.setArtefactRefresh(false);

        clearTimeout(lowConnectionTimer); // Clear the timer if fetch completes within 5 seconds
        setIsLowConnection(false); // Ensure low connection is turned off when data is successfully loaded

        props.setIsFetching(false);
        return; // exit function
      } catch (error) {
        attempts++;
        console.error(
          `Error fetching collections, attempt ${attempts}:`,
          error
        );

        if (attempts === 1 && !hasShownLowConnection) {
          setIsLowConnection(true);
          hasShownLowConnection = true;
        }

        if (attempts >= retries) {
          console.error("Max retries reached. Skipping.");
          clearTimeout(lowConnectionTimer);
          return;
        }

        await new Promise((resolve) => setTimeout(resolve, delay));
      } finally {
        props.setIsFetching(false); // Ensure isFetching is set to false in the finally block
      }
    }
  };

  useEffect(() => {
    if (props.loggedIn === false) {
      navigate("/");
    } else if (props.loggedIn === true) {
      props.setIsFetching(true);
      fetchCollections();
    }
  }, [
    props.loggedIn,
    props.submittedForm,
    props.isPublicView,
    props.collectionRefresh,
  ]);

  function ConnectionIndicator() {
    return (
      <div
        className="low-connection"
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          color: "orange",
          marginLeft: "15px",
          marginTop: "15px",
          zIndex: "1000",
        }}
      >
        <div
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            backgroundColor: "orange",
            marginRight: "3px",
            marginTop: "-1px",
          }}
        ></div>
        <span>Low connection</span>
      </div>
    );
  }

  return (
    <div className="mypage-contents">
      {isLowConnection && <ConnectionIndicator />}
      <div style={{ position: "relative", zIndex: 2 }}>
        <NavBar
          currentCollection={props.currentCollection}
          loggedInUsername={props.loggedInUsername}
          setLoggedIn={props.setLoggedIn}
          setLoggedInUsername={props.setLoggedInUsername}
          handleCreateButtonClick={props.handleCreateButtonClick}
          collectionData={props.collectionData}
          setCollectionData={props.setCollectionData}
          artefactData={props.artefactData}
          setArtefactData={props.setArtefactData}
          placeArtefactsTrigger={props.placeArtefactsTrigger}
          setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
          isScreenCentered={props.isScreenCentered}
          centerScreenHandler={props.centerScreenHandler}
          setFilter={props.setFilter}
          isPublicView={props.isPublicView}
          setIsPublicView={props.setIsPublicView}
          zoomedInFlag={null}
          og_username={props.og_username}
          setOg_username={props.setOg_username}
          droppedImageFile={null}
          setDroppedImageFile={null}
          searchResults={props.searchResults}
          setSearchResults={props.setSearchResults}
          loggedInFullname={props.loggedInFullname}
          setLoggedInFullname={props.setLoggedInFullname}
          artefactRefresh={props.artefactRefresh}
          setArtefactRefresh={props.setArtefactRefresh}
        />
      </div>
      <div style={{ position: "relative", zIndex: 1 }}>
        <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
          <PannableBox
            collections={filteredCollections}
            placingMode={props.placingMode}
            setPlacingMode={props.setPlacingMode}
            getter={props.submittedForm}
            setter={props.setSubmittedForm}
            currentCollection={props.currentCollection}
            collectionData={props.collectionData}
            setCollectionData={props.setCollectionData}
            artefactData={props.artefactData}
            setArtefactData={props.setArtefactData}
            artefacts={artefacts}
            setArtefacts={setArtefacts}
            setCollections={setCollections}
            placeArtefactsTrigger={props.placeArtefactsTrigger}
            setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
            centerScreen={props.centerScreen}
            setCenterScreen={props.setCenterScreen}
            setIsScreenCentered={props.setIsScreenCentered}
            loggedInUsername={props.loggedInUsername}
            handleCreateButtonClick={props.handleCreateButtonClick}
            isPublicView={props.isPublicView}
            otherUser={null}
            isOwner={true}
            og_username={props.og_username}
            droppedImageFile={null}
            setDroppedImageFile={null}
            sharelinkUsername={props.sharelinkUsername}
            setSharelinkUsername={props.setSharelinkUsername}
            loggedInFullname={props.loggedInFullname}
            setLoggedInFullname={props.setLoggedInFullname}
            sharelinkFullname={null}
            setSharelinkFullname={null}
            collectionRefresh={props.collectionRefresh}
            setCollectionRefresh={props.setCollectionRefresh}
            isParentChange={isParentChange}
            setIsParentChange={setIsParentChange}
            artefactRefresh={props.artefactRefresh}
            setArtefactRefresh={props.setArtefactRefresh}
            isFetching={props.isFetching}
          />
        </div>
        {showTutorialModal && (
          <TutorialModal
            show={showTutorialModal}
            handleClose={() => setShowTutorialModal(false)}
          />
        )}
        {showUpdateModal && (
          <AcknowledgeUpdate
            show={showUpdateModal}
            onConfirm={handleConfirmUpdate}
          />
        )}
      </div>
    </div>
  );
};

export default MyPage;
