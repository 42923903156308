import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import { BsPlusLg, BsPersonCircle } from "react-icons/bs";
import { MdOutlineFilterCenterFocus } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "../css/NavBar.css";
import ChooseTypeModal from "./Modals/ChooseTypeModal";
import SettingsModal from "./Modals/SettingsModal";
import FriendsModal from "./Modals/FriendsModal";
import ExploreModal from "./Modals/ExploreModal";
import CreateArtefactModal from "./Modals/CreateArtefactModal";
import CreateCollectionModal from "./Modals/CreateCollectionModal";
import ExplorePage from "./ExplorePageSearch";

function NavBar(props) {
  const [activeModal, setActiveModal] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [hasNotifications, setHasNotifications] = useState(false);

  const handleCloseModal = () => {
    setActiveModal(null);
  };

  const handlePlusButtonClick = () => {
    if (props.currentCollection) {
      if (props.currentCollection.type === "collections") {
        setActiveModal("collection");
      } else if (props.currentCollection.type === "artefacts") {
        setActiveModal("artefact");
      } else {
        setActiveModal("choice");
      }
    } else {
      setActiveModal("collection");
    }
  };

  const handleFilterChange = (newFilter) => {
    props.setFilter(newFilter);
  };

  // const handleFilterChange = (newFilter) => {
  //   props.setFilter(newFilter);

  //   // Force a re-fetch of artefacts based on the new view
  //   if (props.isPublicView) {
  //     // Public view: set the filter to only show public artefacts
  //     props.setIsPublicView(true);
  //   } else {
  //     // Private view: set the filter to include both public and private artefacts
  //     props.setIsPublicView(false);
  //   }

  //   // Trigger a refresh of artefacts
  //   props.setArtefactRefresh({ refresh: true, artefactId: null });
  // };

  const navigate = useNavigate();

  const logoutClick = async (e) => {
    setActiveModal(null);
    e.preventDefault();

    try {
      const response = await fetch(`/api/signout`, {
        method: "POST",
        credentials: "same-origin",
      });

      if (response.status === 200) {
        console.log("response.status", response.status);

        // Successfully signed out
        props.setLoggedIn(false);
        props.setLoggedInUsername("");
        props.setOg_username("");
        navigate("/");
      } else if (response.status === 400) {
        console.log("response.status", response.status);

        // Bad request: handle this case differently
        console.error("Bad request during signout. Please try again.");
        // Optionally, show an error message to the user instead of logging them out
      } else if (response.status === 500) {
        console.log("response.status", response.status);

        // Internal Server Error
        throw new Error("Signout response was not ok");
      }
    } catch (error) {
      // Handle any other errors (e.g., network issues)
      console.error("An error occurred during signout:", error);
    }
  };

  const handleRequestsNotification = (hasRequests) => {
    setHasNotifications(hasRequests);
  };

  useEffect(() => {
    // Function to check if any modal is visible in the DOM
    const isModalOpen = () => {
      return !!document.querySelector(".modal.show");
    };

    // Function to handle the Enter key press
    const handleEnterPress = (event) => {
      if (props.placingMode) return;
      // Check if the pressed key is Enter (keyCode 13 or event.key === "Enter")
      if (event.key === "Enter") {
        event.preventDefault();
        // setActiveModal(null);
        // Ensure it's not active in form fields or text inputs and no modal is visible
        const activeElement = document.activeElement;
        if (
          activeElement.tagName !== "INPUT" &&
          activeElement.tagName !== "TEXTAREA" &&
          activeElement.getAttribute("contenteditable") !== "true" &&
          !isModalOpen() // Check if no modal is currently visible in the DOM
        ) {
          // Trigger the screen centering function if no modal is open
          if (typeof props.centerScreenHandler === "function") {
            props.centerScreenHandler(); // Call the screen centering function
          }
        }
      }
    };

    // Attach the event listener
    window.addEventListener("keydown", handleEnterPress);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleEnterPress);
    };
  }, [props.centerScreenHandler, props.placingMode]);

  useEffect(() => {
    // Function to check if any modal is visible in the DOM
    const isModalOpen = () => {
      return !!document.querySelector(".modal.show");
    };
    if (props.placingMode) return;

    // Function to handle the space bar press
    const handleSpaceBarPress = (event) => {
      if (props.placingMode) return;
      // Check if the pressed key is Space (keyCode 32 or event.key === " ")
      if (event.key === " " || event.keyCode === 32) {
        // Prevent default behavior of spacebar scrolling the page
        // event.preventDefault();
        // setActiveModal(null);

        // Ensure it's not active in form fields or text inputs and no modal is visible
        const activeElement = document.activeElement;
        if (
          activeElement.tagName !== "INPUT" &&
          activeElement.tagName !== "TEXTAREA" &&
          activeElement.getAttribute("contenteditable") !== "true" &&
          !isModalOpen() // Check if no modal is currently visible in the DOM
        ) {
          event.preventDefault();
          const plusButton = document.getElementById("plus-button");
          if (plusButton && !props.isPublicView) {
            plusButton.click();
          }
        }
      }
    };

    // Attach the event listener
    window.addEventListener("keydown", handleSpaceBarPress);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleSpaceBarPress);
    };
  }, [props.isPublicView, props.placingMode, activeModal]); // No dependency needed since we're only setting up a global event listener

  useEffect(() => {
    const isModalOpen = () => {
      return !!document.querySelector(".modal.show");
    };

    const handleKeyPress = (event) => {
      if (props.placingMode) return;

      const keyPressed = event.key.toLowerCase();

      // Prevent keypresses from being registered inside input fields or if modal is open
      const activeElement = document.activeElement;
      const isEditableElement =
        activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA" ||
        activeElement.getAttribute("contenteditable") === "true";

      // Check if the Ctrl or Shift keys are held down
      const isCtrlOrShiftPressed = event.ctrlKey || event.shiftKey;

      // If Ctrl or Shift are pressed, allow default behavior
      if (isEditableElement || isModalOpen() || isCtrlOrShiftPressed) {
        return; // Allow default behavior, including opening DevTools with Ctrl + Shift + I
      }

      // Handle key presses for "i", "w", and "s" only when Ctrl or Shift are not pressed
      if (
        props.currentCollection &&
        props.currentCollection.type === "artefacts" &&
        ["i", "w", "s", "t", "f", "p"].includes(keyPressed)
      ) {
        event.preventDefault(); // Prevent default action for the specified keys

        if (keyPressed === "i") {
          setActiveModal("artefact");
          setSelectedType("Image");
        } else if (keyPressed === "w" || keyPressed === "s") {
          setActiveModal("artefact");
          setSelectedType("Website");
        } else if (keyPressed === "t") {
          setActiveModal("artefact");
          setSelectedType("Text");
        } else if (keyPressed === "f" || keyPressed === "p") {
          setActiveModal("artefact");
          setSelectedType("PDF");
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [props.currentCollection, props.placingMode]);

  // Inside your NavBar component
  useEffect(() => {
    // Function to handle keydown events
    const handleKeyDown = (event) => {
      if (props.placingMode) return;

      // Check if the pressed key is the Escape key
      if (event.key === "Escape" || event.key === "AltGraph") {
        // Close the modal by setting the activeModal to null
        setActiveModal(null);
      }
    };

    // Attach the keydown event listener to the window
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [props.placingMode]); // The empty dependency array ensures this runs only on mount/unmount

  useEffect(() => {
    const keysPressed = new Set(); // Using Set to store the currently pressed keys

    const handleKeyDown = (event) => {
      if (props.placingMode) return;

      // Add the current key to the set and check for Caps Lock separately
      if (event.getModifierState("CapsLock")) {
        keysPressed.add("capslock");
      }
      keysPressed.add(event.key.toLowerCase());

      // Check if all desired keys are pressed simultaneously
      const requiredKeys = new Set(["capslock", "b", "l", "e", "y", "a"]);
      const isAllKeysPressed = [...requiredKeys].every((key) =>
        keysPressed.has(key)
      );

      if (isAllKeysPressed) {
        alert("Hey there, hope you're enjoying Showcase - Benjamin Belay");
        keysPressed.clear(); // Clear the set after the alert to prevent repeated alerts
      }
    };

    const handleKeyUp = (event) => {
      // Remove the released key from the set and check for Caps Lock separately
      keysPressed.delete(event.key.toLowerCase());

      // Handle the Caps Lock key separately, as it doesn't behave like other keys
      if (!event.getModifierState("CapsLock")) {
        keysPressed.delete("capslock");
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [props.placingMode]);

  console.log("logged in username tttdrake", props.loggedInUsername);
  console.log("og username tttdrake", props.og_username);

  return (
    <div
      className="navbar-container"
      style={{ height: "0vh", marginTop: "44px" }}
    >
      <Navbar
        className="navbar-sub-container"
        // style={{ pointerEvents: "none", zIndex: props.zoomedInFlag ? 0 : 1 }}
        style={{
          // display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          // top: 0,
          // left: 0,
          // right: 0,
          // pointerEvents: "none",
          zIndex: 1,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          // boxShadow: "0 3px 7px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* <div className="spacing-container-0" style={{ width: "30vw" }}></div> */}
        <Nav
          className="nav-whole"
          style={{
            marginTop: "0px",
            marginBottom: "2px",
            height: "47px",
            padding: "2px 5px",
            // padding: "6px 5px 67px 10px",
            // backgroundColor: "rgba(243, 243, 243, 0.95)",
            borderRadius: "10px",
            pointerEvents: "auto",
            userSelect: "none",
          }}
        >
          <div className="spacing-container-6" style={{ width: "1.2vw" }}></div>

          <div
            className="personal-public-container"
            style={{
              marginTop: "3px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              className="tabs"
              variant="light"
              onClick={() => {
                handleFilterChange("PUBLIC_ONLY");
                props.setIsPublicView(true);
              }}
              style={{
                background: "#EAEAEA",
                height: "18px",
                width: "67px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "16.5px",
                backgroundColor: props.isPublicView ? "#D6D6D6" : "#EAEAEA",
                border: props.isPublicView ? "1px solid #D6D6D6" : "EAEAEA",
                boxShadow: props.isPublicView
                  ? "0px 2px 4px rgba(0, 0, 0, 0.1)"
                  : "none",
              }}
            >
              <p
                className="tabs-text"
                style={{
                  marginTop: "7px",
                  color: props.isPublicView ? "#8E8E8E" : "#9D9D9D",
                  fontSize: "14px",
                  fontWeight: props.isPublicView ? "bold" : "normal",
                }}
              >
                Public
              </p>
            </Button>
            <Button
              className="tabs"
              variant="light"
              onClick={() => {
                handleFilterChange("ALL");
                props.setIsPublicView(false);
              }}
              style={{
                background: "#EAEAEA",
                height: "18px",
                width: "67px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "17px",
                backgroundColor: !props.isPublicView ? "#D6D6D6" : "#EAEAEA",
                border: !props.isPublicView ? "1px solid #D6D6D6" : "EAEAEA",
                boxShadow: !props.isPublicView
                  ? "0px 2px 4px rgba(0, 0, 0, 0.1)"
                  : "none",
              }}
            >
              <p
                className="tabs-text"
                style={{
                  marginTop: "4px",
                  color: !props.isPublicView ? "#8E8E8E" : "#9D9D9D",
                  fontSize: "14px",
                  fontWeight: !props.isPublicView ? "bold" : "normal",
                }}
              >
                Private
              </p>
            </Button>
          </div>

          <div className="spacing-container-1" style={{ width: "4vw" }}></div>
          <div>
            <Nav.Link
              href="/explore"
              className="explore-group"
              onClick={() => setActiveModal("explore")}
              style={{
                textAlign: "center",
                marginTop: "28px",
                outline: "none",
                border: "none",
              }}
              draggable="false"
              onDragStart={(e) => e.preventDefault()}
            >
              <img
                src={process.env.PUBLIC_URL + "/exploreIcon2.svg"}
                style={{
                  width: "55%",
                  height: "55%",
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                }}
              />{" "}
              <br />{" "}
              <p
                className="explore-text"
                style={{
                  // marginTop: "-3px",
                  marginTop: "-3px",

                  color: "#9D9D9D",
                  fontSize: "15px",
                }}
              >
                Explore
              </p>
            </Nav.Link>
          </div>
          <div className="spacing-container-2" style={{ width: "4vw" }}></div>
          <div className="plus-button-icon" style={{ marginTop: "2px" }}>
            <Button
              id="plus-button"
              className="add-icon"
              variant="light"
              onClick={handlePlusButtonClick}
              disabled={props.isPublicView}
              style={{
                // height: "52px",
                // width: "68px",
                height: "45px",
                width: "57px",
                borderRadius: "12%",
                backgroundColor: "#E4E4E4",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "20px",
                border: "none",
                // outlineStyle: "solid",
                // outlineWidth: "3.5px",
                // outlineColor: "#CFD2CF",
                color: "#CFD2CF",
                cursor: "pointer",
              }}
            >
              <BsPlusLg size={22} color="#9D9D9D" />
            </Button>
          </div>
          <div className="spacing-container-3" style={{ width: "4vw" }}></div>
          <div>
            <Nav.Link
              href=""
              className="friends-group"
              style={{
                textAlign: "center",
                marginTop: "29px",
                outline: "none",
                position: "relative",

                // pointerEvents: "none",
                // userSelect: "none",
                // draggable: "false",
              }}
              onClick={() => setActiveModal("friends")}
              draggable="false"
              onDragStart={(e) => e.preventDefault()}
            >
              {hasNotifications && (
                <span
                  className="notification-dot"
                  style={{
                    position: "absolute",
                    top: "6px",
                    // right: "5px",
                    right: "22%",
                    height: "8px",
                    width: "8px",
                    backgroundColor: "red",
                    // backgroundColor: "#FF8225",
                    borderRadius: "50%",
                  }}
                ></span>
              )}
              <img
                src={process.env.PUBLIC_URL + "/friends-icon2.svg"}
                style={{
                  width: "65%",
                  height: "65%",
                }}
                // draggable="false"
                // onDragStart={(e) => e.preventDefault()}
              />{" "}
              <br />{" "}
              <p
                className="friends-text"
                style={{
                  marginTop: "-6px",
                  color: "#9D9D9D",
                  fontSize: "15px",
                }}
              >
                Friends
              </p>
            </Nav.Link>
          </div>
          <div className="spacing-container-4" style={{ width: "4vw" }}></div>
          <div>
            {/* <NavDropdown
              title={<BsPersonCircle color="#CFD2CF" />}
              className="profile-icon"
              id="collasible-nav-dropdown"
              noCaret
              data-toggle="dropdown"
              style={{
                fontSize: "30px",
                marginTop: "-5px",
                color: "#CFD2CF !important",
              }}
            >
              <NavDropdown.Item href="">Settings</NavDropdown.Item>
              <NavDropdown.Item href="">Help</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="">
                <Button className="logout-button" onClick={logoutClick}>
                  Log out
                </Button>
              </NavDropdown.Item>
            </NavDropdown> */}
            <Button
              variant="link"
              className="profile-button"
              onClick={() => setActiveModal("profile")}
              style={{
                fontSize: "30px",
                color: "#CFD2CF",
                textDecoration: "none",
                marginTop: "-3px",
                outline: "none",
              }}
            >
              <BsPersonCircle />
            </Button>
          </div>
          <div className="spacing-container-4" style={{ width: "0.5vw" }}></div>
        </Nav>
        <div
          className="center-button-container"
          style={{
            position: "absolute",
            left: "107%",

            transform: "translateX(-50%)",
            top: "16px", // Adjust this value to place the button vertically where you want it
            // pointerEvents: "auto",
            pointerEvents: props.isScreenCentered ? "none" : "auto",
            opacity: props.isScreenCentered ? 0 : 1,
            transition: "opacity 0.5s ease-in-out",
            zIndex: 2, // Ensure it is above other elements
            // marginLeft: "320px",
          }}
        >
          <button
            style={{
              border: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
              border: "none",
              outline: "none",
            }}
            onClick={props.centerScreenHandler}
          >
            <MdOutlineFilterCenterFocus size={30} color="#9D9D9D" />
          </button>
        </div>
      </Navbar>
      {/* <div>
        <div
          className={`center-button-container ${
            props.isScreenCentered ? "hidden" : "visible"
          }`}
          style={{
            pointerEvents: "auto",
            transition: "opacity 0.5s ease-in-out",
            opacity: props.isScreenCentered ? 0 : 1,
            pointerEvents: props.isScreenCentered ? "none" : "auto",
          }}
        >
          <button
            style={{
              border: "none",
              backgroundColor: "transparent",
              marginTop: "0.2vw",
              // paddingLeft: "34px",
              marginTop: "5.2vw",
              marginLeft: "1000%",
            }}
            onClick={props.centerScreenHandler}
          >
            <MdOutlineFilterCenterFocus size={30} color="#9D9D9D" />
          </button>
        </div>
      </div> */}

      {/* For collections-only */}
      <CreateCollectionModal
        show={activeModal === "collection"}
        handleClose={handleCloseModal}
        handleCreateButtonClick={props.handleCreateButtonClick}
        collectionData={props.collectionData}
        setCollectionData={props.setCollectionData}
        nameRef={props.nameRef}
      />

      <CreateArtefactModal
        show={activeModal === "artefact"}
        handleClose={handleCloseModal}
        handleCreateButtonClick={props.handleCreateButtonClick}
        artefactData={props.artefactData}
        setArtefactData={props.setArtefactData}
        placeArtefactsTrigger={props.placeArtefactsTrigger}
        setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
        nameRefArtef={props.nameRefArtef}
        imageRefArtef={props.imageRefArtef}
        nameArtef={props.nameArtef}
        formData={props.formData}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        droppedImageFiles={props.droppedImageFiles}
        setDroppedImageFiles={props.setDroppedImageFiles}
        loggedInUsername={props.loggedInUsername}
        artefactsToPlace={props.artefactsToPlace}
        setArtefactsToPlace={props.setArtefactsToPlace}
      />

      {/* For empty page, need to decide col or artef */}
      <ChooseTypeModal
        showColOrArtef={activeModal === "choice"}
        handleColOrArtefClose={handleCloseModal}
        onClickCollection={() => {
          setActiveModal("collection");
        }}
        onClickArtefact={() => {
          setActiveModal("artefact");
        }}
      />

      <SettingsModal
        show={activeModal === "profile"}
        handleClose={handleCloseModal}
        logoutClick={logoutClick}
        loggedInUsername={props.loggedInUsername}
        setLoggedInUsername={props.setLoggedInUsername}
        og_username={props.og_username}
        setOg_username={props.setOg_username}
        loggedInFullname={props.loggedInFullname}
        setLoggedInFullname={props.setLoggedInFullname}
      />

      <FriendsModal
        show={activeModal === "friends"}
        handleClose={handleCloseModal}
        // loggedInUsername={props.loggedInUsername}
        loggedInUsername={props.setOg_username}
        onRequestsNotification={handleRequestsNotification}
      />

      {/* <ExploreModal
        show={activeModal === "explore"}
        handleClose={handleCloseModal}
      /> */}
      {/* <ExplorePage
        show={activeModal === "explore"}
        handleClose={handleCloseModal}
      /> */}
    </div>
  );
}

export default NavBar;
