import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/esm/CloseButton";
import CreateArtefactForm from "../Forms/CreateArtefactForm";
import CreateArtefactWebsiteForm from "../Forms/CreateArtefactWebsiteForm.js";
import CreateArtefactTextForm from "../Forms/CreateArtefactTextForm.js";
import CreateArtefactPDFForm from "../Forms/CreateArtefactPDFForm";
import Button from "react-bootstrap/Button";
import "../../css/ArtefactModal.css";
import { IoImageOutline } from "react-icons/io5";
import { HiOutlineDocumentArrowUp } from "react-icons/hi2";

function CreateArtefactModal({
  show,
  handleClose,
  currentCollection,
  artefactData,
  setArtefactData,
  handleCreateButtonClick,
  placeArtefactsTrigger,
  setPlaceArtefactsTrigger,
  selectedType,
  setSelectedType,
  droppedImageFiles,
  setDroppedImageFiles,
  websiteURL,
  loggedInUsername,
  artefactsToPlace,
  setArtefactsToPlace,
}) {
  useEffect(() => {
    if (!show) {
      setSelectedType(null);
      setArtefactData({
        artefactName: "",
        imageURL: "",
        imageWidth: null,
        imageHeight: null,
        type: "",
        sizing_choice: "m",
      });
    }
  }, [show, setArtefactData, setSelectedType]);

  const renderForm = () => {
    switch (selectedType) {
      case "Image":
        return (
          <CreateArtefactForm
            currentCollection={currentCollection}
            handleClose={handleClose}
            artefactData={artefactData}
            setArtefactData={setArtefactData}
            handleCreateButtonClick={handleCreateButtonClick}
            placeArtefactsTrigger={placeArtefactsTrigger}
            setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
            setSelectedType={setSelectedType}
            droppedImageFiles={droppedImageFiles}
            setDroppedImageFiles={setDroppedImageFiles}
            loggedInUsername={loggedInUsername}
            artefactsToPlace={artefactsToPlace}
            setArtefactsToPlace={setArtefactsToPlace}
          />
        );
      case "Website":
        return (
          <CreateArtefactWebsiteForm
            websiteURL={websiteURL}
            currentCollection={currentCollection}
            handleClose={handleClose}
            artefactData={artefactData}
            setArtefactData={setArtefactData}
            handleCreateButtonClick={handleCreateButtonClick}
            placeArtefactsTrigger={placeArtefactsTrigger}
            setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
            setSelectedType={setSelectedType}
            setArtefactsToPlace={setArtefactsToPlace}
          />
        );
      case "Text":
        return (
          <CreateArtefactTextForm
            currentCollection={currentCollection}
            handleClose={handleClose}
            artefactData={artefactData}
            setArtefactData={setArtefactData}
            handleCreateButtonClick={handleCreateButtonClick}
            placeArtefactsTrigger={placeArtefactsTrigger}
            setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
            setSelectedType={setSelectedType}
            setArtefactsToPlace={setArtefactsToPlace}
          />
        );
      case "PDF":
        return (
          <CreateArtefactPDFForm
            droppedImageFiles={droppedImageFiles}
            setDroppedImageFiles={setDroppedImageFiles}
            currentCollection={currentCollection}
            handleClose={handleClose}
            artefactData={artefactData}
            setArtefactData={setArtefactData}
            handleCreateButtonClick={handleCreateButtonClick}
            placeArtefactsTrigger={placeArtefactsTrigger}
            setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
            setSelectedType={setSelectedType}
            setArtefactsToPlace={setArtefactsToPlace}
          />
        );
      default:
        return (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "15px",
            }}
            className="col-and-artef-buttons"
          >
            <Button
              variant="primary"
              size="lg"
              className="image-button"
              onClick={() => setSelectedType("Image")}
              style={{
                width: "90px",
                height: "60px",
                backgroundColor: "#79b8ff",
                border: "none",
              }}
            >
              <IoImageOutline size={35} />
            </Button>
            <Button
              className="website-button"
              onClick={() => setSelectedType("Website")}
              style={{
                width: "90px",
                height: "60px",
                display: "flex",
                paddingTop: "13px",
                justifyContent: "center",
                textAlign: "center",
                fontFamily: "raleway",
                fontSize: "25px",
                backgroundColor: "#79b8ff",
                border: "none",
              }}
            >
              URL
            </Button>
            <Button
              variant="primary"
              size="lg"
              className="text-button"
              onClick={() => setSelectedType("Text")}
              style={{
                width: "90px",
                height: "60px",
                fontSize: "28px",
                fontFamily: "raleway",
                backgroundColor: "#79b8ff",
                border: "none",
              }}
            >
              Aa
            </Button>
            <Button
              variant="primary"
              size="lg"
              className="pdf-button"
              style={{
                width: "90px",
                height: "60px",
                paddingTop: "5px",
                backgroundColor: "#79b8ff",
                border: "none",
              }}
              onClick={() => setSelectedType("PDF")}
            >
              <HiOutlineDocumentArrowUp size={32} />
            </Button>
          </div>
        );
    }
  };

  return (
    <Modal
      className={`create-artefact-modal no-select ${
        selectedType === "Image" ? "create-artefact-form-expanded" : ""
      }`}
      show={show}
      onHide={() => {
        handleClose();
        setSelectedType(null);
      }}
      backdrop="static"
      keyboard={false}
      centered
      size={selectedType === "Image" ? "lg" : "sm"} // Dynamic size based on selectedType
    >
      <Modal.Body
        className={`creation-form no-select ${
          selectedType === "Image" ? "create-artefact-form-modal" : ""
        }`}
      >
        <CloseButton
          className="close-button"
          onClick={() => {
            handleClose();
            setSelectedType(null);
          }}
        />
        {renderForm()}
      </Modal.Body>
    </Modal>
  );
}

CreateArtefactModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentCollection: PropTypes.object.isRequired,
  artefactData: PropTypes.object.isRequired,
  setArtefactData: PropTypes.func.isRequired,
  handleCreateButtonClick: PropTypes.func.isRequired,
  placeArtefactsTrigger: PropTypes.bool.isRequired,
  setPlaceArtefactsTrigger: PropTypes.func.isRequired,
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func.isRequired,
  setDroppedImageFiles: PropTypes.func.isRequired,
  // websiteURL: PropTypes.string, // Added missing prop
  // loggedInUsername: PropTypes.string, // Added missing prop
  // artefactsToPlace: PropTypes.array, // Added missing prop
  // setArtefactsToPlace: PropTypes.func, // Added missing prop
};

export default CreateArtefactModal;
