import React from "react";
import Modal from "react-bootstrap/Modal";
import "../../css/CollectionModal.css";
import CloseButton from "react-bootstrap/esm/CloseButton";
import CreateCollectionForm from "../Forms/CreateCollectionForm";

function CreateCollectionModal({
  show,
  handleClose,
  handleCreateButtonClick,
  helicopter,
  setHelicopter,
  nameRef,
  collectionData,
  setCollectionData,
}) {
  return (
    <div className="This">
      <Modal
        className="create-collection-modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        height="200px"
        size="sm"
      >
        <Modal.Body className="creation-form">
          <CloseButton className="close-button" onClick={handleClose} />
          <CreateCollectionForm
            handleClose={handleClose}
            handleCreateButtonClick={handleCreateButtonClick}
            helicopter={helicopter}
            setHelicopter={setHelicopter}
            nameRef={nameRef}
            collectionData={collectionData}
            setCollectionData={setCollectionData}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CreateCollectionModal;
