export const updateArtefactName = (
  username,
  artefactId,
  newName,
  content = null,
  contentType = null
) => {
  const body = { artefact_name: newName };

  // If content and content type are provided, add them to the request body
  if (content && contentType) {
    body.content = content;
    body.content_type = contentType;
  }

  return fetch(`/api/user/${username}/artefact/${artefactId}/rename`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      // Include other headers as needed, e.g., authorization
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Failed to update artefact.");
    }
    return response.json(); // or `response.text()`, depending on your API
  });
};
