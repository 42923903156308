export const fetchDescription = async (username) => {
  try {
    const response = await fetch(`/api/user/${username}/description`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
    });

    if (response.ok) {
      const data = await response.json();
      return data.description; // Assuming the response contains a description field
    } else {
      console.error('Failed to fetch description. Status:', response.status);
      return null;
    }
  } catch (error) {
    console.error('API call failed:', error);
    return null;
  }
};
