import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { uploadArtefact } from "../../api/uploadArtefact";
import "../../css/CreateArtefactPDFForm.css";

function CreateArtefactPDFForm(props) {
  const nameRefArtef = useRef(null);
  const pdfRefArtef = useRef(null);
  const [artefactName, setArtefactName] = useState("");
  const [websiteURL, setWebsiteURL] = useState(""); // Not needed for PDF, can be removed
  const [fileName, setFileName] = useState("");
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [copiedFile, setCopiedFile] = useState(null);

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  useEffect(() => {
    console.log("raws", props.droppedImageFiles);
    if (props.droppedImageFiles) {
      setCopiedFile(props.droppedImageFiles);
      onChangePDFInput({ target: { files: [props.droppedImageFiles] } }, false); // isAddMore = false
    }
  }, [props.droppedImageFiles]);

  // Handle file input change
  const onChangePDFInput = (e, isAddMore = false) => {
    const file = e.target.files[0];
    // const file = e.target.files;

    console.log("e.target.files", e.target.files[0], file.type);

    if (file) {
      // Validate file type (must be PDF)
      console.log("file.type ssss", file.type);
      if (file.type !== "application/pdf") {
        console.log("file.type ssts", file.type);

        // alert("Please upload a PDF file.");
        e.target.value = null; // Reset file input
        setFileName("");
        setIsFileSelected(false);
        return;
      }

      // Validate file size (8MB max)
      if (file.size > 8 * 1024 * 1024) {
        alert("File size exceeds 8MB. Please choose a smaller file.");
        e.target.value = null; // Reset file input
        setFileName("");
        setIsFileSelected(false);
        return;
      }

      setFileName(file.name);
      setIsFileSelected(true);
      setCopiedFile(file);
    } else {
      setFileName("");
      setIsFileSelected(false);
      setCopiedFile(null);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    if (e.clipboardData && e.clipboardData.items) {
      const items = e.clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === "file") {
          const file = items[i].getAsFile();
          if (file && file.type === "application/pdf") {
            onChangePDFInput({ target: { files: [file] } }, false);
            break;
          }
        }
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Handle drop
  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
      const file = files[0];
      onChangePDFInput({ target: { files: [file] } }, false);
    }
  };

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  // Reset the form fields
  const resetForm = () => {
    setArtefactName("");
    setFileName("");
    setIsFileSelected(false);
    setIsSubmitting(false);
    setCopiedFile(null);

    if (pdfRefArtef.current) {
      pdfRefArtef.current.value = ""; // Reset file input
    }

    props.setDroppedImageFiles(null); // Clear the dropped file from props
  };

  // Handle form submission
  const submitNewPDFArtefact = async (e) => {
    e.preventDefault();
    const nameArtef = artefactName.trim();
    const file =
      copiedFile || props.droppedImageFiles || pdfRefArtef.current.files[0];

    if (!file) {
      alert("Please select a PDF file.");
      return;
    }

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("artefact", file);

    try {
      const data = await uploadArtefact(formData);

      // Create the artefact object
      const newPDFArtefact = {
        artefactName: nameArtef, // Can be empty string if not provided
        pdfURL: data.url,
        type: "pdf",
      };

      // Pass the artefact as an array to match artefactsToPlace structure
      props.setArtefactsToPlace([newPDFArtefact]);
      props.setPlaceArtefactsTrigger(true);
      props.handleCreateButtonClick();

      // Reset the form after successful submission
      resetForm();
    } catch (error) {
      console.error("Error creating PDF artefact:", error);
      alert("Failed to create the PDF artefact. Please try again.");
      setIsSubmitting(false);
    }
  };

  // Handle artefact name change
  const onArtefactNameChange = (event) => {
    setArtefactName(event.target.value);
  };

  // Automatically focus the PDF input when the component mounts
  useEffect(() => {
    if (pdfRefArtef.current) {
      pdfRefArtef.current.focus();
    }
  }, []);

  return (
    <Form
      className="artefacts-form"
      onSubmit={submitNewPDFArtefact}
      style={{
        width: "65%",
        height: "100%",
        userSelect: "none",
      }}
    >
      <div>
        <Form.Group className="input-box-pdf-artefact">
          <Form.Label
            className="artefacts-pdf-form-heading"
            style={{
              fontFamily: "Raleway, sans-serif",
              fontSize: "27px",
              marginTop: "-30px",
            }}
          >
            PDF Artefact
          </Form.Label>
          <div style={{ height: "1vh" }}></div>

          <div
            className="artefact-upload-pdf"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{
              border: "2px dashed #79b8ff",
              borderRadius: "15px",
              marginTop: "-15px",
              height: "190px",
              width: "370px",
              padding: "30px",
              paddingTop: "50px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Form.Control
              type="file"
              ref={pdfRefArtef}
              accept="application/pdf"
              onChange={onChangePDFInput}
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
                opacity: 0,
                cursor: "pointer",
              }}
            />
            <p style={{ fontFamily: "Raleway, sans-serif", fontSize: "17px" }}>
              {fileName
                ? `Chosen file: ${fileName}`
                : "Drag and drop a PDF here, or click to select a file"}
            </p>
          </div>
        </Form.Group>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "8px",
            marginLeft: "10px",
          }}
        >
          <Form.Group
            style={{
              marginBottom: "0px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            <Form.Control
              style={{
                fontFamily: "Raleway, sans-serif",
                width: "260px",
                userSelect: "none",
                fontSize: "16px",
              }}
              type="text"
              onChange={onArtefactNameChange}
              ref={nameRefArtef}
              placeholder="Enter artefact Name (optional)"
              size="sm"
              autoFocus={!isMobileDevice()}
              value={artefactName}
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={!isFileSelected || isSubmitting}
            onClick={() => {
              props.handleClose();
            }}
            style={{
              backgroundColor: "#79b8ff",
              border: "none",
              marginTop: "5px",
              fontFamily: "Raleway, sans-serif",
              marginTop: "-1px",
            }}
          >
            {isSubmitting ? "Submitting..." : "Create"}
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default CreateArtefactPDFForm;
