import React, { useState, useEffect, useRef } from "react";
import "../css/PreviewItems.css";

function PreviewItems({
  previewArtefact,
  previewCollection,
  cursorPosition,
  imageDimensions,
  setImageDimensions,
  zoomLevel,
}) {
  const defaultWebsiteDimensions = { width: 300, height: 190 };

  const textArtefactRef = useRef(null); // Add this ref

  useEffect(() => {
    if (textArtefactRef.current) {
      const width = textArtefactRef.current.offsetWidth;
      const height = textArtefactRef.current.offsetHeight;
      setImageDimensions({ width, height });
    }
  }, [
    previewArtefact,
    // previewArtefact.content
  ]);

  return (
    <>
      {previewArtefact && previewArtefact.type === "image" && (
        <img
          className="no-select"
          onLoad={(e) => {
            const width = e.target.offsetWidth;
            const height = e.target.offsetHeight;
            setImageDimensions({ width, height });
          }}
          style={{
            position: "absolute",
            // left: `${
            //   cursorPosition.x -
            //   (imageDimensions ? imageDimensions.width / 2 : 0)
            // }px`,
            // top: `${
            //   cursorPosition.y -
            //   (imageDimensions ? imageDimensions.height / 2 : 0)
            // }px`,
            left: `${
              cursorPosition.x / zoomLevel -
              (imageDimensions ? imageDimensions.width / 2 : 0)
            }px`,
            top: `${
              cursorPosition.y / zoomLevel -
              (imageDimensions ? imageDimensions.height / 2 : 0)
            }px`,
            maxWidth: "300px",
            maxHeight: "300px",
            width: "auto",
            height: "auto",
            opacity: "0.7",
            borderRadius: "10px",
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)", // This adds the shadow
            userSelect: "none",
          }}
          src={previewArtefact.imageURL}
          alt={previewArtefact.name}
        />
      )}
      {previewArtefact && previewArtefact.type === "website" && (
        <div
          className="no-select"
          style={{
            position: "absolute",
            // left: `${cursorPosition.x - 170}px`, // Default width / 2
            // top: `${cursorPosition.y - 160}px`, // Default height / 2
            left: `${cursorPosition.x / zoomLevel - 170}px`, // Adjusted with zoomLevel
            top: `${cursorPosition.y / zoomLevel - 160}px`, // Adjusted with zoomLevel
            width: "350px",
            height: "320px",
            // backgroundColor: "#a9ccf9",
            backgroundColor: "#d8d8d8",
            opacity: "0.6",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            userSelect: "none",
          }}
        >
          <div
            style={{
              // flex: 1,
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          ></div>
          <div
            style={{
              flex: 2,
              backgroundColor: "#aaa",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "10px",
              height: "70%",
              width: "70%",
              marginTop: "10%",
              // marginRight: "5%",
            }}
          >
            {/* Thumbnail will go here */}
          </div>
          <p
            style={{
              color: "white",
              fontSize: "25px",
              padding: "19px 12px 0px 0px",
              // marginLeft: "-18px",
            }}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {previewArtefact.name}
          </p>
        </div>
      )}

      {previewArtefact && previewArtefact.type === "text" && (
        <div
          ref={textArtefactRef} // Attach the ref here
          className="no-select"
          style={{
            position: "absolute",
            // left: `${
            //   cursorPosition.x / zoomLevel -
            //   (imageDimensions ? imageDimensions.width / 2 : 0)
            // }px`,
            // top: `${
            //   cursorPosition.y / zoomLevel -
            //   (imageDimensions ? imageDimensions.height / 2 : 0)
            // }px`,
            left: `${cursorPosition.x / zoomLevel - 135}px`, // Adjusted with zoomLevel
            top: `${cursorPosition.y / zoomLevel - 37}px`, // Adjusted with zoomLevel
            maxWidth: "350px",
            width: "auto", // Change width to auto
            height: "auto",
            opacity: "0.7",
            backgroundColor: "#FFF",
            borderRadius: "10px",
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
            padding: "20px",
            overflow: "auto",
            userSelect: "none",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            wordBreak: "break-word",
          }}
        >
          <h2 style={{ color: "#333" }}>{previewArtefact.name}</h2>
          {console.log("sadfasdf", previewArtefact)}
          <div
            style={{
              color: "#555",
            }}
            dangerouslySetInnerHTML={{ __html: previewArtefact.content }}
          />
        </div>
      )}

      {previewArtefact && previewArtefact.type === "pdf" && (
        <div
          className="no-select"
          style={{
            position: "absolute",
            // left: `${cursorPosition.x - 100}px`, // Adjust as needed
            // top: `${cursorPosition.y - 125}px`, // Adjust as needed
            left: `${cursorPosition.x / zoomLevel - 100}px`, // Adjusted with zoomLevel
            top: `${cursorPosition.y / zoomLevel - 125}px`, // Adjusted with zoomLevel
            width: "200px",
            height: "250px",
            backgroundColor: "#F0F0F0",
            opacity: "0.7",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)", // Adds the shadow
            userSelect: "none",
          }}
        >
          <div
            style={{
              backgroundColor: "#B0B0B0",
              width: "60px",
              height: "60px",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
              userSelect: "none",
            }}
          >
            <i
              className="fas fa-file-pdf"
              style={{ fontSize: "30px", color: "#FFF" }}
            ></i>
          </div>
          <p style={{ textAlign: "center", fontSize: "14px", color: "#333" }}>
            {previewArtefact.name}
          </p>
        </div>
      )}

      {previewCollection && (
        <div
          className="no-select"
          style={{
            position: "absolute",
            // left: `${cursorPosition.x - 250 / 2}px`,
            // top: `${cursorPosition.y - 170 / 2}px`,
            left: `${cursorPosition.x / zoomLevel - 250 / 2}px`, // Adjusted with zoomLevel
            top: `${cursorPosition.y / zoomLevel - 170 / 2}px`, // Adjusted with zoomLevel
            backgroundColor: "#F0F0F0",
            height: "170px",
            width: "250px",
            color: "#0F0F0F",
            outlineWidth: "2px",
            borderRadius: "9px",
            fontSize: "25px",
            cursor: "pointer",
            textAlign: "center",
            paddingTop: "100px",
            opacity: "0.6",
            userSelect: "none",
          }}
        >
          {previewCollection.name}
        </div>
      )}
    </>
  );
}

export default PreviewItems;

// import React, { useEffect, useRef } from "react";
// import "../css/PreviewItems.css";

// function PreviewItems({
//   previewArtefacts, // Update to accept an array of artefacts
//   previewCollection,
//   cursorPosition,
//   imageDimensions,
//   setImageDimensions,
//   zoomLevel,
// }) {
//   const defaultWebsiteDimensions = { width: 300, height: 190 };
//   const textArtefactRef = useRef(null);

//   useEffect(() => {
//     if (textArtefactRef.current) {
//       const width = textArtefactRef.current.offsetWidth;
//       const height = textArtefactRef.current.offsetHeight;
//       setImageDimensions({ width, height });
//     }
//   }, [previewArtefacts]);

//   return (
//     <>
//       {previewArtefacts &&
//         previewArtefacts.map((previewArtefact, index) => {
//           const commonStyle = {
//             position: "absolute",
//             left: `${
//               cursorPosition.x / zoomLevel -
//               (imageDimensions ? imageDimensions.width / 2 : 0)
//             }px`,
//             top: `${
//               cursorPosition.y / zoomLevel -
//               (imageDimensions ? imageDimensions.height / 2 : 0)
//             }px`,
//             opacity: "0.7",
//             borderRadius: "10px",
//             boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
//             userSelect: "none",
//           };

//           return (
//             <React.Fragment key={index}>
//               {previewArtefact.type === "image" && (
//                 <img
//                   className="no-select"
//                   onLoad={(e) => {
//                     const width = e.target.offsetWidth;
//                     const height = e.target.offsetHeight;
//                     setImageDimensions({ width, height });
//                   }}
//                   style={{
//                     ...commonStyle,
//                     maxWidth: "300px",
//                     maxHeight: "300px",
//                     width: "auto",
//                     height: "auto",
//                   }}
//                   src={previewArtefact.imageURL}
//                   alt={previewArtefact.name}
//                 />
//               )}
//               {previewArtefact.type === "website" && (
//                 <div
//                   className="no-select"
//                   style={{
//                     ...commonStyle,
//                     width: "350px",
//                     height: "320px",
//                     backgroundColor: "#d8d8d8",
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     padding: "20px",
//                   }}
//                 >
//                   <div
//                     style={{
//                       flex: 2,
//                       backgroundColor: "#aaa",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       borderRadius: "10px",
//                       height: "70%",
//                       width: "70%",
//                       marginTop: "10%",
//                     }}
//                   >
//                     {/* Thumbnail */}
//                   </div>
//                   <p
//                     style={{
//                       color: "white",
//                       fontSize: "25px",
//                       padding: "19px 12px 0px 0px",
//                     }}
//                   >
//                     {previewArtefact.name}
//                   </p>
//                 </div>
//               )}
//               {previewArtefact.type === "text" && (
//                 <div
//                   ref={textArtefactRef}
//                   className="no-select"
//                   style={{
//                     ...commonStyle,
//                     maxWidth: "350px",
//                     width: "auto",
//                     height: "auto",
//                     backgroundColor: "#FFF",
//                     padding: "20px",
//                     overflow: "auto",
//                     whiteSpace: "pre-wrap",
//                     overflowWrap: "break-word",
//                     wordBreak: "break-word",
//                   }}
//                 >
//                   <h2 style={{ color: "#333" }}>{previewArtefact.name}</h2>
//                   <div
//                     style={{ color: "#555" }}
//                     dangerouslySetInnerHTML={{
//                       __html: previewArtefact.content,
//                     }}
//                   />
//                 </div>
//               )}
//               {previewArtefact.type === "pdf" && (
//                 <div
//                   className="no-select"
//                   style={{
//                     ...commonStyle,
//                     width: "200px",
//                     height: "250px",
//                     backgroundColor: "#F0F0F0",
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     padding: "20px",
//                   }}
//                 >
//                   <div
//                     style={{
//                       backgroundColor: "#B0B0B0",
//                       width: "60px",
//                       height: "60px",
//                       borderRadius: "10px",
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       marginBottom: "10px",
//                     }}
//                   >
//                     <i
//                       className="fas fa-file-pdf"
//                       style={{ fontSize: "30px", color: "#FFF" }}
//                     ></i>
//                   </div>
//                   <p
//                     style={{
//                       textAlign: "center",
//                       fontSize: "14px",
//                       color: "#333",
//                     }}
//                   >
//                     {previewArtefact.name}
//                   </p>
//                 </div>
//               )}
//             </React.Fragment>
//           );
//         })}

//       {previewCollection && (
//         <div
//           className="no-select"
//           style={{
//             position: "absolute",
//             left: `${cursorPosition.x / zoomLevel - 125}px`,
//             top: `${cursorPosition.y / zoomLevel - 85}px`,
//             backgroundColor: "#F0F0F0",
//             height: "170px",
//             width: "250px",
//             color: "#0F0F0F",
//             borderRadius: "9px",
//             fontSize: "25px",
//             textAlign: "center",
//             paddingTop: "100px",
//             opacity: "0.6",
//             userSelect: "none",
//           }}
//         >
//           {previewCollection.name}
//         </div>
//       )}
//     </>
//   );
// }

// export default PreviewItems;
