import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../css/ChooseColOrArtef.css";
import CloseButton from "react-bootstrap/esm/CloseButton";

function ChooseTypeModal(props) {
  return (
    <div>
      <Modal
        className="choose-type-modal"
        show={props.showColOrArtef}
        backdrop="static"
        keyboard={false}
        centered
        height="200px"
        size="sm"
        dialogClassName="choose-type-modal"
      >
        <Modal.Body className="creation-form">
          <CloseButton
            className="close-button"
            onClick={props.handleColOrArtefClose}
          />
          <div className="d-grid gap-2 col-and-artef-buttons ">
            <Button
              variant="primary"
              size="lg"
              className="plus-collection-button"
              onClick={props.onClickCollection}
              style={{
                backgroundColor: "#79b8ff",
                border: "none",
              }}
            >
              +Collection
            </Button>
            {"\n"}
            <Button
              variant="primary"
              size="lg"
              className="plus-artefacts-button"
              onClick={props.onClickArtefact}
              style={{
                backgroundColor: "#79b8ff",
                border: "none",
              }}
            >
              +Artefacts
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChooseTypeModal;
