// import React, { useRef, useState, useEffect } from "react";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
// import "../../css/CreateArtefactWebsiteForm.css";

// function CreateArtefactWebsiteForm(props) {
//   const nameRefArtef = useRef(null);
//   const urlRefArtef = useRef(null);
//   const [artefactName, setArtefactName] = useState("");
//   const [websiteURL, setWebsiteURL] = useState("");

//   useEffect(() => {
//     if (props.websiteURL) {
//       setWebsiteURL(props.websiteURL);
//       if (nameRefArtef.current) {
//         nameRefArtef.current.focus(); // Auto-focus on the name field
//       }
//     }
//   }, [props.websiteURL]);

//   const submitNewWebsiteArtefact = async (e) => {
//     e.preventDefault();
//     const nameArtef = nameRefArtef.current.value;
//     let urlArtef = urlRefArtef.current.value.trim();

//     // Add https:// if the URL doesn't start with http:// or https://
//     if (!/^https?:\/\//i.test(urlArtef)) {
//       urlArtef = `https://${urlArtef}`;
//     }

//     // Validate URL using the URL constructor which is more reliable for complex URLs
//     try {
//       new URL(urlArtef);
//     } catch (error) {
//       alert("Please enter a valid URL");
//       return;
//     }

//     try {
//       props.setArtefactData({
//         artefactName: nameArtef,
//         websiteURL: urlArtef,
//         type: "website",
//       });
//       props.setPlaceArtefactsTrigger(true);
//       props.handleCreateButtonClick();
//       props.setSelectedType(null);
//     } catch (error) {
//       console.error("Error submitting the website artefact:", error);
//     }
//   };

//   return (
//     <Form
//       className="website-artefacts-form"
//       onSubmit={submitNewWebsiteArtefact}
//       style={{
//         width: "95%",
//         height: "100%",
//         userSelect: "none",
//       }}
//     >
//       <Form.Group>
//         <Form.Label
//           style={{
//             fontFamily: "raleway",
//             fontSize: "27px",
//             marginTop: "30px",
//           }}
//         >
//           Website Artefact
//         </Form.Label>

//         <div style={{ height: "4.5vh" }}></div>
//         <div
//           style={{ width: "100%", display: "flex", justifyContent: "center" }}
//         >
//           <Form.Control
//             type="text" // Changed from "url" to "text"
//             ref={urlRefArtef}
//             placeholder="Enter Website URL"
//             size="sm"
//             autoFocus
//             required
//             style={{
//               marginTop: "-15px",
//               width: "100%",
//               fontFamily: "raleway",
//               fontSize: "16px",
//             }}
//             value={websiteURL}
//             onChange={(e) => setWebsiteURL(e.target.value)}
//           />
//         </div>
//       </Form.Group>
//       <div style={{ height: "3vh" }}></div>

//       <Form.Group>
//         <Form.Control
//           type="text"
//           ref={nameRefArtef}
//           placeholder="Enter Artefact Name"
//           size="sm"
//           // required
//           style={{
//             width: "100%",
//             fontFamily: "raleway",
//             fontSize: "16px",
//           }}
//           value={artefactName}
//           onChange={(e) => setArtefactName(e.target.value)}
//         />
//       </Form.Group>
//       <div style={{ height: "1.6vh" }}></div>

//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <Button
//           variant="primary"
//           type="submit"
//           required
//           onClick={() => {
//             props.handleClose();
//           }}
//           style={{
//             backgroundColor: "#79b8ff",
//             border: "none",
//             marginTop: "5px",
//             fontFamily: "raleway",
//             marginTop: "-1px",
//           }}
//           // disabled={!artefactName || !websiteURL}
//           disabled={!websiteURL}
//         >
//           Create
//         </Button>
//       </div>
//     </Form>
//   );
// }

// export default CreateArtefactWebsiteForm;

import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../../css/CreateArtefactWebsiteForm.css";

function CreateArtefactWebsiteForm(props) {
  const nameRefArtef = useRef(null);
  const urlRefArtef = useRef(null);
  const [artefactName, setArtefactName] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle form submission
  const submitNewWebsiteArtefact = async (e) => {
    e.preventDefault();

    // Trim inputs
    const nameArtef = artefactName.trim();
    let urlArtef = websiteURL.trim();

    // Validate inputs: Ensure at least a URL is provided
    if (!urlArtef) {
      alert("Please enter a website URL.");
      return;
    }

    // Add https:// if the URL doesn't start with http:// or https://
    if (!/^https?:\/\//i.test(urlArtef)) {
      urlArtef = `https://${urlArtef}`;
    }

    // Validate URL using the URL constructor
    try {
      new URL(urlArtef);
    } catch (error) {
      alert("Please enter a valid URL.");
      return;
    }

    setIsSubmitting(true);

    try {
      // Create the artefact object
      const newWebsiteArtefact = {
        artefactName: nameArtef, // Can be empty string if not provided
        websiteURL: urlArtef,
        type: "website",
      };

      // Pass the artefact as an array to match artefactsToPlace structure
      props.setArtefactsToPlace([newWebsiteArtefact]);
      props.setPlaceArtefactsTrigger(true);
      props.handleCreateButtonClick();

      // Reset the form
      resetForm();
    } catch (error) {
      console.error("Error creating website artefact:", error);
      alert("Failed to create the website artefact. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Reset the form fields
  const resetForm = () => {
    setArtefactName("");
    setWebsiteURL("");
    props.setSelectedType(null);
  };

  // Automatically focus the URL input when the component mounts
  // useEffect(() => {
  //   if (urlRefArtef.current) {
  //     urlRefArtef.current.focus();
  //   }
  // }, []);

  useEffect(() => {
    if (!websiteURL && urlRefArtef.current) {
      urlRefArtef.current.focus();
    }
  }, [websiteURL]);

  useEffect(() => {
    if (props.websiteURL) {
      setWebsiteURL(props.websiteURL);
      if (nameRefArtef.current) {
        nameRefArtef.current.focus(); // Auto-focus on the name field
      }
    }
  }, [props.websiteURL]);

  return (
    <Form
      className="website-artefacts-form"
      onSubmit={submitNewWebsiteArtefact}
      style={{
        width: "95%",
        height: "100%",
        userSelect: "none",
      }}
    >
      <div>
        <Form.Group className="input-box-website-artefact">
          <Form.Label
            className="artefacts-website-form-heading"
            style={{
              fontFamily: "Raleway, sans-serif",
              fontSize: "27px",
              marginTop: "20px",
            }}
          >
            Website Artefact
          </Form.Label>
          <div style={{ height: "4.5vh" }}></div>

          <Form.Control
            className="artefacts-form-url"
            type="text"
            ref={urlRefArtef}
            placeholder="Enter Website URL"
            size="sm"
            style={{
              fontFamily: "Raleway, sans-serif",
              marginTop: "-15px",
              fontSize: "16px",
            }}
            value={websiteURL}
            onChange={(e) => setWebsiteURL(e.target.value)}
          />
        </Form.Group>
        <div style={{ height: "3vh" }}></div>

        <Form.Group className="input-box-website-artefact-name">
          <Form.Control
            type="text"
            ref={nameRefArtef}
            placeholder="Enter Artefact Name (optional)"
            size="sm"
            style={{
              width: "100%",
              fontFamily: "Raleway, sans-serif",
              fontSize: "16px",
            }}
            value={artefactName}
            onChange={(e) => setArtefactName(e.target.value)}
          />
        </Form.Group>
        <div style={{ height: "1.6vh" }}></div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className="create-button-website-artefact"
            variant="primary"
            type="submit"
            onClick={() => {
              props.handleClose();
            }}
            style={{
              backgroundColor: "#79b8ff",
              border: "none",
              marginTop: "5px",
              fontFamily: "Raleway, sans-serif",
              marginTop: "42px",
            }}
            disabled={!websiteURL.trim() || isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Create"}
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default CreateArtefactWebsiteForm;
