// import React, { useEffect, useRef, useState } from "react";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css"; // Quill's CSS for styling
// import "../../css/CreateArtefactTextForm.css";

// function CreateArtefactTextForm(props) {
//   const nameRefArtef = useRef(null);
//   const [textHeading, setTextHeading] = useState("");
//   const [textBody, setTextBody] = useState("");

//   const submitNewTextArtefact = async (e) => {
//     e.preventDefault();
//     const nameArtef = nameRefArtef.current.value;

//     try {
//       props.setArtefactData({
//         artefactName: nameArtef,
//         bodyText: textBody, // Use Quill's content
//         type: "text",
//       });
//       props.setPlaceArtefactsTrigger(true);
//       props.handleCreateButtonClick();
//       props.setSelectedType(null);
//     } catch (error) {
//       // Handle error
//     }
//   };

//   //   useEffect(() => {
//   //   // Focus only if the viewport width is greater than 768 pixels
//   //   if (window.innerWidth > 768) {
//   //     nameRefArtef.current?.focus();
//   //   }
//   // }, []);

//   return (
//     <Form
//       className="artefacts-form"
//       onSubmit={submitNewTextArtefact}
//       style={{
//         width: "95%",
//         height: "100%",
//         userSelect: "none",
//       }}
//     >
//       <div>
//         <Form.Group className="input-box-text-artefact">
//           <Form.Label
//             className="artefacts-text-form-heading"
//             style={{
//               fontFamily: "raleway",
//               fontSize: "27px",
//               marginTop: "0px",
//             }}
//           >
//             Text Artefact
//           </Form.Label>
//           <div style={{ height: "1vh" }}></div>

//           <Form.Control
//             className="artefacts-form-heading"
//             type="text"
//             ref={nameRefArtef}
//             placeholder="Enter Text Heading"
//             size="sm"
//             autoFocus
//             // required
//             style={{
//               fontFamily: "raleway",
//               marginTop: "-13px",
//               fontSize: "16px",
//             }}
//             value={textHeading}
//             onChange={(e) => setTextHeading(e.target.value)}
//           />
//         </Form.Group>
//         <div style={{ height: "2vh" }}></div>

//         <Form.Group className="website-artefacts-form">
//           <ReactQuill
//             value={textBody}
//             onChange={setTextBody} // Update the state with the Quill content
//             placeholder="Enter Text Body"
//             style={{
//               fontFamily: "raleway",
//               fontSize: "16px",
//               marginTop: "-14px",

//               height: "108px", // Set height for Quill editor
//               backgroundColor: "white", // Optional: Set background color for editor
//             }}
//             modules={{
//               toolbar: [
//                 [{ header: [5, false] }],
//                 ["bold", "italic", "underline"],
//                 [{ list: "ordered" }, { list: "bullet" }],
//               ],
//             }}
//           />
//         </Form.Group>
//         <div style={{ height: "1.6vh" }}></div>

//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Button
//             className="create-button-text-artefact"
//             variant="primary"
//             type="submit"
//             onClick={() => {
//               props.handleClose();
//             }}
//             style={{
//               backgroundColor: "#79b8ff",
//               border: "none",
//               marginTop: "5px",
//               fontFamily: "raleway",
//               marginTop: "42px",
//             }}
//             // disabled={!textHeading.trim() || !textBody.trim()}
//             // disabled={!textHeading.trim()}
//             disabled={!textHeading.trim() && !textBody.trim()}
//           >
//             Create
//           </Button>
//         </div>
//       </div>
//     </Form>
//   );
// }

// export default CreateArtefactTextForm;

// import React, { useEffect, useRef, useState } from "react";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css"; // Quill's CSS for styling
// import "../../css/CreateArtefactTextForm.css";

// function CreateArtefactTextForm(props) {
//   const nameRefArtef = useRef(null);
//   const [textHeading, setTextHeading] = useState("");
//   const [textBody, setTextBody] = useState("");

//   const submitNewTextArtefact = async (e) => {
//     e.preventDefault();
//     const nameArtef = nameRefArtef.current.value;

//     try {
//       const artefact = {
//         artefactName: nameArtef,
//         heading: textHeading,
//         bodyText: textBody,
//         content: textBody,
//         type: "text",
//       };

//       props.setArtefactsToPlace([artefact]);
//       props.setPlaceArtefactsTrigger(true);
//       props.handleCreateButtonClick();
//       props.setSelectedType(null);
//       props.handleClose();
//     } catch (error) {
//       // Handle error
//     }
//   };

//   return (
//     <Form
//       className="artefacts-form"
//       onSubmit={submitNewTextArtefact}
//       style={{
//         width: "95%",
//         height: "100%",
//         userSelect: "none",
//       }}
//     >
//       <div>
//         <Form.Group className="input-box-text-artefact">
//           <Form.Label
//             className="artefacts-text-form-heading"
//             style={{
//               fontFamily: "raleway",
//               fontSize: "27px",
//               marginTop: "0px",
//             }}
//           >
//             Text Artefact
//           </Form.Label>
//           <div style={{ height: "1vh" }}></div>

//           <Form.Control
//             className="artefacts-form-heading"
//             type="text"
//             ref={nameRefArtef}
//             placeholder="Enter Text Heading"
//             size="sm"
//             autoFocus
//             style={{
//               fontFamily: "raleway",
//               marginTop: "-13px",
//               fontSize: "16px",
//             }}
//             value={textHeading}
//             onChange={(e) => setTextHeading(e.target.value)}
//           />
//         </Form.Group>
//         <div style={{ height: "2vh" }}></div>

//         <Form.Group className="website-artefacts-form">
//           <ReactQuill
//             value={textBody}
//             onChange={setTextBody} // Update the state with the Quill content
//             placeholder="Enter Text Body"
//             style={{
//               fontFamily: "raleway",
//               fontSize: "16px",
//               marginTop: "-14px",
//               height: "108px", // Set height for Quill editor
//               backgroundColor: "white",
//             }}
//             modules={{
//               toolbar: [
//                 [{ header: [5, false] }],
//                 ["bold", "italic", "underline"],
//                 [{ list: "ordered" }, { list: "bullet" }],
//               ],
//             }}
//           />
//         </Form.Group>
//         <div style={{ height: "1.6vh" }}></div>

//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Button
//             className="create-button-text-artefact"
//             variant="primary"
//             type="submit"
//             style={{
//               backgroundColor: "#79b8ff",
//               border: "none",
//               marginTop: "42px",
//               fontFamily: "raleway",
//             }}
//             disabled={!textHeading.trim() && !textBody.trim()}
//           >
//             Create
//           </Button>
//         </div>
//       </div>
//     </Form>
//   );
// }

// export default CreateArtefactTextForm;

import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill's CSS for styling
import "../../css/CreateArtefactTextForm.css";

function CreateArtefactTextForm(props) {
  const nameRefArtef = useRef(null);
  const [textHeading, setTextHeading] = useState("");
  const [textBody, setTextBody] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle form submission
  const submitNewTextArtefact = async (e) => {
    e.preventDefault();

    // Validate input
    if (!textHeading.trim() && !textBody.trim()) {
      alert("Please enter a heading or body text for the artefact.");
      return;
    }

    setIsSubmitting(true);

    try {
      // Create the artefact object
      const newTextArtefact = {
        artefactName: textHeading.trim(),
        bodyText: textBody.trim(), // Use Quill's content
        content: textBody.trim(), // Use Quill's content
        type: "text",
      };

      // Pass the artefact as an array to match artefactsToPlace structure
      props.setArtefactsToPlace([newTextArtefact]);
      props.setPlaceArtefactsTrigger(true);
      props.handleCreateButtonClick();

      // Reset the form
      resetForm();
    } catch (error) {
      console.error("Error creating text artefact:", error);
      alert("Failed to create the text artefact. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Reset the form fields
  const resetForm = () => {
    setTextHeading("");
    setTextBody("");
    props.setSelectedType(null);
  };

  // Automatically focus the heading input when the component mounts
  useEffect(() => {
    if (nameRefArtef.current) {
      nameRefArtef.current.focus();
    }
  }, []);

  return (
    <Form
      className="artefacts-form"
      onSubmit={submitNewTextArtefact}
      style={{
        width: "95%",
        height: "100%",
        userSelect: "none",
      }}
    >
      <div>
        <Form.Group className="input-box-text-artefact">
          <Form.Label
            className="artefacts-text-form-heading"
            style={{
              fontFamily: "Raleway, sans-serif",
              fontSize: "27px",
              marginTop: "0px",
            }}
          >
            Text Artefact
          </Form.Label>
          <div style={{ height: "1vh" }}></div>

          <Form.Control
            className="artefacts-form-heading"
            type="text"
            ref={nameRefArtef}
            placeholder="Enter Text Heading"
            size="sm"
            autoFocus
            style={{
              fontFamily: "Raleway, sans-serif",
              marginTop: "-13px",
              fontSize: "16px",
            }}
            value={textHeading}
            onChange={(e) => setTextHeading(e.target.value)}
          />
        </Form.Group>
        <div style={{ height: "2vh" }}></div>

        <Form.Group className="website-artefacts-form">
          <ReactQuill
            value={textBody}
            onChange={setTextBody} // Update the state with the Quill content
            placeholder="Enter Text Body"
            style={{
              fontFamily: "Raleway, sans-serif",
              fontSize: "16px",
              marginTop: "-14px",
              height: "108px", // Set height for Quill editor
              backgroundColor: "white", // Optional: Set background color for editor
            }}
            modules={{
              toolbar: [
                [{ header: [5, false] }],
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                // ["clean"],
              ],
            }}
          />
        </Form.Group>
        <div style={{ height: "1.6vh" }}></div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className="create-button-text-artefact"
            variant="primary"
            type="submit"
            onClick={() => {
              props.handleClose();
            }}
            style={{
              backgroundColor: "#79b8ff",
              border: "none",
              marginTop: "42px",
              fontFamily: "Raleway, sans-serif",
            }}
            disabled={(!textHeading.trim() && !textBody.trim()) || isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Create"}
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default CreateArtefactTextForm;
