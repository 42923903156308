import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import NavBar from "../components/NavBar";
import { PannableBox } from "../components/PannableBox";
import "../css/Collection.css";
import FadeTransition from "../components/FadeTransition";

function Collection(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [collections, setCollections] = useState([]);
  const [thisCollection, setThisCollection] = useState(null);
  const [artefacts, setArtefacts] = useState([]);
  const [droppedImageFiles, setDroppedImageFiles] = useState([]);
  const [zoomedInFlag, setZoomedInFlag] = useState(false);
  const [isMovingArtefact, setIsMovingArtefact] = useState(false);
  const [isLowConnection, setIsLowConnection] = useState(false);

  const [viewMode, setViewMode] = useState(
    props.isPublicView ? "PUBLIC" : "PRIVATE"
  );

  const [artefactsToPlace, setArtefactsToPlace] = useState([]);

  const hasNavigatedRef = useRef(false);

  const filteredArtefacts =
    props.filter === "ALL"
      ? artefacts
      : artefacts.filter((artefact) => artefact.is_public);

  const filteredCollections =
    props.filter === "ALL"
      ? collections
      : collections.filter((collection) => collection.public_count > 0);

  const fetchArtefactContent = async (
    artefact,
    retries = 120,
    delay = 1000
  ) => {
    let attempts = 0;
    let hasShownLowConnection = false;

    while (attempts < retries) {
      try {
        const response = await fetch(
          `/api/user/${props.loggedInUsername}/artefact/${artefact.artefact_id}/content`
        );

        if (
          artefact.content_type === "image" ||
          artefact.content_type === "pdf"
        ) {
          const blob = await response.blob();
          const contentUrl = URL.createObjectURL(blob);
          if (attempts > 0) {
            setIsLowConnection(false); // Reset once successful
          }
          return { ...artefact, content: contentUrl };
        } else if (artefact.content_type === "website") {
          const data = await response.json();

          if (data.error) {
            console.log(`Error fetching website artefact: ${data.error}`);
            return {
              ...artefact,
              content: {
                title: "",
                ogImage: "",
                images: [],
                favicon: "",
                url: data.url,
              },
            };
          }

          if (data.hasOwnProperty("data") && typeof data.data === "string") {
            return { ...artefact, content: data.data };
          } else {
            return {
              ...artefact,
              content: {
                title: data.title || "",
                ogImage: data.ogImage || "",
                images: data.images || [],
                favicon: data.favicon || "",
                url: data.url || "",
              },
            };
          }
        } else if (artefact.content_type === "text") {
          const data = await response.json();
          return { ...artefact, content: data.data };
        } else {
          return artefact;
        }
      } catch (error) {
        attempts++;
        console.error(
          `Error fetching artefact content, attempt ${attempts}:`,
          error
        );

        // Show the low connection indicator after the first failed attempt
        if (attempts === 1 && !hasShownLowConnection) {
          setIsLowConnection(true);
          hasShownLowConnection = true;
        }

        if (attempts >= retries) {
          console.error("Max retries reached. Skipping this artefact.");
          return artefact;
        }

        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  const fetchArtefacts = async () => {
    props.setIsFetching(true);

    // Set a timer to trigger the low connection indicator if fetching takes longer than 5 seconds
    const lowConnectionTimer = setTimeout(() => {
      setIsLowConnection(true);
    }, 5000);

    try {
      const response = await fetch(
        `/api/user/${props.loggedInUsername}/collection/${id}`,
        {
          method: "GET",
          credentials: "same-origin",
        }
      );

      if (!response.ok) {
        if (response.status === 404) {
          console.error("Collection not found, navigating back.");
          if (!hasNavigatedRef.current) {
            navigate(-1);
            hasNavigatedRef.current = true;
          }
          clearTimeout(lowConnectionTimer);
          return;
        }
        throw new Error("Network response was not ok");
      }

      const j = await response.json();

      setThisCollection({
        type: j.type,
        collection_id: id,
        collection_name: j.collection_name,
        parent_id: j.parent_id,
        public_count: j.public_count,
      });

      if (j.type === "artefacts") {
        if (id !== thisCollection?.collection_id) {
          setArtefacts([]);
        }

        const fetchPromises = j.data.map((artefact) =>
          fetchArtefactContent(artefact).then((fetchedArtefact) => {
            setArtefacts((prevArtefacts) => {
              if (
                !prevArtefacts.find(
                  (a) => a.artefact_id === fetchedArtefact.artefact_id
                )
              ) {
                return [...prevArtefacts, fetchedArtefact];
              }
              return prevArtefacts;
            });
          })
        );

        // Wait for all artefacts to load
        await Promise.all(fetchPromises);

        clearTimeout(lowConnectionTimer); // Clear the timer if fetch completes within 5 seconds
        setIsLowConnection(false); // Ensure low connection is turned off when all artefacts are successfully loaded
        props.setArtefactRefresh(false);
        props.setIsFetching(false);
      } else if (j.type === "collections") {
        setCollections(j.data);
        setArtefacts([]);
        props.setCollectionRefresh(false);
        clearTimeout(lowConnectionTimer);
        setIsLowConnection(false);
      } else {
        setCollections([]);
        setArtefacts([]);
        props.setCollectionRefresh(false);
        clearTimeout(lowConnectionTimer);
        setIsLowConnection(false);
      }
    } catch (error) {
      console.log("Fetch error: ", error);
    } finally {
      props.setIsFetching(false);
      if (!hasNavigatedRef.current) {
        const previousURL = window.sessionStorage.getItem("previousURL");
        if (previousURL) {
          navigate(previousURL);
        }
        hasNavigatedRef.current = true;
      }
    }
  };

  useEffect(() => {
    if (props.loggedIn === false) {
      navigate("/");
    } else if (props.loggedIn === true && props.loggedInUsername) {
      fetchArtefacts();
    }
    // Only run this effect when loggedIn or loggedInUsername changes
  }, [
    props.loggedIn,
    props.loggedInUsername,
    id,
    props.collectionRefresh,
    props.artefactRefresh,
    viewMode,
  ]);

  useEffect(() => {
    if (viewMode !== (props.isPublicView ? "PUBLIC" : "PRIVATE")) {
      setViewMode(props.isPublicView ? "PUBLIC" : "PRIVATE");
    }
  }, [props.isPublicView]);

  function ConnectionIndicator() {
    return (
      <div
        className="low-connection"
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          color: "orange",
          marginLeft: "15px",
          marginTop: "15px",
          zIndex: "1000",
        }}
      >
        <div
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            backgroundColor: "orange",
            marginRight: "3px",
            marginTop: "-1px",
          }}
        ></div>
        <span>Low connection</span>
      </div>
    );
  }

  return (
    <FadeTransition in={true}>
      <div className="collection-contents">
        {isLowConnection && <ConnectionIndicator />}

        <NavBar
          currentCollection={thisCollection}
          loggedInUsername={props.loggedInUsername}
          setLoggedIn={props.setLoggedIn}
          setLoggedInUsername={props.setLoggedInUsername}
          handleCreateButtonClick={props.handleCreateButtonClick}
          collectionData={props.collectionData}
          setCollectionData={props.setCollectionData}
          artefactData={props.artefactData}
          setArtefactData={props.setArtefactData}
          placeArtefactsTrigger={props.placeArtefactsTrigger}
          setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
          setCenterScreen={props.setCenterScreen}
          isScreenCentered={props.isScreenCentered}
          centerScreenHandler={props.centerScreenHandler}
          setFilter={props.setFilter}
          isPublicView={props.isPublicView}
          setIsPublicView={props.setIsPublicView}
          zoomedInFlag={zoomedInFlag}
          og_username={props.og_username}
          setOg_username={props.setOg_username}
          droppedImageFiles={droppedImageFiles}
          setDroppedImageFiles={setDroppedImageFiles}
          loggedInFullname={props.loggedInFullname}
          setLoggedInFullname={props.setLoggedInFullname}
          placingMode={props.placingMode}
          setPlacingMode={props.setPlacingMode}
          artefactsToPlace={artefactsToPlace}
          setArtefactsToPlace={setArtefactsToPlace}
        />

        <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
          <PannableBox
            collections={filteredCollections}
            placingMode={props.placingMode}
            setPlacingMode={props.setPlacingMode}
            getter={props.submittedForm}
            setter={props.setSubmittedForm}
            currentCollection={thisCollection}
            collectionData={props.collectionData}
            setCollectionData={props.setCollectionData}
            artefactData={props.artefactData}
            artefacts={filteredArtefacts}
            setArtefacts={setArtefacts}
            setArtefactData={props.setArtefactData}
            setCollections={setCollections}
            placeArtefactsTrigger={props.placeArtefactsTrigger}
            setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
            centerScreen={props.centerScreen}
            setCenterScreen={props.setCenterScreen}
            setIsScreenCentered={props.setIsScreenCentered}
            loggedInUsername={props.loggedInUsername}
            handleCreateButtonClick={props.handleCreateButtonClick}
            isPublicView={props.isPublicView}
            otherUser={null}
            zoomedInFlag={zoomedInFlag}
            setZoomedInFlag={setZoomedInFlag}
            isOwner={true}
            og_username={props.og_username}
            droppedImageFiles={droppedImageFiles}
            setDroppedImageFiles={setDroppedImageFiles}
            setIsMovingArtefact={setIsMovingArtefact}
            sharelinkUsername={props.sharelinkUsername}
            setSharelinkUsername={props.setSharelinkUsername}
            loggedInFullname={props.loggedInFullname}
            setLoggedInFullname={props.setLoggedInFullname}
            sharelinkFullname={null}
            setSharelinkFullname={null}
            collectionRefresh={props.collectionRefresh}
            setCollectionRefresh={props.setCollectionRefresh}
            artfactRefresh={props.artfactRefresh}
            setArtefactRefresh={props.setArtefactRefresh}
            isFetching={props.isFetching}
            artefactsToPlace={artefactsToPlace}
            setArtefactsToPlace={setArtefactsToPlace}
          />
        </div>
      </div>
    </FadeTransition>
  );
}

export default Collection;
