import React, { useState, useMemo, useEffect, useRef } from "react";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";
import { useNavigate } from "react-router-dom";
import { BiSolidLockAlt } from "react-icons/bi";
import { CSSTransition } from "react-transition-group";
import { FaFilePdf } from "react-icons/fa"; // If using an icon
import { TwitterTweetEmbed } from "react-twitter-embed";
import {
  InstagramEmbed,
  FacebookPostEmbed,
  LinkedInEmbed,
  PinterestEmbed,
  TikTokEmbed,
  YouTubeEmbed,
} from "react-social-media-embed";
import ReactQuill, { Quill } from "react-quill";
// import InstagramEmbed from "react-instagram-embed";

import "../css/Artefact.css";

function Artefact({
  customX,
  customY,
  art,
  cellSize,
  // handleRightClick,
  attemptingToCloseMenu,
  setAttemptingToCloseMenu,
  isPublicView,
  zoomedInFlag,
  setZoomedInFlag,
  innerDivRef,
  outerDivRef,
  onArtefactClick,
  isEditing,
  editArtefactName,
  isOwner,
}) {
  const artefactRef = useRef(null);

  const navigate = useNavigate();
  const [mouseDownPos, setMouseDownPos] = useState(null);
  const halfCellSize = cellSize / 2;
  // const { coordx_private: x, coordy_private: y } = art;
  // const x = isPublicView ? art.coordx_public : art.coordx_private;
  // const y = isPublicView ? art.coordy_public : art.coordy_private;
  // const [imageDimensions, setImageDimensions] = useState([300, 300]);
  const [imageDimensions, setImageDimensions] = useState({
    width: art.image_width,
    height: art.image_height,
  });

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  /// THE ONLY DIFFERENCE WITH ARTEFACT.JS ARE THE COORDIANTES
  // const x =
  //   customX !== undefined
  //     ? customX
  //     : isPublicView
  //     ? art.coordx_public
  //     : art.coordx_private;
  // const y =
  //   customY !== undefined
  //     ? customY
  //     : isPublicView
  //     ? art.coordy_public
  //     : art.coordy_private;

  //   const x =
  //   customX !== undefined
  //     ? customX
  //     : isPublicView
  //     ? art.coordx_public
  //     : art.coordx_private;
  // const y =
  //   customY !== undefined
  //     ? customY
  //     : isPublicView
  //     ? art.coordy_public
  //     : art.coordy_private;

  // When the user is not the owner, always use public coordinates
  // let x, y;

  const x = art.coordx;
  const y = art.coordy;

  const [ownerUsername, setOwnerUsername] = useState("");

  // will be owner_name which is the username
  // useEffect(() => {
  //   const fetchOwnerUsername = async () => {
  //     try {
  //       const response = await fetch(`/api/user/${art.owner_id}/username`);
  //       const data = await response.json();
  //       setOwnerUsername(data.username); // Adjust based on your actual response structure
  //     } catch (error) {
  //       console.error("Failed to fetch owner username:", error);
  //     }
  //   };

  //   fetchOwnerUsername();
  // }, [art.owner_id]); // Re-run when art.owner_id changes
  /// THE ONLY DIFFERENCE WITH ARTEFACT.JS ARE THE COORDIANTES
  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  const [zoomedIn, setZoomedIn] = useState(false);
  const [isCentered, setIsCentered] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const [thumbnailUrl, setThumbnailUrl] = React.useState("");

  const [showOverlay, setShowOverlay] = useState(false);

  const [newArtefactName, setNewArtefactName] = useState(art.artefact_name);
  const [newArtefactContent, setNewArtefactContent] = useState(
    art.content_type === "text"
    // ? typeof art.content === "object"
    //   ? art.content.bodyText
    //   : JSON.parse(art.content).bodyText
    // : ""
  );

  const handleContentChange = (content, delta, source, editor) => {
    // Strip out empty Quill content (single paragraph with a line break)
    if (editor.getText().trim() === "") {
      // Set content to an empty string
      setNewArtefactContent("");
    } else {
      setNewArtefactContent(content);
    }
  };

  const quillRef = useRef(null); // Reference to Quill editor
  const MAX_LINES = 20;

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();

      const handleTextChange = () => {
        const lines = editor
          .getContents()
          .ops.map((op) => op.insert)
          .join("")
          .split("\n");
        const lineCount = lines.length;

        if (lineCount > MAX_LINES) {
          // Prevent user from adding more content by deleting the last character added
          editor.deleteText(editor.getLength() - 1, 1);
        }
      };

      editor.on("text-change", handleTextChange);

      return () => {
        editor.off("text-change", handleTextChange);
      };
    }
  }, [newArtefactContent]);

  const [touchStartPos, setTouchStartPos] = useState([]);
  const [touchMoveDetected, setTouchMoveDetected] = useState(false);

  // const tweetIdMatch = art.content.match(/status\/(\d+)/);
  // const tweetId = tweetIdMatch ? tweetIdMatch[1] : null;
  const tweetIdMatch =
    typeof art.content === "string"
      ? art.content?.match(/status\/(\d+)/)
      : null;
  const tweetId = tweetIdMatch ? tweetIdMatch[1] : null;

  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      // Store initial positions of both touches
      const touches = Array.from(e.touches).map((touch) => ({
        x: touch.clientX,
        y: touch.clientY,
      }));
      setTouchStartPos(touches);
      setTouchMoveDetected(false);
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 2) {
      setTouchMoveDetected(true);
    }
  };

  const handleTouchEnd = (e) => {
    // Check if two fingers were used, no movement was detected, and both fingers lifted
    if (
      e.touches.length === 0 &&
      !touchMoveDetected &&
      touchStartPos.length === 2
    ) {
      // Prevent the default context menu on mobile
      e.preventDefault();
      // Trigger your custom right-click function
      // handleRightClick(e, art.artefact_id, art.is_public);
    }
    setTouchStartPos([]); // Clear the initial touch positions
  };

  useEffect(() => {
    const artefactElement = artefactRef.current;

    if (artefactElement) {
      artefactElement.addEventListener("touchstart", handleTouchStart);
      artefactElement.addEventListener("touchmove", handleTouchMove);
      artefactElement.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (artefactElement) {
        artefactElement.removeEventListener("touchstart", handleTouchStart);
        artefactElement.removeEventListener("touchmove", handleTouchMove);
        artefactElement.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, []);

  // const instagramEmbedUrl = (url) => {
  //   const cleanUrl = new URL(url);
  //   // Remove query parameters
  //   cleanUrl.search = "";
  //   return `https://www.instagram.com${cleanUrl.pathname}/embed/`;
  // };

  // const isInstagramArtefact = art.content.includes("instagram.com");

  const extractTwitterId = (url) => {
    if (!url) return null;
    const match = url.match(/status\/(\d+)/);
    return match ? match[1] : null;
  };

  const extractInstagramId = (url) => {
    const match = url.match(/instagram\.com\/p\/([^/]+)/);
    return match ? match[1] : null;
  };

  const extractPinterestId = (url) => {
    const match = url.match(/pin\/(\d+)/);
    return match ? match[1] : null;
  };

  const extractYouTubeId = (url) => {
    const match = url.match(/(?:youtube\.com\/.*[?&]v=|youtu\.be\/)([^&]+)/);
    return match ? match[1] : null;
  };

  const extractTikTokId = (url) => {
    const match = url.match(/video\/(\d+)/);
    return match ? match[1] : null;
  };

  const extractLinkedInEmbedUrl = (url) => {
    const embedUrlPattern = /linkedin\.com\/embed\/feed\/update\/([^/?]+)/;
    const activityUrlPattern = /linkedin\.com\/feed\/update\/([^/?]+)/;

    if (embedUrlPattern.test(url)) {
      const match = url.match(embedUrlPattern);
      return match
        ? `https://www.linkedin.com/embed/feed/update/${match[1]}`
        : null;
    } else if (activityUrlPattern.test(url)) {
      const match = url.match(activityUrlPattern);
      return match
        ? `https://www.linkedin.com/embed/feed/update/${match[1]}`
        : null;
    }

    return null; // Return null if no match is found
  };

  // const twitterId = extractTwitterId(art.content);

  // const handleRightClickOnOverlay = (event) => {
  // event.preventDefault(); // Prevent the default context menu
  // Logic to display your custom context menu
  // };

  // useEffect(() => {
  //   if (art.content_type === "website") {
  //     try {
  //       const url = new URL(art.content);
  //       fetchOGImage(url.origin, setThumbnailUrl);
  //     } catch (error) {
  //       console.error("Invalid URL for artefact:", art.content, error);
  //     }
  //   }
  // }, [art.content, art.content_type]); // Depend on both content and content_type

  function generatePastelImageUrl() {
    const h = Math.floor(Math.random() * 361);
    const s = Math.floor(Math.random() * 11 + 20); // Saturation between 20% and 30%
    const l = Math.floor(Math.random() * 11 + 80); // Lightness between 80% and 90%
    return `https://via.placeholder.com/150/${hslToHex(h, s, l)}?text=+`;
  }

  // Function to convert HSL to HEX since some placeholder services might not accept HSL
  function hslToHex(h, s, l) {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, "0"); // Convert to Hex and format
    };
    return `${f(0)}${f(8)}${f(4)}`;
  }

  // const [pageTitle, setPageTitle] = useState("");
  const [pageTitle, setPageTitle] = useState(
    art.content_type === "website" && art.content?.title
      ? art.content.title
      : ""
  );
  const [faviconUrl, setFaviconUrl] = useState("");

  // useEffect(() => {
  //   const fetchWebContent = async (url) => {
  //     try {
  //       const response = await fetch("/api/fetch-web-content", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ url }),
  //       });

  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }

  //       const data = await response.json();
  //       console.log("zzzzzzzzzzzz", data);

  //       setPageTitle(data.title);

  //       if (data.ogImage) {
  //         setThumbnailUrl(data.ogImage);
  //       } else if (data.images && data.images.length > 0) {
  //         // Placeholder logic for selecting the largest image
  //         // This can be replaced with more sophisticated logic based on image dimensions
  //         const largestImage = data.images[0]; // Simplified example, replace with actual logic
  //         setThumbnailUrl(largestImage);
  //       } else {
  //         setThumbnailUrl(generatePastelImageUrl());
  //       }
  //     } catch (error) {
  //       console.error("Error fetching web content:", error);
  //       setThumbnailUrl(generatePastelImageUrl());
  //     }
  //   };

  //   if (art.content_type === "website") {
  //     fetchWebContent(art.content);
  //   }
  // }, [art.content, art.content_type]); // Re-run the effect if art.content or art.content_type changes

  // useEffect(() => {
  //   const fetchWebContent = async (url) => {
  //     try {
  //       const response = await fetch("/api/fetch-web-content", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ url }),
  //       });

  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }

  //       const data = await response.json();
  //       console.log("Web Content Data:", data);

  //       setPageTitle(data.title);

  //       // Use the favicon from the backend if available
  //       if (data.favicon) {
  //         setFaviconUrl(data.favicon);
  //       } else {
  //         // Fallback to Google favicon service
  //         setFaviconUrl(
  //           `https://www.google.com/s2/favicons?domain=${new URL(url).hostname}`
  //         );
  //       }

  //       // Handle the OG image or other images as before
  //       if (data.ogImage) {
  //         setThumbnailUrl(data.ogImage);
  //       } else if (data.images && data.images.length > 0) {
  //         const largestImage = data.images[0]; // Simplified example, replace with actual logic
  //         setThumbnailUrl(largestImage);
  //       } else {
  //         setThumbnailUrl(generatePastelImageUrl());
  //       }
  //     } catch (error) {
  //       console.error("Error fetching web content:", error);
  //       setThumbnailUrl(generatePastelImageUrl());
  //       setFaviconUrl(
  //         `https://www.google.com/s2/favicons?domain=${
  //           new URL(art.content).hostname
  //         }`
  //       ); // Fallback to Google favicon on error
  //     }
  //   };

  //   if (art.content_type === "website") {
  //     fetchWebContent(art.content);
  //   }
  // }, [art.content, art.content_type]);

  useEffect(() => {
    setIsVisible(true);

    // Optional: If you also want to fade out the artefact before it's unmounted, you can trigger the fade-out effect here.
    // Return a cleanup function that triggers the fade-out effect
    return () => setIsVisible(false);
  }, []);

  // Apply the fade-in or fade-out class based on the `isVisible` state
  const artefactClassName = isVisible
    ? "artefact-fade-in"
    : // : "artefact-fade-out";
      "artefact-fade-out";

  const styleArtef = {
    maxWidth: "300px",
    maxHeight: "300px",
    width: "auto",
    height: "auto",
    borderRadius: "10px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    transition: "top 0.3s ease, left 0.3s ease",
    userSelect: "none",
    cursor: "pointer",
  };

  let scaleFactor = 1; // Default scale factor
  switch (art.sizing_choice) {
    case "s":
      scaleFactor = 0.85;
      break;
    case "m":
      scaleFactor = 1;
      break;
    case "l":
      scaleFactor = 1.15;
      break;
    default:
      // You can decide on a default behaviour, or throw an error, etc.
      break;
  }

  let textWidth = 350; // Default width for text
  let textHeight = 150; // Default height for text

  const stylePosition = useMemo(() => {
    const { width, height } = imageDimensions;
    let baseLeft = `${x + halfCellSize - width / 2 - cellSize}`;
    let baseTop = `${y + halfCellSize - height / 2 - cellSize}`;

    console.log(
      "Sizing choice and scale factor:",
      art.sizing_choice,
      scaleFactor
    );

    if (art.content_type === "website") {
      // baseLeft = `${parseInt(baseLeft)}px`; // Append 'px' to ensure CSS understands the unit
      // baseTop = `${parseInt(baseTop) + 70}px`; // Same here, and add 70 pixels to top
      // baseLeft = `${x + halfCellSize - 150}px`; // Assuming some default dimensions for the website artefact
      // baseTop = `${y + halfCellSize - 95}px`; // Adjust this based on your actual size
      baseLeft = `${x + halfCellSize - 350 / 2}`;
      baseTop = `${y + halfCellSize - 320 / 2}`;
      // console.log(
      //   `Website artefact position: left: ${baseLeft}, top: ${baseTop}`
      // ); // Debug log
    } else if (art.content_type === "text") {
      // Assuming some default dimensions for the text artefact
      textWidth = 350;
      textHeight = 70; // Adjust this based on your actual size
      baseLeft = `${x + halfCellSize - textWidth / 2}`;
      baseTop = `${y + halfCellSize - textHeight / 2}`;
    } else if (art.content_type === "pdf") {
      // Assuming some default dimensions for the text artefact
      textWidth = 220; /// BTW YOU HAVE ADDED 20 SO THAT IT FIXES THE POSITION PLACEMENT, NORMALLY WOULD BE 200
      textHeight = 250; // Adjust this based on your actual size
      baseLeft = `${x + halfCellSize - textWidth / 2}`;
      baseTop = `${y + halfCellSize - textHeight / 2}`;
      console.log("aassddd");
    }

    if (zoomedIn) {
      // const centerOffsetX =
      //   (window.innerWidth - imageDimensions.width * 2.5) / 2;
      // const centerOffsetY =
      //   (window.innerHeight - imageDimensions.height * 2.5) / 2;

      // const centerOffsetX =
      //   (window.innerWidth -
      //     (art.content_type === "text" ? 350 * scaleFactor : width) * 2.5) /
      //   2;
      // const centerOffsetY =
      //   (window.innerHeight -
      //     (art.content_type === "text" ? 70 * scaleFactor : height) * 2.5) /
      //   2;

      let scaleFactor = 1; // Default scale factor for unzoomed state
      if (zoomedIn) {
        if (art.content_type === "text") {
          scaleFactor = 1.3; // Apply specific scale factor for text artefacts
        } else {
          scaleFactor = 1.7; // Apply general zoom level for other artefacts
        }
      }

      const centerOffsetX = (window.innerWidth - textWidth * scaleFactor) / 2;
      const centerOffsetY = (window.innerHeight - textHeight * scaleFactor) / 2;

      baseLeft = `${centerOffsetX}px`;
      baseTop = `${centerOffsetY}px`;
    }

    return {
      position: "absolute",
      left: `${baseLeft}px`,
      top: `${baseTop}px`,
      background: "none",
      border: "none",
      transform: `scale(${scaleFactor})`,
    };
  }, [
    x,
    y,
    art.content_type,
    imageDimensions,
    halfCellSize,
    cellSize,
    zoomedIn,
    isCentered,
    art.sizing_choice,
  ]);

  const artefactNameFontSize = useMemo(() => {
    switch (art.sizing_choice) {
      case "s":
        return "19px";
      case "m":
        return "16px";
      case "l":
        return "14px";
      default:
        return "14px"; // Default font size if sizing_choice is not recognized
    }
  }, [art.sizing_choice]);

  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const lockPositionBottom = useMemo(() => {
    let additionalOffset = 0; // Additional offset when artefact name is empty

    // Adjust additionalOffset based on artefact size and if name is empty
    if (art.artefact_name === "") {
      if (art.sizing_choice === "s") {
        additionalOffset = 3; // Move up by 3px for small artefact if name is empty
      } else if (art.sizing_choice === "l") {
        additionalOffset = 0; // Move down by 1px for large artefact if name is empty
      }
    }

    // Determine the base lock position based on the artefact size
    let basePosition;
    switch (art.sizing_choice) {
      case "s":
        basePosition = `calc(-19px + ${additionalOffset}px)`; // Adjust for small artefact
        break;
      case "m":
        basePosition = "-15px"; // Default position for medium artefact
        break;
      case "l":
        basePosition = `calc(-13px + ${additionalOffset}px)`; // Adjust for large artefact
        break;
      default:
        basePosition = "-12px"; // Default to medium size if no size is specified
    }

    // If in editing mode, add "40px" to the calculated base position
    let finalPosition = isEditing
      ? `calc(${basePosition} + 40px)`
      : basePosition;

    // Subtract 30px if the text is expanded and the artefact name is not empty
    if (isTextExpanded && art.artefact_name !== "") {
      finalPosition = `calc(${finalPosition} - 30px)`;
    }

    return finalPosition;
  }, [art.sizing_choice, art.artefact_name, isEditing, isTextExpanded]);

  const lockSize = useMemo(() => {
    switch (art.sizing_choice) {
      case "s":
        return "18px";
      case "m":
        return "15px";
      case "l":
        return "13px";
      default:
        return "14px"; // Default size if sizing_choice is not recognized
    }
  }, [art.sizing_choice]);

  useEffect(() => {
    function handleZoomOut() {
      setZoomedIn(false);
      // setIsCentered(false)
    }

    let timer;
    if (zoomedIn) {
      timer = setTimeout(() => {
        window.addEventListener("click", handleZoomOut);
      }, 0);
    }

    return () => {
      clearTimeout(timer);
      window.removeEventListener("click", handleZoomOut);
    };
  }, [zoomedIn]);

  useEffect(() => {
    // Set zoomedInFlag based on the zoomedIn state
    setZoomedInFlag(zoomedIn);
  }, [zoomedIn]); // Dependency array ensures this effect runs only when zoomedIn changes

  // useEffect(() => {
  //   if (zoomedIn) {
  //     setShowOverlay(true);
  //   } else {
  //     // Delay hiding the overlay to allow the opacity transition
  //     const timer = setTimeout(() => setShowOverlay(false), 500); // Match the transition duration
  //     return () => clearTimeout(timer);
  //   }
  // }, [zoomedIn]);

  const [shouldDelayZIndex, setShouldDelayZIndex] = useState(false);

  const calculatePaddingRight = (username) => {
    // Set a base padding and an additional padding per character
    const basePadding = 0;
    const additionalPaddingPerChar = 0; // Adjust this value to fit your design
    return `${basePadding + username.length * additionalPaddingPerChar}px`;
  };

  const calculateGradientStart = (usernameLength) => {
    // Adjust these values to get the desired fading effect
    // Here we subtract a constant to ensure it starts fading 10px before the username
    const baseStart = 80; // Starting point for the gradient in percentage
    const adjustmentFactor = usernameLength * 2.5; // Adjust this multiplier for fine-tuning
    return baseStart - adjustmentFactor;
  };

  const calculateGradientEnd = (usernameLength) => {
    // Adjust these values to get the desired fading effect
    // Here we subtract a constant to ensure it ends fading 5px before the username
    const baseEnd = 95; // Ending point for the gradient in percentage
    const adjustmentFactor = usernameLength * 2; // Adjust this multiplier for fine-tuning
    return baseEnd - adjustmentFactor;
  };

  // const calculatePaddingRight = (username) => {
  //   // Set a base padding and an additional padding per character
  //   const basePadding = 0; // Base padding in px
  //   const additionalPadding = 0; // Additional padding to provide space for the gradient
  //   return `${basePadding + additionalPadding}px`;
  // };

  // Function to calculate the width of the username in pixels
  const calculateUsernameWidth = (username) => {
    // Assuming an average character width of around 8px (adjust as needed)
    return username.length * 8 + 20; // Add some padding
  };

  // Modify the gradient start and end calculation
  // const getGradientStyle = () => {
  //   const usernameWidth = calculateUsernameWidth(art.ownerUsername);
  //   const containerWidth = imageDimensions.width || 350; // Use default width if no image dimensions

  //   // Calculate the available width for the artefact name
  //   const artefactNameWidth = containerWidth - usernameWidth;

  //   // Gradient start and end positions
  //   const gradientStart = Math.max(artefactNameWidth - 50, 0); // Start fading 30px before the available width
  //   const gradientEnd = artefactNameWidth + 25; // End fading 20px after the available width

  //   // Construct the linear gradient
  //   return {
  //     background: `linear-gradient(to right, black ${gradientStart}px, rgba(0, 0, 0, 0) ${gradientEnd}px)`,
  //     // background: `linear-gradient(to right, black ${
  //     //   100 - art.ownerUsername.length * 9
  //     // }%, rgba(0, 0, 0, 0) 60%)`, // Adjusted gradient
  //     WebkitBackgroundClip: "text", // Make the gradient visible only on the text
  //     color: "transparent", // Make the text itself transparent
  //     paddingRight: `${usernameWidth}px`, // Add padding to avoid cutting off the fade
  //   };
  // };

  const getGradientStyle = () => {
    const usernameWidth = calculateUsernameWidth(art.ownerUsername);
    const containerWidth = imageDimensions.width || 350; // Use default width if no image dimensions

    // Calculate the available width for the artefact name
    const artefactNameWidth = containerWidth - usernameWidth;

    // Calculate gradient start and end as percentages of the container width
    const gradientStartPercent = Math.max(
      ((artefactNameWidth - 50) / containerWidth) * 100,
      0
    );
    const gradientEndPercent =
      ((artefactNameWidth + 25) / containerWidth) * 100;

    // Construct the linear gradient
    return {
      background: `linear-gradient(to right, black ${gradientStartPercent}%, rgba(0, 0, 0, 0) ${gradientEndPercent}%)`,
      WebkitBackgroundClip: "text", // Make the gradient visible only on the text
      color: "transparent", // Make the text itself transparent
      paddingRight: `${usernameWidth}px`, // Add padding to avoid cutting off the fade
    };
  };

  const [showGradient, setShowGradient] = useState(true);

  // Effect to handle the delay in applying the zIndex change
  useEffect(() => {
    let timer;
    if (zoomedIn) {
      // Immediately set zIndex to 6 when zoomed in
      setShouldDelayZIndex(true);
      setShowGradient(false);
    } else {
      // Delay the zIndex change to 0 by 500ms when zooming out
      timer = setTimeout(() => {
        setShouldDelayZIndex(false);
        setShowGradient(true);
      }, 350);
    }

    // Cleanup timer on component unmount or when zoomedIn changes
    return () => clearTimeout(timer);
  }, [zoomedIn]);

  const gradientWidth =
    art.content_type === "image" ? `${imageDimensions.width}px` : "100px";

  // function truncateText(text, maxLength) {
  //   if (text.length <= maxLength) return text;

  //   return text.substring(0, maxLength).trimEnd() + "...";
  // }

  function truncateText(text, maxLength) {
    // Ensure text is defined and a string, or set it to an empty string if it's not
    if (typeof text !== "string") text = "";

    // Return text as is if it's shorter than or equal to maxLength
    if (text.length <= maxLength) return text;

    // Truncate and add ellipsis if it's longer than maxLength
    return text.substring(0, maxLength).trimEnd() + "...";
  }

  // const fileName = art.content.split("/").pop();

  const fileName =
    art.content && typeof art.content === "string"
      ? art.content.split("/").pop()
      : "default.pdf";

  // Function to limit the file name to 30 characters
  const truncatedFileName =
    fileName.length > 27 ? fileName.substring(0, 22) + "..." : fileName;

  // Check if the fileName ends with ".pdf" and add it if necessary
  const displayFileName = truncatedFileName.endsWith(".pdf")
    ? truncatedFileName
    : truncatedFileName + ".pdf";

  // Use this function to truncate pageTitle before rendering
  const truncatedPageTitle = truncateText(pageTitle, 75); // Set maxLength based on your average character count per line * number of lines
  // const truncatedUrl = truncateText(art.content?.url, 40);
  const truncatedUrl = truncateText(
    typeof art.content === "string" ? art.content : art.content?.url,
    40
  );

  const [isHovering, setIsHovering] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [hoverClass, setHoverClass] = useState("fade-out-url");

  const handleMouseEnter = () => {
    const timeout = setTimeout(() => {
      setHoverClass("fade-in-url");
      setIsHovering(true);
    }, 200); // delay
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setHoverClass("fade-out-url");
    setIsHovering(false);
    setHoverTimeout(null);
  };

  const nameHoverTimeoutRef = useRef(null);

  const handleNameMouseEnter = () => {
    nameHoverTimeoutRef.current = setTimeout(() => {
      setIsTextExpanded(true);
    }, 800);
  };

  const handleNameMouseLeave = () => {
    if (nameHoverTimeoutRef.current) {
      clearTimeout(nameHoverTimeoutRef.current);
      nameHoverTimeoutRef.current = null;
    }
    setIsTextExpanded(false); // Reset to false when mouse leaves
  };

  // Clean up the timeout when the component unmounts
  useEffect(() => {
    return () => {
      if (nameHoverTimeoutRef.current) {
        clearTimeout(nameHoverTimeoutRef.current);
      }
    };
  }, []);

  const [error, setError] = useState(false);
  const handleContentLoadError = () => {
    setError(true);
  };

  if (error) return null;

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 6,
          opacity: zoomedIn ? 1 : 0,
          visibility: showOverlay ? "visible" : "hidden",
          transition: "opacity 0.5s ease-in-out, visibility 0.5s ease-in-out",
        }}
      ></div>

      <CSSTransition
        in={isVisible} // Always in since the component itself will be mounted/unmounted to show/hide
        timeout={700} // Duration of the animation (must match your CSS)
        classNames="artefact-transition" // Prefix for the CSS transition classes
        unmountOnExit // Unmount the component from the DOM when it has finished exiting
      >
        <ListGroup.Item
          ref={artefactRef}
          // className={artefactClassName}
          className={`${artefactClassName} no-select`}
          style={{
            ...stylePosition,
            // transform: zoomedIn ? "scale(1.2)" : "scale(1)",
            // transform: `${stylePosition.transform} scale(${scaleFactor})`,
            // transform: `${zoomedIn ? "scale(1.7)" : ""} ${
            //   stylePosition.transform
            // } scale(${scaleFactor})`.trim(),

            // zIndex: shouldDelayZIndex ? 6 : 0,
            // transition: "transform 0.5s",
            transform: `${
              zoomedIn
                ? art.content_type === "text"
                  ? "scale(1.3)"
                  : "scale(1.7)"
                : ""
            } ${stylePosition.transform}`,
            zIndex: shouldDelayZIndex ? 6 : 0,
            transition: "transform 0.5s",
          }}
          key={art.artefact_id}
          id={"artefact-" + art.artefact_id}
          onMouseDown={(e) => {
            setMouseDownPos({
              x: e.screenX,
              y: e.screenY,
            });
          }}
          onMouseUp={(e) => {
            e.preventDefault();
            if (mouseDownPos) {
              if (e.button === 0) {
                // Only capture left clicks
                if (
                  e.screenX === mouseDownPos.x &&
                  e.screenY === mouseDownPos.y
                ) {
                  if (attemptingToCloseMenu) {
                    // If the user is attempting to close the menu, reset the flag and don't navigate
                    setAttemptingToCloseMenu(false);
                  } else {
                    if (art.content_type === "pdf") {
                      window.open(art.content, "_blank");
                    } else if (art.content_type === "website") {
                      const url =
                        typeof art.content === "string"
                          ? art.content
                          : art.content.url;
                      if (url) {
                        window.open(url, "_blank");
                      } else {
                        console.error(
                          "Invalid URL for website artefact:",
                          art.content
                        );
                      }
                      // } else {
                      //   if (art.content_type === "text") {
                      //     onArtefactClick(x, y, -1000, -1000);
                      //   }
                      //   else {
                      //     onArtefactClick(
                      //       x,
                      //       y,
                      //       imageDimensions.height,
                      //       imageDimensions.width
                      //     );
                      //     console.log(
                      //       "rah2",
                      //       x,
                      //       y,
                      //       imageDimensions.height,
                      //       imageDimensions.width,
                      //       art
                      //     );
                      //     setZoomedIn((prevState) => !prevState);
                      //     setIsCentered(true);
                      //   }
                      // }
                    } else {
                      switch (art.content_type) {
                        case "website":
                          window.open(art.content, "_blank");
                          break;
                        case "text":
                          // Trigger zoom and centering logic for text artefacts
                          onArtefactClick(x, y, textHeight, textWidth); // Ensure onArtefactClick can handle text size
                          console.log(
                            "Text artefact zoom:",
                            x,
                            y,
                            textHeight,
                            textWidth
                          );
                          setZoomedIn((prevState) => !prevState);
                          setIsCentered(true);
                          break;
                        default:
                          onArtefactClick(
                            x,
                            y,
                            imageDimensions.height,
                            imageDimensions.width
                          );
                          console.log(
                            "Image artefact zoom:",
                            x,
                            y,
                            imageDimensions.height,
                            imageDimensions.width
                          );
                          setZoomedIn((prevState) => !prevState);
                          setIsCentered(true);
                      }
                    }
                  }
                }
              }
            }
          }}
          // onContextMenu={(e) =>
          // handleRightClick(e, art.artefact_id, art.is_public)
          // }
        >
          {art.content_type === "website" && art.content && (
            <>
              {typeof art.content === "string" && (
                <>
                  {(art.content.includes("twitter.com") &&
                    extractTwitterId(art.content)) ||
                  (art.content.includes("https://x.com") &&
                    extractTwitterId(art.content)) ? (
                    <div
                      className="no-select"
                      style={{
                        marginBottom: "-10px",
                      }}
                    >
                      <TwitterTweetEmbed
                        // tweetId={art.content.split("/").pop()}
                        tweetId={tweetId}
                        options={{
                          theme: "dark",
                          // align: "center",
                          // cards: "hidden",
                          conversation: "none",
                          dnt: true,
                          borderColor: "#ff4500",
                          // linkColor: "#00B2FF",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 1, // Ensure this overlay is above the tweet embed
                          cursor: "pointer",
                        }}
                        // onContextMenu={handleRightClickOnOverlay}
                      ></div>
                    </div>
                  ) : art.content.includes("instagram.com") &&
                    extractInstagramId(art.content) ? (
                    <div
                      style={{
                        marginBottom: "-10px",
                        position: "relative",
                        cursor: "pointer",
                      }}
                      // style={{
                      // width: "325px", // Set a fixed width
                      // height: "570px", // Set a fixed height
                      // position: "relative",
                      // }}
                    >
                      <InstagramEmbed
                        // style={{ userSelect: "none" }}
                        style={{
                          userSelect: "none",
                          width: "100%",
                          height: "100%",
                        }}
                        url={art.content}
                        hideCaption={true}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 1,
                        }}
                        // onContextMenu={handleRightClickOnOverlay}
                      ></div>
                    </div>
                  ) : art.content.includes("tiktok.com") &&
                    extractTikTokId(art.content) ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <TikTokEmbed url={art.content} width={325} />
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 1,
                        }}
                        // onContextMenu={handleRightClickOnOverlay}
                      ></div>
                    </div>
                  ) : (art.content.includes("youtube.com") ||
                      art.content.includes("youtu.be")) &&
                    extractYouTubeId(art.content) ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {/* Wrapper div for optional scaling */}
                      <div style={{}}>
                        <YouTubeEmbed
                          url={art.content}
                          width={325}
                          height={220}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1,
                            bottom: "146.67px",
                          }}
                          // onContextMenu={handleRightClickOnOverlay}
                        ></div>
                      </div>
                    </div>
                  ) : art.content.includes("linkedin.com") &&
                    extractLinkedInEmbedUrl(art.content) ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        overflowY: "auto", // Enable vertical scrolling
                        height: "570px", // Set a fixed height
                        userSelect: "none",
                      }}
                    >
                      <LinkedInEmbed
                        url={extractLinkedInEmbedUrl(art.content)}
                        width={325}
                        height={570}
                        placeholderDisabled={true}
                        style={{ userSelect: "none" }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          zIndex: 1,
                          userSelect: "none",
                        }}
                        // onContextMenu={handleRightClickOnOverlay}
                      ></div>
                    </div>
                  ) : (
                    // null
                    <div
                      className="website-artefact no-select"
                      style={{
                        width: "350px",
                        height: "320px",
                        backgroundColor: "#FDFCFB",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "10px",
                        cursor: "pointer",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                        padding: "20px",
                        minHeight: "320px",
                        userSelect: "none",
                        // marginTop: "0px",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <img
                          src={`https://www.google.com/s2/favicons?domain=${
                            new URL(art.content).hostname
                          }`}
                          alt={`${art.artefact_name} Favicon`}
                          style={{
                            marginBottom: "150px",
                            marginLeft: "-50px",
                            width: "23px",
                            height: "23px",
                            position: "absolute",
                            top: "16px",
                            left: "76px",
                            borderRadius: "10px",
                            userSelect: "none",
                          }}
                        />
                        <p
                          href={art.content}
                          style={{
                            color: "black",
                            fontSize: "25px",
                            padding: "19px 12px 0px 0px",
                            marginLeft: "-18px",
                            width: "20px",
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {/* {art.artefact_name} */}
                        </p>
                      </div>
                      <div
                        style={{
                          flex: 2,
                          backgroundColor: "white",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          borderRadius: "10px",
                          height: "auto",
                          width: "90%",
                          minHeight: "200px",
                          maxHeight: "80%",
                          marginBottom: "-20px",
                        }}
                      >
                        <img
                          // src={art.content?.ogImage || thumbnailUrl}
                          // src={art.content?.ogImage }
                          src={
                            art.content?.ogImage ||
                            art.content?.images?.[0] ||
                            generatePastelImageUrl()
                          }
                          // src={thumbnailUrl}
                          alt={`${art.artefact_name} Thumbnail`}
                          onError={(e) => {
                            e.target.onerror = null; // Prevents infinite loop in case of error
                            e.target.style.background = "white"; // Ensure background is white
                            e.target.src =
                              "data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"; // Transparent pixel
                          }}
                          style={{
                            width: "100%",
                            height: "100%",
                            marginTop: "-20px",
                            borderRadius: "10px",
                            objectFit: "cover",
                            background: "#efefef", // Set initial background to white
                          }}
                        />
                      </div>
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        {/* {pageTitle} */}
                        {truncatedPageTitle}
                      </p>
                      {/* <div style={{height:"10px"}}></div> */}

                      {/* <div
                  style={{
                    marginTop: "-10px",
                    backgroundColor: "#ebebeb",
                    height: "22px",
                    borderRadius: "7px",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "1px", // Adjust spacing as needed
                      color: "#000", // Style as needed
                      fontSize: "14px", // Adjust font size as needed
                    }}
                  >
                    {truncatedUrl}
                  </p>
                </div> */}
                      <div
                        style={{
                          marginTop: "-10px",
                          backgroundColor: "#ebebeb",
                          height: "22px",
                          borderRadius: "7px",
                          width: "100%",
                          position: "relative",
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "1px",
                            color: "#000",
                            fontSize: "14px",
                          }}
                        >
                          {truncatedUrl}
                        </p>

                        <div
                          className={hoverClass}
                          style={{
                            position: "absolute",
                            top: "100%", // Below the URL
                            left: "50%",
                            transform: "translateX(-50%)",
                            backgroundColor: "white",
                            border: "1px solid #ccc",
                            padding: "5px",
                            borderRadius: "3px",
                            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                            zIndex: 10,
                            // whiteSpace: "nowrap",
                            whiteSpace: "normal", // Allows wrapping of long strings
                            maxWidth: "60ch",
                            overflowWrap: "break-word", // Ensures breaking of long strings
                          }}
                        >
                          {art.content.url}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {typeof art.content === "object" && (
                <>
                  {art.content.url.includes("pinterest.com/pin") ||
                  art.content.url.includes("pinterest.co.uk/pin") ||
                  art.content.url.includes("pin.it") ? (
                    <div
                      className="website-artefact no-select"
                      style={{
                        width: "350px", // Adjust the width if necessary
                        height: "auto", // Set to auto to allow the image to define height
                        backgroundColor: "#FDFCFB",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "10px",
                        cursor: "pointer",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                        padding: "20px",
                        minHeight: "320px",
                        userSelect: "none",
                      }}
                    >
                      <div
                        style={{
                          flex: 2,
                          backgroundColor: "white",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          borderRadius: "10px",
                          height: "auto",
                          width: "100%", // Ensure the image takes the full width
                          marginBottom: "-20px",
                        }}
                      >
                        <img
                          // src={thumbnailUrl}
                          // src={art.content?.ogImage || thumbnailUrl}
                          // src={art.content?.ogImage || thumbnailUrl}
                          src={
                            art.content?.ogImage ||
                            art.content?.images?.[0] ||
                            generatePastelImageUrl()
                          }
                          alt={`${art.artefact_name} Thumbnail`}
                          onError={(e) => {
                            e.target.onerror = null; // Prevents infinite loop in case of error
                            e.target.style.background = "white"; // Ensure background is white
                            e.target.src =
                              "data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"; // Transparent pixel
                          }}
                          style={{
                            width: "100%", // Full width of the container
                            height: "100%", // Full height of the container
                            borderRadius: "10px",
                            objectFit: "cover", // Ensure the image covers the container
                            background: "#efefef", // Set initial background to white
                          }}
                        />
                      </div>
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      ></p>
                      <div
                        style={{
                          marginTop: "-10px",
                          // backgroundColor: "#ebebeb",
                          height: "22px",
                          borderRadius: "7px",
                          width: "100%",
                          position: "relative",
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <img
                          src="/Pinterest-logo.png"
                          alt={`${art.artefact_name} Pinterest Logo`}
                          style={{
                            marginBottom: "750px",
                            marginLeft: "-67px",
                            width: "49px",
                            height: "26px",
                            position: "absolute",
                            top: "10px",
                            left: "200px",
                            borderRadius: "10px",
                            userSelect: "none",
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="website-artefact no-select"
                      style={{
                        width: "350px",
                        height: "320px",
                        backgroundColor: "#FDFCFB",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "10px",
                        cursor: "pointer",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                        padding: "20px",
                        minHeight: "320px",
                        userSelect: "none",
                        // marginTop: "0px",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <img
                          src={`https://www.google.com/s2/favicons?domain=${
                            new URL(art.content.url).hostname
                          }`}
                          alt={`${art.artefact_name} Favicon`}
                          style={{
                            marginBottom: "150px",
                            marginLeft: "-50px",
                            width: "23px",
                            height: "23px",
                            position: "absolute",
                            top: "16px",
                            left: "76px",
                            borderRadius: "10px",
                            userSelect: "none",
                          }}
                        />
                        <p
                          href={art.content.url}
                          style={{
                            color: "black",
                            fontSize: "25px",
                            padding: "19px 12px 0px 0px",
                            marginLeft: "-18px",
                            width: "20px",
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {/* {art.artefact_name} */}
                        </p>
                      </div>
                      <div
                        style={{
                          flex: 2,
                          backgroundColor: "white",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          borderRadius: "10px",
                          height: "auto",
                          width: "90%",
                          minHeight: "200px",
                          maxHeight: "80%",
                          marginBottom: "-20px",
                        }}
                      >
                        <img
                          // src={art.content?.ogImage || thumbnailUrl}
                          // src={art.content?.ogImage }
                          src={
                            art.content?.ogImage ||
                            art.content?.images?.[0] ||
                            generatePastelImageUrl()
                          }
                          // src={thumbnailUrl}
                          alt={`${art.artefact_name} Thumbnail`}
                          onError={(e) => {
                            e.target.onerror = null; // Prevents infinite loop in case of error
                            e.target.style.background = "white"; // Ensure background is white
                            e.target.src =
                              "data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"; // Transparent pixel
                          }}
                          style={{
                            width: "100%",
                            height: "100%",
                            marginTop: "-20px",
                            borderRadius: "10px",
                            objectFit: "cover",
                            background: "#efefef", // Set initial background to white
                          }}
                        />
                      </div>
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        {/* {pageTitle} */}
                        {truncatedPageTitle}
                      </p>
                      {/* <div style={{height:"10px"}}></div> */}

                      {/* <div
                    style={{
                      marginTop: "-10px",
                      backgroundColor: "#ebebeb",
                      height: "22px",
                      borderRadius: "7px",
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "1px", // Adjust spacing as needed
                        color: "#000", // Style as needed
                        fontSize: "14px", // Adjust font size as needed
                      }}
                    >
                      {truncatedUrl}
                    </p>
                  </div> */}
                      <div
                        style={{
                          marginTop: "-10px",
                          backgroundColor: "#ebebeb",
                          height: "22px",
                          borderRadius: "7px",
                          width: "100%",
                          position: "relative",
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "1px",
                            color: "#000",
                            fontSize: "14px",
                          }}
                        >
                          {truncatedUrl}
                        </p>

                        <div
                          className={hoverClass}
                          style={{
                            position: "absolute",
                            top: "100%", // Below the URL
                            left: "50%",
                            transform: "translateX(-50%)",
                            backgroundColor: "white",
                            border: "1px solid #ccc",
                            padding: "5px",
                            borderRadius: "3px",
                            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                            zIndex: 10,
                            // whiteSpace: "nowrap",
                            whiteSpace: "normal", // Allows wrapping of long strings
                            maxWidth: "60ch",
                            overflowWrap: "break-word", // Ensures breaking of long strings
                          }}
                        >
                          {art.content.url}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {art.content_type === "pdf" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#e4e4e4",
                userSelect: "none",
              }}
            >
              <embed
                src={art.content}
                type="application/pdf"
                width="200px"
                height="250px"
                style={{
                  borderRadius: "10px",
                  border: "none",
                  backgroundColor: "transparent",
                  overflow: "hidden !important;",
                  scroll: "no",
                }}
                scrollable="no"
                onError={handleContentLoadError}
              />

              <div
                style={{
                  position: "absolute", // Position it absolutely within the parent div
                  top: "22px", // Adjust top as needed to position the box vertically
                  left: "35px", // Adjust left as needed to position the box horizontally
                  width: "202px", // Set the width of the red box
                  height: "257px", // Set the height of the red box
                  backgroundColor: "transparent", // Red color with some transparency
                  border: "17px solid white", // Optional: Add a solid red border
                  borderRadius: "10px", // Optional: Add some rounding to the corners
                  zIndex: 2, // Ensure it appears on top of the embed element
                }}
              ></div>

              {/* Display PDF logo */}
              <div
                style={{ marginTop: "-66px", textAlign: "center", zIndex: 10 }}
              >
                <FaFilePdf
                  size={55}
                  color="#d9534f"
                  style={{ marginTop: "20px" }}
                />
                {/* Display file name */}
                <p
                  style={{
                    marginTop: "5px",
                    fontSize: "14px",
                    color: "#333",
                    marginBottom: "-10px",
                  }}
                >
                  {displayFileName}
                </p>
              </div>
            </div>
          )}

          {art.content_type === "text" && (
            <div
              className="no-select"
              style={{
                maxWidth: isEditing ? "350px" : "350px",
                width: isEditing ? "350px" : "auto",
                minHeight: isEditing ? "300px" : "auto",
                height: isEditing ? "auto" : "auto",
                maxHeight: isEditing ? "auto" : "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                borderRadius: "10px",
                cursor: "pointer",
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#FDFCFB",
                userSelect: "none",
                padding: "20px",
              }}
            >
              {isEditing ? (
                <>
                  <input
                    type="text"
                    onMouseDown={(e) => e.stopPropagation()}
                    onMouseUp={(e) => e.stopPropagation()}
                    value={newArtefactName}
                    onChange={(e) => setNewArtefactName(e.target.value)}
                    placeholder="Edit artefact name"
                    style={{
                      marginBottom: "10px",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      outline: "none",
                      width: "100%",
                    }}
                  />

                  <div
                    onMouseDown={(e) => e.stopPropagation()}
                    onMouseUp={(e) => e.stopPropagation()}
                    style={{
                      width: "100%",
                      minHeight: "200px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      overflow: "hidden",
                    }}
                  >
                    <ReactQuill
                      ref={quillRef}
                      value={newArtefactContent}
                      onChange={handleContentChange}
                      className="custom-quill-editor"
                      placeholder="Edit artefact content"
                      style={{
                        width: "100%",
                        height: "auto",
                        minHeight: "100px !important",
                        maxHeight: "600px",
                        // overflowY: "auto",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        outline: "none",
                        minHeight: "200px",
                      }}
                      modules={{
                        toolbar: [
                          [{ header: [5, false] }],
                          ["bold", "italic", "underline"],
                          [{ list: "ordered" }, { list: "bullet" }],
                        ],
                        clipboard: {
                          matchVisual: false, // Prevents Quill from adding paragraph spacing
                        },
                      }}
                      formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "list",
                        "bullet",
                      ]}
                    />
                  </div>

                  <button
                    onClick={() => {
                      editArtefactName(
                        art.artefact_id,
                        newArtefactName,
                        newArtefactContent
                      );
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    onMouseUp={(e) => e.stopPropagation()}
                    style={{
                      backgroundColor: "#b4b4b4",
                      color: "white",
                      padding: "5px 9px",
                      borderRadius: "5px",
                      border: "none",
                      marginTop: "10px",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                  >
                    Save
                  </button>
                </>
              ) : (
                <>
                  <h2
                    style={{
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                      wordBreak: "break-word",
                    }}
                  >
                    {art.artefact_name}
                  </h2>
                  <div
                    style={{
                      overflowWrap: "break-word",
                      wordBreak: "break-word",
                    }}
                    dangerouslySetInnerHTML={{ __html: newArtefactContent }}
                  />
                </>
              )}
            </div>
          )}

          {art.content_type === "image" && (
            <Image
              onLoad={(e) => {
                const width = e.target.offsetWidth;
                const height = e.target.offsetHeight;
                console.log("rah3", width, height);
                setImageDimensions({ width, height });
              }}
              style={styleArtef}
              src={art.content}
              onError={handleContentLoadError}
            />
          )}
          <div>
            {art.content_type !== "text" && (
              <div
                // className={`text-box ${showGradient ? "" : "hide-gradient"}`}
                style={{
                  position: "relative",

                  // maxWidth: `${imageDimensions.width}px`, // Set maxWidth to the width of the image
                  // '--gradient-width': art.is_public ? '30px' : '80px',
                  // maxWidth:
                  //   art.content_type === "pdf"
                  //     ? "205px"
                  //     : `${imageDimensions.width}px`, // Conditional maxWidth
                  // "--gradient-width": art.is_public ? "30px" : "80px",
                  maxWidth:
                    art.content_type === "pdf"
                      ? "205px"
                      : art.content_type === "website"
                      ? "350px"
                      : `${imageDimensions.width}px`, // Conditional maxWidth
                  "--gradient-width": art.is_public ? "30px" : "80px",
                }}
              >
                <div style={{ position: "fixed", overflow: "hidden" }}>
                  <div
                    className="fading-text-explore-artefact"
                    style={{
                      userSelect: "none",
                      minHeight: "20px",
                      minWidth: "100px",
                      // whiteSpace: "nowrap",
                      whiteSpace: isTextExpanded ? "normal" : "nowrap",
                      fontSize: artefactNameFontSize,
                      transition: "font-size 0.3s ease-in-out",
                      // overflow: "hidden",
                      wordBreak: "break-word",
                      overflow: isTextExpanded ? "visible" : "hidden",
                      // textOverflow: "ellipsis",
                      textOverflow: isTextExpanded ? "clip" : "ellipsis",
                      background: isTextExpanded
                        ? "#000"
                        : `linear-gradient(to right, black ${Math.max(
                            (((imageDimensions.width || 350) -
                              (art.ownerUsername.length * 8 + 20) -
                              50) /
                              (imageDimensions.width || 350)) *
                              100,
                            0
                          )}%, rgba(0, 0, 0, 0) ${
                            (((imageDimensions.width || 350) -
                              (art.ownerUsername.length * 8 + 50) +
                              25) /
                              (imageDimensions.width || 350)) *
                            100
                          }%)`,
                      WebkitBackgroundClip: "text", // Make the gradient visible only on the text
                      color: "transparent", // Make the text itself transparent
                      padding: "0px 5px 0px 5px",
                      paddingRight: `${art.ownerUsername.length * 8 + 0}px`, // Adjust padding to match username length
                      // transform: art.content.includes("youtube")
                      //   ? "translateX(12px)"
                      //   : "none",
                      // transform: art.content.includes("youtube")
                      //   ? "translateX(12px)"
                      //   : "none",
                      marginLeft: art.content?.url?.includes("youtube")
                        ? "0px"
                        : "none",

                      // width: art.content.includes("youtube")
                      //   ? "calc(100% - 26px)"
                      //   : "100%",
                      width:
                        typeof art.content === "string" &&
                        art.content?.includes("youtube")
                          ? "329px"
                          : art.content_type === "website" // Set width based on content type
                          ? "350px" // Set width to 350px for website
                          : art.content_type === "pdf"
                          ? "220px" // Set width to 240px for PDFs
                          : // Adjust width if content is a YouTube link
                          imageDimensions.width
                          ? `${imageDimensions.width}px` // Use imageDimensions.width if defined
                          : "100%",
                      position: "relative",
                      // opacity: isTextExpanded ? 1 : 0,
                      transition: isTextExpanded
                        ? "opacity 0.8s ease-in, color 0.8s ease-in"
                        : "opacity 0.4s ease-out, color 0.4s ease-out",
                    }}
                    onMouseEnter={handleNameMouseEnter}
                    onMouseLeave={handleNameMouseLeave}
                  >
                    {art.artefact_name}
                    {art.artefact_name !== "" && (
                      <div
                        // className="artefact-name-background no-select"
                        className={`artefact-name-background ${
                          isTextExpanded ? "show" : ""
                        } no-select`}
                        style={{
                          position: "absolute",
                          marginTop: "-20px",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          // backgroundColor: "rgba(0, 0, 255, 0.2)", // Blue background color with transparency
                          backgroundColor: "#f0f0f0", // Blue background color with transparency

                          zIndex: -100, // Make sure it is behind the text
                          borderRadius: "10px", // Rounded corners
                          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
                          // opacity: isTextExpanded ? 1 : 0,
                          // transition: isTextExpanded
                          //   ? "opacity 0.4s ease-in"
                          //   : "opacity 0.4s ease-out",
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="artefact-footer"
            // style={{ position: "absolute", bottom: "8px", right: "19px" }}
            style={{
              position: "absolute",
              // bottom: isTextExpanded ? "40px" : "8px",

              bottom:
                art.content_type === "text"
                  ? "9px"
                  : isTextExpanded
                  ? "6px"
                  : lockPositionBottom,
              ...(imageDimensions && imageDimensions.width
                ? { right: "12px" } // If imageDimensions exist, use 'left'
                : { right: "19px" }), // Otherwise, fall back to 'right'
              transition: "bottom 0.3s ease, transform 0.3s ease",

              // transform: art.content.includes("youtube")
              //   ? "translateX(5px)"
              //   : "none",
              transform:
                typeof art.content === "string" &&
                art.content?.includes("youtube")
                  ? "translateX(7px)"
                  : "none",
            }}
          >
            <a>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`/user/${art.ownerUsername}`);
                }}
                onMouseDown={(e) => e.stopPropagation()} // Stop event propagation on mouse down
                onMouseUp={(e) => e.stopPropagation()} // Stop event propagation on mouse up
                style={{
                  fontSize: artefactNameFontSize,
                  zIndex: 4,
                  cursor: "pointer",
                  padding: "2px 7px 2px 2px",
                  backgroundColor: isTextExpanded
                    ? "rgba(255, 255, 255, 0.85)"
                    : "transparent", // Background changes to white when expanded
                  transition: "background-color 0.3s ease",
                  borderRadius: "5px",
                  // boxShadow: isTextExpanded
                  //   ? "0 0 2px 2px white"
                  //   : "none",
                  // boxShadow: isTextExpanded
                  //   ? "0 0 3px 3px rgba(255, 255, 255, 0.8)" // Soft fade around the edges when expanded
                  //   : "none", // No shadow when not expanded
                }}
              >
                <span
                  style={{
                    position: "relative",
                    top: "-1.5px", // Move @ symbol up by 5 pixels
                  }}
                >
                  @
                </span>
                {art.ownerUsername}
              </span>
            </a>
          </div>
        </ListGroup.Item>
      </CSSTransition>
    </>
  );
}

export default Artefact;
