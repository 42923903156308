import React, { useState, useEffect } from "react";
import axios from "axios";
import full_logo from "../full_logo.png"; // Adjust the path as needed
import "../css/Analytics.css"; // Assuming you have a separate CSS file for Analytics

const Analytics = () => {
  // State variables to hold the data
  const [totalUsers, setTotalUsers] = useState(null);
  const [totalCollections, setTotalCollections] = useState(null);
  const [totalArtefacts, setTotalArtefacts] = useState(null);

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch total users
        const usersResponse = await axios.get("/api/analytics/total_users");
        // Fetch total collections
        const collectionsResponse = await axios.get(
          "/api/analytics/total_collections"
        );
        // Fetch total artefacts
        const artefactsResponse = await axios.get(
          "/api/analytics/total_artefacts"
        );

        // Update state with the fetched data
        setTotalUsers(usersResponse.data.totalUsers);
        setTotalCollections(collectionsResponse.data.totalCollections);
        setTotalArtefacts(artefactsResponse.data.totalArtefacts);
        setLoading(false); // Data fetched, stop loading
      } catch (err) {
        console.error("Failed to fetch analytics data:", err);
        setError("Failed to load analytics data.");
        setLoading(false); // Stop loading on error
      }
    };

    fetchData(); // Invoke the data fetching function
  }, []);

  useEffect(() => {
    const preventDefaultZoomActions = (event) => {
      if (
        (event.ctrlKey &&
          (event.key === "+" || event.key === "-" || event.key === "=")) ||
        (event.ctrlKey && event.deltaY)
      ) {
        event.preventDefault(); // Prevent Ctrl + '+' and Ctrl + '-' for zooming
      }
    };

    const preventTouchZoom = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault(); // Prevent pinch-to-zoom
      }
    };

    window.addEventListener("keydown", preventDefaultZoomActions);
    window.addEventListener("wheel", preventDefaultZoomActions, {
      passive: false,
    });
    window.addEventListener("touchmove", preventTouchZoom, { passive: false });

    return () => {
      window.removeEventListener("keydown", preventDefaultZoomActions);
      window.removeEventListener("wheel", preventDefaultZoomActions);
      window.removeEventListener("touchmove", preventTouchZoom);
    };
  }, []);

  // Render loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Render error state
  if (error) {
    return <div>{error}</div>;
  }

  // Render the analytics data
  return (
    <div className="analytics-container">
      <div className="analytics-logo-container">
        <img
          src={full_logo}
          style={{ width: "400px", height: "auto" }}
          alt="Logo Showcase"
          className="analytics-logo-showcase"
        />
      </div>
      <div className="analytics-content">
        <h1>Analytics Dashboard</h1>
        <div style={{ lineHeight: "1.5", marginTop: "10px", padding: "10px" }}>
          <h2>
            Total Users:{" "}
            <span className="analytics-span" style={{ fontWeight: 500 }}>
              {totalUsers !== null ? totalUsers : "Data not available"}
            </span>
          </h2>
        </div>
        <div style={{ lineHeight: "1.5", marginTop: "10px", padding: "10px" }}>
          <h2>
            Total Collections:{" "}
            <span className="analytics-span" style={{ fontWeight: 500 }}>
              {totalCollections !== null
                ? totalCollections
                : "Data not available"}
            </span>
          </h2>
        </div>
        <div style={{ lineHeight: "1.5", marginTop: "10px", padding: "10px" }}>
          <h2>
            Total Artefacts:{" "}
            <span className="analytics-span" style={{ fontWeight: 500 }}>
              {totalArtefacts !== null ? totalArtefacts : "Data not available"}
            </span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
