// import React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import PannableBox from "../components/PannableBox";
// import NavBar_2 from "../components/NavBar_2";

// const OtherUserPageCollection = (props) => {
//   // const { id, urlUsername } = useParams();
//   const { urlUsername, collectionId } = useParams();
//   const id = collectionId;

//   const [userFullName, setUserFullName] = useState(
//     props.loggedInFullname || ""
//   );

//   const navigate = useNavigate();

//   const [collections, setCollections] = useState([]);
//   const [thisCollection, setThisCollection] = useState(null);
//   const [artefacts, setArtefacts] = useState([]);

//   const [droppedImageFile, setDroppedImageFile] = useState(null);

//   const [artefactData, setArtefactData] = useState({
//     artefactName: "",
//     imageURL: "",
//     type: "",
//     // websiteURL: "",
//   });

//   const [zoomedInFlag, setZoomedInFlag] = useState(false);

//   console.log("Golf ball vvvf", id, urlUsername, props.loggedInFullname);
//   const params = useParams();
//   console.log("params", params);

//   const fetchArtefactContent = async (artefact) => {
//     try {
//       const response = await fetch(
//         `/api/user/${urlUsername}/artefact/${artefact.artefact_id}/content`
//       );

//       if (
//         artefact.content_type === "image" ||
//         artefact.content_type === "pdf"
//       ) {
//         const blob = await response.blob();
//         const contentUrl = URL.createObjectURL(blob);
//         return { ...artefact, content: contentUrl };
//       } else if (artefact.content_type === "website") {
//         const data = await response.json();
//         console.log("this is data: ", data);

//         // Check for the error field in the data
//         if (data.error) {
//           console.log(`Error fetching website artefact: ${data.error}`);
//           console.log(`Error fetching website artefact: ${data.url}`);

//           return {
//             ...artefact,
//             content: {
//               title: "", // Empty title
//               ogImage: "", // Empty ogImage
//               images: [], // Empty images array
//               favicon: "", // Empty favicon
//               url: data.url, // Only set the URL
//             },
//           };
//         }

//         // Handle the normal case where there's no error
//         if (data.hasOwnProperty("data") && typeof data.data === "string") {
//           return {
//             ...artefact,
//             content: data.data, // Set the URL from the 'data' field
//           };
//         } else {
//           // Handle rich website metadata
//           return {
//             ...artefact,
//             content: {
//               title: data.title || "", // Handle missing fields gracefully
//               ogImage: data.ogImage || "",
//               images: data.images || [],
//               favicon: data.favicon || "",
//               url: data.url || "", // Set the URL as well
//             },
//           };
//         }
//       } else if (artefact.content_type === "text") {
//         const data = await response.json();
//         return { ...artefact, content: data.data };
//       } else {
//         return artefact;
//       }
//     } catch (error) {
//       console.error("Error fetching artefact content:", error);
//       return artefact; // Return artefact as is in case of failure
//     }
//   };

//   const fetchUserDetails = async () => {
//     try {
//       const response = await fetch(`/api/user/${urlUsername}/details`, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       if (!response.ok) {
//         throw new Error("Failed to fetch user details.");
//       }

//       const data = await response.json();
//       console.log("Fetched user details:", data);
//       setUserFullName(data.full_name); // Set the user's full name
//       props.setLoggedInFullname(data.full_name); // Optionally update parent component's state
//     } catch (error) {
//       console.error("Error fetching user details:", error);
//     }
//   };

//   useEffect(() => {
//     if (props.loggedIn === false) {
//       navigate("/");
//     } else {
//       fetch(`/api/user/${urlUsername}/collection/${id}`, {
//         method: "GET",
//         credentials: "same-origin",
//       })
//         .then((response) => response.json())
//         .then(async (j) => {
//           setThisCollection({
//             type: j.type,
//             collection_id: id,
//             collection_name: j.collection_name,
//             parent_id: j.parent_id,
//             public_count: j.public_count,
//           });
//           if (j.type === "collections") {
//             setCollections(j.data);
//             setArtefacts([]);
//           } else if (j.type === "artefacts") {
//             // Clear previous artefacts list before starting the download
//             //   setArtefacts([]);

//             //   // Fetch and set artefacts one by one
//             //   j.data.forEach(async (artefact) => {
//             //     const artefactWithContent = await fetchArtefactContent(artefact);
//             //     setArtefacts((prevArtefacts) => [
//             //       ...prevArtefacts,
//             //       artefactWithContent,
//             //     ]);
//             //   });

//             //   setCollections([]);
//             // }
//             setArtefacts([]);

//             // Fetch and set artefacts one by one
//             j.data.forEach(async (artefact) => {
//               const artefactWithContent = await fetchArtefactContent(artefact);
//               setArtefacts((prevArtefacts) => {
//                 if (
//                   !prevArtefacts.find(
//                     (a) => a.artefact_id === artefactWithContent.artefact_id
//                   )
//                 ) {
//                   return [...prevArtefacts, artefactWithContent];
//                 }
//                 return prevArtefacts;
//               });
//             });

//             setCollections([]);
//           } else {
//             setCollections([]);
//             setArtefacts([]);
//           }
//         })
//         .catch((error) => {
//           console.log("Fetch error: ", error);
//         });
//       fetchUserDetails();
//     }
//   }, [props.loggedIn, id, urlUsername]);

//   return (
//     <div className="collection-contents">
//       <NavBar_2
//         currentCollection={props.currentCollection}
//         setLoggedIn={props.setLoggedIn}
//         setLoggedInUsername={props.setLoggedInUsername}
//         handleCreateButtonClick={props.handleCreateButtonClick}
//         collectionData={null}
//         setCollectionData={null}
//         artefactData={null}
//         setArtefactData={null}
//         placeArtefactsTrigger={props.placeArtefactsTrigger}
//         setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
//         isScreenCentered={props.isScreenCentered}
//         centerScreenHandler={props.centerScreenHandler}
//         og_username={props.og_username}
//         setOg_username={props.setOg_username}
//         loggedInFullname={props.loggedInFullname}
//         setLoggedInFullname={props.setLoggedInFullname}
//         // setDroppedImageFile={setDroppedImageFile}
//       />

//       <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
//         <PannableBox
//           collections={collections}
//           placingMode={props.placingMode}
//           setPlacingMode={props.setPlacingMode}
//           getter={props.submittedForm}
//           setter={props.setSubmittedForm}
//           currentCollection={thisCollection}
//           // currentCollection={props.currentCollection}
//           // collectionData={null}
//           collectionData={{ collectionName: "", collectionId: "" }}
//           // artefactData={null}
//           setArtefactData={setArtefactData}
//           artefactData={
//             artefactData || {
//               artefactName: "",
//               imageURL: "",
//               type: "",
//               websiteURL: "",
//             }
//           }
//           // artefactData={{ artefactName: "", imageURL: "", type: "" }}
//           //   artefacts={filteredArtefacts}
//           artefacts={artefacts}
//           setArtefacts={setArtefacts}
//           setCollections={setCollections}
//           placeArtefactsTrigger={props.placeArtefactsTrigger}
//           setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
//           centerScreen={props.centerScreen}
//           setCenterScreen={props.setCenterScreen}
//           setIsScreenCentered={props.setIsScreenCentered}
//           loggedInUsername={urlUsername}
//           //   isPublicView={isPublicView}
//           isPublicView={null}
//           otherUser={urlUsername}
//           zoomedInFlag={zoomedInFlag}
//           setZoomedInFlag={setZoomedInFlag}
//           isOwner={props.loggedInUsername === urlUsername}
//           setDroppedImageFile={setDroppedImageFile}
//           og_username={props.og_username}
//           // setOg_username={props.setOg_username}
//           // loggedInFullname={props.loggedInFullname}
//           // setLoggedInFullname={props.setLoggedInFullname}
//           loggedInFullname={userFullName}
//           // loggedInFullname={null}
//           setLoggedInFullname={props.setLoggedInFullname}
//           // setLoggedInFullname={null}
//           sharelinkFullname={null}
//           setSharelinkFullname={null}
//         />
//       </div>
//     </div>
//   );
// };

// export default OtherUserPageCollection;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PannableBox from "../components/PannableBox";
import NavBar_2 from "../components/NavBar_2";

const OtherUserPageCollection = (props) => {
  const { urlUsername, collectionId } = useParams();
  const id = collectionId;

  const [userFullName, setUserFullName] = useState(
    props.loggedInFullname || ""
  );

  const navigate = useNavigate();

  const [collections, setCollections] = useState([]);
  const [thisCollection, setThisCollection] = useState(null);
  const [artefacts, setArtefacts] = useState([]);

  const [droppedImageFile, setDroppedImageFile] = useState(null);

  const [artefactData, setArtefactData] = useState({
    artefactName: "",
    imageURL: "",
    type: "",
  });

  const [zoomedInFlag, setZoomedInFlag] = useState(false);

  const [isLowConnection, setIsLowConnection] = useState(false);

  function ConnectionIndicator() {
    return (
      <div
        className="low-connection"
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          color: "orange",
          marginLeft: "15px",
          marginTop: "15px",
          zIndex: "1000",
        }}
      >
        <div
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            backgroundColor: "orange",
            marginRight: "3px",
            marginTop: "-1px",
          }}
        ></div>
        <span>Low connection</span>
      </div>
    );
  }

  const fetchUserDetails = async (retries = 120, delay = 1000) => {
    let attempts = 0;
    let hasShownLowConnection = false;

    const lowConnectionTimer = setTimeout(() => {
      setIsLowConnection(true);
      hasShownLowConnection = true;
    }, 5000);

    while (attempts < retries) {
      try {
        const response = await fetch(`/api/user/${urlUsername}/details`, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          if (response.status === 404) {
            console.error("User details not found, navigating back.");
            navigate("/");
            clearTimeout(lowConnectionTimer);
            return;
          }
          throw new Error("Failed to fetch user details.");
        }

        const data = await response.json();
        console.log("Fetched user details:", data);
        setUserFullName(data.full_name);
        props.setLoggedInFullname(data.full_name);

        clearTimeout(lowConnectionTimer);
        setIsLowConnection(false);
        return;
      } catch (error) {
        attempts++;
        console.error(
          `Error fetching user details, attempt ${attempts}:`,
          error
        );

        if (attempts === 1 && !hasShownLowConnection) {
          setIsLowConnection(true);
          hasShownLowConnection = true;
        }

        if (attempts >= retries) {
          console.error("Max retries reached. Skipping.");
          clearTimeout(lowConnectionTimer);
          return;
        }

        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  const fetchArtefactContent = async (
    artefact,
    retries = 120,
    delay = 1000
  ) => {
    let attempts = 0;
    let hasShownLowConnection = false;

    const lowConnectionTimer = setTimeout(() => {
      setIsLowConnection(true);
      hasShownLowConnection = true;
    }, 5000);

    while (attempts < retries) {
      try {
        const response = await fetch(
          `/api/user/${urlUsername}/artefact/${artefact.artefact_id}/content`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch artefact content.");
        }

        if (
          artefact.content_type === "image" ||
          artefact.content_type === "pdf"
        ) {
          const blob = await response.blob();
          const contentUrl = URL.createObjectURL(blob);

          clearTimeout(lowConnectionTimer);
          setIsLowConnection(false);

          return { ...artefact, content: contentUrl };
        } else if (artefact.content_type === "website") {
          const data = await response.json();
          console.log("this is data: ", data);

          if (data.error) {
            console.log(`Error fetching website artefact: ${data.error}`);
            console.log(`Error fetching website artefact: ${data.url}`);

            clearTimeout(lowConnectionTimer);
            setIsLowConnection(false);

            return {
              ...artefact,
              content: {
                title: "",
                ogImage: "",
                images: [],
                favicon: "",
                url: data.url,
              },
            };
          }

          if (data.hasOwnProperty("data") && typeof data.data === "string") {
            clearTimeout(lowConnectionTimer);
            setIsLowConnection(false);

            return {
              ...artefact,
              content: data.data,
            };
          } else {
            clearTimeout(lowConnectionTimer);
            setIsLowConnection(false);

            return {
              ...artefact,
              content: {
                title: data.title || "",
                ogImage: data.ogImage || "",
                images: data.images || [],
                favicon: data.favicon || "",
                url: data.url || "",
              },
            };
          }
        } else if (artefact.content_type === "text") {
          const data = await response.json();

          clearTimeout(lowConnectionTimer);
          setIsLowConnection(false);

          return { ...artefact, content: data.data };
        } else {
          clearTimeout(lowConnectionTimer);
          setIsLowConnection(false);

          return artefact;
        }
      } catch (error) {
        attempts++;
        console.error(
          `Error fetching artefact content, attempt ${attempts}:`,
          error
        );

        if (attempts === 1 && !hasShownLowConnection) {
          setIsLowConnection(true);
          hasShownLowConnection = true;
        }

        if (attempts >= retries) {
          console.error("Max retries reached. Skipping this artefact.");
          clearTimeout(lowConnectionTimer);
          return artefact;
        }

        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  const fetchCollectionData = async (retries = 120, delay = 1000) => {
    let attempts = 0;
    let hasShownLowConnection = false;

    const lowConnectionTimer = setTimeout(() => {
      setIsLowConnection(true);
      hasShownLowConnection = true;
    }, 5000);

    while (attempts < retries) {
      try {
        const response = await fetch(
          `/api/user/${urlUsername}/collection/${id}`,
          {
            method: "GET",
            credentials: "same-origin",
          }
        );

        if (!response.ok) {
          if (response.status === 404) {
            console.error("Collection not found, navigating back.");
            navigate("/");
            clearTimeout(lowConnectionTimer);
            return;
          }
          throw new Error("Network response was not ok");
        }

        const j = await response.json();

        setThisCollection({
          type: j.type,
          collection_id: id,
          collection_name: j.collection_name,
          parent_id: j.parent_id,
          public_count: j.public_count,
        });

        if (j.type === "collections") {
          setCollections(j.data);
          setArtefacts([]);
        } else if (j.type === "artefacts") {
          setArtefacts([]);

          const fetchPromises = j.data.map((artefact) =>
            fetchArtefactContent(artefact).then((artefactWithContent) => {
              setArtefacts((prevArtefacts) => {
                if (
                  !prevArtefacts.find(
                    (a) => a.artefact_id === artefactWithContent.artefact_id
                  )
                ) {
                  return [...prevArtefacts, artefactWithContent];
                }
                return prevArtefacts;
              });
            })
          );

          await Promise.all(fetchPromises);
          setCollections([]);
        } else {
          setCollections([]);
          setArtefacts([]);
        }

        clearTimeout(lowConnectionTimer);
        setIsLowConnection(false);
        return;
      } catch (error) {
        attempts++;
        console.error(
          `Error fetching collection data, attempt ${attempts}:`,
          error
        );

        if (attempts === 1 && !hasShownLowConnection) {
          setIsLowConnection(true);
          hasShownLowConnection = true;
        }

        if (attempts >= retries) {
          console.error("Max retries reached. Skipping.");
          clearTimeout(lowConnectionTimer);
          return;
        }

        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  useEffect(() => {
    if (props.loggedIn === false) {
      navigate("/");
    } else {
      fetchUserDetails();
      fetchCollectionData();
    }
  }, [props.loggedIn, id, urlUsername]);

  return (
    <div className="collection-contents">
      {isLowConnection && <ConnectionIndicator />}
      <NavBar_2
        currentCollection={props.currentCollection}
        setLoggedIn={props.setLoggedIn}
        setLoggedInUsername={props.setLoggedInUsername}
        handleCreateButtonClick={props.handleCreateButtonClick}
        collectionData={null}
        setCollectionData={null}
        artefactData={null}
        setArtefactData={null}
        placeArtefactsTrigger={props.placeArtefactsTrigger}
        setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
        isScreenCentered={props.isScreenCentered}
        centerScreenHandler={props.centerScreenHandler}
        og_username={props.og_username}
        setOg_username={props.setOg_username}
        loggedInFullname={props.loggedInFullname}
        setLoggedInFullname={props.setLoggedInFullname}
      />

      <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
        <PannableBox
          collections={collections}
          placingMode={props.placingMode}
          setPlacingMode={props.setPlacingMode}
          getter={props.submittedForm}
          setter={props.setSubmittedForm}
          currentCollection={thisCollection}
          collectionData={{ collectionName: "", collectionId: "" }}
          setArtefactData={setArtefactData}
          artefactData={
            artefactData || {
              artefactName: "",
              imageURL: "",
              type: "",
              websiteURL: "",
            }
          }
          artefacts={artefacts}
          setArtefacts={setArtefacts}
          setCollections={setCollections}
          placeArtefactsTrigger={props.placeArtefactsTrigger}
          setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
          centerScreen={props.centerScreen}
          setCenterScreen={props.setCenterScreen}
          setIsScreenCentered={props.setIsScreenCentered}
          loggedInUsername={urlUsername}
          isPublicView={null}
          otherUser={urlUsername}
          zoomedInFlag={zoomedInFlag}
          setZoomedInFlag={setZoomedInFlag}
          isOwner={props.loggedInUsername === urlUsername}
          setDroppedImageFile={setDroppedImageFile}
          og_username={props.og_username}
          loggedInFullname={userFullName}
          setLoggedInFullname={props.setLoggedInFullname}
          sharelinkFullname={null}
          setSharelinkFullname={null}
        />
      </div>
    </div>
  );
};

export default OtherUserPageCollection;
