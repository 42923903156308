export const moveCollectionBothCoords = async ({
  loggedInUsername,
  collectionId,
  coordx_private,
  coordy_private,
  coordx_public,
  coordy_public,
  isPublicView,
}) => {
  const response = await fetch(
    `/api/user/${loggedInUsername}/collection/${collectionId}/move_both_coords`,
    {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({
        coordx_private,
        coordy_private,
        coordx_public,
        coordy_public,
        isPublicView: isPublicView,
      }),
    }
  );

  return response.status;
};
