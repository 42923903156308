// ContextMenu.jsx
import React, { useState, useEffect } from "react";
import { toggleArtefactPublicity } from "../../api/toggleArtefactPublicity";
import { moveArtefact } from "../../api/moveArtefact";
import { changeArtefactSizing } from "../../api/changeArtefactSizing";
import "../../css/ContextMenu.css";

function ContextMenu(props) {
  const [isPublic, setIsPublic] = useState(props.publicityState);

  useEffect(() => {
    setIsPublic(props.publicityState);
  }, [props.publicityState]);

  const moveAnArtefact = async () => {
    const artefactId = props.contextMenuStateArtefactId;
    const sizingChoice = props.contextMenuStateSizingChoice;

    props.onMoveArtefact(artefactId);

    props.hideContextMenu();
  };

  const togglePrivacy = async () => {
    const newPublicityStatus = !isPublic;
    setIsPublic(newPublicityStatus);

    const currentArtefact = props.artefacts.find(
      (artefact) => artefact.artefact_id === props.contextMenuStateArtefactId
    );

    if (currentArtefact) {
      let newCoords = {};
      if (newPublicityStatus) {
        newCoords = {
          coordx_public: currentArtefact.coordx_private,
          coordy_public: currentArtefact.coordy_private,
        };
      } else {
        newCoords = { coordx_public: null, coordy_public: null };
      }

      const success = await toggleArtefactPublicity(
        props.loggedInUsername,
        props.contextMenuStateArtefactId,
        newPublicityStatus,
        newCoords.coordx_public,
        newCoords.coordy_public
      );

      if (success) {
        props.updateArtefactPublicity(
          props.contextMenuStateArtefactId,
          newPublicityStatus,
          {
            coordx_public: newCoords.coordx_public,
            coordy_public: newCoords.coordy_public,
          }
        );
      } else {
        setIsPublic(!newPublicityStatus);
      }
    } else {
      console.log(
        "Artefact with ID " + props.contextMenuStateArtefactId + " not found."
      );
    }
  };

  const ContextMenuStyle = {
    position: "absolute",
    top: `${props.y + 50}px`,
    left: `${props.x + 0}px`,
    display: props.isVisible ? "flex" : "none",
    flexDirection: "column",
    borderRadius: "5px",
    zIndex: "11",
  };

  // if (props.isPublicView) {
  //   return null;
  // }

  console.log("huhu", props.contextMenuState);

  const [selectedSize, setSelectedSize] = useState(
    props.contextMenuStateSizingChoice
  );

  useEffect(() => {
    console.log("uwu1 ContextMenu props:", props);
    setSelectedSize(props.contextMenuStateSizingChoice || "m");
    console.log(
      "uwu2 Selected size set to:",
      props.contextMenuStateSizingChoice
    );
  }, [props.contextMenuStateSizingChoice]);

  const onSizeChange = (newSize) => {
    const artefactId = props.contextMenuStateArtefactId;
    changeArtefactSizing(props.loggedInUsername, artefactId, newSize)
      .then(() => {
        console.log("Size updated successfully");
        setSelectedSize(newSize);
        props.updateArtefactSize(artefactId, newSize); // Update the parent component's state
      })
      .catch((error) => {
        console.error("Failed to update artefact size", error);
      });
  };

  return (
    <div className="context-menu-wrapper  no-select" style={ContextMenuStyle}>
      <div className="context-menu-box  no-select">
        <button
          className="full-width-button"
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
          }}
          onClick={() => {
            togglePrivacy();
            // props.hideContextMenu(); // Think it's better to show the menu after toggling privacy
          }}
        >
          {isPublic ? "Private" : "Public"}
        </button>
        <button
          className="full-width-button"
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            cursor: props.isFetching ? "default" : "pointer", // Step 5: Update styles based on isFetching
            opacity: props.isFetching ? 0.5 : 1,
          }}
          disabled={props.isFetching}
          onClick={() => {
            if (!props.isFetching) {
              moveAnArtefact();
              props.hideContextMenu();
            }
          }}
        >
          Move
        </button>
        <button
          className="full-width-button"
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
          }}
          onClick={() => {
            props.onEditArtefact(props.contextMenuStateArtefactId); // Use the passed edit handler
            props.hideContextMenu();
          }}
        >
          Edit
        </button>
        {/* Radio buttons for S, M, L sizes */}
        <div
          className="size-options"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            padding: "10px",
          }}
        >
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              name="size"
              value="S"
              // defaultChecked
              checked={selectedSize === "s"}
              onChange={() => onSizeChange("s")}
              style={{ marginRight: "5px" }}
            />
            S
          </label>
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              name="size"
              value="M"
              checked={selectedSize === "m"}
              onChange={() => onSizeChange("m")}
              style={{ marginRight: "5px" }}
            />
            M
          </label>
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              name="size"
              value="L"
              checked={selectedSize === "l"}
              onChange={() => onSizeChange("l")}
              style={{ marginRight: "5px" }}
            />
            L
          </label>
        </div>
        <button
          className="full-width-button"
          style={{
            backgroundColor: "#FA4040",
            color: "white",
            borderRadius: "5px",
            width: "100%",
            padding: "7px",
            zIndex: "10",
          }}
          onClick={() => {
            props.handleDeleteArtefact(props.contextMenuStateArtefactId);
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
}

export default ContextMenu;
