// import React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router";
// import OtherUserPage from "../pages/OtherUserPage";
// import OtherUserPageCollection from "../pages/OtherUserPageCollection"; // import the new component
// import MyPage from "../pages/MyPage";
// import Collection from "../pages/Collection";

// function CheckIsPageOwn(props) {
//   // const { username, collectionId } = useParams();
//   const { urlUsername: username, collectionId } = useParams(); // Match route parameter names

//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   console.log("Yes sir");
//   console.log("props.loggedIn is", props.loggedIn);
//   console.log("username is llkk", username);
//   console.log("props.loggedInUsername is", props.loggedInUsername);
//   console.log("loggedInFullname is hhhh", props.loggedInFullname);
//   console.log("collectionId is", collectionId);

//   if (props.loggedIn && username === props.loggedInUsername) {
//     if (collectionId) {
//       console.log("lobstermobster1 is rendered");
//       return (
//         // <Collection
//         //   loggedIn={props.loggedIn}
//         //   setLoggedIn={props.setLoggedIn}
//         //   loggedInUsername={props.loggedInUsername}
//         //   setLoggedInUsername={props.setLoggedInUsername}
//         //   submittedForm={props.submittedForm}
//         //   setSubmittedForm={props.setSubmittedForm}
//         //   placingMode={props.placingMode}
//         //   setPlacingMode={props.setPlacingMode}
//         //   currentCollection={props.currentCollection}
//         //   handleCreateButtonClick={props.handleCreateButtonClick}
//         //   placeArtefactsTrigger={props.placeArtefactsTrigger}
//         //   setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
//         //   centerScreen={props.centerScreen}
//         //   setCenterScreen={props.setCenterScreen}
//         //   isScreenCentered={props.isScreenCentered}
//         //   setIsScreenCentered={props.setIsScreenCentered}
//         //   centerScreenHandler={props.centerScreenHandler}
//         //   setOg_username={props.setOg_username}
//         //   loggedInFullname={props.loggedInFullname}
//         //   setLoggedInFullname={props.setLoggedInFullname}
//         // />
//         <Collection
//           loggedIn={props.loggedIn}
//           setLoggedIn={props.setLoggedIn}
//           loggedInUsername={props.loggedInUsername}
//           setLoggedInUsername={props.setLoggedInUsername}
//           submittedForm={props.submittedForm}
//           setSubmittedForm={props.setSubmittedForm}
//           placingMode={props.placingMode}
//           setPlacingMode={props.setPlacingMode}
//           currentCollection={props.currentCollection}
//           handleCreateButtonClick={props.handleCreateButtonClick}
//           placeArtefactsTrigger={props.placeArtefactsTrigger}
//           setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
//           centerScreen={props.centerScreen}
//           setCenterScreen={props.setCenterScreen}
//           isScreenCentered={props.isScreenCentered}
//           setIsScreenCentered={props.setIsScreenCentered}
//           centerScreenHandler={props.centerScreenHandler}
//           collectionData={props.collectionData}
//           setCollectionData={props.setCollectionData}
//           artefactData={props.artefactData}
//           setArtefactData={props.setArtefactData}
//           filter={props.filter}
//           setFilter={props.setFilter}
//           isPublicView={props.isPublicView}
//           setIsPublicView={props.setIsPublicView}
//           og_username={props.og_username}
//           setOg_username={props.setOg_username}
//           sharelinkUsername={props.sharelinkUsername}
//           setSharelinkUsername={props.setSharelinkUsername}
//           loggedInFullname={props.loggedInFullname}
//           setLoggedInFullname={props.setLoggedInFullname}
//           collectionRefresh={props.collectionRefresh}
//           setCollectionRefresh={props.setCollectionRefresh}
//           artefactRefresh={props.artefactRefresh}
//           setArtefactRefresh={props.setArtefactRefresh}
//           isFetching={props.isFetching}
//           setIsFetching={props.setIsFetching}
//         />
//       );
//     } else {
//       console.log("lobstermobster2 is rendered");

//       return (
//         // <MyPage
//         // loggedIn={props.loggedIn}
//         // setLoggedIn={props.setLoggedIn}
//         // loggedInUsername={props.loggedInUsername}
//         // setLoggedInUsername={props.setLoggedInUsername}
//         // submittedForm={props.submittedForm}
//         // setSubmittedForm={props.setSubmittedForm}
//         // placingMode={props.placingMode}
//         // setPlacingMode={props.setPlacingMode}
//         // currentCollection={props.currentCollection}
//         // handleCreateButtonClick={props.handleCreateButtonClick}
//         // placeArtefactsTrigger={props.placeArtefactsTrigger}
//         // setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
//         // centerScreen={props.centerScreen}
//         // setCenterScreen={props.setCenterScreen}
//         // isScreenCentered={props.isScreenCentered}
//         // setIsScreenCentered={props.setIsScreenCentered}
//         // centerScreenHandler={props.centerScreenHandler}
//         // setOg_username={props.setOg_username}
//         // loggedInFullname={props.loggedInFullname}
//         // setLoggedInFullname={props.setLoggedInFullname}
//         // />
//         <MyPage
//           loggedIn={props.loggedIn}
//           setLoggedIn={props.setLoggedIn}
//           loggedInUsername={props.loggedInUsername}
//           setLoggedInUsername={props.setLoggedInUsername}
//           submittedForm={props.submittedForm}
//           setSubmittedForm={props.setSubmittedForm}
//           placingMode={props.placingMode}
//           setPlacingMode={props.setPlacingMode}
//           currentCollection={props.currentCollection}
//           handleCreateButtonClick={props.handleCreateButtonClick}
//           placeArtefactsTrigger={props.placeArtefactsTrigger}
//           setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
//           centerScreen={props.centerScreen}
//           setCenterScreen={props.setCenterScreen}
//           isScreenCentered={props.isScreenCentered}
//           setIsScreenCentered={props.setIsScreenCentered}
//           centerScreenHandler={props.centerScreenHandler}
//           collectionData={props.collectionData}
//           setCollectionData={props.setCollectionData}
//           artefactData={props.artefactData}
//           setArtefactData={props.setArtefactData}
//           filter={props.filter}
//           setFilter={props.setFilter}
//           isPublicView={props.isPublicView}
//           setIsPublicView={props.setIsPublicView}
//           og_username={props.og_username}
//           setOg_username={props.setOg_username}
//           sharelinkUsername={props.sharelinkUsername}
//           setSharelinkUsername={props.setSharelinkUsername}
//           loggedInFullname={props.loggedInFullname}
//           setLoggedInFullname={props.setLoggedInFullname}
//           collectionRefresh={props.collectionRefresh}
//           setCollectionRefresh={props.setCollectionRefresh}
//           artefactRefresh={props.artefactRefresh}
//           setArtefactRefresh={props.setArtefactRefresh}
//           isFetching={props.isFetching}
//           setIsFetching={props.setIsFetching}
//         />
//       );
//     }
//   } else {
//     if (collectionId) {
//       console.log("lobstermobster3 is rendered");
//       return (
//         <OtherUserPageCollection
//           collectionId={collectionId}
//           urlUsername={username}
//           setLoggedIn={props.setLoggedIn}
//           setLoggedInUsername={props.setLoggedInUsername}
//           collections={props.collections}
//           setCollections={props.setCollections}
//           // submittedForm={props.submittedForm}
//           // setSubmittedForm={props.setSubmittedForm}
//           // placingMode={props.placingMode}
//           // setPlacingMode={props.setPlacingMode}
//           currentCollection={props.currentCollection}
//           // handleCreateButtonClick={props.handleCreateButtonClick}
//           artefacts={props.artefacts}
//           setArtefacts={props.setArtefacts}
//           // placeArtefactsTrigger={props.placeArtefactsTrigger}
//           // setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
//           centerScreen={props.centerScreen}
//           setCenterScreen={props.setCenterScreen}
//           isScreenCentered={props.isScreenCentered}
//           setIsScreenCentered={props.setIsScreenCentered}
//           centerScreenHandler={props.centerScreenHandler}
//           og_username={props.og_username}
//           setOg_username={props.setOg_username}
//           loggedInFullname={props.loggedInFullname}
//           setLoggedInFullname={props.setLoggedInFullname}
//         />
//       );
//     } else {
//       console.log("lobstermobster4 is rendered");

//       return (
//         <OtherUserPage
//           urlUsername={username}
//           setLoggedIn={props.setLoggedIn}
//           setLoggedInUsername={props.setLoggedInUsername}
//           collections={props.collections}
//           setCollections={props.setCollections}
//           // submittedForm={props.submittedForm}
//           // setSubmittedForm={props.setSubmittedForm}
//           // placingMode={props.placingMode}
//           // setPlacingMode={props.setPlacingMode}
//           currentCollection={props.currentCollection}
//           // handleCreateButtonClick={props.handleCreateButtonClick}
//           artefacts={props.artefacts}
//           setArtefacts={props.setArtefacts}
//           // placeArtefactsTrigger={props.placeArtefactsTrigger}
//           // setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
//           centerScreen={props.centerScreen}
//           setCenterScreen={props.setCenterScreen}
//           isScreenCentered={props.isScreenCentered}
//           setIsScreenCentered={props.setIsScreenCentered}
//           centerScreenHandler={props.centerScreenHandler}
//           og_username={props.og_username}
//           setOg_username={props.setOg_username}
//           loggedInFullname={props.loggedInFullname}
//           setLoggedInFullname={props.setLoggedInFullname}
//         />
//       );
//     }
//   }
// }

// export default CheckIsPageOwn;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import OtherUserPage from "../pages/OtherUserPage";
import OtherUserPageCollection from "../pages/OtherUserPageCollection"; // import the new component

function CheckIsPageOwn(props) {
  const { urlUsername: username, collectionId } = useParams(); // Match route parameter names
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    if (props.loggedIn && username === props.loggedInUsername) {
      // If user is viewing their own page
      if (collectionId) {
        console.log("Redirecting to user's own collection page");
        navigate(`/collection/${collectionId}`);
      } else {
        console.log("Redirecting to user's own MyPage");
        navigate("/mypage");
      }
    }
  }, [
    props.loggedIn,
    username,
    collectionId,
    props.loggedInUsername,
    navigate,
  ]);

  if (props.loggedIn && username === props.loggedInUsername) {
    // While redirection is happening, you can return null or a loading state
    return null;
  } else {
    if (collectionId) {
      // Render the other user's collection page
      console.log("Rendering other user's collection");
      return (
        <OtherUserPageCollection
          collectionId={collectionId}
          urlUsername={username}
          setLoggedIn={props.setLoggedIn}
          setLoggedInUsername={props.setLoggedInUsername}
          collections={props.collections}
          setCollections={props.setCollections}
          currentCollection={props.currentCollection}
          artefacts={props.artefacts}
          setArtefacts={props.setArtefacts}
          centerScreen={props.centerScreen}
          setCenterScreen={props.setCenterScreen}
          isScreenCentered={props.isScreenCentered}
          setIsScreenCentered={props.setIsScreenCentered}
          centerScreenHandler={props.centerScreenHandler}
          og_username={props.og_username}
          setOg_username={props.setOg_username}
          loggedInFullname={props.loggedInFullname}
          setLoggedInFullname={props.setLoggedInFullname}
          setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
        />
      );
    } else {
      // Render the other user's page
      console.log("Rendering other user's page");
      return (
        <OtherUserPage
          urlUsername={username}
          setLoggedIn={props.setLoggedIn}
          setLoggedInUsername={props.setLoggedInUsername}
          collections={props.collections}
          setCollections={props.setCollections}
          currentCollection={props.currentCollection}
          artefacts={props.artefacts}
          setArtefacts={props.setArtefacts}
          centerScreen={props.centerScreen}
          setCenterScreen={props.setCenterScreen}
          isScreenCentered={props.isScreenCentered}
          setIsScreenCentered={props.setIsScreenCentered}
          centerScreenHandler={props.centerScreenHandler}
          og_username={props.og_username}
          setOg_username={props.setOg_username}
          loggedInFullname={props.loggedInFullname}
          setLoggedInFullname={props.setLoggedInFullname}
          setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
        />
      );
    }
  }
}

export default CheckIsPageOwn;
