import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../../css/CloseAccount.css";

function CloseAccount({
  show,
  onHide,
  onConfirm,
  collectionType,
  collectionId,
  og_username,
}) {
  console.log("Collection Type:", collectionType);
  const message =
    "You will be permanently deleting all information and contents related to this account. This includes all artefacts, collections and information.";

  useEffect(() => {
    if (show) {
      document.body.classList.add("disable-background");
    } else {
      document.body.classList.remove("disable-background");
    }

    return () => {
      document.body.classList.remove("disable-background");
    };
  }, [show]);

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleDeleteAccount = async () => {
    try {
      // Make the DELETE request using fetch
      const response = await fetch(
        // `/api/user/${og_username}/delete_account`
        `/api/account/delete_account`,

        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Include credentials (cookies) in the request
          body: JSON.stringify({ confirmDelete: "DELETE" }), // Send the delete confirmation phrase
        }
      );

      if (response.ok) {
        // If the response is successful, notify the user and redirect
        alert("Account deleted successfully");
        onConfirm(); // Optional: call onConfirm if you need additional actions
        onHide(); // Close the modal
        window.location.href = "/"; // Redirect to the home page or another page after deletion
      } else {
        // If response is not ok, show an error
        const errorData = await response.json();
        alert(`Failed to delete account: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Failed to delete account:", error);
      alert("Failed to delete account. Please try again.");
    }
  };

  return (
    <div
      className="close-account no-select"
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <Modal
        className="close-account-modal "
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="close-account-modal"
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Close Account</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-close-account">
          {message}
          {/* <p>Please type "DELETE" to confirm.</p>
          <input
            type="text"
            className="confirm-delete-input"
            value={inputValue}
            onChange={handleInputChange}
          /> */}
        </Modal.Body>

        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px", // adds space between the input and the button
            padding: "20px", // adds padding inside the footer for spacing around
            margin: "0px", // removes margin around the footer
          }}
          className="modal-footer-close-account"
        >
          {/* <div className="input-container"> */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <input
              type="text"
              className="confirm-delete-input"
              value={inputValue}
              onChange={handleInputChange}
              placeholder='Type "DELETE" to confirm'
              style={{
                height: "50px",
                borderRadius: "5px",
                padding: "5px 5px 5px 10px",
                outline: "none",
                border: "none",
                fontSize: "15px",
                width: "210px",
              }}
            />
          </div>
          <Button
            // style={{marginLeft: "30px"}}
            className={`close-account_confirm_delete ${
              inputValue === "DELETE" ? "delete-active" : ""
            }`}
            variant="danger"
            // onClick={() => {
            //   if (inputValue === "DELETE") {
            //     onConfirm(); // Call the confirm handler
            //     onHide(); // Close the modal after confirmation
            //   }
            // }}
            onClick={handleDeleteAccount}
            disabled={inputValue !== "DELETE"}
          >
            Delete Account
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CloseAccount;
