// import React, { useState, useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import "../css/Signin.css";
// import { Link } from "react-router-dom";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
// import logoIcon from "../logo_icon.png"; // Adjust the path as needed
// import logoShowcase from "../logo_showcase.png";

// const Signin = (props) => {
//   const [errorMsg, setErrorMsg] = useState("");
//   // const [identifier, setIdentifier] = useState(""); // To handle username or email
//   // const [password, setPassword] = useState("");
//   const [identifier, setIdentifier] = useState(
//     localStorage.getItem("username") || ""
//   ); // Pre-fill from localStorage
//   const [password, setPassword] = useState(
//     localStorage.getItem("password") || ""
//   ); // Pre-fill from localStorage

//   const [showPassword, setShowPassword] = useState(false);

//   const navigate = useNavigate();

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   // // const usernameRef = useRef(null);
//   // const identifierRef = useRef(null);
//   // const passRef = useRef(null);

//   const handleSubmit = (event) => {
//     event.preventDefault();

//     // Check if identifier is lowercase
//     if (identifier !== identifier.toLowerCase()) {
//       setErrorMsg("Username must be in lowercase");
//       return;
//     }

//     fetch("/api/check_signin")
//       .then((response) => response.json())
//       .then((j) => {
//         console.log("response", j);
//         // setLoggedIn(j.loggedIn);
//         // setLoggedInUsername(j.username);
//         // setOg_username(j.username);
//         // setLoggedInFullname(j.full_name);
//       });

//     fetch("/api/signin", {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       credentials: "same-origin",
//       body: JSON.stringify({
//         identifier: identifier,
//         password: password,
//       }),
//     }).then((response) => {
//       if (response.status === 200) {
//         response.json().then((j) => {
//           console.log("Logged in as", j);
//           props.setLoggedInUsername(j.username);
//           props.setLoggedIn(true);
//           props.setOg_username(j.username);
//           props.setLoggedInFullname(j.full_name);
//         });
//       } else {
//         response.json().then((data) => {
//           if (response.status === 400) {
//             setErrorMsg("You must logout before logging in again");
//           } else if (response.status === 401) {
//             setErrorMsg("Incorrect username/email or password");
//           } else if (response.status === 500) {
//             setErrorMsg("Something went wrong, please try again later");
//           }
//         });
//       }
//     });
//   };

//   useEffect(() => {
//     if (props.loggedIn) {
//       navigate("/mypage");
//     }
//   }, [props.loggedIn, navigate]);

//   const handleIdentifierChange = (value) => {
//     setIdentifier(value);
//     // Clear the error message if the user is correcting the identifier
//     if (errorMsg) setErrorMsg("");
//     // If the identifier contains uppercase letters, show an error message
//     if (value !== value.toLowerCase()) {
//       setErrorMsg("Username must be in lowercase");
//     } else {
//       setErrorMsg(""); // Clear the error if it meets the requirement
//     }
//   };

//   useEffect(() => {
//     const preventDefaultZoomActions = (event) => {
//       if (
//         (event.ctrlKey &&
//           (event.key === "+" || event.key === "-" || event.key === "=")) ||
//         (event.ctrlKey && event.deltaY)
//       ) {
//         event.preventDefault(); // Prevent Ctrl + '+' and Ctrl + '-' for zooming
//       }
//     };

//     const preventTouchZoom = (event) => {
//       if (event.touches.length > 1) {
//         event.preventDefault(); // Prevent pinch-to-zoom
//       }
//     };

//     window.addEventListener("keydown", preventDefaultZoomActions);
//     window.addEventListener("wheel", preventDefaultZoomActions, {
//       passive: false,
//     });
//     window.addEventListener("touchmove", preventTouchZoom, { passive: false });

//     return () => {
//       window.removeEventListener("keydown", preventDefaultZoomActions);
//       window.removeEventListener("wheel", preventDefaultZoomActions);
//       window.removeEventListener("touchmove", preventTouchZoom);
//     };
//   }, []);

//   return (
//     <div className="home-container">
//       <div className="home-content" style={{ marginTop: "0px" }}>
//         <div
//           className="signIn-container no-select"
//           style={{
//             marginLeft: "140px",
//             marginTop: "150px",
//             userSelect: "none",
//           }}
//         >
//           <h2>Sign In</h2>
//           <div style={{ marginTop: "20px" }}>
//             <Form onSubmit={handleSubmit}>
//               <Form.Floating className="mb-3">
//                 <Form.Control
//                   type="text"
//                   id="floatingInputIdentifier"
//                   placeholder="Username or Email"
//                   value={identifier}
//                   onChange={({ target: { value } }) =>
//                     handleIdentifierChange(value)
//                   }
//                   style={{ height: "53px" }}
//                 />
//                 <Form.Label
//                   htmlFor="floatingInputIdentifier"
//                   style={{ marginTop: "-1px" }}
//                 >
//                   Username or Email
//                 </Form.Label>
//               </Form.Floating>

//               <Form.Floating
//                 className="mb-3"
//                 style={{ position: "relative", marginTop: "17px" }}
//               >
//                 <Form.Control
//                   type={showPassword ? "text" : "password"}
//                   id="floatingPassword"
//                   placeholder="Password"
//                   value={password}
//                   onChange={({ target: { value } }) => {
//                     setPassword(value);
//                     if (errorMsg) setErrorMsg(""); // Clear error message on change
//                   }}
//                   style={{ height: "53px" }}
//                 />
//                 <Form.Label
//                   htmlFor="floatingPassword"
//                   style={{ marginTop: "-1px" }}
//                 >
//                   Password
//                 </Form.Label>
//                 <span
//                   className="password-icon"
//                   onClick={togglePasswordVisibility}
//                   style={{
//                     position: "absolute",
//                     top: "50%",
//                     right: "20px",
//                     transform: "translateY(-50%)",
//                     cursor: "pointer",
//                     color: "grey",
//                   }}
//                 >
//                   {showPassword ? <FaEyeSlash /> : <FaEye />}
//                 </span>
//               </Form.Floating>

//               <div
//                 className="error-message"
//                 style={{
//                   height: "30px",
//                   textAlign: "center",
//                   color: "red",
//                   marginTop: "-8px",
//                 }}
//               >
//                 {errorMsg && <span>{errorMsg}</span>}
//               </div>

//               <div style={{ marginBottom: "5px" }}></div>
//               <div className="login-button-container">
//                 <Button
//                   className="login-button"
//                   variant="primary"
//                   type="submit"
//                 >
//                   LOGIN
//                 </Button>
//               </div>
//               <br />
//               <Form.Text id="passwordHelpBlock" muted>
//                 Don't have an account?{" "}
//                 <Link to="/signup" style={{ marginLeft: "3px" }}>
//                   Sign Up
//                 </Link>
//               </Form.Text>
//             </Form>
//           </div>
//         </div>
//         <div
//           className="logo-container"
//           style={{ marginLeft: "40.5px", marginTop: "131px" }}
//         >
//           <img src={logoIcon} alt="Logo Icon" className="logo-icon" />
//           <img
//             src={logoShowcase}
//             alt="Logo Showcase"
//             className="logo-showcase"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Signin;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "../css/Signin.css";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import logoIcon from "../logo_icon.png"; // Adjust the path as needed
import logoShowcase from "../logo_showcase.png";

const Signin = (props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [identifier, setIdentifier] = useState(
    localStorage.getItem("username") || ""
  ); // Pre-fill from localStorage
  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  ); // Pre-fill from localStorage

  const [showPassword, setShowPassword] = useState(false);
  const [isLowConnection, setIsLowConnection] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Ref to manage the low connection timer
  const lowConnectionTimerRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Reset error message
    setErrorMsg("");

    // Check if identifier is lowercase
    if (identifier !== identifier.toLowerCase()) {
      setErrorMsg("Username must be in lowercase");
      return;
    }

    setIsLowConnection(false);
    // Start the low connection timer
    lowConnectionTimerRef.current = setTimeout(() => {
      setIsLowConnection(true);
    }, 5000); // 5 seconds

    try {
      // Optionally, you can handle /api/check_signin here
      const checkResponse = await fetch("/api/check_signin");

      // Clear the timeout since the request completed
      clearTimeout(lowConnectionTimerRef.current);
      lowConnectionTimerRef.current = null;

      if (!checkResponse.ok) {
        // Handle check_signin error if necessary
        console.error("Error in /api/check_signin");
        // Optionally set an error message or proceed based on your API design
      } else {
        const checkData = await checkResponse.json();
        console.log("check_signin response:", checkData);
        // Handle check_signin response if needed
      }

      // Proceed with the sign-in request
      const signinResponse = await fetch("/api/signin", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
        body: JSON.stringify({
          identifier: identifier,
          password: password,
        }),
      });

      // Clear the timeout since the request completed
      clearTimeout(lowConnectionTimerRef.current);
      lowConnectionTimerRef.current = null;

      if (signinResponse.status === 200) {
        const signinData = await signinResponse.json();
        console.log("Logged in as", signinData);
        props.setLoggedInUsername(signinData.username);
        props.setLoggedIn(true);
        props.setOg_username(signinData.username);
        props.setLoggedInFullname(signinData.full_name);
        setIsLowConnection(false);
        // Optionally, save to localStorage
        localStorage.setItem("username", identifier);
        localStorage.setItem("password", password);
      } else {
        const errorData = await signinResponse.json();
        if (signinResponse.status === 400) {
          setErrorMsg("You must logout before logging in again");
        } else if (signinResponse.status === 401) {
          setErrorMsg("Incorrect username/email or password");
        } else if (signinResponse.status === 500) {
          setErrorMsg("Something went wrong, please try again later");
        } else {
          setErrorMsg("An unexpected error occurred");
        }
      }
    } catch (error) {
      console.error("Error during sign-in:", error);
      clearTimeout(lowConnectionTimerRef.current);
      lowConnectionTimerRef.current = null;
      setIsLowConnection(true);
      setErrorMsg("Network error. Please check your connection.");
    }
  };

  useEffect(() => {
    if (props.loggedIn) {
      navigate("/mypage");
    }
  }, [props.loggedIn, navigate]);

  const handleIdentifierChange = (value) => {
    setIdentifier(value);
    // Clear the error message if the user is correcting the identifier
    if (errorMsg) setErrorMsg("");
    // If the identifier contains uppercase letters, show an error message
    if (value !== value.toLowerCase()) {
      setErrorMsg("Username must be in lowercase");
    } else {
      setErrorMsg(""); // Clear the error if it meets the requirement
    }
  };

  useEffect(() => {
    const preventDefaultZoomActions = (event) => {
      if (
        (event.ctrlKey &&
          (event.key === "+" || event.key === "-" || event.key === "=")) ||
        (event.ctrlKey && event.deltaY)
      ) {
        event.preventDefault(); // Prevent Ctrl + '+' and Ctrl + '-' for zooming
      }
    };

    const preventTouchZoom = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault(); // Prevent pinch-to-zoom
      }
    };

    window.addEventListener("keydown", preventDefaultZoomActions);
    window.addEventListener("wheel", preventDefaultZoomActions, {
      passive: false,
    });
    window.addEventListener("touchmove", preventTouchZoom, { passive: false });

    return () => {
      window.removeEventListener("keydown", preventDefaultZoomActions);
      window.removeEventListener("wheel", preventDefaultZoomActions);
      window.removeEventListener("touchmove", preventTouchZoom);
      // Clear the timer if component unmounts
      if (lowConnectionTimerRef.current) {
        clearTimeout(lowConnectionTimerRef.current);
      }
    };
  }, []);

  // Define the ConnectionIndicator component
  function ConnectionIndicator() {
    return (
      <div
        className="low-connection"
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          color: "orange",
          marginLeft: "15px",
          marginTop: "15px",
          zIndex: "1000",
        }}
      >
        <div
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            backgroundColor: "orange",
            marginRight: "3px",
            marginTop: "-1px",
          }}
        ></div>
        <span>Low connection</span>
      </div>
    );
  }

  return (
    <div className="home-container">
      <div
        className="home-content"
        style={{ marginTop: "0px", position: "relative" }}
      >
        {/* Display the ConnectionIndicator if there's a low connection */}
        {isLowConnection && <ConnectionIndicator />}

        <div
          className="signIn-container no-select"
          style={{
            marginLeft: "140px",
            marginTop: "150px",
            userSelect: "none",
          }}
        >
          <h2>Sign In</h2>
          <div style={{ marginTop: "20px" }}>
            <Form onSubmit={handleSubmit}>
              <Form.Floating className="mb-3">
                <Form.Control
                  type="text"
                  id="floatingInputIdentifier"
                  placeholder="Username or Email"
                  value={identifier}
                  onChange={({ target: { value } }) =>
                    handleIdentifierChange(value)
                  }
                  style={{ height: "53px" }}
                />
                <Form.Label
                  htmlFor="floatingInputIdentifier"
                  style={{ marginTop: "-1px" }}
                >
                  Username or Email
                </Form.Label>
              </Form.Floating>

              <Form.Floating
                className="mb-3"
                style={{ position: "relative", marginTop: "17px" }}
              >
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  id="floatingPassword"
                  placeholder="Password"
                  value={password}
                  onChange={({ target: { value } }) => {
                    setPassword(value);
                    if (errorMsg) setErrorMsg(""); // Clear error message on change
                  }}
                  style={{ height: "53px" }}
                />
                <Form.Label
                  htmlFor="floatingPassword"
                  style={{ marginTop: "-1px" }}
                >
                  Password
                </Form.Label>
                <span
                  className="password-icon"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "20px",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "grey",
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </Form.Floating>

              <div
                className="error-message"
                style={{
                  height: "30px",
                  textAlign: "center",
                  color: "red",
                  marginTop: "-8px",
                }}
              >
                {errorMsg && <span>{errorMsg}</span>}
              </div>

              <div style={{ marginBottom: "5px" }}></div>
              <div className="login-button-container">
                <Button
                  className="login-button"
                  variant="primary"
                  type="submit"
                  disabled={isLowConnection} // Optional: disable button if low connection
                >
                  LOGIN
                </Button>
              </div>
              <br />
              <Form.Text id="passwordHelpBlock" muted>
                Don't have an account?{" "}
                <Link to="/signup" style={{ marginLeft: "3px" }}>
                  Sign Up
                </Link>
              </Form.Text>
            </Form>
          </div>
        </div>
        <div
          className="logo-container"
          style={{ marginLeft: "40.5px", marginTop: "131px" }}
        >
          <img src={logoIcon} alt="Logo Icon" className="logo-icon" />
          <img
            src={logoShowcase}
            alt="Logo Showcase"
            className="logo-showcase"
          />
        </div>
      </div>
    </div>
  );
};

export default Signin;
