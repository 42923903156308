import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import NavBar_sharelink from "./NavBar_sharelink";
import Home_navbar from "./Home_Navbar";
import { PannableBox } from "./PannableBox";
import logoIcon from "../logo_icon.png";
import logoShowcase from "../logo_showcase.png";
import { MdOutlineFilterCenterFocus } from "react-icons/md";
// import "../../css/Collection.css";
import "../css/SharelinkView.css";
import FadeTransition from "./FadeTransition";

function SharelinkView(props) {
  // props.setSharelinkUsername(true);

  // const { linkId } = useParams();
  // const linkId = "157edb04-22dd-443f-a32b-d9dbbce51fa2";
  // const shareLinkIds = [
  //   "157edb04-22dd-443f-a32b-d9dbbce51fa2", // Starting link ID
  //   "e916cc16-a8d1-4e40-9ee2-28d61fdbe61c",
  //   "fdb43775-3bb4-4256-967a-250a82c9900c",
  // ];
  // const [linkId, setLinkId] = useState(shareLinkIds[0]);

  const { linkId, setLinkId } = props;

  const [artefacts, setArtefacts] = useState([]);
  const [thisCollection, setThisCollection] = useState(null);
  const [droppedImageFile, setDroppedImageFile] = useState(null);
  const [zoomedInFlag, setZoomedInFlag] = useState(false);
  const [isMovingArtefact, setIsMovingArtefact] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLinkValid, setIsLinkValid] = useState(false);
  const [showValidMessage, setShowValidMessage] = useState(false);
  const [durationMessage, setDurationMessage] = useState("");
  const [moveUpZoomSlider, setMoveUpZoomSlider] = useState(true);
  const [homePageFlag, setHomePageFlag] = useState(true);

  const [sharelinkUsername, setSharelinkUsername] = useState("");
  const [sharelinkFullname, setSharelinkFullname] = useState("");

  const [sharelinkOwner, setSharelinkOwner] = useState("");

  const [isLowConnection, setIsLowConnection] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 120; // Adjust as needed
  const retryDelay = 1000; // 2 seconds

  // const fetchArtefactContent = async (artefact) => {
  //   if (artefact.content_type === "image" || artefact.content_type === "pdf") {
  //     try {
  //       // Fetch content from the backend API
  //       // const response = await fetch(
  //       //   `/api/sharelink/artefact/${artefact.artefact_id}/content`
  //       // );

  //       console.log(
  //         "props.loggedInUsername hesreeee",
  //         props.loggedInUsername,
  //         sharelinkUsername
  //       );

  //       // const response = await fetch(
  //       //   `/api/user/${props.loggedInUsername}/artefact/${artefact.artefact_id}/content`
  //       // );

  //       const response = await fetch(
  //         `/api/sharelink/artefact/${artefact.artefact_id}/content`
  //       );

  //       if (!response.ok) {
  //         throw new Error("Failed to fetch artefact content.");
  //       }

  //       const blob = await response.blob();
  //       const contentUrl = URL.createObjectURL(blob);
  //       console.log("Fetched content URL:", contentUrl);

  //       return { ...artefact, content: contentUrl };
  //     } catch (error) {
  //       console.error("Error fetching artefact content:", error);
  //       return artefact; // Return artefact as is in case of failure
  //     }
  //   }

  //   return artefact;
  // };

  // const fetchArtefactContent = async (artefact) => {
  //   try {
  //     const response = await fetch(
  //       `/api/sharelink/content?link=${linkId}&artefact_id=${artefact.artefact_id}`
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch artefact content.");
  //     }

  //     if (
  //       artefact.content_type === "image" ||
  //       artefact.content_type === "pdf"
  //     ) {
  //       const blob = await response.blob();
  //       const contentUrl = URL.createObjectURL(blob);
  //       return { ...artefact, content: contentUrl };
  //     } else if (artefact.content_type === "website") {
  //       const data = await response.json();
  //       if (data.hasOwnProperty("data") && typeof data.data === "string") {
  //         return {
  //           ...artefact,
  //           content: data.data,
  //         };
  //       } else {
  //         return {
  //           ...artefact,
  //           content: data,
  //         };
  //       }
  //     } else if (artefact.content_type === "text") {
  //       const data = await response.json();
  //       return { ...artefact, content: data.data };
  //     } else {
  //       return artefact;
  //     }
  //   } catch (error) {
  //     console.error("Error fetching artefact content:", error);
  //     return artefact;
  //   }
  // };

  // const fetchSharedData = () => {
  //   fetch(`/api/sharelink?link=${linkId}`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         if (response.status === 404) {
  //           throw new Error("This sharelink has expired.");
  //         }
  //         throw new Error("Error");
  //       }
  //       return response.json();
  //     })
  //     .then(async (data) => {
  //       setThisCollection({
  //         type: data.type,
  //         collection_name: data.collection_name || "Unnamed Collection",
  //         parent_id: data.parent_id,
  //       });

  //       if (data.owner) {
  //         setSharelinkUsername(data.owner);
  //         setSharelinkFullname(data.full_name);
  //       }

  //       const durationMessage = data.account_public ? "2 months " : "48 hours ";

  //       if (data.type === "artefacts") {
  //         // Map the artefacts to fetch their content and update state
  //         const artefactsWithContent = await Promise.all(
  //           data.data.map(fetchArtefactContent)
  //         );
  //         setArtefacts(artefactsWithContent || []);
  //       } else {
  //         setArtefacts([]);
  //       }

  //       setIsLinkValid(true);

  //       setTimeout(() => {
  //         setShowValidMessage(true);
  //         setTimeout(() => {
  //           setShowValidMessage(false);
  //         }, 4000);
  //       }, 500);
  //       setDurationMessage(durationMessage);
  //     })
  //     .catch((error) => {
  //       setErrorMessage(error.message);
  //       console.log("Fetch error: ", error);
  //     });
  // };

  const fetchArtefactContent = async (
    artefact,
    retries = maxRetries,
    delay = retryDelay
  ) => {
    let attempts = 0;
    let hasShownLowConnection = false;

    while (attempts < retries) {
      try {
        const response = await fetch(
          `/api/sharelink/content?link=${linkId}&artefact_id=${artefact.artefact_id}`
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch artefact content: ${response.statusText}`
          );
        }

        if (
          artefact.content_type === "image" ||
          artefact.content_type === "pdf"
        ) {
          const blob = await response.blob();
          const contentUrl = URL.createObjectURL(blob);
          if (attempts > 0) {
            setIsLowConnection(false); // Reset once successful
          }
          return { ...artefact, content: contentUrl };
        } else if (artefact.content_type === "website") {
          const data = await response.json();
          if (data.hasOwnProperty("data") && typeof data.data === "string") {
            return { ...artefact, content: data.data };
          } else {
            return { ...artefact, content: data };
          }
        } else if (artefact.content_type === "text") {
          const data = await response.json();
          return { ...artefact, content: data.data };
        } else {
          return artefact;
        }
      } catch (error) {
        attempts++;
        console.error(
          `Error fetching artefact content, attempt ${attempts}:`,
          error
        );

        // Show the low connection indicator after the first failed attempt
        if (attempts === 1 && !hasShownLowConnection) {
          setIsLowConnection(true);
          hasShownLowConnection = true;
        }

        if (attempts >= retries) {
          console.error("Max retries reached. Skipping this artefact.");
          return artefact;
        }

        // Wait before retrying
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  // fetchSharedData with low connection timer and retry logic
  const fetchSharedData = async () => {
    // props.setIsFetching(true);

    // Set a timer to trigger the low connection indicator if fetching takes longer than 5 seconds
    const lowConnectionTimer = setTimeout(() => {
      setIsLowConnection(true);
    }, 5000);

    try {
      const response = await fetch(`/api/sharelink?link=${linkId}`);

      if (!response.ok) {
        if (response.status === 404) {
          throw new Error("This sharelink has expired.");
        }
        throw new Error("Error fetching sharelink data.");
      }

      const data = await response.json();

      setThisCollection({
        type: data.type,
        collection_name: data.collection_name || "Unnamed Collection",
        parent_id: data.parent_id,
      });

      if (data.owner) {
        setSharelinkUsername(data.owner);
        setSharelinkFullname(data.full_name);
      }

      const durationMessage = data.account_public ? "2 months " : "48 hours ";

      if (data.type === "artefacts") {
        // Fetch artefacts with retry logic
        const artefactsWithContent = await Promise.all(
          data.data.map((artefact) => fetchArtefactContent(artefact))
        );
        setArtefacts(artefactsWithContent || []);
      } else {
        setArtefacts([]);
      }

      setIsLinkValid(true);

      setTimeout(() => {
        setShowValidMessage(true);
        setTimeout(() => {
          setShowValidMessage(false);
        }, 4000);
      }, 500);
      setDurationMessage(durationMessage);

      // Clear the low connection timer if fetch completes successfully
      clearTimeout(lowConnectionTimer);
      setIsLowConnection(false);
    } catch (error) {
      setErrorMessage(error.message);
      console.error("Fetch error:", error);
    } finally {
      // props.setIsFetching(false);
      clearTimeout(lowConnectionTimer);
    }
  };

  useEffect(() => {
    fetchSharedData();
  }, [linkId, sharelinkUsername]);

  function ConnectionIndicator() {
    return (
      <div
        className="low-connection-home"
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          color: "orange",
          marginLeft: "15px",
          marginTop: "15px",
          zIndex: "1000",
        }}
      >
        <div
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            backgroundColor: "orange",
            marginRight: "3px",
            marginTop: "-1px",
          }}
        ></div>
        <span>Low connection</span>
      </div>
    );
  }

  return (
    <FadeTransition in={true}>
      <div
        className="collection-contents no-select"
        style={{
          pointerEvents: props.isInteractable ? "auto" : "none", // Enable or disable pointer events
          opacity: props.isInteractable ? 1 : 0.6, // Optional: Visual feedback for disabled state
          transition: "opacity 0.3s ease, pointer-events 0.3s ease",
        }}
      >
        {isLowConnection && <ConnectionIndicator />}
        <div style={{ position: "relative" }}>
          {" "}
          {/* <Home_navbar /> */}
          {!props.homePageFlag && !props.isPhoneMode && (
            <div
              className="center-button-container"
              style={{
                position: "absolute", // Changed from 'fixed' to 'absolute'
                left: "calc(50% + 208px)", // Align it exactly 10px to the right of the navbar
                top: "30px", // Align with the navbar's top position
                // pointerEvents: "auto",
                pointerEvents: props.isScreenCentered ? "none" : "auto",

                opacity: props.isScreenCentered ? 0 : 1,
                transition: "opacity 0.5s ease-in-out",
                zIndex: 11, // Ensure it is above other elements
              }}
            >
              <button
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  outline: "none",
                }}
                onClick={props.centerScreenHandler}
              >
                <MdOutlineFilterCenterFocus size={30} color="#9D9D9D" />
              </button>
            </div>
          )}
          {/* {errorMessage && (
            <div
              className="error-message"
              style={{
                position: "absolute",
                left: "calc(50% - 96px)",
                color: "red",
                textAlign: "center",
                marginTop: "87px",
                flexShrink: 0,
              }}
            >
              <p>{errorMessage}</p>
            </div>
          )} */}
          {errorMessage ? (
            <div
              className="error-message"
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                color: "red",
                textAlign: "center",
                marginTop: "87px",
                flexShrink: 0,
                zIndex: 100,
              }}
            >
              <p>{errorMessage}</p>
            </div>
          ) : (
            isLinkValid && (
              <div
                className={`valid-message ${showValidMessage ? "show" : ""}`}
              >
                {/* <p>
                  This link is valid for {durationMessage} after{" "}
                  <span style={{ position: "relative", top: "-2px" }}>@</span>
                  {sharelinkUsername} shared this collection.
                </p> */}
              </div>
            )
          )}
        </div>
        {/* <div
          className="logo-container no-select"
          style={{
            display: "flex",
            flexDirection: "column", // Stack the content vertically
            alignItems: "center", // Center horizontally
            position: "fixed", // Fixes the logo to the bottom of the page
            bottom: "4px", // Adjust positioning from the bottom
            left: "50%", // Horizontally center
            transform: "translateX(-50%)", // Ensure it's centered properly
            zIndex: 1000, // Ensure it stays on top of other content
            pointerEvents: "none", // Prevent interaction with the logo
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={logoIcon}
              style={{
                height: "43px",
                marginTop: "11px",
                marginLeft: "2px",
              }}
              alt="Logo Icon"
              className="logo-icon"
            />
            <img
              src={logoShowcase}
              style={{
                height: "65px",
                marginTop: "0px",
                marginLeft: "7px",
              }}
              alt="Logo Showcase"
              className="logo-showcase"
            />
          </div>

          <div
            className="url-text"
            style={{
              marginTop: "-18px",
              fontSize: "14px",
              color: "#000",
              textAlign: "center",
              pointerEvents: "auto",
            }}
          >
            showcase-app.co
          </div>
        </div> */}

        <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
          <PannableBox
            collections={[]}
            placingMode={false}
            setPlacingMode={props.setPlacingMode}
            getter={props.submittedForm}
            setter={props.setSubmittedForm}
            currentCollection={thisCollection || {}}
            collectionData={props.collectionData || {}}
            setCollectionData={props.setCollectionData}
            artefactData={props.artefactData || {}}
            artefacts={artefacts}
            setArtefacts={setArtefacts}
            setArtefactData={props.setArtefactData}
            setCollections={() => {}}
            placeArtefactsTrigger={props.placeArtefactsTrigger}
            setPlaceArtefactsTrigger={props.setPlaceArtefactsTrigger}
            centerScreen={props.centerScreen}
            setCenterScreen={props.setCenterScreen}
            setIsScreenCentered={props.setIsScreenCentered}
            loggedInUsername={null}
            handleCreateButtonClick={props.handleCreateButtonClick}
            isPublicView={true}
            otherUser={null}
            zoomedInFlag={zoomedInFlag}
            setZoomedInFlag={setZoomedInFlag}
            isOwner={false}
            og_username={props.og_username}
            droppedImageFile={droppedImageFile}
            setDroppedImageFile={setDroppedImageFile}
            setIsMovingArtefact={setIsMovingArtefact}
            sharelinkUsername={sharelinkUsername}
            setSharelinkUsername={setSharelinkUsername}
            sharelinkFullname={sharelinkFullname}
            setSharelinkFullname={setSharelinkFullname}
            moveUpZoomSlider={moveUpZoomSlider}
            homePageFlag={homePageFlag}
          />
        </div>
      </div>
    </FadeTransition>
  );
}

export default SharelinkView;
