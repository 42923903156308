// FollowButton.js
import React from "react";

function FollowButton({ userId, followStatus, handleFollow }) {
  const getButtonText = () => {
    console.log("yes h followStatus", followStatus);
    if (followStatus === "none" || followStatus === "rejected") {
      return "Follow";
    } else if (followStatus === "pending") {
      return "Pending";
    } else if (followStatus === "approved") {
      return "Following";
    }
  };

  const onButtonClick = () => {
    let currentStatus = followStatus || "none";
    let newStatus;

    if (currentStatus === "none" || currentStatus === "rejected") {
      newStatus = "pending";
    } else if (currentStatus === "pending") {
      newStatus = "none"; // Assuming you want to revert to no follow request. Adjust as needed.
    } else if (currentStatus === "approved") {
      newStatus = "none"; // Assuming unfollow sets it back to 'none'. Adjust as needed.
    }

    handleFollow(userId, newStatus);
  };

  return (
    <button
      onClick={onButtonClick}
      style={{
        fontSize: "16px",
        borderRadius: "5px",
        border: "none",
        backgroundColor: "#ccc", // Adjust the grey color as you like
        marginLeft: "7px",
        paddingTop: "2px",
      }}
    >
      {getButtonText()}
    </button>
  );
}

export default FollowButton;
