export const updateCollectionName = async (username, collectionId, newName) => {
  try {
    const response = await fetch(
      `/api/user/${username}/collection/${collectionId}/rename`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ collection_name: newName }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Failed to update collection name:", error);
    throw error;
  }
};
