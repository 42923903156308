import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Signup from "./pages/Signup";
import Signin from "./pages/Signin";
import Home from "./pages/Home";
import CheckIsPageOwn from "./components/CheckIsPageOwn";
import Collection from "./pages/Collection";
import MyPage from "./pages/MyPage";
import OtherUserPageCollection from "./pages/OtherUserPageCollection";
import OtherUserPage from "./pages/OtherUserPage";
import ExplorePage from "./pages/ExplorePage";
import SharelinkView from "./pages/SharelinkView";
import Analytics from "./pages/Analytics";
import TermsConditions from "./pages/Terms-Conditions";
import PrivacyPolicy from "./pages/Privacy-Policy";
import SessionManager from "./api/SessionManager";
import "./css/App.css"; // Include your CSS transitions

export default function App() {
  // const [loggedIn, setLoggedIn] = useState(false);
  const [loggedIn, setLoggedIn] = useState(null);

  const [loggedInUsername, setLoggedInUsername] = useState("");

  const [submittedForm, setSubmittedForm] = useState(false);
  const [placingMode, setPlacingMode] = useState(false);
  const [currentCollection, setCurrentCollection] = useState(null);
  const [placeArtefactsTrigger, setPlaceArtefactsTrigger] = useState(false);
  const [centerScreen, setCenterScreen] = useState(false);
  const [isScreenCentered, setIsScreenCentered] = useState(true);
  const [collectionRefresh, setCollectionRefresh] = useState(false);
  // const [artefactRefresh, setArtefactRefresh] = useState(false);
  const [artefactRefresh, setArtefactRefresh] = useState({
    refresh: false,
    artefactId: null, // or set an initial artefactId if you have one
  });

  const [isFetching, setIsFetching] = useState(false);

  const [sharelinkUsername, setSharelinkUsername] = useState("");

  const [og_username, setOg_username] = useState("");

  const [loggedInFullname, setLoggedInFullname] = useState([]);

  const [collectionData, setCollectionData] = useState({
    collectionName: "",
    collectionId: "",
  });
  const [artefactData, setArtefactData] = useState({
    artefactName: "",
    imageURL: "",
    type: "",
  });
  const [filter, setFilter] = useState("ALL"); // can be 'ALL' or 'PUBLIC_ONLY'

  const [isPublicView, setIsPublicView] = useState(false);

  const centerScreenHandler = () => {
    setCenterScreen(true);
    setIsScreenCentered(true);
  };

  const handleCreateButtonClick = () => {
    setPlacingMode(true);
  };

  useEffect(() => {
    fetch("/api/check_signin")
      .then((response) => response.json())
      .then((j) => {
        setLoggedIn(j.loggedIn);
        setLoggedInUsername(j.username);
        setOg_username(j.username);
        setLoggedInFullname(j.full_name);
      });
  }, []);

  useEffect(() => {
    setIsPublicView(false);

    return;
  }, [loggedIn]);

  console.log("og_username oh yeaaaa", og_username);
  console.log("loggedInFullname oh yeaaaa", loggedInFullname);

  return (
    <BrowserRouter>
      <SessionManager
        setLoggedIn={setLoggedIn}
        setLoggedInUsername={setLoggedInUsername}
      >
        {/* <Routes> */}
        <RoutesWithTransitions>
          <Route
            path="/"
            element={
              <Home
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                setArtefactData={setArtefactData}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                og_username={og_username}
                artefactData={artefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
              />
            }
          />
          {/* This lets you send people Home-1, Home-2 with set examples rather than just sending a page with info */}
          <Route
            path="/home-:id"
            element={
              <Home
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                setArtefactData={setArtefactData}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                og_username={og_username}
                artefactData={artefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
              />
            }
          />
          <Route
            path="/signin"
            element={
              <Signin
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                setLoggedInFullname={setLoggedInFullname}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
              />
            }
          />
          <Route
            path="/signup"
            element={
              <Signup
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                setLoggedInFullname={setLoggedInFullname}
                setOg_username={setOg_username}
              />
            }
          />
          <Route
            path="/collection/:id"
            element={
              <Collection
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                collectionData={collectionData}
                setCollectionData={setCollectionData}
                artefactData={artefactData}
                setArtefactData={setArtefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                og_username={og_username}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
                collectionRefresh={collectionRefresh}
                setCollectionRefresh={setCollectionRefresh}
                artefactRefresh={artefactRefresh}
                setArtefactRefresh={setArtefactRefresh}
                isFetching={isFetching}
                setIsFetching={setIsFetching}
              />
            }
          />
          <Route
            path="/mypage"
            element={
              <MyPage
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                collectionData={collectionData}
                setCollectionData={setCollectionData}
                artefactData={artefactData}
                setArtefactData={setArtefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                og_username={og_username}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
                collectionRefresh={collectionRefresh}
                setCollectionRefresh={setCollectionRefresh}
                artefactRefresh={artefactRefresh}
                setArtefactRefresh={setArtefactRefresh}
                isFetching={isFetching}
                setIsFetching={setIsFetching}
              />
            }
          />
          <Route path="/artefact/:id" />
          <Route
            path="/user/:urlUsername/collection/:collectionId"
            element={
              <CheckIsPageOwn
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                collectionData={collectionData}
                setCollectionData={setCollectionData}
                artefactData={artefactData}
                setArtefactData={setArtefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                og_username={og_username}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
                collectionRefresh={collectionRefresh}
                setCollectionRefresh={setCollectionRefresh}
                artefactRefresh={artefactRefresh}
                setArtefactRefresh={setArtefactRefresh}
                isFetching={isFetching}
                setIsFetching={setIsFetching}
              />
            }
          />
          <Route
            path="/user/:urlUsername"
            element={
              <CheckIsPageOwn
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                collectionData={collectionData}
                setCollectionData={setCollectionData}
                artefactData={artefactData}
                setArtefactData={setArtefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                og_username={og_username}
                setOg_username={setOg_username}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
                collectionRefresh={collectionRefresh}
                setCollectionRefresh={setCollectionRefresh}
                artefactRefresh={artefactRefresh}
                setArtefactRefresh={setArtefactRefresh}
                isFetching={isFetching}
                setIsFetching={setIsFetching}
              />
            }
          />

          <Route
            path="/explore"
            element={
              <ExplorePage
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                collectionData={collectionData}
                setCollectionData={setCollectionData}
                artefactData={artefactData}
                setArtefactData={setArtefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                og_username={og_username}
                setOg_username={setOg_username}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
              />
            }
          />
          <Route
            path="/share/:linkId"
            element={
              <SharelinkView
                loggedIn={loggedIn}
                setLoggedIn={setLoggedIn}
                loggedInUsername={loggedInUsername}
                setLoggedInUsername={setLoggedInUsername}
                submittedForm={submittedForm}
                setSubmittedForm={setSubmittedForm}
                placingMode={placingMode}
                setPlacingMode={setPlacingMode}
                currentCollection={currentCollection}
                setArtefactData={setArtefactData}
                handleCreateButtonClick={handleCreateButtonClick}
                placeArtefactsTrigger={placeArtefactsTrigger}
                setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
                centerScreen={centerScreen}
                setCenterScreen={setCenterScreen}
                isScreenCentered={isScreenCentered}
                setIsScreenCentered={setIsScreenCentered}
                centerScreenHandler={centerScreenHandler}
                og_username={og_username}
                setOg_username={setOg_username}
                // collectionData={collectionData}
                // setCollectionData={setCollectionData}
                artefactData={artefactData}
                filter={filter}
                setFilter={setFilter}
                isPublicView={isPublicView}
                setIsPublicView={setIsPublicView}
                sharelinkUsername={sharelinkUsername}
                setSharelinkUsername={setSharelinkUsername}
                loggedInFullname={loggedInFullname}
                setLoggedInFullname={setLoggedInFullname}
              />
            }
          />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* </Routes> */}
        </RoutesWithTransitions>
      </SessionManager>
    </BrowserRouter>
  );
}

function RoutesWithTransitions({ children }) {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.pathname} classNames="fade" timeout={500}>
        <Routes location={location}>{children}</Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}
