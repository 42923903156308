export const deleteCollection = async (username, collection_id) => {
  try {
    const response = await fetch(
      `/api/user/${username}/collection/${collection_id}`,
      {
        method: "DELETE",
        credentials: "same-origin",
      }
    );

    // Check if the deletion was successful based on the response status
    if (response.ok) {
      console.log("Collection deleted successfully");
      return true;
    } else {
      console.error("Failed to delete collection. Status:", response.status);
      return false;
    }
  } catch (error) {
    console.error("API call failed:", error);
    return false;
  }
};
