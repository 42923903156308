import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import { uploadArtefact } from "../../api/uploadArtefact";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import "../../css/CreateArtefactForm.css";

function CreateArtefactForm(props) {
  const nameRefArtef = useRef(null);
  const addImageRef = useRef(null);

  const [artefactDataArray, setArtefactDataArray] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resetForm = () => {
    setArtefactDataArray([]);
    setCurrentIndex(0);
    props.setArtefactData({
      artefactName: "",
      imageURL: "",
      imageWidth: null,
      imageHeight: null,
      type: "",
      sizing_choice: "m",
    });
  };

  useEffect(() => {
    console.log("Dropped image files:", props.droppedImageFiles);
    if (props.droppedImageFiles && props.droppedImageFiles.length > 0) {
      onChangeImageInput({ target: { files: props.droppedImageFiles } }, false); // isAddMore = true
    }
  }, [props.droppedImageFiles]);

  // You can place this function inside your CreateArtefactForm component or in a separate utils file
  const isHEIC = (file) => {
    const heicTypes = ["image/heic", "image/heif"];
    const heicExtensions = ["heic", "heif"];

    const fileExtension = file.name.split(".").pop().toLowerCase();

    return (
      heicTypes.includes(file.type) || heicExtensions.includes(fileExtension)
    );
  };

  const processFiles = async (files, isAddMore = false) => {
    // Limit to only the files that would not exceed the max of 10 items
    const maxAllowedFiles = 10 - artefactDataArray.length;
    const filesToProcess = files.slice(0, maxAllowedFiles);

    if (filesToProcess.length === 0) {
      alert("You can upload a maximum of 10 artefacts at a time.");
      return;
    }

    const newArtefacts = await Promise.all(
      filesToProcess.map(async (file, index) => {
        console.log("Original File:", file);

        // Check if the file is HEIC/HEIF based on type or extension
        if (isHEIC(file)) {
          try {
            console.log("Converting HEIC/HEIF to JPEG...");
            const convertedBlob = await heic2any({
              blob: file,
              toType: "image/jpeg",
              quality: 0.8, // Adjust quality as needed
            });

            // Create a new File object from the converted Blob
            file = new File(
              [convertedBlob],
              file.name.replace(/\.[^/.]+$/, ".jpg"),
              { type: "image/jpeg" }
            );

            console.log("Converted File:", file);
          } catch (error) {
            console.error("HEIC Conversion Error:", error);
            alert(
              "Failed to convert HEIC image. Please try a different image."
            );
            return null; // Skip adding this artefact
          }
        }

        const isGif = file.type === "image/gif";
        console.log("Is GIF:", isGif);

        if (isGif) {
          // Process GIF without compression
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const img = new window.Image();
              img.onload = () => {
                resolve({
                  imageURL: reader.result,
                  imageWidth: img.width,
                  imageHeight: img.height,
                  file: file, // Use original file without compression
                  fileName: file.name,
                  artefactName:
                    index === 0 && !artefactDataArray[0]?.imageURL
                      ? artefactDataArray[0]?.artefactName || ""
                      : "", // Use initial name for first image if available
                });
              };
              img.src = reader.result;
            };
            reader.readAsDataURL(file);
          });
        } else {
          // Compress the image
          const isMobile = window.innerWidth <= 768;
          const options = {
            maxSizeMB: isMobile ? 2 : 4,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
          };

          try {
            console.log("Compressing image...");
            const compressed = await imageCompression(file, options);
            console.log("Compressed File:", compressed);

            const reader = new FileReader();
            return new Promise((resolve) => {
              reader.onloadend = () => {
                const img = new window.Image();
                img.onload = () => {
                  resolve({
                    imageURL: reader.result,
                    imageWidth: img.width,
                    imageHeight: img.height,
                    file: compressed,
                    fileName: file.name,
                    artefactName:
                      index === 0 && !artefactDataArray[0]?.imageURL
                        ? artefactDataArray[0]?.artefactName || ""
                        : "", // Only use artefact name for the first image
                  });
                };
                img.src = reader.result;
              };
              reader.readAsDataURL(compressed);
            });
          } catch (error) {
            console.error("Image Compression Error:", error);
            alert("Error with an image file type. Please try again.");
            return null; // Skip adding this artefact
          }
        }
      })
    );

    // Filter out any null artefacts
    const successfullyProcessedArtefacts = newArtefacts.filter(
      (artefact) => artefact !== null
    );

    if (successfullyProcessedArtefacts.length === 0) {
      // If no artefacts were successfully processed, do not update the state
      return;
    }

    setArtefactDataArray((prevArray) => {
      if (prevArray.length > 0 && !prevArray[0].imageURL) {
        const updatedArray = [...prevArray];
        updatedArray[0] = successfullyProcessedArtefacts[0];
        return isAddMore
          ? [...updatedArray, ...successfullyProcessedArtefacts.slice(1)]
          : updatedArray;
      } else {
        const updatedArray = isAddMore
          ? [...prevArray, ...successfullyProcessedArtefacts]
          : successfullyProcessedArtefacts;

        if (isAddMore && successfullyProcessedArtefacts.length > 0) {
          setCurrentIndex(prevArray.length); // Set index to the start of the newly added items
        }

        return updatedArray;
      }
    });
  };

  const handlePaste = async (e) => {
    e.preventDefault();
    if (e.clipboardData && e.clipboardData.items) {
      const items = e.clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === "file") {
          const file = items[i].getAsFile();
          if (file && file.type.startsWith("image/")) {
            await processFiles([file], true);
            break;
          }
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  // const onChangeImageInput = (e, isAddMore = false) => {
  //   const files = Array.from(e.target.files);
  //   processFiles(files, isAddMore);
  // };

  const onChangeImageInput = async (e, isAddMore = false) => {
    const files = Array.from(e.target.files);
    await processFiles(files, isAddMore);
  };

  const removeArtefact = () => {
    setArtefactDataArray((prevArray) => {
      const newArray = prevArray.filter((_, index) => index !== currentIndex);
      setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0)); // Update the currentIndex if needed
      return newArray;
    });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    processFiles(files, true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const submitNewArtefact = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const uploadedArtefacts = await Promise.all(
        artefactDataArray.map(async (artefact, index) => {
          console.log(`Uploading artefact ${index + 1}:`, artefact);
          const nameArtef = artefact.artefactName || "";
          const formData = new FormData();
          formData.append("artefact", artefact.file, artefact.file.name);

          for (let pair of formData.entries()) {
            console.log(`${pair[0]}:`, pair[1]);
          }

          const data = await uploadArtefact(formData);
          console.log(`Upload response for artefact ${index + 1}:`, data);
          const previewBlobUrl = URL.createObjectURL(artefact.file);

          return {
            artefactName: nameArtef,
            imageURL: data.url,
            content: data.url,
            type: "image",
            imageWidth: artefact.imageWidth,
            imageHeight: artefact.imageHeight,
            sizing_choice: "m",
            preemptive_upload: previewBlobUrl,
          };
        })
      );

      // Pass the entire array to the parent component

      console.log("Uploaded artefacts:", uploadedArtefacts);
      props.setArtefactsToPlace(uploadedArtefacts);
      props.setPlaceArtefactsTrigger(true);
      props.handleCreateButtonClick();
      props.setSelectedType(null);
      props.setDroppedImageFiles(null);
      setArtefactDataArray([]); // Reset after submission
      setCurrentIndex(0);
      // setCompressedFile(null); // Reset compressed file after upload
    } catch (error) {
      console.error("Error uploading the artefacts:", error);
      alert("Error with an image file type. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // const onArtefactNameChange = (event) => {
  //   const updatedArray = [...artefactDataArray];
  //   updatedArray[currentIndex].artefactName = event.target.value;
  //   setArtefactDataArray(updatedArray);
  // };

  const onArtefactNameChange = (event) => {
    const newName = event.target.value;

    setArtefactDataArray((prevArray) => {
      // Create a placeholder if array is empty, else update the existing entry
      if (prevArray.length === 0) {
        return [
          {
            artefactName: newName,
            imageURL: "",
            imageWidth: null,
            imageHeight: null,
            file: null,
          },
        ];
      } else {
        const updatedArray = [...prevArray];
        updatedArray[currentIndex].artefactName = newName;
        return updatedArray;
      }
    });
  };

  return (
    <Form className="artefact-form" onSubmit={submitNewArtefact}>
      <h2
        className="title-img-artefact"
        style={{
          position: "absolute",
          textAlign: "center",
          marginBottom: "0px",
          fontFamily: "raleway",
          // marginLeft: "-440px",
          // marginLeft: "-340px",
          // marginLeft: "-20px",
          marginTop: "-5px",
          marginBottom: "-5px",
        }}
      >
        Image Artefact
      </h2>
      <div className="space-for-artefact-form" style={{ height: "25px" }}></div>
      <Form.Group>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            marginTop: "10px",
          }}
        >
          {/* Left Arrow Button */}
          <Button
            className={`arrow-button ${
              currentIndex === 0 || artefactDataArray.length === 0
                ? "disabled"
                : ""
            }`}
            disabled={currentIndex === 0 || artefactDataArray.length === 0}
            onClick={() => setCurrentIndex(currentIndex - 1)}
            style={{
              backgroundColor:
                currentIndex === 0 || artefactDataArray.length === 0
                  ? "#ccc"
                  : "#79b8ff",
              borderRadius: "50%",
              border: "2px solid",
              borderColor:
                currentIndex === 0 || artefactDataArray.length === 0
                  ? "#ccc"
                  : "#79b8ff",
              padding: "5px",
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "-50px", // Space between arrow and upload area
              zIndex: 2,
              pointer: "auto",
            }}
          >
            <BsArrowLeft size={24} />
          </Button>

          {/* Artefact Upload Area */}
          <div
            className="artefact-upload"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onPaste={handlePaste}
            style={{
              border: "2px dashed #79b8ff",
              borderRadius: "15px",
              height: "340px",
              width: "470px",
              padding: "30px",
              paddingTop: "50px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              marginBottom: "0px",
              whiteSpace: "nowrap",
            }}
          >
            {artefactDataArray.length > 0 && (
              <div
                className="artefact-number"
                style={{
                  position: "absolute",
                  top: "2px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  padding: "5px 0px 0px 0px",
                  fontFamily: "raleway",
                  fontSize: "18px",
                  whiteSpace: "nowrap",
                  zIndex: 9,
                }}
              >
                Artefact {currentIndex + 1} out of {artefactDataArray.length}
              </div>
            )}
            {artefactDataArray[currentIndex]?.imageURL && (
              <p
                style={{
                  color: "#999", // Greyed out color
                  fontSize: "14px",
                  position: "absolute",
                  top: "33px", // Adjust this value to control the position
                  textAlign: "center",
                  zIndex: 1,
                  pointerEvents: "none", // Prevents interaction with the text
                  marginBottom: "10px",
                  fontFamily: "raleway",
                }}
              >
                Drag and drop, paste, or click to add images
              </p>
            )}
            <Form.Control
              type="file"
              accept="image/png, image/jpeg, image/heic, image/heif, image/gif"
              onChange={(e) => onChangeImageInput(e, true)}
              multiple
              ref={addImageRef}
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
                opacity: 0,
              }}
            />
            {artefactDataArray[currentIndex]?.imageURL ? (
              <Image
                src={artefactDataArray[currentIndex].imageURL}
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                  borderRadius: "10px",
                  marginBottom: "20px",
                  fontFamily: "raleway",
                  marginTop: "40px",
                }}
              />
            ) : (
              <p style={{ marginTop: "-5px" }}>
                Drag and drop, paste, or click to add images
              </p>
            )}
          </div>

          {/* Right Arrow Button */}
          <Button
            className={`arrow-button ${
              currentIndex === artefactDataArray.length - 1 ||
              artefactDataArray.length === 0
                ? "disabled"
                : ""
            }`}
            disabled={
              currentIndex === artefactDataArray.length - 1 ||
              artefactDataArray.length === 0
            }
            onClick={() => setCurrentIndex(currentIndex + 1)}
            style={{
              backgroundColor:
                currentIndex === artefactDataArray.length - 1 ||
                artefactDataArray.length === 0
                  ? "#ccc"
                  : "#79b8ff",
              borderRadius: "50%",
              border: "2px solid",
              borderColor:
                currentIndex === artefactDataArray.length - 1 ||
                artefactDataArray.length === 0
                  ? "#ccc"
                  : "#79b8ff",
              padding: "5px",
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "-50px", // Space between upload area and arrow
              zIndex: 4,
            }}
          >
            <BsArrowRight size={24} />
          </Button>
        </div>
      </Form.Group>

      <Form.Group>
        <Form.Control
          type="text"
          value={artefactDataArray[currentIndex]?.artefactName || ""}
          onChange={onArtefactNameChange}
          ref={nameRefArtef}
          style={{
            fontFamily: "raleway",
            userSelect: "none",
            fontSize: "16px",
            marginTop: "10px",
          }}
          placeholder="Enter artefact Name"
          size="m"
        />
      </Form.Group>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          alignItems: "center",
        }}
      >
        <Button
          className="add-more-artefact"
          onClick={() => addImageRef.current.click()}
          style={{
            backgroundColor: "#aaa",
            outline: "none",
            border: "none",
            height: "36px",
          }}
        >
          + Artefacts
        </Button>

        {/* New - Artefact Button */}
        {artefactDataArray.length > 0 && (
          <Button
            className="remove-artefact-button"
            onClick={removeArtefact}
            style={{
              marginLeft: "-260px",
              border: "none",
              height: "36px",
              marginTop: "0px",
            }}
          >
            - Artefact
          </Button>
        )}
        <Button
          className="create-artefact-button"
          // variant="primary"
          type="submit"
          // disabled={!artefactDataArray.length || isSubmitting}
          // disabled={!artefactDataArray.length}
          disabled={
            !artefactDataArray.length ||
            isSubmitting ||
            artefactDataArray.some((artefact) => !artefact.imageURL) // Ensure each artefact has an image
          }
          onClick={() => {
            props.handleClose();
          }}
        >
          {isSubmitting ? "Submitting..." : "Create"}
        </Button>
      </div>
    </Form>
  );
}

export default CreateArtefactForm;
