import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import ProfileBox from "./ProfileBox";
import { createArtefact } from "../api/createArtefact";
import { createCollection } from "../api/createCollection";
import { moveArtefact } from "../api/moveArtefact";
import { moveCollection } from "../api/moveCollection";
import "../css/PannableBox.css";
import ExploreArtefactsList from "./ExploreArtefactsList";
import ExploreArtefactsList_2 from "./ExploreArtefactsList_2";

export default function PannableBox({
  collections,
  placingMode,
  setPlacingMode,
  getter,
  setter,
  currentCollection,
  collectionData,
  setCollectionData,
  artefactData,
  // artefacts,
  // setArtefacts,
  setCollections,
  placeArtefactsTrigger,
  setPlaceArtefactsTrigger,
  centerScreen,
  setCenterScreen,
  setIsScreenCentered,
  loggedInUsername,
  isPublicView,
  zoomedInFlag,
  setZoomedInFlag,
  otherUser,
  setArtefactData,
  handleCreateButtonClick,
  og_username,
  loggedInFullname,
  setLoggedInFullname,
  setIsLowConnection,
  isLowConnection,
}) {
  const outerDivRef = useRef(null);
  const innerDivRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [velocityX, setVelocityX] = useState(0);
  const [velocityY, setVelocityY] = useState(0);
  const [lastTouchTime, setLastTouchTime] = useState(null);

  const [coordinates, setCoordinates] = useState(null);

  const cellSize = 20;
  // const pannableBoxHeight = 4000;
  // const pannableBoxWidth = 5000;
  const [artefacts, setArtefacts] = useState([]);

  const [pannableBoxWidth, setPannableBoxWidth] = useState(2500);
  const [pannableBoxHeight, setPannableBoxHeight] = useState(2000);

  const [isBoxSizeUpdated, setIsBoxSizeUpdated] = useState(false);

  const [artefactNumber, setArtefactNumber] = useState(false);

  const halfBoxHeight = pannableBoxHeight / 2;
  const halfBoxWidth = pannableBoxWidth / 2;

  const previousDimensions = useRef({
    width: pannableBoxWidth,
    height: pannableBoxHeight,
  });

  const [innerDivTop, setInnerDivTop] = useState(
    -halfBoxHeight + window.innerHeight / 2
  );

  const [innerDivLeft, setInnerDivLeft] = useState(
    -halfBoxWidth + window.innerWidth / 2
  );

  const ITEM_EXPANSION_SIZE = 60; // 40px for both width and height

  const totalItems = artefacts.length;

  const calculateBoundingBox = (artefacts) => {
    if (artefacts.length === 0) {
      return {
        minX: 0,
        minY: 0,
        maxX: window.innerWidth,
        maxY: window.innerHeight,
      };
    }

    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    artefacts.forEach((artefact) => {
      const x = artefact.coordx;
      const y = artefact.coordy;

      if (x < minX) minX = x;
      if (y < minY) minY = y;
      if (x > maxX) maxX = x;
      if (y > maxY) maxY = y;
    });

    return { minX, minY, maxX, maxY };
  };

  useEffect(() => {
    if (artefacts.length > 6) {
      const { minX, minY, maxX, maxY } = calculateBoundingBox(artefacts);

      const padding = 500; // Adjust as needed

      const newWidth = maxX - minX + padding * 2;
      const newHeight = maxY - minY + padding * 2;

      const deltaWidth = newWidth - pannableBoxWidth;
      const deltaHeight = newHeight - pannableBoxHeight;

      // Adjust the inner div's position to keep the center fixed
      setInnerDivLeft((prevLeft) => prevLeft - deltaWidth / 2);
      setInnerDivTop((prevTop) => prevTop - deltaHeight / 2);

      // Update the pannable box size
      setPannableBoxWidth(newWidth);
      setPannableBoxHeight(newHeight);
    }
  }, [artefacts]);

  // Adjust artefact positions
  const [adjustedArtefacts, setAdjustedArtefacts] = useState([]);

  useEffect(() => {
    if (artefacts.length > 0) {
      const { minX, minY } = calculateBoundingBox(artefacts);
      const padding = 500; // Should match the padding used above

      const adjusted = artefacts.map((artefact) => ({
        ...artefact,
        coordx: artefact.coordx - minX + padding,
        coordy: artefact.coordy - minY + padding,
      }));

      setAdjustedArtefacts(adjusted);
    } else {
      setAdjustedArtefacts(artefacts);
    }
  }, [artefacts]);

  // alert("totalItems: " + totalItems);

  // const updateBoxSize = () => {
  //   const totalItems = artefacts.length;
  //   const newHeight = 2500 + totalItems * ITEM_EXPANSION_SIZE;
  //   const newWidth = 3500 + totalItems * ITEM_EXPANSION_SIZE;
  //   // const newHeight = 2500;
  //   // const newWidth = 3500;

  //   // Calculate the change in width and height
  //   const deltaWidth = newWidth - previousDimensions.current.width;
  //   const deltaHeight = newHeight - previousDimensions.current.height;

  //   // Adjust the inner div's position to keep the center fixed
  //   setInnerDivLeft((prevLeft) => prevLeft - deltaWidth / 2);
  //   setInnerDivTop((prevTop) => prevTop - deltaHeight / 2);

  //   // Update the pannable box size
  //   setPannableBoxWidth(newWidth);
  //   setPannableBoxHeight(newHeight);

  //   // Store the new dimensions for the next update
  //   previousDimensions.current = { width: newWidth, height: newHeight };

  //   setIsBoxSizeUpdated(true);
  // };

  // useEffect(() => {
  //   if (artefacts.length > 0) {
  //     updateBoxSize(); // Update the box size when artefacts change
  //   }
  // }, [artefacts.length, artefactNumber]);

  // useEffect(() => {
  //   // Function to update box size based on the number of artefacts
  //   const updateBoxSize = () => {
  //     const totalItems = artefacts.length;
  //     const newHeight = 2500 + totalItems * ITEM_EXPANSION_SIZE;
  //     const newWidth = 3500 + totalItems * ITEM_EXPANSION_SIZE;

  //     // Calculate the change in width and height
  //     const deltaWidth = newWidth - previousDimensions.current.width;
  //     const deltaHeight = newHeight - previousDimensions.current.height;

  //     // Adjust the inner div's position to keep the center fixed
  //     setInnerDivLeft((prevLeft) => prevLeft - deltaWidth / 2);
  //     setInnerDivTop((prevTop) => prevTop - deltaHeight / 2);

  //     // Update the pannable box size
  //     setPannableBoxWidth(newWidth);
  //     setPannableBoxHeight(newHeight);

  //     // Store the new dimensions for the next update
  //     previousDimensions.current = { width: newWidth, height: newHeight };

  //     setIsBoxSizeUpdated(true);
  //   };

  //   // Only update the box size when the number of artefacts changes
  //   if (artefacts.length > 0) {
  //     updateBoxSize();
  //   }
  // }, [artefacts.length]);

  useEffect(() => {
    // Skip centering if placingMode is true
    if (placingMode || placeArtefactsTrigger) return;

    const newLeft = window.innerWidth / 2 - halfBoxWidth;
    const newTop = window.innerHeight / 2 - halfBoxHeight;

    // Directly set the positions without transition for the initial render
    innerDivRef.current.style.transition = ""; // Disable transition
    setInnerDivLeft(newLeft);
    setInnerDivTop(newTop);
  }, [pannableBoxHeight, pannableBoxWidth, placingMode, placeArtefactsTrigger]);

  const backgroundSize = `${cellSize}px ${cellSize}px`;

  const [correctionVelocityX, setCorrectionVelocityX] = useState(0);
  const [correctionVelocityY, setCorrectionVelocityY] = useState(0);

  const [previewArtefact, setPreviewArtefact] = useState(null);
  const [previewCollection, setPreviewCollection] = useState(null);

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const [imageDimensions, setImageDimensions] = useState(null);

  const redBoxCenterX = pannableBoxWidth / 2;
  const redBoxCenterY = pannableBoxHeight / 2;

  const [mouseDownPos, setMouseDownPos] = useState(null);
  const [touchStartPos, setTouchStartPos] = useState(null);

  const [followingButtonExplore, setFollowingButtonExplore] = useState(false);

  const [isPanningDisabled, setIsPanningDisabled] = useState(false);

  const addedPixelScreenCentre = 2; //fixes the screen being slightly off centre

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu behavior
  };

  // const [innerDivTop, setInnerDivTop] = useState(
  //   -pannableBoxHeight / 2 + window.innerHeight / 2 - addedPixelScreenCentre
  // );

  // const [innerDivLeft, setInnerDivLeft] = useState(
  //   -pannableBoxWidth / 2 + window.innerWidth / 2
  // );

  const calculateElasticOffset = (outerDivDim, innerDivDim, currentOffset) => {
    let offsetCorrection = 0;
    if (currentOffset > 0) {
      offsetCorrection = -currentOffset;
    } else if (currentOffset + innerDivDim < outerDivDim) {
      offsetCorrection = outerDivDim - (currentOffset + innerDivDim);
    }
    return offsetCorrection;
  };

  // useEffect(() => {
  //   console.log("MUHAHAHAHAHA", placeArtefactsTrigger, artefactData);
  //   if (placeArtefactsTrigger) {
  //     // Create a preview artefact at (1000, 1000)
  //     const preview = {
  //       name: artefactData.artefactName,
  //       coordinates: { x: 0, y: 0 },
  //       imageURL: artefactData.imageURL,
  //       type: artefactData.type,
  //     };
  //     setPreviewArtefact(preview);
  //   }
  // }, [placeArtefactsTrigger, artefactData]);

  // useEffect(() => {
  //   console.log("MULALAHAHAHA", placingMode, placeArtefactsTrigger);

  //   if (placingMode && !placeArtefactsTrigger) {
  //     console.log("MULALAHAHAHA 2", placingMode, placeArtefactsTrigger);

  //     // Assuming placingMode is true when you are creating a collection
  //     const preview = {
  //       name: collectionData.collectionName,
  //       coordinates: { x: 0, y: 0 },
  //       // any other attributes
  //     };
  //     setPreviewCollection(preview);
  //   }
  // }, [placingMode, collectionData.collectionName]);

  const [zoomAttempts, setZoomAttempts] = useState(0);
  const [showZoomMessage, setShowZoomMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const preventDefaultZoomActions = (event) => {
      if (
        (event.ctrlKey &&
          (event.key === "+" || event.key === "-" || event.key === "=")) ||
        (event.ctrlKey && event.deltaY)
      ) {
        event.preventDefault(); // Prevent Ctrl + '+' and Ctrl + '-' for zooming
        handleZoomAttempt();
      }
    };

    const preventTouchZoom = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault(); // Prevent pinch-to-zoom
        handleZoomAttempt();
      }
    };

    const handleZoomAttempt = () => {
      setZoomAttempts((prev) => prev + 1);
    };

    window.addEventListener("keydown", preventDefaultZoomActions);
    window.addEventListener("wheel", preventDefaultZoomActions, {
      passive: false,
    });
    window.addEventListener("touchmove", preventTouchZoom, { passive: false });

    return () => {
      window.removeEventListener("keydown", preventDefaultZoomActions);
      window.removeEventListener("wheel", preventDefaultZoomActions);
      window.removeEventListener("touchmove", preventTouchZoom);
    };
  }, []);

  // useEffect(() => {
  //   if (zoomAttempts >= 3) {
  //     setShowZoomMessage(true);
  //     // Optionally reset the count or set a timeout to hide the message again
  //     setTimeout(() => setShowZoomMessage(false), 5000); // Hide message after 5 seconds
  //     setZoomAttempts(0); // Reset the count after showing the message
  //   }
  // }, [zoomAttempts]);

  useEffect(() => {
    if (zoomAttempts >= 3) {
      setShowMessage(true); // Start showing the message
      setShowZoomMessage(true); // Set it to visible and apply the CSS animation

      // Hide message after 5 seconds (with fade out)
      setTimeout(() => {
        setShowZoomMessage(false); // Triggers the fade-out effect
        setTimeout(() => {
          setShowMessage(false); // Completely hides the element after fade-out
        }, 1000); // Wait for the opacity transition to finish
      }, 5000);

      setZoomAttempts(0); // Reset the count after showing the message
    }
  }, [zoomAttempts]);

  useEffect(() => {
    const updateCursorPosition = (event) => {
      const rect = innerDivRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setCursorPosition({ x, y });
    };

    window.addEventListener("mousemove", updateCursorPosition);

    return () => {
      window.removeEventListener("mousemove", updateCursorPosition);
    };
  }, []);

  const [currentTransform, setCurrentTransform] = useState({ top: 0, left: 0 });
  const [isCentering, setIsCentering] = useState(false);

  const centeringTimeoutRef = useRef(null);

  const handleArtefactClick = (x, y, height, width) => {
    // if (isCentering) return;

    // setTimeout(() => {
    //   setArtefactClicked(false);
    // }, 300);

    // Clear any existing centering timeout
    if (centeringTimeoutRef.current) {
      clearTimeout(centeringTimeoutRef.current);
      centeringTimeoutRef.current = null;
    }

    innerDivRef.current.style.transition = "";

    // Reset velocities to prevent interference
    setVelocityX(0);
    setVelocityY(0);
    setCorrectionVelocityX(0);
    setCorrectionVelocityY(0);

    // Calculate the center point of the artefact
    // const artefactCenterY = y + height / 1114.5 / 2; //note: 1114.5 is some arbitrary large number. For some reason, currently height and width in Artefact.js are not what is being stored in the backend. Please fix and make this make sense, this is only a temp working solution
    // const artefactCenterX = x + width / 1114.5 / 2;

    const artefactCenterY = y + height / 1114.5 / 2 + halfBoxHeight / 11230;
    const artefactCenterX = x + width / 1114.5 / 2 + halfBoxWidth / 131230;

    // Calculate the new top-left corner of the view to center the artefact
    const newLeft = -artefactCenterX + window.innerWidth / 2;
    const newTop = -artefactCenterY + window.innerHeight / 2;

    // Apply the transition and set the center position
    const animationDuration = 0.8;
    innerDivRef.current.style.transition = `transform ${animationDuration}s ease-out`; // Adjust the duration as needed
    // setInnerDivTop(artefactCenterY);
    // setInnerDivLeft(artefactCenterX);

    setIsCentering(true);

    setInnerDivTop(newTop);
    setInnerDivLeft(newLeft);

    // Reset the centerScreen state after centering
    centeringTimeoutRef.current = setTimeout(() => {
      innerDivRef.current.style.transition = ""; // Reset the transition property
      setIsCentering(false);
      centeringTimeoutRef.current = null;
    }, animationDuration * 1000); // Adjust the timeout to match the transition duration

    console.log(
      "rahh",
      x,
      y,
      height,
      width,

      artefactCenterX,
      artefactCenterY,
      newLeft,
      newTop
    );

    // setInnerDivLeft(newLeft);
    // setInnerDivTop(newTop);
  };

  const handleTrackpadSwipe = (e) => {
    if (isCentering || isPanningDisabled) return;
    e.preventDefault(); // Prevent the page from scrolling

    // const { deltaX, deltaY } = e;

    // You might want to adjust the sensitivity of the panning by multiplying deltas by a certain factor
    const sensitivity = 1;

    // let newLeft = innerDivLeft - deltaX * sensitivity;
    // let newTop = innerDivTop - deltaY * sensitivity;

    // setInnerDivLeft(newLeft);
    // setInnerDivTop(newTop);

    const deltaX = e.deltaX * sensitivity;
    const deltaY = e.deltaY * sensitivity;

    // Update the positions immediately based on the swipe
    setInnerDivLeft((prevLeft) => prevLeft - deltaX);
    setInnerDivTop((prevTop) => prevTop - deltaY);

    // Immediately apply correction if needed, without waiting for the next animation frame
    applyBoundaryCorrection();
    setIsScreenCentered(false);
  };

  const applyBoundaryCorrection = () => {
    if (isCentering) return;
    if (!outerDivRef.current || !innerDivRef.current) return;

    const outerDivBounds = outerDivRef.current.getBoundingClientRect();
    const innerDivBounds = innerDivRef.current.getBoundingClientRect();

    const offsetXCorrection = calculateElasticOffset(
      outerDivBounds.width,
      innerDivBounds.width,
      innerDivBounds.left - outerDivBounds.left
    );
    const offsetYCorrection = calculateElasticOffset(
      outerDivBounds.height,
      innerDivBounds.height,
      innerDivBounds.top - outerDivBounds.top
    );

    if (Math.abs(offsetXCorrection) > 0 || Math.abs(offsetYCorrection) > 0) {
      // Apply the correction over time to create the elastic effect
      requestAnimationFrame(animateCorrection);
    }
  };

  const animateCorrection = () => {
    if (!outerDivRef.current || !innerDivRef.current) return;

    const outerDivBounds = outerDivRef.current.getBoundingClientRect();
    const innerDivBounds = innerDivRef.current.getBoundingClientRect();

    let offsetXCorrection = calculateElasticOffset(
      outerDivBounds.width,
      innerDivBounds.width,
      innerDivBounds.left - outerDivBounds.left
    );
    let offsetYCorrection = calculateElasticOffset(
      outerDivBounds.height,
      innerDivBounds.height,
      innerDivBounds.top - outerDivBounds.top
    );

    // Apply a fraction of the correction to make it elastic
    setInnerDivLeft((prevLeft) => prevLeft + offsetXCorrection * 0.002);
    setInnerDivTop((prevTop) => prevTop + offsetYCorrection * 0.002);

    // If significant correction is still needed, continue the animation
    if (Math.abs(offsetXCorrection) > 1 || Math.abs(offsetYCorrection) > 1) {
      requestAnimationFrame(animateCorrection);
    }
  };

  const handleMouseDown = async (e) => {
    if (isCentering) return;
    setIsCentering(false);
    e.preventDefault();
    const rect = innerDivRef.current.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    // const gridX = Math.floor(offsetX / cellSize) * cellSize;
    // const gridY = Math.floor(offsetY / cellSize) * cellSize;
    const gridX = Math.floor((offsetX - halfBoxWidth) / cellSize) * cellSize;
    const gridY = Math.floor((offsetY - halfBoxHeight) / cellSize) * cellSize;

    setMouseDownPos({
      x: e.screenX,
      y: e.screenY,
    });

    const initialX = e.clientX;
    const initialY = e.clientY;
    let currentX = initialX;
    let currentY = initialY;
    let initialTop = innerDivTop;
    let initialLeft = innerDivLeft;
    setIsDragging(true);
    const handleMouseMove = (e) => {
      e.preventDefault();
      if (!isDragging) {
        currentX = e.clientX;
        currentY = e.clientY;
        const diffX = currentX - initialX;
        const diffY = currentY - initialY;
        setInnerDivTop(initialTop + diffY);
        setInnerDivLeft(initialLeft + diffX);
      }
    };
    const handleMouseUp = async (e) => {
      // e.preventDefault();
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      setIsDragging(false);
      setVelocityX(currentX - initialX);
      setVelocityY(currentY - initialY);

      if (mouseDownPos) {
        if (e.screenX === mouseDownPos.x && e.screenY === mouseDownPos.y) {
          console.log("case 1", placingMode, placeArtefactsTrigger);
          if (placingMode === true) {
            console.log("case 2", placingMode, placeArtefactsTrigger);

            setPlacingMode(false);
            var coordinates = { x: gridX, y: gridY };
            setCoordinates({ x: gridX, y: gridY });

            console.log("bucks coordinates", coordinates);

            setPreviewArtefact(null);
            setPreviewCollection(null);

            if (placeArtefactsTrigger === true) {
              console.log("case 3", placingMode, placeArtefactsTrigger);

              setPlaceArtefactsTrigger(false);
              console.log("case 4", placingMode, placeArtefactsTrigger);

              console.log("case 5", artefactData);

              const artefactType = artefactData.type; // This should be either 'image' or 'website'

              let content = null;

              switch (artefactType) {
                case "image":
                  content = artefactData.imageURL;
                  break;
                case "website":
                  content = artefactData.websiteURL;
                  break;
                case "text":
                  content = JSON.stringify({
                    heading: artefactData.heading,
                    bodyText: artefactData.bodyText,
                  });
                  break;
                default:
                  console.warn("Unknown artefact type");
                  return;
              }

              let status;
              console.log("bucks coordinates", coordinates);
              console.log(
                "bucks coordinates",
                coordinates.x,
                coordinates.y,
                artefactData,
                artefactData.artefactId,
                content,
                coordinates
              );

              if (artefactData.artefactId) {
                // Update the artefact
                status = await moveArtefact({
                  artefactId: artefactData.artefactId,
                  isPublicView: isPublicView, // Make sure to pass this flag correctly
                  coordx_private: isPublicView ? undefined : coordinates.x, // Set private coordinates only if in private view
                  coordy_private: isPublicView ? undefined : coordinates.y, // Set private coordinates only if in private view
                  coordx_public: isPublicView ? coordinates.x : undefined, // Set public coordinates only if in public view
                  coordy_public: isPublicView ? coordinates.y : undefined, // Set public coordinates only if in public view
                  is_public: artefactData.is_public,
                  content,
                  type: artefactType,
                });
              } else {
                // Create new artefact
                console.log("STEIN create", artefactData);

                status = await createArtefact({
                  currentCollection,
                  nameArtef: artefactData.artefactName,
                  coordinates,
                  content,
                  is_public: artefactData.is_public,
                  type: artefactType,
                  width: artefactData.image_width,
                  height: artefactData.image_height,
                });
              }

              if (status === 200 || status === 201) {
                // Assuming 200 for successful update, 201 for successful creation
                setter(!getter);
                return;
              }
            } else {
              let status;
              console.log(
                "glass",
                collectionData,
                collectionData.collectionName,
                collectionData.collectionId
              );
              if (collectionData.collectionId) {
                console.log("tango create", collectionData, coordinates);
                status = await moveCollection({
                  collectionId: collectionData.collectionId,
                  coordx_private: !isPublicView ? coordinates.x : undefined,
                  coordy_private: !isPublicView ? coordinates.y : undefined,
                  coordx_public: isPublicView ? coordinates.x : undefined,
                  coordy_public: isPublicView ? coordinates.y : undefined,
                  isPublicView,
                  collectionName: collectionData.collectionName, // Assuming this info is in collectionData
                  // Add other necessary fields for updating the collection
                });
              } else {
                status = await createCollection({
                  currentCollection,
                  nameCol: collectionData.collectionName,
                  coordinates,
                });
              }
              if (status === 200 || status === 201) {
                // Assuming 200 for successful update, 201 for successful creation
                setter(!getter);
                return;
              }
            }
          }
        }
      }
    };
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleTouchStart = (e) => {
    if (isCentering) return;

    const touch = e.touches[0];
    const initialX = touch.clientX;
    const initialY = touch.clientY;

    setTouchStartPos({
      x: initialX,
      y: initialY,
      top: innerDivTop,
      left: innerDivLeft,
    });

    setLastTouchTime(Date.now()); // Track the time of touch start
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!isDragging || !touchStartPos) return;

    const touch = e.touches[0];
    const currentX = touch.clientX;
    const currentY = touch.clientY;
    const diffX = currentX - touchStartPos.x;
    const diffY = currentY - touchStartPos.y;

    setInnerDivTop(touchStartPos.top + diffY);
    setInnerDivLeft(touchStartPos.left + diffX);
  };

  const handleTouchEnd = (e) => {
    if (isCentering) return;

    const touchEndTime = Date.now(); // Track the time of touch end
    const timeDiff = touchEndTime - lastTouchTime; // Calculate the time difference

    if (timeDiff > 0 && touchStartPos) {
      const touch = e.changedTouches[0];
      const endX = touch.clientX;
      const endY = touch.clientY;

      const velocityX = (endX - touchStartPos.x) / timeDiff;
      const velocityY = (endY - touchStartPos.y) / timeDiff;

      setVelocityX(velocityX * 100); // Scale velocity for momentum
      setVelocityY(velocityY * 100); // Scale velocity for momentum
    }

    setIsDragging(false);
    setTouchStartPos(null);

    applyBoundaryCorrection(); // Apply boundary correction after touch ends
  };

  const animateToCenter = () => {
    const centerX = -pannableBoxWidth / 2 + window.innerWidth / 2;
    const centerY = -pannableBoxHeight / 2 + window.innerHeight / 2;

    const startTop = innerDivTop;
    const startLeft = innerDivLeft;

    const deltaX = centerX - startLeft;
    const deltaY = centerY - startTop;

    const duration = 800; // duration in milliseconds
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1); // progress between 0 and 1

      // Ease-in-out effect
      const easeInOut =
        progress < 0.5
          ? 4 * progress * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 3) / 2;

      setInnerDivTop(startTop + deltaY * easeInOut);
      setInnerDivLeft(startLeft + deltaX * easeInOut);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        setIsScreenCentered(true);
        setIsPanningDisabled(false); // Re-enable panning after centering
      }
    };

    setIsPanningDisabled(true); // Disable panning during centering
    requestAnimationFrame(animate);
  };

  const [lastTapTime, setLastTapTime] = useState(0);

  const handleDoubleTap = (e) => {
    if (placingMode) return;

    if (e.target === innerDivRef.current || e.target === outerDivRef.current) {
      const currentTime = Date.now();
      const tapGap = currentTime - lastTapTime;

      if (tapGap < 300 && tapGap > 0) {
        // Double-tap detected
        animateToCenter(); // Use the extracted animation function
      }

      setLastTapTime(currentTime);
    }
  };

  const VELOCITY_THRESHOLD = 0.3;
  useEffect(() => {
    innerDivRef.current.style.transform = `translateY(${innerDivTop}px) translateX(${innerDivLeft}px) `;

    if (centerScreen) {
      animateToCenter(); // Use the extracted animation function
      setCenterScreen(false); // Reset the centerScreen state
    }

    if (!isDragging && outerDivRef.current) {
      const outerDivBounds = outerDivRef.current.getBoundingClientRect();
      const innerDivBounds = innerDivRef.current.getBoundingClientRect();

      const offsetXCorrection = calculateElasticOffset(
        outerDivBounds.width,
        innerDivBounds.width,
        innerDivBounds.left - outerDivBounds.left
      );
      const offsetYCorrection = calculateElasticOffset(
        outerDivBounds.height,
        innerDivBounds.height,
        innerDivBounds.top - outerDivBounds.top
      );

      setCorrectionVelocityX(offsetXCorrection);
      setCorrectionVelocityY(offsetYCorrection);
    }

    if (!isDragging) {
      if (
        Math.abs(velocityX) < VELOCITY_THRESHOLD &&
        Math.abs(velocityY) < VELOCITY_THRESHOLD &&
        Math.abs(correctionVelocityX) < VELOCITY_THRESHOLD &&
        Math.abs(correctionVelocityY) < VELOCITY_THRESHOLD
      ) {
        setVelocityX(0);
        setVelocityY(0);
        setCorrectionVelocityX(0);
        setCorrectionVelocityY(0);

        return;
      }
      const animationFrame = requestAnimationFrame(() => {
        setInnerDivTop(
          (prevTop) => prevTop + velocityY * 0.1 + correctionVelocityY * 0.1
        ); // Adjust the correctionVelocity * 0.1 values only for the amount of elasticity
        setInnerDivLeft(
          (prevLeft) => prevLeft + velocityX * 0.1 + correctionVelocityX * 0.1
        );
        setVelocityX((prevVelocityX) => prevVelocityX * 0.9);
        setVelocityY((prevVelocityY) => prevVelocityY * 0.9);
        setCorrectionVelocityX(
          (prevCorrectionVelocityX) => prevCorrectionVelocityX * 0.9
        );
        setCorrectionVelocityY(
          (prevCorrectionVelocityY) => prevCorrectionVelocityY * 0.9
        );
      });
      setIsScreenCentered(false); // This makes the center screen button initially not visible, and only once you pan, then becomes visible. Must be placed here.
      return () => cancelAnimationFrame(animationFrame);
    }
  }, [
    innerDivTop,
    innerDivLeft,
    velocityX,
    velocityY,
    isDragging,
    centerScreen,
  ]);

  const gridCoordinates = [];
  let innerDivWidth;
  let innerDivHeight;

  if (innerDivRef.current) {
    innerDivWidth = innerDivRef.current.clientWidth;
    innerDivHeight = innerDivRef.current.clientHeight;
  }

  for (
    let row = -innerDivHeight / 2;
    row < innerDivHeight / 2;
    row += cellSize
  ) {
    for (
      let col = -innerDivWidth / 2;
      col < innerDivWidth / 2;
      col += cellSize
    ) {
      gridCoordinates.push({ x: col, y: row });
    }
  }

  useEffect(() => {
    // Disable browser back/forward navigation on trackpad swipe
    const preventNavigationGestures = (e) => {
      // Check for trackpad gesture by looking at the event properties
      if (e.ctrlKey || e.metaKey || e.deltaX !== 0) {
        e.preventDefault();
      }
    };

    // Prevent trackpad navigation on wheel event
    window.addEventListener("wheel", preventNavigationGestures, {
      passive: false,
    });

    // Prevent touch gestures like swipe navigation
    const preventTouchNavigation = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    window.addEventListener("touchmove", preventTouchNavigation, {
      passive: false,
    });

    return () => {
      // Cleanup event listeners when the component unmounts
      window.removeEventListener("wheel", preventNavigationGestures);
      window.removeEventListener("touchmove", preventTouchNavigation);
    };
  }, []);

  return (
    <div
      className="no-select"
      ref={outerDivRef}
      onWheel={handleTrackpadSwipe}
      onContextMenu={handleContextMenu}
      onTouchStart={handleDoubleTap}
      style={{
        overflow: "hidden",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div
        className={`fade-no-zoom-message ${
          showZoomMessage ? "show" : ""
        } no-select`}
        style={{ whiteSpace: "nowrap" }}
      >
        No zoom functionality in Explore Page
      </div>

      <div
        className="panning-box no-select"
        ref={innerDivRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{
          width: `${pannableBoxWidth}px`,
          height: `${pannableBoxHeight}px`,
          // background: "red",
          // translateY: `${innerDivTop}px`,
          // translateX: `${innerDivLeft}px`,
          transform: `translate(${innerDivLeft}px, ${innerDivTop}px))`,
          transition: "width 0.5s ease, height 0.5s ease, transform 0.5s ease",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, 100px)",
          gridTemplateRows: "repeat(auto-fill, 100px)",
          backgroundSize: backgroundSize,
          backgroundImage: `radial-gradient(circle, #D3D3D3 1px, rgba(0, 0, 0, 0) 1px)`,
          boxShadow: "0 0 100px 40px white inset",
        }}
      >
        {/* <div
          style={{
            top: `${pannableBoxHeight / 2 - 25}px`, // Center vertically (half of the box height minus half of the red box height)
            left: `${pannableBoxWidth / 2 - 25}px`, // Center horizontally (half of the box width minus half of the red box width)
            position: "absolute",
            width: "50px",
            height: "50px",
            backgroundColor: "red",
          }}
        ></div> */}

        <div style={{ zIndex: "10" }}>
          <ProfileBox
            loggedInUsername={loggedInUsername}
            currentCollection={currentCollection}
            collections={collections}
            og_username={og_username}
            followingButtonExplore={followingButtonExplore}
            sharelinkUsername={null}
            setSharelinkUsername={null}
            pannableBoxHeight={pannableBoxHeight}
            pannableBoxWidth={pannableBoxWidth}
            loggedInFullname={loggedInFullname}
            setLoggedInFullname={setLoggedInFullname}
          />
        </div>
        <ExploreArtefactsList_2
          // artefacts={artefacts}
          redBoxCenterX={pannableBoxWidth / 2}
          redBoxCenterY={pannableBoxHeight / 2}
          coordinates={coordinates}
          cellSize={cellSize}
          // artefacts={artefacts}
          artefacts={adjustedArtefacts}
          setArtefacts={setArtefacts}
          isPublicView={isPublicView}
          setPlaceArtefactsTrigger={setPlaceArtefactsTrigger}
          setPlacingMode={setPlacingMode}
          setArtefactData={setArtefactData}
          setZoomedInFlag={setZoomedInFlag}
          handleCreateButtonClick={handleCreateButtonClick}
          handleArtefactClick={handleArtefactClick}
          // pannableBoxHeight={2500}
          // pannableBoxWidth={3500}
          pannableBoxHeight={pannableBoxHeight}
          pannableBoxWidth={pannableBoxWidth}
          totalItems={totalItems}
          artefactNumber={artefactNumber}
          setArtefactNumber={setArtefactNumber}
          setIsLowConnection={setIsLowConnection}
          isLowConnection={isLowConnection}
        />
      </div>
    </div>
  );
}

export { PannableBox };
