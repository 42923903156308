import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../../css/ConfirmDeleteModal.css";

function ConfirmDeleteModal({
  show,
  onHide,
  onConfirm,
  collectionType,
  collectionId,
}) {
  console.log("Collection Type:", collectionType);
  const message =
    collectionType === "artefacts"
      ? "You will be deleting all artefacts inside this collection."
      : "You will be deleting all collections inside this collection.";

  useEffect(() => {
    if (show) {
      document.body.classList.add("disable-background");
    } else {
      document.body.classList.remove("disable-background");
    }

    return () => {
      document.body.classList.remove("disable-background");
    };
  }, [show]);

  return (
    <div
      className="no-select"
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <Modal
        className="confirm-delete-modal "
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="confirm-delete-modal"
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-confirm-delete">{message}</Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel-button_confirm_delete"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            className="cancel-button_confirm_delete"
            variant="danger"
            onClick={() => {
              onConfirm(); // Call the confirm handler
              onHide(); // Close the modal after confirmation
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConfirmDeleteModal;
