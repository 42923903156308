export const createCollection = async ({
  loggedInUsername,
  currentCollection,
  nameCol,
  coordinates,
}) => {
  // const isRootCollection = currentCollection === null;
  // const apiUrl = isRootCollection
  //   ? "/api/create_root_collection"
  //   : `/api/collection/${currentCollection.collection_id}/create_collection`;

  // // const apiUrl = `/api/user/${props.loggedInUsername}/collection/${id}`; //but id gets replaced with root; new!

  // // Determine if it's a root collection based on the absence of a current collection ID
  // const isRootCollection = !currentCollection;

  // // Build the API URL. If it's a root collection, the API expects the `root` keyword as the collection ID.
  // const collectionId = isRootCollection
  //   ? "root"
  //   : currentCollection.collection_id;
  // const apiUrl = `/api/user/${username}/collection/${collectionId}/create_collection`;

  const collectionId = currentCollection
    ? currentCollection.collection_id
    : "root";

  const apiUrl = `/api/user/${loggedInUsername}/collection/${collectionId}/create_collection`;

  const response = await fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
    body: JSON.stringify({
      collection_name: nameCol,
      // parent_id: isRootCollection ? null : currentCollection.collection_id,
      coordx_private: coordinates.x,
      coordy_private: coordinates.y,
    }),
  });
  return response.status;
};
