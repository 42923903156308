import { CSSTransition } from "react-transition-group";
import "../css/Transition.css"; // Import your CSS for transitions

const FadeTransition = ({ children, in: inProp }) => (
  <CSSTransition in={inProp} timeout={3000} classNames="fade" unmountOnExit>
    {children}
  </CSSTransition>
);

export default FadeTransition;
